import { atom } from 'recoil';

const staticCompanyCollections = {
  TOP_TRADED: {
    key: '35041338-abd5-11ed-a867-1289b1cd9f57',
  },
  MOST_POPULAR: {
    key: '87a2f681-ac52-11ed-a867-1289b1cd9f57',
  },
};

const companyCollectionsState = atom({
  key: 'companyCollectionsState',
  default: undefined,
});

const activeCompanyCollectionState = atom({
  key: 'activeCompanyCollectionState',
  default: staticCompanyCollections.TOP_TRADED.key,
});

export {
  companyCollectionsState,
  activeCompanyCollectionState,
  staticCompanyCollections,
};
