import { useEffect, useState } from 'react';

import { APIListingURL } from './_api-urls';
import { companyCommunityStatsState } from '../_state';
import { useFetchWrapper } from '../_helpers';
import { useRecoilState } from 'recoil';

export function useGetCommunityStats(listingKey='') {
  const [communityStats, setCommunityStats] = useRecoilState(companyCommunityStatsState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    function abort(){ controller.abort();}

    if (Object.keys(communityStats).length > 0){
      setLoaded(true);
      return abort;
    }

    const url = `${APIListingURL(listingKey)}/community`;
    fetchWrapper.get(url, null, signal)
      .then((response) => {
        if (!response) return; // aborted request gives response === undefined
        setCommunityStats(response);
        setLoaded(true);
      });
    return abort;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingKey, communityStats]);

  return { loaded };
}
