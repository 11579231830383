import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import styles from './styles';

const ConfirmationDialog = (props) => {
  const {
    canCancel,
    content,
    ctaCancel,
    ctaDisabled,
    ctaConfirm,
    maxWidth,
    onCancel,
    onConfirm,
    open,
    saving,
    startIcon,
    endIcon,
    title,
    titleIcon,
  } = props;

  return (
    <Dialog
      open={open}
      maxWidth={maxWidth}
      PaperProps={{
        sx: styles.DialogPaper
      }}
    >
      <DialogTitle>
        <Stack spacing={2} sx={styles.Title}>
          <Box sx={styles.TitleIcon}>{titleIcon}</Box>
          <Typography variant='h3' color='primary'>{title}</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Typography component='div' sx={styles.Content}>{content}</Typography>
      </DialogContent>
      <DialogActions sx={styles.DialogActions}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={canCancel ? 6 : 12}>
            <LoadingButton
              loading={saving}
              variant='contained'
              size='large'
              startIcon={startIcon}
              endIcon={endIcon}
              onClick={onConfirm}
              disabled={ctaDisabled}
              color='primary'
              sx={styles.Button}
            >
              {ctaConfirm}
            </LoadingButton>
          </Grid>
          {canCancel && (
            <Grid item xs={12} sm={6}>
              <Button
                variant='outlined'
                size='large'
                onClick={onCancel}
                disabled={saving}
                color='primary'
                sx={styles.Button}
              >
                {ctaCancel}
              </Button>
            </Grid>
          )}
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.defaultProps = {
  canCancel: true,
  content: '',
  ctaCancel: 'Cancel',
  ctaDisabled: false,
  ctaConfirm: 'Confirm',
  maxWidth: 'sm',
  onCancel: () => { return; },
  onConfirm: () => { return; },
  open: false,
  saving: false,
  startIcon: false,
  endIcon: false,
  title: '',
  titleIcon: '',
};

ConfirmationDialog.propTypes = {
  canCancel: PropTypes.bool,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  ctaCancel: PropTypes.string,
  ctaConfirm: PropTypes.string,
  ctaDisabled: PropTypes.bool,
  maxWidth: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
  saving: PropTypes.bool,
  startIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  endIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  titleIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default ConfirmationDialog;
