const styles = {
  ChartWrapper: {
    // Hacks to get the ResponsiveContainer to respond properly
    // when nested inside a flexbox
    flex: 1,
    minWidth: 0,
    '.recharts-wrapper, .recharts-surface': {
      width: '100%!important'
    }
  },
  TooltipWrapper: {
    outline: 'none'
  },
  Tooltip: ({ palette }) => ({
    backgroundColor: palette.common.white,
    borderRadius: '0.625rem',
    border: `1px solid ${palette.primary.main}`,
    padding: '0.25rem 0.5rem',
  })
};

export default styles;
