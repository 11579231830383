export const STF_TRANSACTION_FEE = 0.01;

export const TEN_MEGABYTES = 10485760;

export const YES_NO = [
  {
    id: 1,
    value: true,
    label: 'Yes'
  },
  {
    id: 2,
    value: false,
    label: 'No'
  }
];

export const SECTORS = {
  'ACCOMMODATIONS': 'Accommodations',
  'ACCOUNTING': 'Accounting',
  'ADVERTISING': 'Advertising',
  'AEROSPACE': 'Aerospace',
  'AGRICULTURE': 'Agriculture',
  'AGRICULTURE_AGRIBUSINESS': 'Agriculture & Agribusiness',
  'AIRPLANES_AND_AIRPORTS': 'Airplanes & Airports',
  'AIR_TRANSPORTATION': 'Air Transportation',
  'APPAREL_ACCESSORIES': 'Apparel & Accessories',
  'AUTO': 'Auto',
  'BANKING': 'Banking',
  'BEAUTY_COSMETICS': 'Beauty & Cosmetics',
  'BIOTECHNOLOGY': 'Biotechnology',
  'BUSINESS_SERVICES': 'Business Services',
  'CHEMICAL': 'Chemical',
  'COAL_MINING': 'Coal Mining',
  'COMMERCIAL': 'Commercial',
  'COMMERCIAL_BANKING': 'Commercial Banking',
  'COMMUNICATIONS': 'Communications',
  'COMPUTER': 'Computer',
  'COMPUTERS': 'Computers',
  'CONSTRUCTION': 'Construction',
  'CONSULTING': 'Consulting',
  'CONSUMER_PRODUCTS': 'Consumer Products',
  'EDUCATION': 'Education',
  'ELECTRIC_UTILITIES': 'Electric Utilities',
  'ELECTRONICS': 'Electronics',
  'EMPLOYMENT': 'Employment',
  'ENERGY': 'Energy',
  'ENERGY_CONSERVATION': 'Energy Conservation',
  'ENTERTAINMENT_RECREATION': 'Entertainment & Recreation',
  'ENVIRONMENTAL_SERVICES': 'Environmental Services',
  'FASHION': 'Fashion',
  'FINANCIAL_SERVICES': 'Financial Services',
  'FOOD_BEVERAGE': 'Food & Beverage',
  'HEALTH': 'Health',
  'HEALTH_INSURANCE': 'Health Insurance',
  'HEDGE_FUND': 'Hedge Fund',
  'HOSPITALS_AND_PHYSICIANS': 'Hospitals & Physicians',
  'INFORMATION': 'Information',
  'INFORMATION_TECHNOLOGY': 'Information Technology',
  'INSURANCE': 'Insurance',
  'INVESTMENT_BANKING': 'Investment Banking',
  'JOURNALISM_NEWS': 'Journalism & News',
  'LEGAL_SERVICES': 'Legal Services',
  'LODGING_AND_CONVENTIONS': 'Lodging & Conventions',
  'MANUFACTURING': 'Manufacturing',
  'MEDIA_BROADCASTING': 'Media & Broadcasting',
  'MEDICAL_DEVICES_SUPPLIES': 'Medical Devices & Supplies',
  'MOTION_PICTURES_VIDEO': 'Motion Pictures & Video',
  'MUSIC': 'Music',
  'OIL_AND_GAS': 'Oil & Gas',
  'OTHER': 'Other',
  'OTHER_BANKING': 'Other Banking & Financial Services',
  'OTHER_ENERGY': 'Other Energy',
  'OTHER_HEALTH_CARE': 'Other Health Care',
  'OTHER_INVESTMENT_FUND': 'Other Investment Fund',
  'OTHER_REAL_ESTATE': 'Other Real Estate',
  'OTHER_TECHNOLOGY': 'Other Technology',
  'OTHER_TRAVEL': 'Other Travel',
  'PHARMACEUTICAL': 'Pharmaceutical',
  'PHARMACEUTICALS': 'Pharmaceuticals',
  'POOLED_INVESTMENT_FUND': 'Pooled Investment Fund',
  'PRIVATE_EQUITY_FUND': 'Private Equity Fund',
  'PUBLIC_ADMINISTRATION': 'Public Administration',
  'PUBLIC_RELATIONS': 'Public Relations',
  'PUBLISHING': 'Publishing',
  'REAL_ESTATE': 'Real Estate',
  'REITS_AND_FINANCE': 'REITS & Finance',
  'RESIDENTIAL': 'Residential',
  'RESTAURANTS': 'Restaurants',
  'RETAIL': 'Retail',
  'RETAILING': 'Retailing',
  'SERVICE': 'Service',
  'SPORTS': 'Sports',
  'TECHNOLOGY': 'Technology',
  'TELECOMMUNICATIONS': 'Telecommunications',
  'TOURISM': 'Tourism',
  'TOURISM_AND_TRAVEL_SERVICES': 'Tourism & Travel Services',
  'TRANSPORTATION': 'Transportation',
  'TRAVEL': 'Travel',
  'UTILITIES': 'Utilities',
  'VENTURE_CAPITAL_FUND': 'Venture Capital Fund',
  'VIDEO_GAME': 'Video Game',
  'WEB_SERVICES': 'Web Services'
};

export const OFFER_STATUSES = {
  'PUBLISHED': 'Pending',
  'FAILED': 'Cancelled',
  'FULFILLED': 'Fulfilled',
};

export const PRIMARY_ASK_STATUSES = {
  'PUBLISHED': 'Pending',
  'FAILED': 'Failed',
  'FULFILLED': 'Closed',
  'POST_PROCESSING': 'Closed'
};

export const MARKETS_CARD = {
  'PRIMARY': 'Primary Market',
  'SECONDARY': 'Secondary Market',
  'ADMINISTRATIVE_TRANSFER': 'Admin. Transfer',
  'CORPORATE_ACTION': 'Corporate Action'
};

export const MARKETS_TAB = {
  'PRIMARY': 'Primary',
  'SECONDARY': 'Secondary',
  'ADMINISTRATIVE_TRANSFER': 'Admin. Transfer',
  'CORPORATE_ACTION': 'Corporate Action'
};

export const EXEMPTIONS = {
  '4_A_6': {
    enabled: true,
    explanation: '4(a)(6) Exemption is available for resale',
  },
  '506_C': {
    enabled: false,
    explanation: '506(C) Exemption is not available for resale',
  },
  '506_B': {
    enabled: false,
    explanation: '506(B) Exemption is not available for resale',
  },
};

export const NOTIFICATIONS = {
  'COMPLETE_PROFILE': {
    title: 'Complete Your User Profile',
    severity: 'info',
    message: 'Your user profile must be completed before you can start trading.',
    cta: 'Complete Profile',
  },
  'RETRY_VERIFICATION': {
    title: 'Verify Your Identity',
    severity: 'error',
    message: 'Verify your identifying information',
    cta: 'Review',
  },
  'REAUTH_BANK_ACC': {
    title: 'Reauthenticate Your Bank Account',
    severity: 'warning',
    message: 'We are having trouble reaching your bank account',
    cta: 'Reauthenticate',
  },
  'CONFIRM_MICRO_DEPOSITS': {
    title: 'Confirm Micro-Deposits',
    severity: 'warning',
    message: 'Finish connecting your bank account',
    cta: 'Confirm Micro-Deposits',
  },
  'DOCUMENT_NEEDED_VERIFICATION': {
    title: 'Verify Your Identity',
    severity: 'warning',
    message: 'Document needed for identity verification',
    cta: 'Upload Your ID',
  },
  'CONFIRM_MATERIAL_CHANGE': {
    title: 'Confirm Material Change',
    severity: 'warning',
    message: 'Attention: Reconfirm your investment commitment',
    cta: 'Respond',
  },
  'TIN_MISSING': {
    title: 'Tax Information Needed',
    severity: 'error',
    message: 'Verify your identifying information',
    cta: 'Review Now',
  },
  'KYC_CALL_TO_ACTION': {
    title: 'Your investor profile requires attention',
    severity: 'error',
    message: undefined,
    cta: 'Review Now',
  },
};

export const RESIDENCY_STATUSES = {
  'US_CITIZEN': 'US Citizen',
  'US_RESIDENT': 'US Resident',
  'OTHER': 'Other',
};

export const INCOME_NETWORTH_OPTIONS = [
  {
    value: 0,
    label: 'Less than $40,000'
  },
  {
    value: 4000000,
    label: '$40,000 - $50,000'
  },
  {
    value: 5000000,
    label: '$50,001 - $60,000'
  },
  {
    value: 6000000,
    label: '$60,001 - $70,000'
  },
  {
    value: 7000000,
    label: '$70,001 - $80,000'
  },
  {
    value: 8000000,
    label: '$80,001 - $90,000'
  },
  {
    value: 9000000,
    label: '$90,001 - $100,000'
  },
  {
    value: 10000000,
    label: '$100,001 - $107,000'
  },
  {
    value: 10700000,
    label: '$107,001 - $120,000'
  },
  {
    value: 12000000,
    label: '$120,001 - $124,000'
  },
  {
    value: 12400000,
    label: '$124,001 - $150,000'
  },
  {
    value: 15000000,
    label: '$150,001 - $200,000'
  },
  {
    value: 20000000,
    label: '$200,001 - $250,000'
  },
  {
    value: 25000000,
    label: '$250,001 - $300,000'
  },
  {
    value: 30000000,
    label: '$300,001 - $400,000'
  },
  {
    value: 40000000,
    label: '$400,001 - $500,000'
  },
  {
    value: 50000000,
    label: '$500,001 - $600,000'
  },
  {
    value: 60000000,
    label: '$600,001 - $700,000'
  },
  {
    value: 70000000,
    label: '$700,001 - $800,000'
  },
  {
    value: 80000000,
    label: '$800,001 - $900,000'
  },
  {
    value: 90000000,
    label: '$900,001 - $1,000,000'
  },
  {
    value: 100000000,
    label: '$1,000,001 - $1,070,000'
  },
  {
    value: 107000000,
    label: '$1,070,001 - $1,240,000'
  },
  {
    value: 124000000,
    label: '$1,240,001 - $5,000,000'
  },
  {
    value: 500000000,
    label: '$5,000,000 or more'
  },
];

export const INCOME_TEMPLUM_OPTIONS = [
  {
    value: 0,
    label: 'Under $50,000',
  },
  {
    value: 5000000,
    label: '$50,001 - $100,000',
  },
  {
    value: 10000000,
    label: '$100,001 - $150,000',
  },
  {
    value: 15000000,
    label: '$150,001 - $200,000',
  },
  {
    value: 20000000,
    label: 'Over $200,000',
  },
];

export const NETWORTH_TEMPLUM_OPTIONS = [
  {
    value: 5000000,
    label: '$50,000 - $100,000',
  },
  {
    value: 10000000,
    label: '$100,001 - $250,000',
  },
  {
    value: 25000000,
    label: '$250,001 - $500,000',
  },
  {
    value: 50000000,
    label: '$500,001 - $750,000',
  },
  {
    value: 75000000,
    label: '$750,001 - $1,000,000',
  },
  {
    value: 100000000,
    label: 'Over $1,000,000',
  },
];

export const EXPENSES_TEMPLUM_OPTIONS = [
  {
    value: 0,
    label: '$50,000 and under',
  },
  {
    value: 5000000,
    label: '$50,001 - $100,000',
  },
  {
    value: 10000000,
    label: '$100,001 - $250,000',
  },
  {
    value: 25000000,
    label: '$250,001 - $500,000',
  },
  {
    value: 50000000,
    label: 'Over $500,000',
  },
];

export const VERIFICATION_DOC_OPTIONS = [
  {
    value: 'PASSPORT',
    label: 'Government Issued Passport',
  },
  {
    value: 'LICENSE',
    label: 'Driver’s License',
  },
  {
    value: 'ID_CARD',
    label: 'Government I.D. Card',
  },
];

export const ALL_VERIFICATION_DOC_OPTIONS = [
  ...VERIFICATION_DOC_OPTIONS,
  {
    value: 'SUPPORTING_DOC_1',
    label: 'Other',
  }
];

export const VERIFICATION_DOC_FILES = {
  'LICENSE': [
    {usage: 'LICENSE', label: 'Driver‘s License (Front)'},
    {usage: 'LICENSE_BACK', label: 'Driver‘s License (Back)'},
  ],
  'ID_CARD': [
    {usage: 'ID_CARD', label: 'ID Card (Front)'},
    {usage: 'ID_CARD_BACK', label: 'ID Card (Back)'}
  ],
  'PASSPORT': [
    {usage: 'PASSPORT', label: 'Passport'},
  ],
};

export const VERIFICATION_DOC_FILE_USAGES = Object.keys(VERIFICATION_DOC_FILES).map(docType => VERIFICATION_DOC_FILES[docType].map(type => type.usage)).flat();

export const VERIFICATION_DOC_FILE_TYPES = {
  contentTypes: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'],
  fileTypes: ['JPEG', 'PNG', 'PDF'],
};

export const AUTHORIZATION_DOC_OPTIONS = [
  {
    value: 'SUPPORTING_DOC_1',
    label: 'Document One',
  },
  {
    value: 'SUPPORTING_DOC_2',
    label: 'Document Two',
  },
];

export const AUTHORIZATION_DOC_FILE_TYPES = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];

export const HOLDING_ENTITY_TYPES = [
  {
    value: 'TRUST',
    label: 'Trust',
  },
  {
    value: 'LP',
    label: 'Limited Partnership',
  },
  {
    value: 'LLC',
    label: 'Limited Liability Company',
  },
  {
    value: 'S_CORP',
    label: 'S Corp',
  },
  {
    value: 'C_CORP',
    label: 'C Corp',
  },
  {
    value: 'OTHER',
    label: 'Other',
  },
];

export const HOLDING_ENTITY_TRUST_AUTHORITY_TYPES = [
  {
    value: 'TRUSTEE',
    label: 'Trustee Only',
  },
  {
    value: 'TRUSTEE_GRANTOR',
    label: 'Trustee and Grantor',
  },
  {
    value: 'APPOINT_REMOVE_TRUSTEES',
    label: 'Authority to Appoint/Remove Trustees',
  },
  {
    value: 'AMEND_TRUST',
    label: 'Authority to Amend Trust',
  }
];

export const HOLDING_ENTITY_DOCS_TEMPLUM = [
  {
    value: 'CERTIFICATE_OF_INCORPORATION',
    label: 'Articles of Incorporation *',
  },
  {
    value: 'CERTIFICATE_OF_FORMATION',
    label: 'Certificate of Formation *',
  },
  {
    value: 'OPERATING_AGREEMENT',
    label: 'Operating Agreement *',
  },
  {
    value: 'TRUST_DOCUMENT',
    label: 'Trust Document *',
  },
  {
    value: 'EIN_EVIDENCE',
    label: 'Evidence of EIN *',
  },
];

export const HOLDING_ENTITY_DOC_TEMPLUM_FILE_TYPES = [
  'application/pdf',
  'image/*',
  '.doc',
  '.docx',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
];

export const PERSON_TITLES = [
  {
    value: 'MR',
    label: 'Mr.'
  },
  {
    value: 'MRS',
    label: 'Mrs.'
  },
  {
    value: 'MS',
    label: 'Ms.'
  },
  {
    value: 'DR',
    label: 'Dr.'
  },
  {
    value: 'OTHER',
    label: 'Other'
  },
];

export const PERSON_EMPLOYMENT_STATUSES = [
  {
    value: 'EMPLOYED',
    label: 'Employed',
  },
  {
    value: 'UNEMPLOYED',
    label: 'Unemployed',
  },
  {
    value: 'SELF_EMPLOYED',
    label: 'Self Employed',
  },
  {
    value: 'STUDENT',
    label: 'Student',
  },
  {
    value: 'RETIRED',
    label: 'Retired',
  },
];

export const RISK_TOLERANCES = [
  {
    label: 'Conservative',
    value: 'CONSERVATIVE',
  },
  {
    label: 'Moderately Conservative',
    value: 'MODERATELY_CONSERVATIVE',
  },
  {
    label: 'Moderate',
    value: 'MODERATE',
  },
  {
    label: 'Moderately Aggressive',
    value: 'MODERATELY_AGGRESSIVE',
  },
  {
    label: 'Aggressive',
    value: 'AGGRESSIVE',
  },
];

export const INVESTMENT_DECISIONS = [
  {
    value: 'SOLELY',
    label: 'I generally make my own decisions and/or consult with my co-applicants.',
  },
  {
    value: 'WITH_FINANCIAL_PROFESSIONAL',
    label: 'I consult with my broker, investment advisor, CPA, or other financial professional.',
  },
  {
    value: 'WITH_FAMILY_FRIENDS',
    label: 'I discuss investment decisions with my family and/or friends.',
  },
];

export const LIQUIDITY_NEEDS = [
  {
    value: 'VERY_IMPORTANT',
    label: 'Very Important',
  },
  {
    value: 'IMPORTANT',
    label: 'Important',
  },
  {
    value: 'SOMEWHAT_IMPORTANT',
    label: 'Somewhat Important',
  },
  {
    value: 'NOT_IMPORTANT',
    label: 'Not Important',
  },
];

export const INVESTMENT_FUNDING_SOURCES = {
  INDIVIDUAL: [
    {
      value: 'INCOME',
      label: 'Income',
    },
    {
      value: 'SAVINGS',
      label: 'Pension or retirement savings',
    },
    {
      value: 'ANOTHER_SOURCE',
      label: 'Funds from another source',
    },
    {
      value: 'GIFT',
      label: 'Gift',
    },
    {
      value: 'BUSINESS_PROPERTY_SALE',
      label: 'Sale of business or property',
    },
    {
      value: 'INSURANCE_PAYOUT',
      label: 'Insurance payout',
    },
    {
      value: 'INHERITANCE',
      label: 'Inheritance',
    },
    {
      value: 'SOCIAL_SECURITY_BENEFITS',
      label: 'Social Security benefits',
    },
    {
      value: 'HELOC',
      label: 'Home Equity Line of Credit (HELOC)',
    },
    {
      value: 'OTHER',
      label: 'Other',
    },
  ],
  HOLDING_ENTITY: [
    {
      value: 'INCOME',
      label: 'Salary/Wages/Savings',
    },
    {
      value: 'CORPORATE_INCOME',
      label: 'Corporate Income',
    },
    {
      value: 'WORKING_CAPITAL',
      label: 'Working Capital',
    },
    {
      value: 'FAMILY_RELATIVES_INHERITANCE',
      label: 'Family/Relatives/Inheritance',
    },
    {
      value: 'INVESTMENT_CAPITAL_GAINS',
      label: 'Investment capital gains',
    },
    {
      value: 'SALES_PROPERTY',
      label: 'Sale(s) of property(ies)',
    },
  ],
};

export const TAX_FILING_STATUS = [
  {
    value: 'SINGLE',
    label: 'Single',
  },
  {
    value: 'MARRIED_FILING_JOINTLY',
    label: 'Married filing jointly',
  },
  {
    value: 'MARRIED_FILING_SEPARATELY',
    label: 'Married filing separately',
  },
  {
    value: 'HEAD_OF_HOUSEHOLD',
    label: 'Head of household',
  },
  {
    value: 'QUALIFYING_WIDOW_DEPENDENT_CHILD',
    label: 'Qualifying widow(er) w/ dependent child',
  },
  {
    value: 'NON_PROFIT_ORG',
    label: 'Not-for-profit organization',
  },
  {
    value: 'NON_US_RESIDENT',
    label: 'Non-U.S. resident',
  },
  {
    value: 'OTHER',
    label: 'Otherwise not subject to U.S. taxes',
  },
];

export const ACCREDITED_INVESTOR_CRITERIA = {
  INDIVIDUAL: [
    {
      value: 'NET_WORTH',
      label: 'My net worth (either individually or combined with my spouse) exceeds $1m (excluding the value of my/our primary residence)',
    },
    {
      value: 'INDIVIDUAL_INCOME',
      label: 'My annual income has exceeded $200,000 for the past 2 years (and I expect this level of income to continue)',
    },
    {
      value: 'JOINT_INCOME',
      label: 'Combined with my spouse, our annual income has exceeded $300,000 for the past 2 years (and I/we expect this level of income to continue)',
    },
    {
      value: 'FINRA_LICENSE',
      label: 'I hold a FINRA Series 7, 65, and/or 82 license that is in good standing',
    },
  ],
  HOLDING_ENTITY: [
    {
      value: 'ALL_OWNERS_ACCREDITED',
      label: 'All of the entity’s equity owners are accredited investors.',
    },
    {
      value: 'NET_WORTH',
      label: 'The entity is a corporation, trust, LLC, partnership, or similar entity that has assets exceeding $5 million.',
    },
  ]
};

export const QUALIFIED_PURCHASER_CRITERIA = {
  INDIVIDUAL: [
    {
      id: 1,
      value: true,
      label: 'Yes (I have at least $5m in investments)',
    },
    {
      id: 2,
      value: false,
      label: 'No (I do not have at least $5m in investments)',
    },
  ],
  HOLDING_ENTITY: [
    {
      id: 1,
      value: true,
      label: 'Yes (the entity’s assets exceed $5m)',
    },
    {
      id: 2,
      value: false,
      label: 'No (the entity’s assets do not exceed $5m)',
    },
  ]
};

export const INVESTMENT_OBJECTIVES = [
  {
    value: 'INCOME',
    label: 'Generate income',
  },
  {
    value: 'CAPITAL_APPRECIATION',
    label: 'Capital appreciation',
  },
  {
    value: 'PARTIAL_FUND_RETIREMENT',
    label: 'Partially fund retirement',
  },
  {
    value: 'WHOLE_FUND_RETIREMENT',
    label: 'Wholly fund retirement',
  },
  {
    value: 'CAPITAL_PRESERVATION',
    label: 'Preservation of capital',
  },
  {
    value: 'EDUCATION',
    label: 'Pay for education',
  },
  {
    value: 'HOUSE',
    label: 'Pay for a house',
  },
  {
    value: 'SPECULATION',
    label: 'Market speculation',
  },
];

export const ASSET_TYPES = [
  {
    value: 'EQUITY',
    label: 'Equity Securities',
  },
  {
    value: 'DEBT',
    label: 'Debt Securities',
  },
  {
    value: 'CREDIT',
    label: 'Credit Instruments',
  },
  {
    value: 'LIMITED_PARTNERSHIP',
    label: 'Limited Partnership interests',
  },
  {
    value: 'NO_EXPERIENCE',
    label: 'I have not invested previously',
  },
];

export const INDUSTRY_EXPERIENCE = [
  {
    value: 'TECHNOLOGY',
    label: 'Technology',
  },
  {
    value: 'CONSUMER_GOODS',
    label: 'Consumer Goods',
  },
  {
    value: 'FINANCIAL_SERVICES',
    label: 'Financial Services',
  },
  {
    value: 'HEALTHCARE',
    label: 'Healthcare',
  },
  {
    value: 'INDUSTRIAL',
    label: 'Industrial',
  },
  {
    value: 'ENERGY',
    label: 'Energy',
  },
  {
    value: 'REAL_ESTATE',
    label: 'Real Estate',
  },
  {
    value: 'AGRICULTURE',
    label: 'Agriculture',
  },
  {
    value: 'INFRASTRUCTURE',
    label: 'Infrastructure',
  },
  {
    value: 'SOCIAL_IMPACT',
    label: 'Social Impact',
  },
  {
    value: 'ALTERNATIVES',
    label: 'Alternatives',
  },
  {
    value: 'ENTERTAINMENT',
    label: 'Entertainment',
  },
];

export const GOVERNMENT_BRANCHES = [
  {
    value: 'EXECUTIVE',
    label: 'Executive',
  },
  {
    value: 'LEGISLATIVE',
    label: 'Legislative',
  },
  {
    value: 'JUDICIAL',
    label: 'Judicial',
  },
  {
    value: 'ADMINISTRATIVE',
    label: 'Administrative',
  },
  {
    value: 'MILITARY',
    label: 'Military',
  },
  {
    value: 'SENIOR_OFFICIAL_OF_A_MAJOR_POLITICAL_PARTY',
    label: 'Senior Offical of a Major Political Party',
  },
  {
    value: 'SENIOR_EXECUTIVE_OF_A_FOREIGN_GOVERNMENT_OWNED_CORPORATION',
    label: 'Senior Executive of a Foreign Government-owned Corporation',
  },
];

export const SOURCES_OF_WEALTH = [
  {
    value: 'INCOME',
    label: 'Income from employment',
  },
  {
    value: 'INHERITANCE',
    label: 'Family inheritance',
  },
  {
    value: 'INVESTMENT',
    label: 'Investments',
  },
  {
    value: 'BUSINESS_OWNERSHIP',
    label: 'Business Ownership',
  },
];
