import { Paper } from '@mui/material';
import PropTypes from 'prop-types';
import styles from './styles';

const Card = ({sx, children, elevation, ...other}) => {
  let classes = [];

  if (Array.isArray(sx)){
    classes = [styles.Card, ...sx];
  }else{
    classes = [styles.Card, sx];
  }

  return (
    <Paper elevation={elevation} square={false} {...other} sx={classes}>
      {children}
    </Paper>
  );
};

Card.defaultProps = {
  sx: () => { return; },
  elevation: 1,
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.func)]),
  elevation: PropTypes.number,
};

export default Card;
