import { Button, Grid, Link, List, ListItem, Stack, Typography } from '@mui/material';
import { fetchHoldingEntityInfoAttribute, templumFormKycStatusState } from '../../../_state';
import { useCallback, useEffect, useState } from 'react';
import { useGetTemplumFormKycStatus, useSubmitTemplumKyc } from '../../../_actions/kyc-templum.actions';

import CheckIcon from '@mui/icons-material/Check';
import Checkbox from '../../../netcapital-components/Checkbox';
import CircularLoading from '../../../netcapital-components/CircularLoading';
import ConfirmationDialog from '../../../netcapital-components/ConfirmationDialog';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import { useGetHoldingEntityInfo } from '../../../_actions/holding-entities.actions';
import { useRecoilValue } from 'recoil';

const KycDisclosures = ({ holdingEntityKey }) => {
  const {loaded: loadedKycStatus, refreshData: refreshKycStatus} = useGetTemplumFormKycStatus(holdingEntityKey);
  const kycStatus = useRecoilValue(templumFormKycStatusState);
  const {loaded: loadedHoldingEntityInfo} = useGetHoldingEntityInfo(holdingEntityKey);
  const entityType = useRecoilValue(fetchHoldingEntityInfoAttribute('type'));

  const [termsOfServiceTemplum, setTermsOfServiceTemplum] = useState(false);
  const [netcapitalAgreement, setNetcapitalAgreement] = useState(false);
  const [trustAgreement, setTrustAgreement] = useState(false);
  const [isAuthorizedForEntity, setIsAuthorizedForEntity] = useState(false);
  const [isAccurate, setIsAccurate] = useState(false);

  const handleChangeTermsOfServiceTemplum = useCallback((e) => setTermsOfServiceTemplum(e.target.checked), [setTermsOfServiceTemplum]);
  const handleChangeNetcapitalAgreement = useCallback((e) => setNetcapitalAgreement(e.target.checked), [setNetcapitalAgreement]);
  const handleChangeTrustAgreement = useCallback((e) => setTrustAgreement(e.target.checked), [setTrustAgreement]);
  const handleChangeIsAuthorizedForEntity = useCallback((e) => setIsAuthorizedForEntity(e.target.checked), [setIsAuthorizedForEntity]);
  const handleChangeIsAccurate = useCallback((e) => setIsAccurate(e.target.checked), [setIsAccurate]);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const toggleConfirmModal = useCallback(() => setConfirmModalOpen(!confirmModalOpen), [confirmModalOpen]);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const toggleSuccessModal = useCallback(() => setSuccessModalOpen(!successModalOpen), [successModalOpen]);

  const hasAlreadySubmit = ['KYC_UNDER_REVIEW', 'KYC_ACCEPTED'].includes(kycStatus?.status);
  const [canSubmit, setCanSubmit] = useState(false);
  const [saved, setSaved] = useState(false);
  const {save, saving} = useSubmitTemplumKyc(holdingEntityKey);
  const handleSuccess = useCallback(() => {
    refreshKycStatus();
    setSaved(true);
    toggleConfirmModal();
    toggleSuccessModal();
  }, [refreshKycStatus, toggleConfirmModal, toggleSuccessModal]);
  const handleConfirmSubmit = useCallback(() => {
    save(handleSuccess);
  }, [handleSuccess, save]);

  useEffect(() => {
    const isComplete = kycStatus?.completion === 100;
    const hasAgreedToTerms = Boolean(
      netcapitalAgreement
      && termsOfServiceTemplum
      && (holdingEntityKey ? isAuthorizedForEntity : true)
      && (holdingEntityKey && entityType === 'TRUST' ? trustAgreement : true)
      && isAccurate
    );

    setCanSubmit(isComplete && !hasAlreadySubmit && hasAgreedToTerms);
  }, [netcapitalAgreement, termsOfServiceTemplum, trustAgreement, isAuthorizedForEntity, isAccurate, kycStatus, hasAlreadySubmit, holdingEntityKey, entityType]);

  if (!loadedKycStatus || !loadedHoldingEntityInfo) return <CircularLoading />;

  return (
    <Grid item container rowSpacing={4}>
      <Grid item xs={12}>
        <Stack spacing={2}>
          <Typography variant='caption'>I have received, understand, and agree to the following: (please select the following checkboxes)</Typography>
          <Checkbox
            label={<>I have read and agree to the Netcapital: <Link href={`${process.env.REACT_APP_WEB_HOST}/terms`} target='_blank' rel='noopener'>Terms of Service</Link>, <Link href={`${process.env.REACT_APP_WEB_HOST}/privacy`} target='_blank' rel='noopener'>Privacy Policy</Link>, <Link href={`${process.env.REACT_APP_WEB_HOST}/legal/copyright`} target='_blank' rel='noopener'>Copyright Policy</Link>, <Link href={`${process.env.REACT_APP_WEB_HOST}/legal/arbitration`} target='_blank' rel='noopener'>Arbitration Agreement</Link>, <Link href={`${process.env.REACT_APP_WEB_HOST}/legal/record-owner`} target='_blank' rel='noopener'>Record Ownership and Voting Agreement</Link>, <Link href={`${process.env.REACT_APP_WEB_HOST}/legal/issuer`} target='_blank' rel='noopener'>Issuer Agreement</Link>, <Link href={`${process.env.REACT_APP_WEB_HOST}/legal/investor`} target='_blank' rel='noopener'>Master Investor Agreement</Link>, <Link href={`${process.env.REACT_APP_CDN_PDF}/legal/Transfer-Agent-Agreement_FORM.pdf`} target='_blank' rel='noopener'>Transfer Agent Agreement</Link>, and <Link href={`${process.env.REACT_APP_WEB_HOST}/legal/waiver-letter`} target='_blank' rel='noopener'>Waiver Letter</Link></>}
            checked={Boolean(netcapitalAgreement) || hasAlreadySubmit}
            onChange={handleChangeNetcapitalAgreement}
            disabled={hasAlreadySubmit}
          />
          <Checkbox
            label={<>
            I have read and agree to the Templum: <Link href='https://www.templuminc.com/legal/subscriber-agreement' target='_blank' rel='noopener'>Customer Agreement</Link>, <Link href='https://www.templuminc.com/legal/subscriber-agreement#arbitration' target='_blank' rel='noopener'>Predispute Arbitration Agreement</Link>, <Link href='https://www.templuminc.com/legal/privacy-policy' target='_blank' rel='noopener'>Privacy Policy</Link>, <Link href='https://www.templuminc.com/legal/business-continuity' target='_blank' rel='noopener'>Business Continuity Plan</Link>, <Link href='https://www.templuminc.com/legal/patriot-act-disclosure' target='_blank' rel='noopener'>Patriot Act Disclosure</Link>, <Link href='https://files.brokercheck.finra.org/crs_102840.pdf' target='_blank' rel='noopener'>Form CRS</Link>. I have read, understand and accept <Link href='https://onfido.com/facial-scan-policy-and-release' target='_blank' rel='noopener'>Onfido Facial Scan Policy and Release</Link>, <Link href='https://onfido.com/privacy' target='_blank' rel='noopener'>Onfido Privacy Policy</Link> and <Link href='https://onfido.com/terms-of-service' target='_blank' rel='noopener'>Onfido Terms of Service</Link>.
              <br /><br />
              Unregistered securities involve a high degree of risk, including possible loss of your entire investment, and there is no guarantee of liquidity in any given unregistered security notwithstanding such security being listed on the Templum Markets Alternative Trading System.
            </>}
            checked={Boolean(termsOfServiceTemplum) || hasAlreadySubmit}
            onChange={handleChangeTermsOfServiceTemplum}
            disabled={hasAlreadySubmit}
          />
          {holdingEntityKey && (
            <Checkbox
              label='I acknowledge that I am authorized to enter into this agreement on behalf of this entity.'
              checked={Boolean(isAuthorizedForEntity) || hasAlreadySubmit}
              onChange={handleChangeIsAuthorizedForEntity}
              disabled={hasAlreadySubmit}
            />
          )}
          {holdingEntityKey && entityType === 'TRUST' && (
            <Checkbox
              label={
                <Stack spacing={1}>
                  <Typography>As a Trustee, I certify that:</Typography>
                  <Typography variant='body2' component='div'>
                    <List component='ul'>
                      <ListItem>The trust has not been revoked, modified, or amended in any manner that would cause the statements contained in this trust certification to be incorrect.</ListItem>
                      <ListItem>The trust exists under all applicable laws.</ListItem>
                      <ListItem>There are no Trustees of the Trust other than those listed above. Every trustee (whether you are the sole trustee or there is more than one) has signed below and is authorized to make these statements. Should only one person execute this agreement, it shall be a representation that the signer is the sole Trustee.</ListItem>
                      <ListItem>You agree to inform Templum Markets, in writing, of any change in the composition of the Trustees, or any other event that could alter the certifications made herein.</ListItem>
                      <ListItem>You have the authority under the trust and applicable law to enter into transactions and issue the instructions that you make on behalf of the Trust account with Templum Markets. The Trustees understand that all such transactions and instructions will be governed by the terms and conditions of all other account agreements applicable to this account. The Trustees, jointly and severally, indemnify Templum Markets and hold Templum Markets harmless from any claim, loss, expense, or other liability for acting pursuant to the instructions given by the Trustees. The Trustees further agree not to give any instructions for which they are not in full compliance with the Trust.</ListItem>
                      <ListItem>Templum Markets has the authority to accept instructions relative to the account from those individuals listed as Trustees in Sections 2and 3. They may execute any documents on behalf of the Trust that Templum Markets may require, and each trustee may act independently.</ListItem>
                      <ListItem>Templum Markets is authorized to follow the instructions of any Trustee and to deliver funds, securities, or any other assets in the account to any Trustee on any Trustee’s instructions, including delivering assets to a Trustee personally. Templum Markets, in its sole discretion and for its sole protection, may require the written consent of any or allTrustees before acting on the instructions of any Trustee.</ListItem>
                      <ListItem>If included, the pages of the Trust document are true copies of the valid legal document currently in effect.</ListItem>
                      <ListItem>Where applicable, plural references in this certification shall be deemed singular</ListItem>
                    </List>
                  </Typography>
                </Stack>
              }
              checked={Boolean(trustAgreement) || hasAlreadySubmit}
              onChange={handleChangeTrustAgreement}
              disabled={hasAlreadySubmit}
            />
          )}
          <Checkbox
            label='I hereby certify that, to the best of my knowledge, the provided information is true and accurate.'
            checked={Boolean(isAccurate) || hasAlreadySubmit}
            onChange={handleChangeIsAccurate}
            disabled={hasAlreadySubmit}
          />
        </Stack>
      </Grid>
      <Grid item container xs={12} justifyContent='right'>
        <Button
          startIcon={<RocketLaunchOutlinedIcon />}
          onClick={toggleConfirmModal}
          disabled={!canSubmit || saving || saved}
          variant='contained'
        >
          Submit
        </Button>
      </Grid>
      <ConfirmationDialog
        open={confirmModalOpen}
        onClose={toggleConfirmModal}
        ctaDisabled={saving}
        title={'Submit Your Application'}
        titleIcon={<RocketLaunchOutlinedIcon color='primary' />}
        content={'This information will be submitted to and reviewed by Templum Markets LLC. You will be notified if any additional or clarifying information is required, and when your application is approved or denied.'}
        onConfirm={handleConfirmSubmit}
        onCancel={toggleConfirmModal}
      />
      <ConfirmationDialog
        open={successModalOpen}
        onClose={toggleSuccessModal}
        title={'Application Successfully Submitted'}
        titleIcon={<CheckIcon color='success' />}
        content={'Thank you! You will receive email updates about the status of your application as it is processed.'}
        onConfirm={toggleSuccessModal}
        ctaConfirm={'Ok'}
        canCancel={false}
      />
    </Grid>
  );
};

export default KycDisclosures;
