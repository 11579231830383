import { Button, Grid, Stack, Typography } from '@mui/material';
import { useGetDwollaKycInfo, useSubmitDwollaKycInfo } from '../../../_actions/kyc-dwolla.actions';

import AddressField from '../../FormFields/Address';
import CircularLoading from '../../../netcapital-components/CircularLoading';
import DateOfBirthField from '../../FormFields/DateOfBirth';
import PersonNameFieldset from '../../FormFields/PersonName';
import PropTypes from 'prop-types';
import TinField from '../../FormFields/Tin';
import { fetchNotificationResponseAttribute } from '../../../_state';
import { useCallback } from 'react';

const DwollaKycTin = ({ onSuccess }) => {
  const {loaded} = useGetDwollaKycInfo();
  const {save, saving} = useSubmitDwollaKycInfo();
  const onSubmit = useCallback(() => save(onSuccess), [save, onSuccess]);

  if (!loaded) return <CircularLoading />;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stack spacing={2}>
          <Typography variant='h4'>Review &amp; Confirm Your Info</Typography>
          <Typography fontStyle='italic'>Your information should match what is on your Government Issued I.D.</Typography>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <PersonNameFieldset
          recoilState={{
            firstName: fetchNotificationResponseAttribute('firstName'),
            lastName: fetchNotificationResponseAttribute('lastName'),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <AddressField
          recoilState={fetchNotificationResponseAttribute('address')}
        />
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={12} sm={6}>
          <DateOfBirthField
            recoilState={fetchNotificationResponseAttribute('dateOfBirth')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TinField
            recoilState={fetchNotificationResponseAttribute('secureTin')}
            forceSet
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button
          onClick={onSubmit}
          disabled={saving}
          variant='contained'
        >Confirm</Button>
      </Grid>
    </Grid>
  );
};

DwollaKycTin.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default DwollaKycTin;
