const styles = {
  TableContainer: {
    margin: 'auto',
    position: 'relative',
  },
  Spinner: {
    position: 'sticky',
    top: '64px',
    zIndex: 3,
  }
};

export default styles;
