import { Avatar, Stack, Typography } from '@mui/material';
import { availableSharesByCompanyState, companyState, fetchOfferAttribute } from '../../_state';

import { pluralize } from '../../_helpers';
import { useRecoilValue } from 'recoil';

const AvailableShares = () => {
  const company = useRecoilValue(companyState);
  const availableShares = useRecoilValue(availableSharesByCompanyState);
  const offerType = useRecoilValue(fetchOfferAttribute('offerType'));

  return (
    <Stack direction='row' justifyContent='center' spacing={2}>
      <Avatar src={company.logoUrl} />
      <Stack>
        <Typography variant='h2' fontWeight='fontWeightBold'>{company.doingBusinessAs}</Typography>
        {offerType === 'ASK' && (<div>
          <Typography fontWeight='fontWeightMedium' color='primary' component='span'>{availableShares} {pluralize('Share', availableShares)}</Typography> <Typography component='span' color='grey'>eligible to sell</Typography>
        </div>)}
      </Stack>
    </Stack>
  );
};

export default AvailableShares;
