import { Stack, Typography } from '@mui/material';

import { investorState } from '../../_state';
import { useRecoilValue } from 'recoil';

const OfferOwner = () => {
  const investor = useRecoilValue(investorState);

  return (
    <Stack spacing={1}>
      <Stack direction='row' spacing={0.5} justifyContent='center'>
        <Typography variant='body2' fontWeight='light' color='grey.500'>
          Customer Account:
        </Typography>
        <Typography variant='body2' fontWeight='medium' textTransform='uppercase' color='grey.500'>
          {investor.name}
        </Typography>
      </Stack>
      <Stack direction='row' spacing={0.5} justifyContent='center'>
        <Typography variant='body2' fontWeight='light' color='grey.500'>
          Order Type:
        </Typography>
        <Typography variant='body2' fontWeight='medium' textTransform='uppercase' color='grey.500'>
          Good-Till-Canceled (GTC)
        </Typography>
      </Stack>
      <Typography variant='caption' color='grey.500' textAlign='center'>Order submission and matching capabilities provided by Templum Markets LLC.</Typography>
    </Stack>
  );
};

export default OfferOwner;
