// skipcq: JS-C1003 - yup does not expose itself as an ES Module.
import * as Yup from 'yup';

import { INCOME_NETWORTH_OPTIONS, INVESTMENT_FUNDING_SOURCES, LIQUIDITY_NEEDS, RISK_TOLERANCES, TAX_FILING_STATUS } from '../../../../static-info';

const FinancialInfoSchema = Yup.object({
  taxStatus: Yup.string()
    .oneOf(TAX_FILING_STATUS.map((option) => option.value))
    .required('Required'),
  annualIncome: Yup.number()
    .oneOf(INCOME_NETWORTH_OPTIONS.map((option) => option.value))
    .required('Required'),
  netWorth: Yup.number()
    .oneOf(INCOME_NETWORTH_OPTIONS.map((option) => option.value))
    .required('Required'),
  liquidNetWorth: Yup.number().nullable(),
  annualExpenses: Yup.string().nullable(),
  hasIlliquidInvestments: Yup.bool().nullable(),
  liquidityNeeds: Yup.string()
    .oneOf(LIQUIDITY_NEEDS.map((option) => option.value))
    .required('Required'),
  riskTolerance: Yup.string()
    .oneOf(RISK_TOLERANCES.map((option) => option.value))
    .required('Required'),
  investmentFundSources: Yup.object({
    fundSources: Yup.array().of(
      Yup.string().oneOf(INVESTMENT_FUNDING_SOURCES.INDIVIDUAL.map((option) => option.value))
    ).nullable(),
    otherSource: Yup.string().when('fundSources', {
      is: (fundSources) => fundSources?.findIndex((source) => source === 'OTHER') > -1,
      then: (schema) => schema.required('Required')
    }),
  }).nullable()
});

export default FinancialInfoSchema;
