import { DateFormat, NumberFormat } from '../../_helpers';
import { MARKETS_TAB, OFFER_STATUSES } from '../../static-info';
import { Table, TableBody, TableContainer } from '@mui/material';

import ReceiptLineItem from './ReceiptLineItem';
import styles from './styles';

const getTransactionAction = (offerType) => {
  if (offerType === 'ASK') {
    return 'Sale';
  }
  if (offerType === 'BID') {
    return 'Buy';
  }
  return 'Other';
};

const ReceiptDetails = ({ transactionDetails }) => {
  return (
    <TableContainer>
      <Table size={'small'} sx={styles.LineItemContainer}>
        <TableBody>
          <ReceiptLineItem
            label={'Market'}
            value={MARKETS_TAB[transactionDetails?.market]}
          />
          <ReceiptLineItem
            label={'Status'}
            value={OFFER_STATUSES[transactionDetails?.offerStatus]}
          />
          <ReceiptLineItem
            label={'Commitment Date'}
            value={DateFormat.datetimeShort(transactionDetails?.publishedOn)}
          />
          <ReceiptLineItem
            label={'Issuer'}
            value={transactionDetails?.listing?.company?.doingBusinessAs}
          />
          <ReceiptLineItem
            label={'Type'}
            value={getTransactionAction(transactionDetails?.type).toUpperCase()}
          />
          <ReceiptLineItem
            label={'Quantity'}
            value={`${NumberFormat.thousands(transactionDetails?.totalQuantity)} Shares`}
          />
          <ReceiptLineItem
            label={'Price'}
            value={NumberFormat.cents(transactionDetails?.pricePerUnit)}
          />
          <ReceiptLineItem
            label={'Value'}
            value={NumberFormat.cents(transactionDetails?.totalValue)}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReceiptDetails;
