import { DateFormat, NumberFormat } from '../../../../_helpers';
import { MARKETS_TAB, OFFER_STATUSES } from '../../../../static-info';
import {
  Stack,
  TableCell,
  TableRow,
  Typography,
  styled
} from '@mui/material';
import { formatVerbiageByTransactionAttributes, getColoringByTransactionAttributes } from '../../../TransactionsOverview/utils';
import { useCallback, useState } from 'react';

import CancelTransactionModal from '../../../TransactionsOverview/Transaction/cancel';
import TransactionDetailsToggle from '../../../TransactionDetails/toggle';
import TransactionQuantity from './quantity';
import TransactionSchema from '../../../TransactionsOverview/Transaction/schema';
import styles from '../styles';

const StyledTableCell = styled(TableCell)(styles.TableCell);

const InvestorTransaction = ({ onCancelTransaction, transaction }) => {
  const transactionVerbiage = formatVerbiageByTransactionAttributes(transaction.market, transaction.type, transaction.offerStatus);
  const conditionalTransactionColoring = getColoringByTransactionAttributes(transaction.market, transaction.type, transaction.offerStatus);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const toggleConfirmationModal = useCallback(() => setConfirmModalOpen(!confirmModalOpen), [confirmModalOpen, setConfirmModalOpen]);

  return (
    <TableRow>
      <StyledTableCell component='th' scope='row'>
        <Typography variant='body2'>{MARKETS_TAB[transaction.market]}</Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography variant='body2' component='div'>
          <TransactionQuantity
            transaction={transaction.transaction}
            allocatedQuantity={transaction.allocatedQuantity}
            totalQuantity={transaction.totalQuantity}
            type={transaction.type}
            offerStatus={transaction.offerStatus}
          />
        </Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography variant='body2'>
          {NumberFormat.cents(transaction.pricePerUnit, {minimumFractionDigits: 2})}
        </Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography
          variant='body2'
          color={conditionalTransactionColoring}
        >
          {NumberFormat.cents(transaction.totalValue)}
        </Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography variant='body2'>
          {OFFER_STATUSES[transaction.offerStatus]}
        </Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography variant='body2' sx={styles.NoWrap}>
          {DateFormat.datetime(transaction.modified)}
        </Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
          <Typography
            variant='body2'
            color={conditionalTransactionColoring}
          >
            {transactionVerbiage}
          </Typography>
          <TransactionDetailsToggle
            transaction={transaction}
            onOpenConfirmCancel={toggleConfirmationModal}
            onCancelTransaction={onCancelTransaction}
          />
          <CancelTransactionModal
            transaction={transaction}
            onCancelTransaction={onCancelTransaction}
            confirmModalOpen={confirmModalOpen}
            toggleConfirmationModal={toggleConfirmationModal}
          />
        </Stack>
      </StyledTableCell>
    </TableRow>
  );
};

InvestorTransaction.propTypes = TransactionSchema;

export default InvestorTransaction;
