import CountrySelect from '../../netcapital-components/AddressInput/CountrySelect';
import Field from './Field';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

const CitizenshipField = ({ recoilState, disabled }) => {
  const [citizenship, setCitizenship] = useRecoilState(recoilState);
  const handleChangeCitizenship = useCallback(newValue => setCitizenship(newValue), [setCitizenship]);

  return (
    <Field label='Country of Citizenship *'>
      <CountrySelect
        onChange={handleChangeCitizenship}
        value={citizenship}
        disabled={disabled}
        requirementLabel='*'
      />
    </Field>
  );
};

CitizenshipField.defaultProps = {
  disabled: false,
};

CitizenshipField.propTypes = {
  recoilState: PropTypes.shape({key: PropTypes.string.isRequired}).isRequired,
  disabled: PropTypes.bool,
};

export default CitizenshipField;
