import { Grid, Typography } from '@mui/material';

import ReadOnlyImageField from '../../ReadOnlyField/image';
import { getVerificationDocConfigByUsage } from '../../../../_helpers';
import { useRecoilValue } from 'recoil';
import { userVerificationDocumentsState } from '../../../../_state';

const IdentityVerificationReadOnly = () => {
  const docs = useRecoilValue(userVerificationDocumentsState);
  const selfieDoc = docs?.find(doc => doc.usage === 'SELFIE');

  if (Array.isArray(docs) && docs.length === 0) {
    return (
      <Grid item xs={12}><Typography fontStyle={'italic'}>You have not submit any identity verification documents.</Typography></Grid>
    );
  }

  return (
    <>
      {docs?.map(doc => {
        const docTypeConfig = getVerificationDocConfigByUsage(doc.usage);

        if (!docTypeConfig || doc.usage === 'SELFIE') return null;

        return (
          <Grid item container xs={12} rowSpacing={1} key={doc.usage}>
            <ReadOnlyImageField label={docTypeConfig.label} src={doc.url} fileName={doc.name} />
          </Grid>
        );
      })}

      {selfieDoc && (
        <Grid item container xs={12} rowSpacing={1} key={selfieDoc.usage}>
          <ReadOnlyImageField label={'Selfie'} src={selfieDoc.url} fileName={selfieDoc.name} />
        </Grid>
      )}
    </>
  );
};

export default IdentityVerificationReadOnly;
