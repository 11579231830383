import { Divider, Grid } from '@mui/material';

import PropTypes from 'prop-types';
import styles from './styles';
import { v4 as uuidv4 } from 'uuid';

const renderElements = (elements, forceDivider) => {
  return elements.map((Child, index) => (
    <Grid item xs={12} key={uuidv4()}>
      <Child />
      {(forceDivider || index < elements.length-1) && <Divider sx={styles.MobileDivider} variant='fullWidth' />}
    </Grid>
  ));
};

const SidebarLayout = ({ topWell, centerWell, sidebar, bottomWell }) => {
  const hasTopWell = topWell.length > 0;
  const hasCenterWell = centerWell.length > 0;
  const hasSidebar = sidebar.length > 0;
  const hasBottomWell = bottomWell.length > 0;

  return (
    <Grid container spacing={{xs: 3, md:4, xl: 6}} name='SidebarLayout' sx={styles.SidebarLayoutContainer}>
      {hasTopWell && (
        <Grid item xs={12} name='TopWell' sx={styles.LayoutSlot}>
          <Grid container spacing={{xs: 3, md:4, xl: 6}}>
            {renderElements(topWell, true)}
          </Grid>
        </Grid>
      )}
      {hasCenterWell && (
        <Grid item xs={12} lg={hasSidebar ? 7 : 12} name='CenterWell' sx={styles.LayoutSlot}>
          <Grid container spacing={{xs: 3, md:4, xl: 6}}>
            {renderElements(centerWell)}
          </Grid>
        </Grid>
      )}
      {hasSidebar && (
        <Grid item xs={12} lg={5} name='Sidebar' sx={styles.LayoutSlot}>
          <Grid container spacing={{xs: 3, md:4, xl: 6}}>
            {renderElements(sidebar)}
          </Grid>
        </Grid>
      )}
      {hasBottomWell && (
        <Grid item xs={12} name='BottomWell' sx={styles.LayoutSlot}>
          <Grid container spacing={{xs: 3, md:4, xl: 6}}>
            {renderElements(bottomWell)}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

SidebarLayout.defaultProps = {
  topWell: [],
  centerWell: [],
  sidebar: [],
  bottomWell: [],
};

SidebarLayout.propTypes = {
  topWell: PropTypes.arrayOf(PropTypes.elementType),
  centerWell: PropTypes.arrayOf(PropTypes.elementType),
  sidebar: PropTypes.arrayOf(PropTypes.elementType),
  bottomWell: PropTypes.arrayOf(PropTypes.elementType),
};

export default SidebarLayout;
