const styles = {
  DialogPaper: ({ breakpoints }) => ({
    borderRadius: 0,
    [breakpoints.up('sm')]: {
      '& .MuiDialog-paper': {
        borderRadius: '12px',
      },
    },
  }),
  ContentContainer: ({ breakpoints }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '.ModalSvg': {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '185px',
      height: '159px',
      [breakpoints.up('400')]: {
        width: '250px',
        height: '190px',
      },
      [breakpoints.up('500')]: {
        width: '280px',
        height: '210px',
      },
      [breakpoints.up('sm')]: {
        width: 'auto !important', //plain auto gets overidden by above h/w
        height: 'auto !important', //plain auto gets overidden by above h/w
      },
    },
  }),
  ModalBody: ({ breakpoints }) => ({
    '&.MuiTypography-root': {
      display: 'block',
      textAlign: 'center',
      paddingTop: '0.5rem',
      marginBottom: '150px',
      [breakpoints.up('sm')]: {
        marginBottom: '10px',
      },
    }
  }),
  ModalTitle: ({breakpoints}) => ({
    textAlign: 'center',
    paddingBottom: '0.5rem',
    [breakpoints.up('lg')]: {
      fontSize: '36px',
    },
  }),
  SmallSkipBtn: {
    position: 'absolute',
    top: '12px',
    right: '24px',
  },
  SmallSkipText: {
    fontSize: '0.9rem'
  },
  Step: ({ breakpoints }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    width: '87px',
    [breakpoints.up('sm')]: {
      paddingLeft: '8px',
      paddingRight: '8px',
      width: 'auto',
    },
  }),
  StepLargerWidth: ({ breakpoints }) => ({
    width: '104px',
    [breakpoints.up('sm')]: {
      width: 'auto',
    },
  }),
  StepLabel: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    '& .MuiStepLabel-iconContainer':{
      paddingBottom: '0.5rem',
      paddingRight: 0,
    },
  },
  StepLabelSubText: {
    textAlign: 'center',
  },
  Completed: ({ palette }) => ({
    color: palette.gain.main,
    '& .MuiStepLabel-label': {
      color: palette.gain.main,
    },
    '& .MuiStepLabel-label.Mui-completed': {
      color: palette.gain.main,
    },
    '& .Mui-completed':{
      color: palette.gain.main,
    },
    '& .MuiSvgIcon-root.Mui-completed':{
      color: palette.gain.main,
    }
  }),
  Reviewing: ({ palette }) => ({
    color: palette.secondary.main,
    '& .MuiStepLabel-label': {
      color: palette.secondary.main,
    },
  }),
  Incomplete: ({ palette }) => ({
    color: palette.grey[500],
    '& .MuiStepLabel-label': {
      color: palette.grey[500],
    },
    '& .Mui-active': {
      color: palette.grey[500],
    }
  }),
  Error: ({ palette }) => ({
    color: palette.error.main,
    '& .MuiStepLabel-label': {
      color: palette.error.main,
    },
    '&. MuiSvgIcon-root': {
      color: palette.error.main,
    }
  }),
  ButtonFullWidth: {
    width: '100%',
  },
  DialogActions: ({ breakpoints, palette }) => ({
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: '8px',
    overflow: 'hidden',
    backgroundColor: palette.common.white,
    zIndex: 2000,
    [breakpoints.up('sm')]: {
      position: 'static',
      padding: '16px 0px 0px 0px',
      overflow: 'visible'
    },
  }),
  DialogActionsCtas: {
    width: '100%',
  },
};

export default styles;
