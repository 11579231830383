import { Box, Typography } from '@mui/material';

import PropTypes from 'prop-types';
import { SVG_COMPONENTS_EMPTY_STATES } from './svg-components-map';
import styles from './styles';

const EmptyStateDisplay = ({ componentName, message, messagePosition }) => {
  return (
    <Box textAlign='center'>
      {messagePosition === 'top' ? <Typography variant='body2' sx={styles.EmptyMessage}>{message}</Typography> : null}
      {componentName === 'FundingHistoryTable' ? null : SVG_COMPONENTS_EMPTY_STATES[componentName]} {/* FundingHistoryTable does not use an svg for it's empty state */}
      {messagePosition === 'bottom' ? <Typography variant='body2' sx={styles.EmptyMessage}>{message}</Typography> : null}
    </Box>
  );
};

EmptyStateDisplay.defaultProps = {
  componentName: '',
  message: '',
  messagePosition: 'bottom'
};

//Marked as non-required, due to the fact that we are not choosing to show an empty state for PortfolioValueHistory graph when !data.length. Otherwise, would throw a propType error.
EmptyStateDisplay.propTypes = {
  componentName: PropTypes.string,
  message: PropTypes.string,
  messagePosition: PropTypes.string
};

export default EmptyStateDisplay;
