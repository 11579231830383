import { Button } from '@mui/material';
import Carousel from '../../netcapital-components/Carousel';
import PropTypes from 'prop-types';
import styles from './styles';
import { useCallback } from 'react';

const dateRanges = [
  {
    id: 'today',
    label: '24H',
  },
  {
    id: 'last-week',
    label: '7D'
  },
  {
    id: 'last-month',
    label: '1M'
  },
  {
    id: 'last-year',
    label: '1Y'
  },
];

const DateFilters = ({ setActiveDateRange, activeDateRange }) => {
  const handleClickCategory = useCallback((e) => {
    setActiveDateRange(e.currentTarget.id);
  }, [setActiveDateRange]);

  return (
    <Carousel>
      {dateRanges.map(dateRange => (
        <Button
          id={dateRange.id}
          onClick={handleClickCategory}
          variant={activeDateRange === dateRange.id ? 'contained' : 'outlined'}
          key={dateRange.id}
          size='medium'
          sx={styles.Options}
        >
          {dateRange.label}
        </Button>
      ))}
    </Carousel>
  );
};

DateFilters.propTypes = {
  setActiveDateRange: PropTypes.func.isRequired,
  activeDateRange: PropTypes.string,
};

export default DateFilters;
