const styles = {
  MoneyInput: {
    display: 'block',
    '.MuiInputBase-root': {
      width: '100%',
    },
  },
};

export default styles;
