const styles = {
  Row: {
    borderBottomColor: 'grey.100',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    paddingBottom: 1,
    paddingTop: 1,
    '&:last-of-type': {
      borderWidth: 0,
      paddingBottom: 0,
    },
  },
  ListItem: {
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  ImageContainer: {
    'img': {
      width: '100%',
    },
  },
};

export default styles;
