import { useCallback, useState } from 'react';
import { useDeleteHoldingEntityDocument, useSaveHoldingEntityDocument } from '../../_actions/holding-entities.actions';

import FileUpload from '../../netcapital-components/FileUpload';
import FormFieldErrorMessage from '../../netcapital-components/FormValidation/FormFieldErrorMessage';
import { HOLDING_ENTITY_DOC_TEMPLUM_FILE_TYPES } from '../../static-info';
import PropTypes from 'prop-types';
import { holdingEntityDocumentsState } from '../../_state';
import { useFormFieldValidation } from '../../netcapital-components/FormValidation';
import { useRecoilValue } from 'recoil';

const HoldingEntityDocument = ({ usage, holdingEntityKey, onSuccess, fieldName }) => {
  const [errorMessage, setErrorMessage] = useState();
  const documents = useRecoilValue(holdingEntityDocumentsState);
  const file = documents?.find(doc => doc.usage === usage);
  const {destroy, deleting} = useDeleteHoldingEntityDocument(holdingEntityKey);
  const {save, saving} = useSaveHoldingEntityDocument(holdingEntityKey);
  const {errorMessage: validationErrorMessage, eventListeners} = useFormFieldValidation(fieldName);

  const handleChangeFile = useCallback(selectedFile => {
    eventListeners.onFocus();
    if (!selectedFile && file?.key) {
      destroy(file.key, onSuccess);
      return;
    }

    save(usage, selectedFile, onSuccess);
    setErrorMessage('');

  }, [destroy, eventListeners, file?.key, onSuccess, save, usage]);

  return (
    <>
      <FileUpload
        fileType={HOLDING_ENTITY_DOC_TEMPLUM_FILE_TYPES.join(',')}
        file={file}
        onChange={handleChangeFile}
        loading={saving || deleting}
        disabled={saving || deleting}
      />
      <FormFieldErrorMessage>{errorMessage || validationErrorMessage}</FormFieldErrorMessage>
    </>
  );
};

HoldingEntityDocument.defaultProps = {
  onSuccess: () => { return; },
  fieldName: '',
};

HoldingEntityDocument.propTypes = {
  usage: PropTypes.string.isRequired,
  holdingEntityKey: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
  fieldName: PropTypes.string,
};

export default HoldingEntityDocument;
