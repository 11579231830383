import { APIUserURL, APIs } from './_api-urls';
import { notificationsFetchState, notificationsState, userState } from '../_state';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { DateTime } from 'luxon';
import { useFetchWrapper } from '../_helpers';

export function useGetNotifications(userKey) {
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [fetchState, setFetchState] = useRecoilState(notificationsFetchState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    function abort(){ controller.abort();}
    const isExpired = DateTime.now() >= DateTime.fromISO(fetchState?.expiresOn);

    if (notifications !== undefined && !isExpired) {
      setLoaded(true);
      return abort;
    }

    fetchWrapper.get(`${APIs.Users}/${userKey}/notifications`, null, signal, false)
      .then((response) => {
        if (!response) return; // aborted request gives response === undefined
        setNotifications(response);
        setFetchState({ ...fetchState, expiresOn: DateTime.now().plus({minutes: 1}).toISO()});
        setLoaded(true);
      });

    return abort;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userKey, notifications, fetchState]);

  return { loaded };
}

export function useDeleteNotification() {
  const fetchWrapper = useFetchWrapper();
  const [deleting, setDeleting] = useState(false);
  const user = useRecoilValue(userState);
  const [notifications, setNotifications] = useRecoilState(notificationsState);

  const destroy = useCallback((notificationKey) => {
    const controller = new AbortController();
    const signal = controller.signal;
    const abort = () => controller.abort();

    if (!notificationKey) return abort;

    setDeleting(true);

    const url = `${APIUserURL(user.entityKey)}/notifications/${notificationKey}`;
    fetchWrapper.delete(url, null, signal)
      .then((response) => {
        if (!response) return; // aborted request gives response === undefined
        const notificationIndex = notifications.findIndex(n => n.key === notificationKey);
        setNotifications([
          ...notifications.slice(0, notificationIndex),
          ...notifications.slice(notificationIndex + 1)
        ]);
        setDeleting(false);
      });
    return abort;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications, user?.entityKey]);

  return { destroy, deleting };
}
