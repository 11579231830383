// skipcq: JS-C1003 - yup does not expose itself as an ES Module.
import * as Yup from 'yup';

import { ACCREDITED_INVESTOR_CRITERIA, ASSET_TYPES, INDUSTRY_EXPERIENCE, INVESTMENT_DECISIONS, INVESTMENT_OBJECTIVES } from '../../../../static-info';

import InvestorAffiliationsSchema from '../../InvestorAffiliationsSchema';

const InvestorInfoSchema = Yup.object({
  accreditation: Yup.object({
    isAccreditedInvestor: Yup.bool().required('Required'),
    method: Yup.string()
      .nullable()
      .oneOf(ACCREDITED_INVESTOR_CRITERIA.INDIVIDUAL.map(type => type.value))
      .when('isAccreditedInvestor', {
        is: true,
        then: (schema) => schema.required('Required'),
      }),
  }),
  isQualifiedPurchaser: Yup.bool()
    .nullable()
    .when('accreditation.isAccreditedInvestor', {
      is: true,
      then: (schema) => schema.required('Required'),
    }),
  affiliations: InvestorAffiliationsSchema,
  investmentObjectives: Yup.array().of(
    Yup.string()
      .oneOf(INVESTMENT_OBJECTIVES.map(option => option.value))
      .required('Required')
  ).min(1, 'Required'),
  investmentDecisions: Yup.string()
    .oneOf(INVESTMENT_DECISIONS.map(option => option.value))
    .nullable(),
  assetTypesExperience: Yup.array().of(
    Yup.string()
      .oneOf(ASSET_TYPES.map(option => option.value))
      .required('Required')
  ).min(1, 'Required'),
  industriesExperience: Yup.array().of(
    Yup.string()
      .oneOf(INDUSTRY_EXPERIENCE.map(option => option.value))
  ).nullable(),
});

export default InvestorInfoSchema;
