import { TableCell, TableRow, Typography } from '@mui/material';

import styles from './styles';

const COLUMN_NAMES = [
  'Price Per Unit',
  'Volume',
  'Status'
];

const PendingOffersHeader = () => {
  return (
    <TableRow>
      {COLUMN_NAMES.map(column => (
        <TableCell key={column}>
          <Typography variant='overline' color='table.header' fontWeight='fontWeightMedium' sx={styles.NoWrap}>
            {column}
          </Typography>
        </TableCell>
      ))}
    </TableRow>
  );
};

export default PendingOffersHeader;
