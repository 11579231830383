import AnnouncementsOverview from './Announcements';
import FundingHistoryOverview from './FundingHistory';
import SharePriceHistory from '../../SharePriceHistory';
import SidebarLayout from '../../../layouts/SidebarLayout';

const OverviewTab = () => {
  return (
    <SidebarLayout
      topWell={[SharePriceHistory]}
      centerWell={[FundingHistoryOverview]}
      sidebar={[AnnouncementsOverview]}
    />
  );
};

export default OverviewTab;
