import { Avatar, Stack, Typography } from '@mui/material';
import { NumberFormat, pluralize } from '../../_helpers';
import { companyState, fetchOfferAttribute, fetchWalletAttribute, offerState } from '../../_state';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import ConfirmationDialog from '../../netcapital-components/ConfirmationDialog';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import OfferOwner from './owner';
import PropTypes from 'prop-types';
import { STF_TRANSACTION_FEE } from '../../static-info';
import styles from './styles';
import { useAlertActions } from '../../_actions/alert.actions';
import { useCallback } from 'react';
import { useCreateOffer } from '../../_actions/offer.actions';

const ConfirmOffer = (props) => {
  const { open, onSuccess, onCancel } = props;
  const alertActions = useAlertActions();
  const company = useRecoilValue(companyState);
  const quantity = useRecoilValue(fetchOfferAttribute('quantity'));
  const pricePerUnit = useRecoilValue(fetchOfferAttribute('pricePerUnit'));
  const offerType = useRecoilValue(fetchOfferAttribute('offerType'));
  const walletBalance = useRecoilValue(fetchWalletAttribute('availableAmount'));
  const isBid = offerType === 'BID';
  const resetOffer = useResetRecoilState(offerState);
  const { save, saving } = useCreateOffer();

  const handleSubmit = useCallback(() => {
    if(offerType === 'BID' && quantity * pricePerUnit + (quantity * pricePerUnit * STF_TRANSACTION_FEE) > walletBalance){
      const transferWalletLink = `<a href=${process.env.REACT_APP_WEB_HOST}/payments/transfer>transfer funds into your wallet</a>`;
      alertActions.error('Insufficient Wallet Funds', {}, `<p>Your Netcapital wallet doesn't have enough funds to cover the cost of the trade and transaction fees. To move forward, you will need to ${transferWalletLink}. If you've recently transferred money, kindly keep in mind that it may take up to 3-5 business days for the funds to be available in your wallet.</p>`, {});
      return;
    }
    save(company.key, onSuccess);

  }, [alertActions, company.key, offerType, onSuccess, pricePerUnit, quantity, save, walletBalance]);

  const handleCancel = useCallback(() => {
    resetOffer();
    onCancel();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onCancel]);

  const transactionValue = quantity * pricePerUnit;

  const feeValue = quantity * pricePerUnit * STF_TRANSACTION_FEE;

  const valueWithFee = isBid
    ? NumberFormat.cents((transactionValue) + feeValue, {minimumFractionDigits: 2})
    : NumberFormat.cents(transactionValue - feeValue, {minimumFractionDigits: 2});

  return (
    <ConfirmationDialog
      open={open}
      ctaConfirm={'Confirm Order'}
      ctaDisabled={saving}
      saving={saving}
      onConfirm={handleSubmit}
      onCancel={handleCancel}
      title={'Confirm your order'}
      titleIcon={<HandshakeOutlinedIcon color='primary' />}
      content={
        <Stack spacing={3} sx={styles.ConfirmContent}>
          <Stack spacing={1}>
            <Typography textAlign='center' component='div'>
              You are placing a <Typography component='span' fontWeight='medium' color={offerType === 'BID' ? 'gain.main' : 'loss.main'}>{offerType === 'BID' ? 'Buy' : 'Sell'} order</Typography> for
            </Typography>
            <Typography textAlign='center'>
              <Typography component='span' fontWeight='medium'>{NumberFormat.thousands(quantity)} {pluralize('share', quantity)}</Typography> at {NumberFormat.cents(pricePerUnit)} per share of
            </Typography>
          </Stack>
          <Stack direction='row' justifyContent='center' alignItems='center' spacing={1}>
            <Avatar src={company.logoUrl} />
            <Typography variant='h3'>{company.doingBusinessAs}</Typography>
          </Stack>
          <Typography variant='caption1' textAlign='center'>
            If a matched {offerType === 'BID' ? 'sell' : 'buy'} order is identified and the trade is executed, you will be charged a 1% trade commission by Templum Markets and the {offerType === 'BID' ? 'total cost' : 'net proceeds'} of your order will be <Typography component='span'  variant='caption1' fontWeight='medium'>{valueWithFee}</Typography> (<Typography component='span' variant='caption1' fontWeight='medium'>{NumberFormat.cents(transactionValue, {minimumFractionDigits: 2})}</Typography> transaction price {offerType === 'BID' ? 'plus' : 'minus'} <Typography component='span' variant='caption1' fontWeight='medium'>{NumberFormat.cents(feeValue, {minimumFractionDigits: 2})}</Typography> trade commission). You will not be charged a trade commission if the order is not executed.
          </Typography>
          <OfferOwner />
        </Stack>
      }
    />
  );
};

ConfirmOffer.defaultProps = {
  onSuccess: () => { return; },
  onCancel: () => { return; },
};

ConfirmOffer.propTypes = {
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  open: PropTypes.bool.isRequired,
};

export default ConfirmOffer;
