const styles = {
  AccountFormContainer: ({ breakpoints }) => ({
    marginTop: '3rem',
    [breakpoints.down('md')]: {
      marginTop: '1rem'
    }
  }),
  CreateHoldingEntityButton: {
    alignSelf: 'end',
    minWidth: '12.5rem',
    whiteSpace: 'nowrap',
  },
  AccountsNav: {
    flexGrow: 1,
    marginLeft: {
      xs: -2,
      sm: 0,
    },
    marginRight: {
      xs: -2,
      sm: 0,
    },
  },
  Disclaimer: {
    marginBottom: '2rem'
  },
};

export default styles;
