const styles = {
  Title:{
    padding: '0.75rem 2.188rem',
    width: '100%',
    minHeight: '5.625rem'
  },
  Close: {
    position: 'absolute',
    right: 0,
    top: '0.5rem',
  },
};

export default styles;
