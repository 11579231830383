import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { getClickableLink, getCompanySocialLinks } from './utils';

import { DateFormat } from '../../../_helpers';
import GridItem from '../GridItem';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import Markdown from 'react-markdown';
import { ReactComponent as NoImageSvg } from '../../../assets/no-image.svg';
import { SECTORS } from '../../../static-info';
import SocialLinks from './socialLinks';
import Team from '../Team';
import _ from 'lodash';
import { companyState } from '../../../_state';
import styles from './styles';
import { useRecoilValue } from 'recoil';

const CompanyLogo = ({ url, doingBusinessAs }) => {
  if (url) {
    return (
      <Box sx={styles.CompanyLogoContainer}>
        <img
          src={url}
          alt={doingBusinessAs}
          className='company-logo'
        />
      </Box>
    );
  }
  return (
    <Box sx={styles.CompanyLogoContainer}>
      <NoImageSvg />
    </Box>
  );
};

const Company = () => {
  const company = useRecoilValue(companyState);
  const nullResultMessage = 'Not Provided';

  return (
    <Grid container spacing={8}>
      <Grid item xs={6} sm={4} md={3}>
        <Stack spacing={4} sx={styles.CompanyLogoGridItem}>
          <Stack spacing={1}>
            <CompanyLogo url={company.logoUrl} doingBusinessAs={company.doingBusinessAs} />
            <SocialLinks links={getCompanySocialLinks(company)} />
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={8} lg={9}>
        <Grid container spacing={4}>
          <GridItem
            title='Location'
            content={company.address ? `${company.address.city}, ${company.address.state}` : nullResultMessage}
          />
          <GridItem
            title='Industry'
            content={company.industry || nullResultMessage}
          />
          <GridItem
            title='Sector'
            content={SECTORS[company.sector] || nullResultMessage}
          />
          <GridItem
            title='Company Formation'
            content={_.capitalize(company.companyFormation) || nullResultMessage}
          />
          <GridItem
            title='No. of Employees'
            content={company.numberOfEmployees || nullResultMessage}
          />
          <GridItem
            title='Date of Formation'
            content={DateFormat.short(company.dateOfFormation) || nullResultMessage}
          />
          <Grid item xs={12} sm={6} lg={4}>
            <Button
              href={`${process.env.REACT_APP_WEB_HOST}/companies/${company.code}`}
              target='_blank'
              rel='noopener'
              variant='outlined'
              size='large'
              sx={styles.NoWrap}
            >
              Offering Page
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Button
              href={getClickableLink(company.website)}
              target='_blank'
              rel='noopener'
              variant='outlined'
              size='large'
              startIcon={<LanguageOutlinedIcon />}
            >
              Website
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {company.shortPitch && (
        <Grid item xs={12}>
          <Box sx={styles.BlockQuoteContainer}>
            <Typography
              variant='subtitle1'
              fontWeight='fontWeightBold'
              color='primary'
              sx={styles.BlockQuote}
            >
              {company.shortPitch}
            </Typography>
          </Box>
        </Grid>
      )}
      {company.businessSummary && (
        <Grid item xs={12}>
          <Stack spacing={2}>
            <Typography variant='h3'>Business Summary</Typography>
            <Markdown>{company.businessSummary}</Markdown>
          </Stack>
        </Grid>
      )}
      <Grid item xs={12}>
        <Stack spacing={2}>
          <Typography variant='h3'>The Team</Typography>
          <Team />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Company;

