const styles = {
  IconForSmallFont: {
    fontSize: '1.2rem',
  },
  IconContainer: {
    lineHeight: 0,
  },
  GainIcon: {
    transform: 'scaleY(-1)'
  },
};

export default styles;
