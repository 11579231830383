import { Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import AccountFormContext from './context';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import styles from './styles';
import { useContext } from 'react';
import { useTheme } from '@emotion/react';

const AccountFormContent = ({ component, disabled, label }) => {
  const {holdingEntityKey} = useContext(AccountFormContext);
  const StepForm = component;
  const theme = useTheme();
  const isBelowMediumBreakpoint = useMediaQuery(theme.breakpoints.down('md'));
  const {search} = useLocation();

  return (
    <Grid container rowSpacing={1} columnSpacing={2}>
      <Grid item xs={12} sx={styles.FormTitleContainer}>
        <Typography variant='h3' sx={styles.FormTitle}>
          {isBelowMediumBreakpoint && (
            <IconButton to={`../${search}`} component={RouterLink} sx={styles.GoBack}>
              <ArrowBackIcon color='primary' />
            </IconButton>
          )}
          {label}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='caption2'>* Required</Typography>
      </Grid>
      <Grid item xs={12}>
        <StepForm
          disabled={disabled}
          holdingEntityKey={holdingEntityKey}
        />
      </Grid>
    </Grid>
  );
};

export default AccountFormContent;
