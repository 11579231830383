import { atom, selector } from 'recoil';

const sessionState = atom({
  key: 'sessionState',
  default: {}
});

const isAuthenticatedState = selector({
  key: 'isAuthenticatedState',
  get: ({get}) => get(sessionState)?.isAuthenticated,
  set: ({get, set}, newValue) => set(sessionState, { ...get(sessionState), ...{isAuthenticated: newValue}})
});

const accessTokenState = selector({
  key: 'accessTokenState',
  get: ({get}) => get(sessionState)?.accessToken,
  set: ({get, set}, newValue) => set(sessionState, { ...get(sessionState), ...{accessToken: newValue}})
});

const accessTokenExpiresAtState = selector({
  key: 'accessTokenExpiresAtState',
  get: ({get}) => get(sessionState)?.accessTokenExpiresAt,
  set: ({get, set}, newValue) => set(sessionState, { ...get(sessionState), ...{accessTokenExpiresAt: newValue}})
});

const mimicModeState = selector({
  key: 'mimicModeState',
  get: ({get}) => get(sessionState)?.mimicMode,
  set: ({get, set}, newValue) => set(sessionState, { ...get(sessionState), ...{mimicMode: newValue}})
});

export {
  sessionState,
  isAuthenticatedState,
  accessTokenState,
  accessTokenExpiresAtState,
  mimicModeState
};
