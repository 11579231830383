import { Box, Stack } from '@mui/material';
import { companyState, fetchAnnouncements } from '../../../_state';
import { useCallback, useRef, useState } from 'react';

import AnnouncementModal from '../../AnnouncementModal';
import Announcements from './Announcements';
import AnnouncementsQueryContext from './context';
import CircularLoading from '../../../netcapital-components/CircularLoading';
import { DEFAULT_QUERY_PARAMS } from '../../../_actions/_api-urls';
import Pagination from '../../../netcapital-components/Pagination';
import { STICKY_HEADER_HEIGHT } from '../../../layouts/layout-styles';
import SearchAnnouncements from './search';
import styles from './styles';
import { useGetAnnouncements } from '../../../_actions/announcements.actions';
import { useRecoilValue } from 'recoil';

const AnnouncementsTab = () => {
  const company = useRecoilValue(companyState);
  const [queryParams, setQueryParams] = useState({...DEFAULT_QUERY_PARAMS, orderby: 'date:desc'});
  const appendOnFetch = false;
  const {loaded: loadedAnnouncements} = useGetAnnouncements(company.key, queryParams, appendOnFetch);
  const announcements = useRecoilValue(fetchAnnouncements('data'));
  const announcementsMetadata = useRecoilValue(fetchAnnouncements('metadata'));
  const [openIndex, setOpen] = useState(-1);
  const scrollPointRef = useRef();

  const handleClickAnnouncement = useCallback((index) => () => {
    setOpen(index);
  }, []);

  const closeAnnouncement = useCallback(() => {
    setOpen(-1);
  }, []);

  const handleChangePage = useCallback((newPage) => {
    setQueryParams({
      ...queryParams,
      page: newPage
    });
    window.scrollTo(0, scrollPointRef.current.offsetTop - STICKY_HEADER_HEIGHT);
  }, [queryParams]);

  const handleChangeItemsPerPage = useCallback((newItemsPerPage) => {
    setQueryParams({
      ...queryParams,
      page: 1,
      limit: newItemsPerPage
    });
    window.scrollTo(0, scrollPointRef.current.offsetTop - STICKY_HEADER_HEIGHT);
  }, [queryParams]);

  return (
    <AnnouncementsQueryContext.Provider value={{queryParams, setQueryParams}}>
      <Stack spacing={4}>
        <Box maxWidth={'sm'} alignSelf={'end'}>
          <SearchAnnouncements />
        </Box>
        <Box sx={styles.AnnouncementsContainer} ref={scrollPointRef}>
          <Announcements
            announcements={announcements || []}
            onClickAnnouncement={handleClickAnnouncement}
          />
          {!loadedAnnouncements && (
            <Stack justifyContent={'center'} alignItems={'center'} sx={styles.Loading}>
              <CircularLoading />
            </Stack>
          )}
        </Box>
        {announcements?.length > 0 && (
          <Pagination
            onChangePage={handleChangePage}
            onChangeItemsPerPage={handleChangeItemsPerPage}
            totalPages={announcementsMetadata?.pageCount}
          />
        )}
        {announcements?.length && openIndex !== -1 && (
          <AnnouncementModal
            announcement={announcements[openIndex]}
            onClose={closeAnnouncement}
            open={openIndex !== -1}
          />
        )}
      </Stack>
    </AnnouncementsQueryContext.Provider>
  );
};

export default AnnouncementsTab;
