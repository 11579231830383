import { LinearProgress, List, Typography } from '@mui/material';
import { fetchTransactions, investorState } from '../../_state';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { DEFAULT_QUERY_PARAMS } from '../../_actions/_api-urls';
import InfiniteScroll from '../../netcapital-components/InfiniteScroll';
import Section from '../Section';
import Transaction from './Transaction';
import produce from 'immer';
import { useGetTransactions } from '../../_actions/transactions.actions';

const transactionsExpansionStorage = localStorage.getItem('transactionsExpansionStorage') === 'true' || true;

const TransactionsOverview = () => {
  const [expanded, setExpanded] = useState(transactionsExpansionStorage);
  const handleCardExpansion = useCallback((value) => {
    setExpanded(value);
    localStorage.setItem('transactionsExpansionStorage', value);
  }, [setExpanded]);

  const investor = useRecoilValue(investorState);
  const [queryParams, setQueryParams] = useState({
    ...DEFAULT_QUERY_PARAMS,
    holdingEntityKey: investor.entityKey
  });
  const { loaded: loadedTransactions } = useGetTransactions(queryParams);
  const [transactions, setTransactions] = useRecoilState(fetchTransactions('data'));
  const transactionsMeta = useRecoilValue(fetchTransactions('metadata'));
  const hasMoreTransactions = transactionsMeta?.limit * queryParams?.page < transactionsMeta?.totalCount;

  const fetchMoreTransactions = useCallback(() => {
    setQueryParams({...queryParams, page: queryParams.page + 1});
  }, [queryParams]);

  useEffect(() => {
    setQueryParams({
      ...DEFAULT_QUERY_PARAMS,
      holdingEntityKey: investor.entityKey,
      page: 1
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investor.entityKey]);

  const onCancelTransaction = useCallback((transaction) => {
    const transactionIndex = transactions.findIndex((tx) => tx.offerKey === transaction.offerKey);
    const updatedTransaction = produce(transactions[transactionIndex], (draft) => {
      draft.offerStatus = 'FAILED';
    });
    const updatedTransactions = produce(transactions, (draft) => {
      draft[transactionIndex] = updatedTransaction;
    });
    setTransactions(updatedTransactions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactions]);

  return (
    <Section
      componentName='Transactions'
      content={transactions?.length ?
        <InfiniteScroll
          currentItemsCount={transactions.length}
          currentPage={queryParams.page}
          endMessage={<Typography variant='caption' textAlign='center' component='div'>End of results</Typography>}
          fetchData={fetchMoreTransactions}
          hasMore={hasMoreTransactions}
          loadingMessage={<LinearProgress />}
          scrollHeight={400}
        >
          <List disablePadding>
            {transactions.map(transaction => (
              <Transaction
                key={transaction.offerKey}
                onCancelTransaction={onCancelTransaction}
                transaction={transaction}
              />
            ))}
          </List>
        </InfiniteScroll>
        :
        null
      }
      emptyMessage='You have not initiated any transactions.'
      expanded={expanded}
      onAction={handleCardExpansion}
      loaded={loadedTransactions}
      title='Transactions'
    />
  );
};

export default TransactionsOverview;
