import { Link, Stack } from '@mui/material';

import { styled } from '@mui/material/styles';
import styles from './styles';

const MenuLink = styled(Link)(styles.MenuLink);

const FooterLinks = () => {
  return (
    <Stack
      direction='column'
      justifyContent='flex-start'
      spacing={1}>
      <MenuLink href='https://info.netcapital.com/issuers-contact-us' variant='body2'>Raise Capital</MenuLink>
      <MenuLink href='mailto:hello@netcapital.com' variant='body2' sx={styles.FooterMenuEmail}>hello@netcapital.com</MenuLink>
    </Stack>
  );
};

export default FooterLinks;
