import { INCOME_NETWORTH_OPTIONS, INVESTMENT_FUNDING_SOURCES, LIQUIDITY_NEEDS, RISK_TOLERANCES, YES_NO } from '../../../../static-info';
import { fetchHoldingEntityGlobalLegalName, fetchHoldingEntityInfoAttribute } from '../../../../_state';
import { useCallback, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import AddressField from '../../../FormFields/Address';
import DateSelector from '../../../../netcapital-components/DateSelector';
import EntityType from './EntityType';
import Field from '../../../FormFields/Field';
import { Grid } from '@mui/material';
import HasMilitaryOrGovernmentAffiliations from '../../MilitaryOrGovernmentAffiliations';
import HoldingEntityAccreditation from './HoldingEntityAccreditation';
import HoldingEntityInfoReadOnly from './read-only';
import NetWorthField from '../../../FormFields/NetWorth';
import PropTypes from 'prop-types';
import RadioGroup from '../../../../netcapital-components/RadioGroup';
import Selectbox from '../../../../netcapital-components/Selectbox';
import TextField from '../../../../netcapital-components/TextField';

const HoldingEntityInfoFields = ({ holdingEntityKey, disabled }) => {
  const [legalName, setLegalName] = useRecoilState(fetchHoldingEntityInfoAttribute('legalName'));
  const [federalTaxId, setFederalTaxId] = useRecoilState(fetchHoldingEntityInfoAttribute('federalTaxId'));
  const [dateFormed, setDateFormed] = useRecoilState(fetchHoldingEntityInfoAttribute('dateOfFormation'));
  const [isOrRelatedToIndustryProfessional, setIsOrRelatedToIndustryProfessional] = useRecoilState(fetchHoldingEntityInfoAttribute('affiliations.isOrRelatedToIndustryProfessional'));
  const [financialEntityIdentifier, setFinancialEntityIdentifier] = useRecoilState(fetchHoldingEntityInfoAttribute('affiliations.industryProfessionalName'));
  const [isOrRelatedToControlPerson, setIsOrRelatedToControlPerson] = useRecoilState(fetchHoldingEntityInfoAttribute('affiliations.isOrRelatedToControlPerson'));
  const [controlEntityIdentifier, setControlEntityIdentifier] = useRecoilState(fetchHoldingEntityInfoAttribute('affiliations.controlPersonCompanyName'));
  const [liquidityNeeds, setLiquidityNeeds] = useRecoilState(fetchHoldingEntityInfoAttribute('liquidityNeeds'));
  const [riskTolerance, setRiskTolerance] = useRecoilState(fetchHoldingEntityInfoAttribute('riskTolerance'));

  const [investmentFundingSources, setInvestmentFundingSources] = useRecoilState(fetchHoldingEntityInfoAttribute('investmentFundSources.fundSources'));
  const [otherInvestmentFundingSource, setOtherInvestmentFundingSource] = useRecoilState(fetchHoldingEntityInfoAttribute('investmentFundSources.otherSource'));

  const setHoldingEntityGlobalLegalName = useSetRecoilState(fetchHoldingEntityGlobalLegalName(holdingEntityKey));
  const handleChangeLegalName = useCallback((e) => {
    setLegalName(e.target.value);
    setHoldingEntityGlobalLegalName(e.target.value);
  }, [setHoldingEntityGlobalLegalName, setLegalName]);

  const handleChangeFederalTaxId = useCallback((e) => setFederalTaxId(e.target.value), [setFederalTaxId]);
  const handleDateFormedChange = useCallback((date) => setDateFormed(date), [setDateFormed]);

  const handleChangeIsOrRelatedToIndustryProfessional = useCallback((e) => {
    if(e.target.value === 'false'){
      setFinancialEntityIdentifier('');
    }
    setIsOrRelatedToIndustryProfessional(e.target.value === 'true');
  },[setIsOrRelatedToIndustryProfessional, setFinancialEntityIdentifier]);
  const handleChangeIsOrRelatedToControlPerson = useCallback((e) => {
    if(e.target.value === 'false'){
      setControlEntityIdentifier('');
    }
    setIsOrRelatedToControlPerson(e.target.value === 'true');
  },[setIsOrRelatedToControlPerson, setControlEntityIdentifier]);
  const handleChangeFinancialEntityIdentifier = useCallback((e) => {
    setFinancialEntityIdentifier(e.target.value);
  },[setFinancialEntityIdentifier]);
  const handleChangeControlEntityIdentifier = useCallback((e) => {
    setControlEntityIdentifier(e.target.value);
  },[setControlEntityIdentifier]);
  const handleChangeLiquidityNeeds = useCallback((e) => setLiquidityNeeds(e.target.value), [setLiquidityNeeds]);
  const handleChangeRiskTolerance = useCallback((e) => setRiskTolerance(e.target.value), [setRiskTolerance]);

  // pre-set fundingSources if the object is null
  const investmentFundingSourcesObject = useRecoilValue(fetchHoldingEntityInfoAttribute('investmentFundSources'));
  useEffect(() => {
    if (investmentFundingSourcesObject === null) {
      setInvestmentFundingSources([]);
    }
  }, [investmentFundingSourcesObject, setInvestmentFundingSources]);
  const handleChangeInvestmentFundingSources = useCallback((e) => {
    if(Array.isArray(e.target.value) && !e.target.value.includes('OTHER')){
      setOtherInvestmentFundingSource('');
    }
    setInvestmentFundingSources(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value,);
  }, [setInvestmentFundingSources, setOtherInvestmentFundingSource]);
  const investmentFundingSourceIsOther = investmentFundingSources?.findIndex(reason => reason === 'OTHER') > -1;
  const handleChangeOtherInvestmentFundingSource = useCallback((e) => setOtherInvestmentFundingSource(e.target.value), [setOtherInvestmentFundingSource]);

  if (disabled) return <HoldingEntityInfoReadOnly />;

  return (
    <Grid item container rowSpacing={4} columnSpacing={2}>
      <Grid item xs={12} sm={6}>
        <Field label='Entity Name *'>
          <TextField
            value={legalName || ''}
            onChange={handleChangeLegalName}
            variant='outlined'
            fieldName={'legalName'}
          />
        </Field>
      </Grid>
      <EntityType />
      <Grid item xs={12} sm={6}>
        <Field label='Federal Tax ID Number *'>
          <TextField
            value={federalTaxId || ''}
            onChange={handleChangeFederalTaxId}
            variant='outlined'
            fieldName={'federalTaxId'}
          />
        </Field>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field label='Date of Formation *'>
          <DateSelector
            onChange={handleDateFormedChange}
            value={dateFormed || ''}
            fieldName={'dateOfFormation'}
          />
        </Field>
      </Grid>
      <Grid item xs={12}>
        <AddressField
          label='Address *'
          recoilState={fetchHoldingEntityInfoAttribute('address')}
          requirementLabel='*'
          forceCountry='US'
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field label='How important are the entity‘s liquidity needs? *'>
          <Selectbox
            options={LIQUIDITY_NEEDS}
            value={liquidityNeeds || ''}
            onChange={handleChangeLiquidityNeeds}
            fieldName={'liquidityNeeds'}
          />
        </Field>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field label='How would you describe the entity‘s risk tolerance? *'>
          <Selectbox
            options={RISK_TOLERANCES}
            value={riskTolerance || ''}
            onChange={handleChangeRiskTolerance}
            fieldName={'riskTolerance'}
          />
        </Field>
      </Grid>
      <Grid item xs={12}>
        <Field label='Investment Funding Sources *'>
          <Selectbox
            multiple
            options={INVESTMENT_FUNDING_SOURCES.HOLDING_ENTITY}
            value={investmentFundingSources || []}
            onChange={handleChangeInvestmentFundingSources}
            placeholder='Please select all that apply'
            fieldName={'investmentFundSources.fundSources'}
          />
        </Field>
      </Grid>
      {investmentFundingSourceIsOther && (
        <Grid item xs={12}>
          <Field label={'As your response above includes \'Other\', please explain: *'}>
            <TextField
              value={otherInvestmentFundingSource || ''}
              onChange={handleChangeOtherInvestmentFundingSource}
              placeholder='Other Funding Source'
              fieldName={'investmentFundSources.otherSource'}
            />
          </Field>
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <NetWorthField
          label='Net Worth *'
          recoilState={fetchHoldingEntityInfoAttribute('netWorth')}
          options={INCOME_NETWORTH_OPTIONS}
          fieldName={'netWorth'}
        />
      </Grid>
      <HoldingEntityAccreditation />
      <Grid item xs={12}>
        <Field label='Are you, or is anyone affiliated with this entity, an associated person of a broker-dealer or member of a National Securities Exchange? *'>
          <RadioGroup
            options={YES_NO}
            value={isOrRelatedToIndustryProfessional}
            onChange={handleChangeIsOrRelatedToIndustryProfessional}
            fieldName={'affiliations.isOrRelatedToIndustryProfessional'}
          />
        </Field>
      </Grid>
      {isOrRelatedToIndustryProfessional && (
        <Grid item xs={12}>
          <Field label='Name of Broker-Dealer or National Securities Exchange *'>
            <TextField
              value={financialEntityIdentifier || ''}
              fieldName={'affiliations.industryProfessionalName'}
              onChange={handleChangeFinancialEntityIdentifier}
              name='Broker-Dealer or National Securities Exchange'
              placeholder='Add the name of a relevant entity'
            />
          </Field>
        </Grid>
      )}
      <Grid item xs={12}>
        <Field label='Are you, or is anyone affiliated with this entity, a director, 10% stakeholder, or senior officer of a publicly traded company? *'>
          <RadioGroup
            options={YES_NO}
            value={isOrRelatedToControlPerson}
            fieldName={'affiliations.isOrRelatedToControlPerson'}
            onChange={handleChangeIsOrRelatedToControlPerson}
          />
        </Field>
      </Grid>
      {isOrRelatedToControlPerson && (
        <Grid item xs={12}>
          <Field label='Name of Company or Ticker Symbol *'>
            <TextField
              value={controlEntityIdentifier || ''}
              fieldName={'affiliations.controlPersonCompanyName'}
              onChange={handleChangeControlEntityIdentifier}
              name='Company Name or Ticker Symbol'
              placeholder='Add a company name or ticker symbol'
            />
          </Field>
        </Grid>
      )}
      <HasMilitaryOrGovernmentAffiliations
        label='Are you, or is anyone affiliated with this entity, currently or formerly, either: A senior military, government, or political official; or an immediate family member or close associate, either personally or professionally, of a senior military, government, or political official? *'
        recoilState={{
          hasMilitaryOrGovernmentAffiliation: fetchHoldingEntityInfoAttribute('affiliations.hasMilitaryOrGovernmentAffiliation'),
          militaryOrGovernmentAffiliation: fetchHoldingEntityInfoAttribute('affiliations.militaryOrGovernmentAffiliation'),
        }}
      />
    </Grid>
  );
};

HoldingEntityInfoFields.defaultProps = {
  disabled: false,
};

HoldingEntityInfoFields.propTypes = {
  holdingEntityKey: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default HoldingEntityInfoFields;
