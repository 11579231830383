export const PAGE_MARGIN_WIDTHS = Object.freeze({
  xs: 2,
  sm: 3,
  md: 5,
  lg: 4,
  xl: 5,
});

export const MAIN_MENU_MARGIN_WIDTHS = Object.freeze({
  xs: 2,
  sm: 3,
  md: 5,
  lg: 2,
  xl: 3,
});

const calculateNegativeMargins = (widthsObj) => {
  Object.keys(widthsObj).forEach(breakpoint => {
    widthsObj[breakpoint] *= -1;
  });
  return widthsObj;
};
export const NEGATIVE_PAGE_MARGIN_WIDTHS = calculateNegativeMargins({ ...PAGE_MARGIN_WIDTHS });

export const MAIN_MENU_WIDTH = {
  xs: 320,
  sm: 320,
  md: 320,
  lg: 236,
  xl: 300,
};

export const STICKY_HEADER_HEIGHT = 72;
