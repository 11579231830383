import AddressField from '../../../FormFields/Address';
import EmployerInfoReadOnly from './read-only';
import Field from '../../../FormFields/Field';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import TextField from '../../../../netcapital-components/TextField';
import { fetchUserPersonalInfoAttribute } from '../../../../_state';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

const EmployerInfo = ({ disabled }) => {
  const [employerName, setEmployerName] = useRecoilState(fetchUserPersonalInfoAttribute('employment.employer.name'));
  const [employerOccupation, setEmployerOccupation] = useRecoilState(fetchUserPersonalInfoAttribute('employment.employer.occupation'));
  const [employerTitle, setEmployerTitle] = useRecoilState(fetchUserPersonalInfoAttribute('employment.employer.title'));

  const handleChangeEmployerName = useCallback((e) => setEmployerName(e.target.value), [setEmployerName]);
  const handleChangeEmployerOccupation = useCallback((e) => setEmployerOccupation(e.target.value), [setEmployerOccupation]);
  const handleChangeEmployerTitle = useCallback((e) => setEmployerTitle(e.target.value), [setEmployerTitle]);

  if (disabled) return <EmployerInfoReadOnly />;

  return (
    <Grid item container rowSpacing={4} columnSpacing={2}>
      <Grid item xs={12} sm={6}>
        <Field label='Employer Name *'>
          <TextField
            value={employerName}
            onChange={handleChangeEmployerName}
            fieldName={'employment.employer.name'}
          />
        </Field>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field label='Job Title *'>
          <TextField
            value={employerTitle}
            onChange={handleChangeEmployerTitle}
            fieldName={'employment.employer.title'}
          />
        </Field>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field label='Occupation *'>
          <TextField
            value={employerOccupation}
            onChange={handleChangeEmployerOccupation}
            fieldName={'employment.employer.occupation'}
          />
        </Field>
      </Grid>
      <Grid item xs={12}>
        <AddressField
          label='Employer Address (optional)'
          recoilState={fetchUserPersonalInfoAttribute('employment.employer.address')}
          displayPhone={false}
          fieldNamePrefix={'employment.employer.address'}
        />
      </Grid>
    </Grid>
  );
};

EmployerInfo.defaultProps = {
  disabled: false,
};

EmployerInfo.propTypes = {
  disabled: PropTypes.bool,
};

export default EmployerInfo;
