const styles = {
  Container: ({palette, spacing}) => ({
    borderWidth: '1px',
    borderColor: palette.grey[500],
    borderStyle: 'solid',
    borderRadius: '1.875rem',
    padding: spacing(1),
    position: 'relative',
  }),
  Input: {
    paddingLeft: 3,
    paddingRight: 3,
    '.MuiInputBase-input': {
      color: 'primary',
      fontSize: '1.25rem',
      textAlign: 'center',
      paddingBottom: 0,
    },
  },
  Button: {
    backgroundColor: 'primary.light',
    display: 'block',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    padding: 0,
    lineHeight: 0,
    zIndex: 1,
  },
  ButtonLeft: ({spacing}) => ({
    left: spacing(1),
  }),
  ButtonRight:({spacing}) => ( {
    right: spacing(1),
  }),
};

export default styles;
