import { Grid, Link } from '@mui/material';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';

const SocialLinks = () => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={2}>
        <Link href='https://www.facebook.com/Netcapital/' target='_blank' rel='noopener' color='common.white'>
          <FacebookIcon />
        </Link>
      </Grid>
      <Grid item xs={2}>
        <Link href='https://www.linkedin.com/company/netcapital' target='_blank' rel='noopener' color='common.white'>
          <LinkedInIcon />
        </Link>
      </Grid>
      <Grid item xs={2}>
        <Link href='https://twitter.com/netcapital' target='_blank' rel='noopener' color='common.white'>
          <TwitterIcon />
        </Link>
      </Grid>
      <Grid item xs={2}>
        <Link href='https://www.instagram.com/netcapital/' target='_blank' rel='noopener' color='common.white'>
          <InstagramIcon />
        </Link>
      </Grid>
      <Grid item xs={2}>
        <Link href='https://www.youtube.com/channel/UCxwsozT-gzwmpewB4IXnRsw' target='_blank' rel='noopener' color='common.white'>
          <YouTubeIcon />
        </Link>
      </Grid>
    </Grid>
  );
};

export default SocialLinks;
