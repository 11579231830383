const styles = {
  ContentContainer: ({ breakpoints }) => ({
    position: 'relative',
    padding: '8px',
    [breakpoints.up('sm')]:{
      padding: '16px',
    },
  }),
  OfferValues: () => ({
    alignItems: 'center',
  }),
  At: ({breakpoints}) => ({
    fontWeight: 'medium',
    [breakpoints.up('sm')]: {
      position: 'relative',
      top: '-0.5rem',
    },
  }),
  InputLabel: () => ({
    color: 'grey.600',
    fontSize: '0.75rem',
    fontWeight: 'medium',
    textAlign: 'center',
  }),
  RowLabel: () => ({
    flexGrow: 1,
  }),
  RowBottom: () => ({
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    borderBottomColor: 'grey.400',
  }),
  RowTotal: () => ({
    fontWeight: 'medium',
  }),
  ConfirmContent: () => ({
    textAlign: 'center',
  }),
  ButtonConfirm: () => ({
    height: '1.5rem',
  }),
  ToggleButton: () => ({
    width: {
      xs: '110px',
      sm: '146px',
    },
  }),
  loading: () => ({
    height: '1rem',
    width: '1rem'
  }),
  Success: () => ({
    color: 'common.white',
    // TODO: cleaner way to handle these overrides?
    '.MuiTypography-cardTitle, .MuiTypography-body1, .MuiTypography-body2, .MuiLink-root': {
      color: 'common.white',
    },
  }),
  ButtonCancel: () => ({
    lineHeight: 1,
  }),
  ButtonDarkGreen: () => ({
    // This is a hack to get around a bug in MUI Button
    // that does not work with colors not keyed as `main`
    backgroundColor: 'gain.dark',
    '&.MuiButtonBase-root':{
      marginTop: 'auto',
    },
    '&.MuiButton-root:hover':{
      color: 'common.black',
      backgroundColor: 'gain.light'
    }
  }),
  ButtonLightPurple: () => ({
    // This is a hack to get around a bug in MUI Button
    // that does not work with colors not keyed as `main`
    backgroundColor: 'primary.dark',
    color: 'common.white',
    '&.MuiButtonBase-root':{
      marginTop: 'auto',
    },
    '&.MuiButton-root:hover':{
      backgroundColor: 'primary.light',
      color: 'primary.main'
    }
  }),
  PendingOffersLink: {
    '.MuiAlert-action': {
      alignItems: 'center',
      paddingTop: 0,
    }
  },
};

export default styles;
