import { EXPENSES_TEMPLUM_OPTIONS, INCOME_NETWORTH_OPTIONS, INVESTMENT_FUNDING_SOURCES, LIQUIDITY_NEEDS, RISK_TOLERANCES, TAX_FILING_STATUS } from '../../../../static-info';
import { NumberFormat, getOptionLabelByValue, renderBooleanAnswer } from '../../../../_helpers';

import { Grid } from '@mui/material';
import ReadOnlyField from '../../ReadOnlyField';
import { fetchUserFinancialInfoAttribute } from '../../../../_state';
import { useRecoilValue } from 'recoil';

const FinancialInfoReadOnly = () => {
  const taxStatus = useRecoilValue(fetchUserFinancialInfoAttribute('taxStatus'));
  const investmentFundSources = useRecoilValue(fetchUserFinancialInfoAttribute('investmentFundSources.fundSources'));
  const otherInvestmentFundSource = useRecoilValue(fetchUserFinancialInfoAttribute('investmentFundSources.otherSource'));
  const annualIncome = useRecoilValue(fetchUserFinancialInfoAttribute('annualIncome'));
  const netWorth = useRecoilValue(fetchUserFinancialInfoAttribute('netWorth'));
  const liquidNetWorth = useRecoilValue(fetchUserFinancialInfoAttribute('liquidNetWorth'));
  const annualExpenses = useRecoilValue(fetchUserFinancialInfoAttribute('annualExpenses'));
  const hasIlliquidInvestments = useRecoilValue(fetchUserFinancialInfoAttribute('hasIlliquidInvestments'));
  const liquidityNeeds = useRecoilValue(fetchUserFinancialInfoAttribute('liquidityNeeds'));
  const riskTolerance = useRecoilValue(fetchUserFinancialInfoAttribute('riskTolerance'));

  return (
    <Grid item container xs={12} rowSpacing={1}>
      <ReadOnlyField
        label={'Tax Status'}
        value={getOptionLabelByValue(taxStatus, TAX_FILING_STATUS)}
      />
      <ReadOnlyField
        label={'Investment Fund Sources'}
        value={getOptionLabelByValue(investmentFundSources, INVESTMENT_FUNDING_SOURCES.INDIVIDUAL)}
      />
      {otherInvestmentFundSource && (
        <ReadOnlyField
          label={'Other Investment Fund Source'}
          value={otherInvestmentFundSource}
        />
      )}
      <ReadOnlyField
        label={'Annual Income'}
        value={getOptionLabelByValue(annualIncome, INCOME_NETWORTH_OPTIONS)}
      />
      <ReadOnlyField
        label={'Net Worth'}
        value={getOptionLabelByValue(netWorth, INCOME_NETWORTH_OPTIONS)}
      />
      <ReadOnlyField
        label={'Liquid Net Worth'}
        value={NumberFormat.cents(liquidNetWorth)}
      />
      <ReadOnlyField
        label={'Annual Expenses'}
        value={getOptionLabelByValue(annualExpenses, EXPENSES_TEMPLUM_OPTIONS)}
      />
      <ReadOnlyField
        label={'Have you previously invested in illiquid, alternative investments?'}
        value={renderBooleanAnswer(hasIlliquidInvestments)}
      />
      <ReadOnlyField
        label={'Liquidity Needs'}
        value={getOptionLabelByValue(liquidityNeeds, LIQUIDITY_NEEDS)}
      />
      <ReadOnlyField
        label={'Risk Tolerance'}
        value={getOptionLabelByValue(riskTolerance, RISK_TOLERANCES)}
      />
    </Grid>
  );
};

export default FinancialInfoReadOnly;
