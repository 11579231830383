import { NumberFormat, pluralize } from '../../_helpers';
import { TableCell, TableRow, Typography } from '@mui/material';

import PendingOfferDetails from './PendingOfferDetails';
import PropTypes from 'prop-types';

const PendingOffer = ({ offer, onSelectPrice }) => {

  return (
    <TableRow>
      <TableCell>
        <Typography
          variant='body2'
          color={offer.type === 'BID' ? 'gain.main' : 'loss.main'}
        >
          {NumberFormat.cents(offer.pricePerUnit, {minimumFractionDigits: 2})}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body2'>
          {NumberFormat.thousands(offer.totalQuantity)} {pluralize('share', offer.totalQuantity)}
        </Typography>
      </TableCell>
      <TableCell>
        <PendingOfferDetails offer={offer} onSelectPrice={onSelectPrice} />
      </TableCell>
    </TableRow>
  );
};

PendingOffer.propTypes = {
  offer: PropTypes.shape({
    pricePerUnit: PropTypes.number.isRequired,
    totalQuantity: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired
  }).isRequired
};

export default PendingOffer;
