import { Box, Button, IconButton, Stack } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import styles from './styles';
import useEmblaCarousel from 'embla-carousel-react';

const ButtonPrevious = ({buttonType, onClick}) => {
  if (buttonType === 'large') {
    return (
      <Button
        variant='text'
        onClick={onClick}
        startIcon={<ArrowBackOutlinedIcon />}
        sx={[styles.PrevNextButtonLarge, styles.PrevButton]}
      >Previous</Button>
    );
  }
  return (
    <IconButton name='CarouselPrevButton' onClick={onClick} sx={[styles.PrevNextButton, styles.PrevButton]} disableRipple>
      <NavigateBeforeIcon />
    </IconButton>
  );
};

const ButtonNext = ({buttonType, onClick}) => {
  if (buttonType === 'large') {
    return (
      <Button
        variant='text'
        onClick={onClick}
        endIcon={<ArrowForwardOutlinedIcon />}
        sx={[styles.PrevNextButtonLarge, styles.NextButton]}
      >Next</Button>
    );
  }
  return (
    <IconButton name='CarouselNextButton' onClick={onClick} sx={[styles.PrevNextButton, styles.NextButton]} disableRipple>
      <NavigateNextIcon />
    </IconButton>
  );
};

const carouselOptions = {
  align: 0,
  containScroll: 'trimSnaps',
  skipSnaps: true,
  slidesToScroll: 'auto',
};

const Carousel = (props) => {
  const {
    children,
    options={},
    arrows=true,
    dots=false,
    buttonType='normal',
  } = props;

  const [emblaRef, emblaApi] = useEmblaCarousel({...carouselOptions, ...options});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showPrevButton, setShowPrevButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const scrollPrev = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
  }, [emblaApi]);

  const scrollToSlide = useCallback((index) => () => {
    if (!emblaApi) return;
    emblaApi.scrollTo(index);
  }, [emblaApi]);

  const onSelect = useCallback(() => {
    if (!emblaApi || !arrows) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());
    setShowNextButton(emblaApi.canScrollNext());
    setShowPrevButton(emblaApi.canScrollPrev());
  }, [emblaApi, arrows]);

  useEffect(() => {
    if (!emblaApi) return;
    emblaApi.reInit(); // prevent race condition where number of slides is wrong
    emblaApi.on('select', onSelect);
    emblaApi.on('init', onSelect);
    setScrollSnaps(emblaApi.scrollSnapList());
  }, [emblaApi, onSelect, children]);

  return (
    <Box name='Carousel' sx={styles.Carousel}>
      <Box name='CarouselViewport' ref={emblaRef}>
        <Box name='CarouselContainer' sx={styles.CarouselContainer}>
          {children}
        </Box>
      </Box>
      <Stack direction='row' spacing={2} sx={buttonType === 'large' ? [styles.Dots, styles.LargeButtonsContainer] : styles.Dots}>
        {dots && scrollSnaps.map((snapPoint, index) => (
          <Box
            onClick={scrollToSlide(index)}
            sx={selectedIndex === index ? [styles.Dot, styles.DotActive] : styles.Dot}
            key={snapPoint}
            name={`CarouselDot-${index}`} />
        ))}
        {arrows && showPrevButton && <ButtonPrevious onClick={scrollPrev} buttonType={buttonType} />}
        {arrows && showNextButton && <ButtonNext onClick={scrollNext}  buttonType={buttonType} />}
      </Stack>
    </Box>
  );
};

export default Carousel;
