import { atom } from 'recoil';

const companyCommunityStatsState = atom({
  key: 'companyCommunityStatsState',
  default: {}
});

export {
  companyCommunityStatsState,
};
