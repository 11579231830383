import { Box, Typography } from '@mui/material';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';

import CurrentRaiseContext from './context';
import { companyFundingHistoryGraphState } from '../../../_state';
import styles from './styles';
import { useContext } from 'react';
import { useRecoilValue } from 'recoil';
import { useTheme } from '@emotion/react';

const RaisePercentageChart = () => {
  const theme = useTheme();
  const fundingHistoryGraphData = useRecoilValue(companyFundingHistoryGraphState);
  const {selectedRaise} = useContext(CurrentRaiseContext);
  const raise = fundingHistoryGraphData?.find(raiseData => raiseData.offerKey === selectedRaise);
  const data = [
    {
      name: 'amountRaised',
      value: raise?.raisedGoal,
    },
    {
      name: 'amountNotRaised',
      value: raise?.goal - raise?.raisedGoal || 0,
    },
  ];

  if (!raise) return false;

  return (
    <Box sx={styles.RaisePercentageChartWrap}>
      <ResponsiveContainer height={260}>
        <PieChart>
          <Pie
            data={data}
            dataKey='value'
            stroke='none'
          >
            {data.map((entry) => (
              <Cell
                key={`cell-${entry.offerKey}`}
                fill={entry.name === 'amountRaised' ? theme.palette.gain.main : theme.palette.primary.main}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <Typography
        variant='h3'
        color={theme.palette.common.white}
        sx={styles.RaisePercentage}
      >
        {`${Math.round(raise.percentage)}%`}
      </Typography>
    </Box>
  );
};

export default RaisePercentageChart;
