import { atom, selectorFamily } from 'recoil';

import _ from 'lodash';
import produce from 'immer';

const holdingEntitiesState = atom({
  key: 'holdingEntitiesState',
  default: undefined
});

const fetchHoldingEntityGlobalLegalName = selectorFamily({
  key: 'fetchHoldingEntityGlobalLegalName',
  get: entityKey => ({get}) => {
    return _.get(get(holdingEntitiesState)?.find(entity => entity.entityKey === entityKey), 'legalName');
  },
  set: entityKey => ({get, set}, newValue) => {
    const entityIndex = get(holdingEntitiesState)?.findIndex(entity => entity.entityKey === entityKey);
    const updatedValues = produce(get(holdingEntitiesState), (draft) => {
      _.set(draft[entityIndex], 'legalName', newValue);
    });
    set(holdingEntitiesState, [...updatedValues]);
  }
});

const holdingEntityInfoState = atom({
  key: 'holdingEntityInfoState',
  default: {}
});

const fetchHoldingEntityInfoAttribute = selectorFamily({
  key: 'fetchHoldingEntityInfoAttribute',
  get: attribute => ({get}) => _.get(get(holdingEntityInfoState), attribute),
  set: attribute => ({set}, newValue) => {
    set(holdingEntityInfoState,
      prevState => produce(prevState, (draft) => _.set(draft, attribute, newValue))
    );
  },
});

const holdingEntityRepresentativeContactInfoState = atom({
  key: 'holdingEntityRepresentativeContactInfoState',
  default: {}
});

const fetchHoldingEntityRepresentativeContactInfoAttribute = selectorFamily({
  key: 'fetchHoldingEntityRepresentativeContactInfoAttribute',
  get: attribute => ({get}) => _.get(get(holdingEntityRepresentativeContactInfoState), attribute),
  set: attribute => ({set}, newValue) => {
    set(holdingEntityRepresentativeContactInfoState,
      prevState => produce(prevState, (draft) => _.set(draft, attribute, newValue))
    );
  },
});

const holdingEntityDocumentsState = atom({
  key: 'holdingEntityDocumentsState',
  default: []
});

const fetchHoldingEntityDocument = selectorFamily({
  key: 'fetchHoldingEntityDocument',
  get: usage => ({get}) => {
    const files = get(holdingEntityDocumentsState) || [];
    return files.find(file => file.usage === usage);
  },
  set: usage => ({get, set}, newFile) => {
    const files = get(holdingEntityDocumentsState) || [];

    if (!newFile) {
      set(holdingEntityDocumentsState, files.filter(file => file.usage !== usage));
    }
    else {
      const fileIndex = Math.max(files.findIndex(file => file.usage === usage), files.length);
      const updatedFiles = produce(files, (draft) => {
        draft[fileIndex] = newFile;
      });
      set(holdingEntityDocumentsState, updatedFiles);
    }
  }
});

const holdingEntityBeneficialOwnersState = atom({
  key: 'holdingEntityBeneficialOwnersState',
  default: {}
});

const fetchHoldingEntityBeneficialOwnersAttribute = selectorFamily({
  key: 'fetchHoldingEntityBeneficialOwnersAttribute',
  get: attribute => ({get}) => _.get(get(holdingEntityBeneficialOwnersState), attribute),
  set: attribute => ({set}, newValue) => {
    set(holdingEntityBeneficialOwnersState,
      prevState => produce(prevState, (draft) => _.set(draft, attribute, newValue))
    );
  },
});

const fetchHoldingEntityBeneficialOwnerVerificationDocType = selectorFamily({
  key: 'fetchHoldingEntityBeneficialOwnerVerificationDocType',
  get: index => ({get}) => get(holdingEntityBeneficialOwnersState)?.beneficialOwners?.[index]?.verificationDocType || null,
  set: index => ({set}, newValue) => {
    set(holdingEntityBeneficialOwnersState,
      prevState => produce(prevState, (draft) => {
        _.set(draft, `beneficialOwners[${index}].verificationDocType`, newValue);
      })
    );
  }
});

const fetchHoldingEntityBeneficialOwnerVerificationDocuments = selectorFamily({
  key: 'fetchHoldingEntityBeneficialOwnerVerificationDocuments',
  get: index => ({get}) => {
    const files = get(holdingEntityBeneficialOwnersState)?.beneficialOwners?.[index]?.verificationDocuments || [];
    return files ? files : [];
  },
  set: index => ({set}, newFiles) => {
    set(holdingEntityBeneficialOwnersState,
      prevState => produce(prevState, (draft) => {
        _.set(draft, `beneficialOwners[${index}].verificationDocuments`, newFiles);
      })
    );
  }
});

export {
  fetchHoldingEntityGlobalLegalName,
  holdingEntitiesState,
  holdingEntityInfoState,
  fetchHoldingEntityInfoAttribute,
  holdingEntityDocumentsState,
  fetchHoldingEntityDocument,
  holdingEntityRepresentativeContactInfoState,
  fetchHoldingEntityRepresentativeContactInfoAttribute,
  fetchHoldingEntityBeneficialOwnerVerificationDocuments,
  fetchHoldingEntityBeneficialOwnerVerificationDocType,
  holdingEntityBeneficialOwnersState,
  fetchHoldingEntityBeneficialOwnersAttribute,
};
