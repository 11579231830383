import { Table, TableBody, TableContainer, TableHead, Typography } from '@mui/material';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';

import EmptyStateDisplay from '../../components/EmptyStateDisplay';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styles from './styles';

const InfiniteScrollTable = (props) => {
  const {
    body,
    componentName,
    currentPage,
    emptyMessage,
    endMessage,
    fetchMore,
    hasMore,
    header,
    loaded,
    minHeight,
    maxHeight
  } = props;

  const tableEl = useRef();
  const [distanceBottom, setDistanceBottom] = useState(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const scrollListener = useCallback(_.debounce(() => {
    const bottom = tableEl.current.scrollHeight - tableEl.current.clientHeight;
    if (!distanceBottom) {
      setDistanceBottom(Math.round(bottom * 0.1));
    }
    if (tableEl.current.scrollTop >= bottom - distanceBottom && hasMore && loaded) {
      fetchMore();
    }
  }, 200), [tableEl, distanceBottom, hasMore, loaded]);

  useLayoutEffect(() => {
    const tableRef = tableEl.current;
    tableRef.addEventListener('scroll', scrollListener);
    return () => {
      tableRef.removeEventListener('scroll', scrollListener);
    };
  }, [scrollListener]);

  if(loaded && !body?.length){
    //show table column headers even when !data.length
    return (
      <>
        <TableContainer ref={tableEl} sx={[styles.TableContainer, { maxHeight }, { marginBottom: 4 }]}>
          <Table stickyHeader>
            <TableHead>
              {header}
            </TableHead>
          </Table>
        </TableContainer>
        <EmptyStateDisplay componentName={componentName} message={emptyMessage} />
      </>
    );
  }

  return (
    <TableContainer ref={tableEl} sx={[styles.TableContainer, { minHeight, maxHeight }]}>
      <Table stickyHeader>
        <TableHead>
          {header}
        </TableHead>
        <TableBody>
          {body}
        </TableBody>
      </Table>
      {(!hasMore && currentPage > 1) && endMessage}
    </TableContainer>
  );
};

InfiniteScrollTable.defaultProps = {
  currentPage: 1,
  endMessage: <Typography variant='caption' textAlign='center' component='div'>End of results</Typography>,
  minHeight: 0,
  maxHeight: 500,
};

InfiniteScrollTable.propTypes = {
  body: PropTypes.node.isRequired,
  currentPage: PropTypes.number,
  endMessage: PropTypes.node,
  fetchMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  header: PropTypes.node.isRequired,
  loaded: PropTypes.bool.isRequired,
  minHeight: PropTypes.number,
  maxHeight: PropTypes.number,
};

export default InfiniteScrollTable;
