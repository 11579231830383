const addressFormats = {
  'US': {
    'name': 'United States',
    'fields': [
      {
        'key': 'thoroughfare',
        'label': 'Street Address'
      },
      {
        'key': 'premise',
        'label': 'Building, Floor, Suite, etc.'
      },
      {
        'key': 'localityname',
        'label': 'City'
      },
      {
        'key': 'administrativearea',
        'label': 'State',
        'break': ', ',
        'renderKey': true,
        'options': {
          'AL': 'Alabama',
          'AK': 'Alaska',
          'AZ': 'Arizona',
          'AR': 'Arkansas',
          'CA': 'California',
          'CO': 'Colorado',
          'CT': 'Connecticut',
          'DE': 'Delaware',
          'DC': 'District Of Columbia',
          'FL': 'Florida',
          'GA': 'Georgia',
          'HI': 'Hawaii',
          'ID': 'Idaho',
          'IL': 'Illinois',
          'IN': 'Indiana',
          'IA': 'Iowa',
          'KS': 'Kansas',
          'KY': 'Kentucky',
          'LA': 'Louisiana',
          'ME': 'Maine',
          'MD': 'Maryland',
          'MA': 'Massachusetts',
          'MI': 'Michigan',
          'MN': 'Minnesota',
          'MS': 'Mississippi',
          'MO': 'Missouri',
          'MT': 'Montana',
          'NE': 'Nebraska',
          'NV': 'Nevada',
          'NH': 'New Hampshire',
          'NJ': 'New Jersey',
          'NM': 'New Mexico',
          'NY': 'New York',
          'NC': 'North Carolina',
          'ND': 'North Dakota',
          'OH': 'Ohio',
          'OK': 'Oklahoma',
          'OR': 'Oregon',
          'PA': 'Pennsylvania',
          'RI': 'Rhode Island',
          'SC': 'South Carolina',
          'SD': 'South Dakota',
          'TN': 'Tennessee',
          'TX': 'Texas',
          'UT': 'Utah',
          'VT': 'Vermont',
          'VA': 'Virginia',
          'WA': 'Washington',
          'WV': 'West Virginia',
          'WI': 'Wisconsin',
          'WY': 'Wyoming',
        }
      },
      {
        'key': 'postalcode',
        'label': 'ZIP Code',
        'break': ' '
      }
    ]
  },
  'AF': {
    'name': 'Afghanistan'
  },
  'AX': {
    'name': 'Aland Islands'
  },
  'AL': {
    'name': 'Albania'
  },
  'DZ': {
    'name': 'Algeria'
  },
  'AS': {
    'name': 'American Samoa'
  },
  'AD': {
    'name': 'Andorra'
  },
  'AO': {
    'name': 'Angola'
  },
  'AI': {
    'name': 'Anguilla'
  },
  'AQ': {
    'name': 'Antarctica'
  },
  'AG': {
    'name': 'Antigua and Barbuda'
  },
  'AR': {
    'name': 'Argentina'
  },
  'AM': {
    'name': 'Armenia'
  },
  'AW': {
    'name': 'Aruba'
  },
  'AU': {
    'name': 'Australia',
    'fields': [
      {
        'key': 'thoroughfare',
        'label': 'Address 1'
      },
      {
        'key': 'premise',
        'label': 'Address 2'
      },
      {
        'key': 'localityname',
        'label': 'City/Suburb'
      },
      {
        'key': 'administrativearea',
        'label': 'State',
        'options': {
          'ACT': 'Australian Capital Territory',
          'NSW': 'New South Wales',
          'NT': 'Northern Territory',
          'QLD': 'Queensland',
          'SA': 'South Australia',
          'TAS': 'Tasmania',
          'VIC': 'Victoria',
          'WA': 'Western Australia'
        }
      },
      {
        'key': 'postalcode',
        'label': 'Postcode'
      }
    ]
  },
  'AT': {
    'name': 'Austria'
  },
  'AZ': {
    'name': 'Azerbaijan'
  },
  'BS': {
    'name': 'Bahamas'
  },
  'BH': {
    'name': 'Bahrain'
  },
  'BD': {
    'name': 'Bangladesh'
  },
  'BB': {
    'name': 'Barbados'
  },
  'BY': {
    'name': 'Belarus'
  },
  'BE': {
    'name': 'Belgium'
  },
  'BZ': {
    'name': 'Belize'
  },
  'BJ': {
    'name': 'Benin'
  },
  'BM': {
    'name': 'Bermuda'
  },
  'BT': {
    'name': 'Bhutan'
  },
  'BO': {
    'name': 'Bolivia'
  },
  'BA': {
    'name': 'Bosnia and Herzegovina'
  },
  'BW': {
    'name': 'Botswana'
  },
  'BV': {
    'name': 'Bouvet Island'
  },
  'BR': {
    'name': 'Brazil',
    'fields': [
      {
        'key': 'thoroughfare',
        'label': 'Thoroughfare'
      },
      {
        'key': 'premise',
        'label': 'Complement'
      },
      {
        'key': 'dependent_localityname',
        'label': 'Neighborhood'
      },
      {
        'key': 'localityname',
        'label': 'City'
      },
      {
        'key': 'administrativearea',
        'label': 'State',
        'options': {
          'AC': 'Acre',
          'AL': 'Alagoas',
          'AM': 'Amazonas',
          'AP': 'Amapa',
          'BA': 'Bahia',
          'CE': 'Ceara',
          'DF': 'Distrito Federal',
          'ES': 'Espirito Santo',
          'GO': 'Goias',
          'MA': 'Maranhao',
          'MG': 'Minas Gerais',
          'MS': 'Mato Grosso do Sul',
          'MT': 'Mato Grosso',
          'PA': 'Para',
          'PB': 'Paraiba',
          'PE': 'Pernambuco',
          'PI': 'Piaui',
          'PR': 'Parana',
          'RJ': 'Rio de Janeiro',
          'RN': 'Rio Grande do Norte',
          'RO': 'Rondonia',
          'RR': 'Roraima',
          'RS': 'Rio Grande do Sul',
          'SC': 'Santa Catarina',
          'SE': 'Sergipe',
          'SP': 'Sao Paulo',
          'TO': 'Tocantins'
        }
      },
      {
        'key': 'postalcode',
        'label': 'Postal Code'
      }
    ]
  },
  'IO': {
    'name': 'British Indian Ocean Territory'
  },
  'VG': {
    'name': 'British Virgin Islands'
  },
  'BN': {
    'name': 'Brunei'
  },
  'BG': {
    'name': 'Bulgaria'
  },
  'BF': {
    'name': 'Burkina Faso'
  },
  'BI': {
    'name': 'Burundi'
  },
  'KH': {
    'name': 'Cambodia'
  },
  'CM': {
    'name': 'Cameroon'
  },
  'CA': {
    'name': 'Canada',
    'fields': [
      {
        'key': 'thoroughfare',
        'label': 'Address 1'
      },
      {
        'key': 'premise',
        'label': 'Address 2'
      },
      {
        'key': 'localityname',
        'label': 'City'
      },
      {
        'key': 'administrativearea',
        'label': 'Province',
        'options': {
          'AB': 'Alberta',
          'BC': 'British Columbia',
          'MB': 'Manitoba',
          'NB': 'New Brunswick',
          'NL': 'Newfoundland',
          'NT': 'Northwest Territories',
          'NS': 'Nova Scotia',
          'NU': 'Nunavut',
          'ON': 'Ontario',
          'PE': 'Prince Edward Island',
          'QC': 'Quebec',
          'SK': 'Saskatchewan',
          'YT': 'Yukon Territory'
        }
      },
      {
        'key': 'postalcode',
        'label': 'Postal Code'
      }
    ]
  },
  'CV': {
    'name': 'Cape Verde'
  },
  'KY': {
    'name': 'Cayman Islands'
  },
  'CF': {
    'name': 'Central African Republic'
  },
  'TD': {
    'name': 'Chad'
  },
  'CL': {
    'name': 'Chile'
  },
  'CN': {
    'name': 'China'
  },
  'CX': {
    'name': 'Christmas Island'
  },
  'CC': {
    'name': 'Cocos (Keeling) Islands'
  },
  'CO': {
    'name': 'Colombia',
    'fields': [
      {
        'key': 'thoroughfare',
        'label': 'Address 1'
      },
      {
        'key': 'premise',
        'label': 'Address 2'
      },
      {
        'key': 'localityname',
        'label': 'City'
      }
    ]
  },
  'KM': {
    'name': 'Comoros'
  },
  'CG': {
    'name': 'Congo (Brazzaville)'
  },
  'CD': {
    'name': 'Congo (Kinshasa)'
  },
  'CK': {
    'name': 'Cook Islands'
  },
  'CR': {
    'name': 'Costa Rica'
  },
  'CU': {
    'name': 'Cuba'
  },
  'HR': {
    'name': 'Croatia'
  },
  'CW': {
    'name': 'CuraÁao'
  },
  'CY': {
    'name': 'Cyprus'
  },
  'CZ': {
    'name': 'Czech Republic'
  },
  'DK': {
    'name': 'Denmark'
  },
  'DJ': {
    'name': 'Djibouti'
  },
  'DM': {
    'name': 'Dominica'
  },
  'DO': {
    'name': 'Dominican Republic'
  },
  'EC': {
    'name': 'Ecuador'
  },
  'EG': {
    'name': 'Egypt'
  },
  'SV': {
    'name': 'El Salvador'
  },
  'GQ': {
    'name': 'Equatorial Guinea'
  },
  'ER': {
    'name': 'Eritrea'
  },
  'EE': {
    'name': 'Estonia'
  },
  'ET': {
    'name': 'Ethiopia'
  },
  'FK': {
    'name': 'Falkland Islands'
  },
  'FO': {
    'name': 'Faroe Islands'
  },
  'FJ': {
    'name': 'Fiji'
  },
  'FI': {
    'name': 'Finland'
  },
  'FR': {
    'name': 'France',
    'fields': [
      {
        'key': 'thoroughfare',
        'label': 'Address 1'
      },
      {
        'key': 'premise',
        'label': 'Address 2'
      },
      {
        'key': 'postalcode',
        'label': 'Postal Code'
      },
      {
        'key': 'localityname',
        'label': 'City'
      }
    ]
  },
  'GF': {
    'name': 'French Guiana'
  },
  'PF': {
    'name': 'French Polynesia'
  },
  'TF': {
    'name': 'French Southern Territories'
  },
  'GA': {
    'name': 'Gabon'
  },
  'GM': {
    'name': 'Gambia'
  },
  'GE': {
    'name': 'Georgia'
  },
  'DE': {
    'name': 'Germany',
    'fields': [
      {
        'key': 'thoroughfare',
        'label': 'Address 1'
      },
      {
        'key': 'premise',
        'label': 'Address 2'
      },
      {
        'key': 'postalcode',
        'label': 'Postal Code'
      },
      {
        'key': 'localityname',
        'label': 'City'
      }
    ]
  },
  'GH': {
    'name': 'Ghana'
  },
  'GI': {
    'name': 'Gibraltar'
  },
  'GR': {
    'name': 'Greece',
    'fields': [
      {
        'key': 'thoroughfare',
        'label': 'Address 1'
      },
      {
        'key': 'premise',
        'label': 'Address 2'
      },
      {
        'key': 'postalcode',
        'label': 'Postal Code'
      },
      {
        'key': 'localityname',
        'label': 'City'
      }
    ]
  },
  'GL': {
    'name': 'Greenland'
  },
  'GD': {
    'name': 'Grenada'
  },
  'GP': {
    'name': 'Guadeloupe'
  },
  'GU': {
    'name': 'Guam'
  },
  'GT': {
    'name': 'Guatemala'
  },
  'GG': {
    'name': 'Guernsey'
  },
  'GN': {
    'name': 'Guinea'
  },
  'GW': {
    'name': 'Guinea-Bissau'
  },
  'GY': {
    'name': 'Guyana'
  },
  'HT': {
    'name': 'Haiti'
  },
  'HM': {
    'name': 'Heard Island and McDonald Islands'
  },
  'HN': {
    'name': 'Honduras'
  },
  'HK': {
    'name': 'Hong Kong S.A.R., China',
    'fields': [
      {
        'key': 'thoroughfare',
        'label': 'Address 1'
      },
      {
        'key': 'premise',
        'label': 'Address 2'
      },
      {
        'key': 'localityname',
        'label': 'District'
      },
      {
        'key': 'administrativearea',
        'label': 'Area',
        'options': {
          'Kowloon': 'Kowloon',
          'Hong Kong Island': 'Hong Kong Island',
          'New Territories': 'New Territories'
        }
      }
    ]
  },
  'HU': {
    'name': 'Hungary'
  },
  'IS': {
    'name': 'Iceland'
  },
  'IN': {
    'name': 'India',
    'fields': [
      {
        'key': 'thoroughfare',
        'label': 'Address 1'
      },
      {
        'key': 'premise',
        'label': 'Address 2'
      },
      {
        'key': 'localityname',
        'label': 'City'
      },
      {
        'key': 'administrativearea',
        'label': 'State',
        'options': {
          'AP': 'Andhra Pradesh',
          'AR': 'Arunachal Pradesh',
          'AS': 'Assam',
          'BR': 'Bihar',
          'CT': 'Chhattisgarh',
          'GA': 'Goa',
          'GJ': 'Gujarat',
          'HR': 'Haryana',
          'HP': 'Himachal Pradesh',
          'JK': 'Jammu and Kashmir',
          'JH': 'Jharkhand',
          'KA': 'Karnataka',
          'KL': 'Kerala',
          'MP': 'Madhya Pradesh',
          'MH': 'Maharashtra',
          'MN': 'Manipur',
          'ML': 'Meghalaya',
          'MZ': 'Mizoram',
          'NL': 'Nagaland',
          'OR': 'Odisha',
          'PB': 'Punjab',
          'RJ': 'Rajasthan',
          'SK': 'Sikkim',
          'TN': 'Tamil Nadu',
          'TR': 'Tripura',
          'UP': 'Uttar Pradesh',
          'UT': 'Uttarakhand',
          'WB': 'West Bengal',
          'AN': 'Andaman and Nicobar Islands',
          'CH': 'Chandigarh',
          'DN': 'Dadra and Nagar Haveli',
          'DD': 'Daman and Diu',
          'LD': 'Lakshadweep',
          'DL': 'National Capital Territory of Delhi',
          'PY': 'Puducherry'
        }
      },
      {
        'key': 'postalcode',
        'label': 'PIN Code'
      }
    ]
  },
  'ID': {
    'name': 'Indonesia'
  },
  'IR': {
    'name': 'Iran'
  },
  'IQ': {
    'name': 'Iraq'
  },
  'IE': {
    'name': 'Ireland'
  },
  'IM': {
    'name': 'Isle of Man'
  },
  'IL': {
    'name': 'Israel',
    'fields': [
      {
        'key': 'thoroughfare',
        'label': 'Address 1'
      },
      {
        'key': 'premise',
        'label': 'Address 2'
      },
      {
        'key': 'postalcode',
        'label': 'Postal Code'
      },
      {
        'key': 'localityname',
        'label': 'City'
      }
    ]
  },
  'IT': {
    'name': 'Italy',
    'fields': [
      {
        'key': 'thoroughfare',
        'label': 'Address 1'
      },
      {
        'key': 'premise',
        'label': 'Address 2'
      },
      {
        'key': 'postalcode',
        'label': 'Postal Code'
      },
      {
        'key': 'localityname',
        'label': 'City'
      },
      {
        'key': 'administrativearea',
        'label': 'Province',
        'options': {
          'AG': 'Agrigento',
          'AL': 'Alessandria',
          'AN': 'Ancona',
          'AO': 'Valle d\'Aosta/VallÈe d\'Aoste',
          'AP': 'Ascoli Piceno',
          'AQ': 'L\'Aquila',
          'AR': 'Arezzo',
          'AT': 'Asti',
          'AV': 'Avellino',
          'BA': 'Bari',
          'BG': 'Bergamo',
          'BI': 'Biella',
          'BL': 'Belluno',
          'BN': 'Benevento',
          'BO': 'Bologna',
          'BR': 'Brindisi',
          'BS': 'Brescia',
          'BT': 'Barletta-Andria-Trani',
          'BZ': 'Bolzano/Bozen',
          'CA': 'Cagliari',
          'CB': 'Campobasso',
          'CE': 'Caserta',
          'CH': 'Chieti',
          'CI': 'Carbonia-Iglesias',
          'CL': 'Caltanissetta',
          'CN': 'Cuneo',
          'CO': 'Como',
          'CR': 'Cremona',
          'CS': 'Cosenza',
          'CT': 'Catania',
          'CZ': 'Catanzaro',
          'EN': 'Enna',
          'FC': 'ForlÏ-Cesena',
          'FE': 'Ferrara',
          'FG': 'Foggia',
          'FI': 'Firenze',
          'FM': 'Fermo',
          'FR': 'Frosinone',
          'GE': 'Genova',
          'GO': 'Gorizia',
          'GR': 'Grosseto',
          'IM': 'Imperia',
          'IS': 'Isernia',
          'KR': 'Crotone',
          'LC': 'Lecco',
          'LE': 'Lecce',
          'LI': 'Livorno',
          'LO': 'Lodi',
          'LT': 'Latina',
          'LU': 'Lucca',
          'MB': 'Monza e Brianza',
          'MC': 'Macerata',
          'ME': 'Messina',
          'MI': 'Milano',
          'MN': 'Mantova',
          'MO': 'Modena',
          'MS': 'Massa-Carrara',
          'MT': 'Matera',
          'NA': 'Napoli',
          'NO': 'Novara',
          'NU': 'Nuoro',
          'OG': 'Ogliastra',
          'OR': 'Oristano',
          'OT': 'Olbia-Tempio',
          'PA': 'Palermo',
          'PC': 'Piacenza',
          'PD': 'Padova',
          'PE': 'Pescara',
          'PG': 'Perugia',
          'PI': 'Pisa',
          'PN': 'Pordenone',
          'PO': 'Prato',
          'PR': 'Parma',
          'PT': 'Pistoia',
          'PU': 'Pesaro e Urbino',
          'PV': 'Pavia',
          'PZ': 'Potenza',
          'RA': 'Ravenna',
          'RC': 'Reggio di Calabria',
          'RE': 'Reggio nell\'Emilia',
          'RG': 'Ragusa',
          'RI': 'Rieti',
          'RM': 'Roma',
          'RN': 'Rimini',
          'RO': 'Rovigo',
          'SA': 'Salerno',
          'SI': 'Siena',
          'SO': 'Sondrio',
          'SP': 'La Spezia',
          'SR': 'Siracusa',
          'SS': 'Sassari',
          'SV': 'Savona',
          'TA': 'Taranto',
          'TE': 'Teramo',
          'TN': 'Trento',
          'TO': 'Torino',
          'TP': 'Trapani',
          'TR': 'Terni',
          'TS': 'Trieste',
          'TV': 'Treviso',
          'UD': 'Udine',
          'VA': 'Varese',
          'VB': 'Verbano-Cusio-Ossola',
          'VC': 'Vercelli',
          'VE': 'Venezia',
          'VI': 'Vicenza',
          'VR': 'Verona',
          'VS': 'Medio Campidano',
          'VT': 'Viterbo',
          'VV': 'Vibo Valentia'
        }
      }
    ]
  },
  'CI': {
    'name': 'Ivory Coast'
  },
  'JM': {
    'name': 'Jamaica'
  },
  'JP': {
    'name': 'Japan',
    'fields': [
      {
        'key': 'thoroughfare',
        'label': 'Address 1'
      },
      {
        'key': 'premise',
        'label': 'Address 2'
      },
      {
        'key': 'postalcode',
        'label': 'Postal Code'
      },
      {
        'key': 'localityname',
        'label': 'City'
      },
      {
        'key': 'administrativearea',
        'label': 'Prefecture',
        'options': {
          '10': 'Gunma',
          '11': 'Saitama',
          '12': 'Chiba',
          '13': 'Tokyo',
          '14': 'Kanagawa',
          '15': 'Niigata',
          '16': 'Toyama',
          '17': 'Ishikawa',
          '18': 'Fukui',
          '19': 'Yamanashi',
          '20': 'Nagano',
          '21': 'Gifu',
          '22': 'Shizuoka',
          '23': 'Aichi',
          '24': 'Mie',
          '25': 'Shiga',
          '26': 'Kyoto',
          '27': 'Osaka',
          '28': 'Hyogo',
          '29': 'Nara',
          '30': 'Wakayama',
          '31': 'Tottori',
          '32': 'Shimane',
          '33': 'Okayama',
          '34': 'Hiroshima',
          '35': 'Yamaguchi',
          '36': 'Tokushima',
          '37': 'Kagawa',
          '38': 'Ehime',
          '39': 'Kochi',
          '40': 'Fukuoka',
          '41': 'Saga',
          '42': 'Nagasaki',
          '43': 'Kumamoto',
          '44': 'Oita',
          '45': 'Miyazaki',
          '46': 'Kagoshima',
          '47': 'Okinawa',
          '01': 'Hokkaido',
          '02': 'Aomori',
          '03': 'Iwate',
          '04': 'Miyagi',
          '05': 'Akita',
          '06': 'Yamagata',
          '07': 'Fukushima',
          '08': 'Ibaraki',
          '09': 'Tochigi'
        }
      }
    ]
  },
  'JE': {
    'name': 'Jersey'
  },
  'JO': {
    'name': 'Jordan'
  },
  'KZ': {
    'name': 'Kazakhstan'
  },
  'KE': {
    'name': 'Kenya'
  },
  'KI': {
    'name': 'Kiribati'
  },
  'KW': {
    'name': 'Kuwait'
  },
  'KG': {
    'name': 'Kyrgyzstan'
  },
  'KV': {
    'name': 'Kosovo'
  },
  'LA': {
    'name': 'Laos'
  },
  'LV': {
    'name': 'Latvia'
  },
  'LB': {
    'name': 'Lebanon'
  },
  'LS': {
    'name': 'Lesotho'
  },
  'LR': {
    'name': 'Liberia'
  },
  'LY': {
    'name': 'Libya'
  },
  'LI': {
    'name': 'Liechtenstein'
  },
  'LT': {
    'name': 'Lithuania'
  },
  'LU': {
    'name': 'Luxembourg'
  },
  'MO': {
    'name': 'Macao S.A.R., China'
  },
  'MK': {
    'name': 'Macedonia'
  },
  'MG': {
    'name': 'Madagascar'
  },
  'MW': {
    'name': 'Malawi'
  },
  'MY': {
    'name': 'Malaysia'
  },
  'MV': {
    'name': 'Maldives'
  },
  'ML': {
    'name': 'Mali'
  },
  'MT': {
    'name': 'Malta'
  },
  'MH': {
    'name': 'Marshall Islands'
  },
  'MQ': {
    'name': 'Martinique'
  },
  'MR': {
    'name': 'Mauritania'
  },
  'MU': {
    'name': 'Mauritius'
  },
  'YT': {
    'name': 'Mayotte'
  },
  'MX': {
    'name': 'Mexico',
    'fields': [
      {
        'key': 'thoroughfare',
        'label': 'Address 1'
      },
      {
        'key': 'premise',
        'label': 'Address 2'
      },
      {
        'key': 'dependent_localityname',
        'label': 'Neighborhood'
      },
      {
        'key': 'localityname',
        'label': 'City'
      },
      {
        'key': 'administrativearea',
        'label': 'State',
        'options': {
          'AGU': 'Aguascalientes',
          'BCN': 'Baja California',
          'BCS': 'Baja California Sur',
          'CAM': 'Campeche',
          'COA': 'Coahuila',
          'COL': 'Colima',
          'CHP': 'Chiapas',
          'CHH': 'Chihuahua',
          'DIF': 'Distrito Federal',
          'DUG': 'Durango',
          'MEX': 'Estado de México',
          'GUA': 'Guanajuato',
          'GRO': 'Guerrero',
          'HID': 'Hidalgo',
          'JAL': 'Jalisco',
          'MIC': 'Michoacán',
          'MOR': 'Morelos',
          'NAY': 'Nayarit',
          'NLE': 'Nuevo León',
          'OAX': 'Oaxaca',
          'PUE': 'Puebla',
          'QUE': 'Queretaro',
          'ROO': 'Quintana Roo',
          'SLP': 'San Luis Potosí',
          'SIN': 'Sinaloa',
          'SON': 'Sonora',
          'TAB': 'Tabasco',
          'TAM': 'Tamaulipas',
          'TLA': 'Tlaxcala',
          'VER': 'Veracruz',
          'YUC': 'Yucatán',
          'ZAC': 'Zacatecas'
        }
      },
      {
        'key': 'postalcode',
        'label': 'Postal Code'
      }
    ]
  },
  'FM': {
    'name': 'Micronesia'
  },
  'MD': {
    'name': 'Moldova'
  },
  'MC': {
    'name': 'Monaco'
  },
  'MN': {
    'name': 'Mongolia'
  },
  'ME': {
    'name': 'Montenegro'
  },
  'MS': {
    'name': 'Montserrat'
  },
  'MA': {
    'name': 'Morocco'
  },
  'MZ': {
    'name': 'Mozambique'
  },
  'MM': {
    'name': 'Myanmar'
  },
  'NA': {
    'name': 'Namibia'
  },
  'NR': {
    'name': 'Nauru'
  },
  'NP': {
    'name': 'Nepal'
  },
  'NL': {
    'name': 'Netherlands',
    'fields': [
      {
        'key': 'thoroughfare',
        'label': 'Address 1'
      },
      {
        'key': 'premise',
        'label': 'Address 2'
      },
      {
        'key': 'postalcode',
        'label': 'Postal Code'
      },
      {
        'key': 'localityname',
        'label': 'City'
      }
    ]
  },
  'AN': {
    'name': 'Netherlands Antilles'
  },
  'NC': {
    'name': 'New Caledonia'
  },
  'NZ': {
    'name': 'New Zealand'
  },
  'NI': {
    'name': 'Nicaragua'
  },
  'NE': {
    'name': 'Niger'
  },
  'NG': {
    'name': 'Nigeria'
  },
  'NU': {
    'name': 'Niue'
  },
  'NF': {
    'name': 'Norfolk Island'
  },
  'MP': {
    'name': 'Northern Mariana Islands'
  },
  'KP': {
    'name': 'North Korea'
  },
  'NO': {
    'name': 'Norway'
  },
  'OM': {
    'name': 'Oman'
  },
  'PK': {
    'name': 'Pakistan'
  },
  'PW': {
    'name': 'Palau'
  },
  'PS': {
    'name': 'Palestinian Territory'
  },
  'PA': {
    'name': 'Panama'
  },
  'PG': {
    'name': 'Papua New Guinea'
  },
  'PY': {
    'name': 'Paraguay'
  },
  'PE': {
    'name': 'Peru'
  },
  'PH': {
    'name': 'Philippines'
  },
  'PN': {
    'name': 'Pitcairn'
  },
  'PL': {
    'name': 'Poland'
  },
  'PT': {
    'name': 'Portugal'
  },
  'PR': {
    'name': 'Puerto Rico'
  },
  'QA': {
    'name': 'Qatar'
  },
  'RE': {
    'name': 'Reunion'
  },
  'RO': {
    'name': 'Romania'
  },
  'RU': {
    'name': 'Russia',
    'fields': [
      {
        'key': 'thoroughfare',
        'label': 'Address 1'
      },
      {
        'key': 'premise',
        'label': 'Address 2'
      },
      {
        'key': 'localityname',
        'label': 'City'
      },
      {
        'key': 'administrativearea',
        'label': 'Province',
        'options': {
          'MOW': 'Moskva',
          'SPE': 'Sankt-Peterburg',
          'AD': 'Adygeya, Respublika',
          'AL': 'Altay, Respublika',
          'BA': 'Bashkortostan, Respublika',
          'BU': 'Buryatiya, Respublika',
          'CE': 'Chechenskaya Respublika',
          'CU': 'Chuvashskaya Respublika',
          'DA': 'Dagestan, Respublika',
          'IN': 'Ingushetiya, Respublika',
          'KB': 'Kabardino-Balkarskaya Respublika',
          'KL': 'Kalmykiya, Respublika',
          'KC': 'Karachayevo-Cherkesskaya Respublika',
          'KR': 'Kareliya, Respublika',
          'KK': 'Khakasiya, Respublika',
          'KO': 'Komi, Respublika',
          'ME': 'Mariy El, Respublika',
          'MO': 'Mordoviya, Respublika',
          'SA': 'Sakha, Respublika [Yakutiya]',
          'SE': 'Severnaya Osetiya-Alaniya, Respublika',
          'TA': 'Tatarstan, Respublika',
          'TY': 'Tyva, Respublika [Tuva]',
          'UD': 'Udmurtskaya Respublika',
          'ALT': 'Altayskiy kray',
          'KAM': 'Kamchatskiy kray',
          'KHA': 'Khabarovskiy kray',
          'KDA': 'Krasnodarskiy kray',
          'KYA': 'Krasnoyarskiy kray',
          'PER': 'Permskiy kray',
          'PRI': 'Primorskiy kray',
          'STA': 'Stavropolskiy kray',
          'ZAB': 'Zabaykalskiy kray',
          'AMU': 'Amurskaya oblast',
          'ARK': 'Arkhangelskaya oblast',
          'AST': 'Astrakhanskaya oblast',
          'BEL': 'Belgorodskaya oblast',
          'BRY': 'Bryanskaya oblast',
          'CHE': 'Chelyabinskaya oblast',
          'IRK': 'Irkutskaya oblast',
          'IVA': 'Ivanovskaya oblast',
          'KGD': 'Kaliningradskaya oblast',
          'KLU': 'Kaluzhskaya oblast',
          'KEM': 'Kemerovskaya oblast',
          'KIR': 'Kirovskaya oblast',
          'KOS': 'Kostromskaya oblast',
          'KGN': 'Kurganskaya oblast',
          'KRS': 'Kurskaya oblast',
          'LEN': 'Leningradskaya oblast',
          'LIP': 'Lipetskaya oblast',
          'MAG': 'Magadanskaya oblast',
          'MOS': 'Moskovskaya oblast',
          'MUR': 'Murmanskaya oblast',
          'NIZ': 'Nizhegorodskaya oblast',
          'NGR': 'Novgorodskaya oblast',
          'NVS': 'Novosibirskaya oblast',
          'OMS': 'Omskaya oblast',
          'ORE': 'Orenburgskaya oblast',
          'ORL': 'Orlovskaya oblast',
          'PNZ': 'Penzenskaya oblast',
          'PSK': 'Pskovskaya oblast',
          'ROS': 'Rostovskaya oblast',
          'RYA': 'Ryazanskaya oblast',
          'SAK': 'Sakhalinskaya oblast',
          'SAM': 'Samarskaya oblast',
          'SAR': 'Saratovskaya oblast',
          'SMO': 'Smolenskaya oblast',
          'SVE': 'Sverdlovskaya oblast',
          'TAM': 'Tambovskaya oblast',
          'TOM': 'Tomskaya oblast',
          'TUL': 'Tulskaya oblast',
          'TVE': 'Tverskaya oblast',
          'TYU': 'Tyumenskaya oblast',
          'ULY': 'Ulyanovskaya oblast',
          'VLA': 'Vladimirskaya oblast',
          'VGG': 'Volgogradskaya oblast',
          'VLG': 'Vologodskaya oblast',
          'VOR': 'Voronezhskaya oblast',
          'YAR': 'Yaroslavskaya oblast',
          'YEV': 'Yevreyskaya avtonomnaya oblast',
          'CHU': 'Chukotskiy avtonomnyy okrug',
          'KHM': 'Khanty-Mansiyskiy avtonomnyy okrug-Yugra',
          'NEN': 'Nenetskiy avtonomnyy okrug',
          'YAN': 'Yamalo-Nenetskiy avtonomnyy okrug'
        }
      },
      {
        'key': 'postalcode',
        'label': 'Postal Code'
      }
    ]
  },
  'RW': {
    'name': 'Rwanda'
  },
  'BL': {
    'name': 'Saint BarthÈlemy'
  },
  'SH': {
    'name': 'Saint Helena'
  },
  'KN': {
    'name': 'Saint Kitts and Nevis'
  },
  'LC': {
    'name': 'Saint Lucia'
  },
  'MF': {
    'name': 'Saint Martin (French part)'
  },
  'PM': {
    'name': 'Saint Pierre and Miquelon'
  },
  'VC': {
    'name': 'Saint Vincent and the Grenadines'
  },
  'WS': {
    'name': 'Samoa'
  },
  'SM': {
    'name': 'San Marino'
  },
  'ST': {
    'name': 'Sao Tome and Principe'
  },
  'SA': {
    'name': 'Saudi Arabia'
  },
  'SN': {
    'name': 'Senegal'
  },
  'RS': {
    'name': 'Serbia'
  },
  'SC': {
    'name': 'Seychelles'
  },
  'SL': {
    'name': 'Sierra Leone'
  },
  'SG': {
    'name': 'Singapore',
    'fields': [
      {
        'key': 'thoroughfare',
        'label': 'Address 1'
      },
      {
        'key': 'premise',
        'label': 'Address 2'
      },
      {
        'key': 'postalcode',
        'label': 'Postal Code'
      }
    ]
  },
  'SK': {
    'name': 'Slovakia'
  },
  'SI': {
    'name': 'Slovenia'
  },
  'SB': {
    'name': 'Solomon Islands'
  },
  'SO': {
    'name': 'Somalia'
  },
  'ZA': {
    'name': 'South Africa'
  },
  'GS': {
    'name': 'South Georgia and the South Sandwich Islands'
  },
  'KR': {
    'name': 'South Korea'
  },
  'ES': {
    'name': 'Spain',
    'fields': [
      {
        'key': 'thoroughfare',
        'label': 'Address 1'
      },
      {
        'key': 'premise',
        'label': 'Address 2'
      },
      {
        'key': 'postalcode',
        'label': 'Postal Code'
      },
      {
        'key': 'localityname',
        'label': 'City'
      },
      {
        'key': 'administrativearea',
        'label': 'Province',
        'options': {
          'C': 'A Coruña',
          'VI': 'Alava',
          'AB': 'Albacete',
          'A': 'Alicante',
          'AL': 'Almería',
          'O': 'Asturias',
          'AV': 'Ávila',
          'BA': 'Badajoz',
          'PM': 'Baleares',
          'B': 'Barcelona',
          'BU': 'Burgos',
          'CC': 'Cáceres',
          'CA': 'Cádiz',
          'S': 'Cantabria',
          'CS': 'Castellón',
          'CE': 'Ceuta',
          'CR': 'Ciudad Real',
          'CO': 'Córdoba',
          'CU': 'Cuenca',
          'GI': 'Gerona',
          'GR': 'Granada',
          'GU': 'Guadalajara',
          'SS': 'Guipúzcoa',
          'H': 'Huelva',
          'HU': 'Huesca',
          'J': 'Jaén',
          'LO': 'La Rioja',
          'GC': 'Las Palmas',
          'LE': 'León',
          'L': 'Lérida',
          'LU': 'Lugo',
          'M': 'Madrid',
          'MA': 'Málaga',
          'ML': 'Melilla',
          'MU': 'Murcia',
          'NA': 'Navarra',
          'OR': 'Ourense',
          'P': 'Palencia',
          'PO': 'Pontevedra',
          'SA': 'Salamanca',
          'TF': 'Santa Cruz de Tenerife',
          'SG': 'Segovia',
          'SE': 'Sevilla',
          'SO': 'Soria',
          'T': 'Tarragona',
          'TE': 'Teruel',
          'TO': 'Toledo',
          'V': 'Valencia',
          'VA': 'Valladolid',
          'BI': 'Vizcaya',
          'ZA': 'Zamora',
          'Z': 'Zaragoza'
        }
      }
    ]
  },
  'LK': {
    'name': 'Sri Lanka'
  },
  'SD': {
    'name': 'Sudan'
  },
  'SR': {
    'name': 'Suriname'
  },
  'SJ': {
    'name': 'Svalbard and Jan Mayen'
  },
  'SZ': {
    'name': 'Swaziland'
  },
  'SE': {
    'name': 'Sweden'
  },
  'CH': {
    'name': 'Switzerland'
  },
  'SY': {
    'name': 'Syria'
  },
  'TW': {
    'name': 'Taiwan'
  },
  'TJ': {
    'name': 'Tajikistan'
  },
  'TZ': {
    'name': 'Tanzania'
  },
  'TH': {
    'name': 'Thailand'
  },
  'TL': {
    'name': 'Timor-Leste'
  },
  'TG': {
    'name': 'Togo'
  },
  'TK': {
    'name': 'Tokelau'
  },
  'TO': {
    'name': 'Tonga'
  },
  'TT': {
    'name': 'Trinidad and Tobago'
  },
  'TN': {
    'name': 'Tunisia'
  },
  'TR': {
    'name': 'Turkey',
    'fields': [
      {
        'key': 'thoroughfare',
        'label': 'Address 1'
      },
      {
        'key': 'premise',
        'label': 'Address 2'
      },
      {
        'key': 'postalcode',
        'label': 'Postal Code'
      },
      {
        'key': 'administrativearea',
        'label': 'Province',
        'options': {
          'Adana': 'Adana',
          'Adıyaman': 'Adıyaman',
          'Afyon': 'Afyon',
          'Ağrı': 'Ağrı',
          'Aksaray': 'Aksaray',
          'Amasya': 'Amasya',
          'Ankara': 'Ankara',
          'Antalya': 'Antalya',
          'Ardahan': 'Ardahan',
          'Artvin': 'Artvin',
          'Aydın': 'Aydın',
          'Balıkesir': 'Balıkesir',
          'Bartın': 'Bartın',
          'Batman': 'Batman',
          'Bayburt': 'Bayburt',
          'Bilecik': 'Bilecik',
          'Bingöl': 'Bingöl',
          'Bitlis': 'Bitlis',
          'Bolu': 'Bolu',
          'Burdur': 'Burdur',
          'Bursa': 'Bursa',
          'Çanakkale': 'Çanakkale',
          'Çankırı': 'Çankırı',
          'Çorum': 'Çorum',
          'Denizli': 'Denizli',
          'Diyarbakır': 'Diyarbakır',
          'Düzce': 'Düzce',
          'Edirne': 'Edirne',
          'Elazığ': 'Elazığ',
          'Erzincan': 'Erzincan',
          'Erzurum': 'Erzurum',
          'Eskişehir': 'Eskişehir',
          'Gaziantep': 'Gaziantep',
          'Giresun': 'Giresun',
          'Gümüşhane': 'Gümüşhane',
          'Hakkari': 'Hakkari',
          'Hatay': 'Hatay',
          'Iğdır': 'Iğdır',
          'Isparta': 'Isparta',
          'İstanbul': 'İstanbul',
          'İzmir': 'İzmir',
          'Kahramanmaraş': 'Kahramanmaraş',
          'Karabük': 'Karabük',
          'Karaman': 'Karaman',
          'Kars': 'Kars',
          'Kastamonu': 'Kastamonu',
          'Kayseri': 'Kayseri',
          'Kırıkkale': 'Kırıkkale',
          'Kırklareli': 'Kırklareli',
          'Kırşehir': 'Kırşehir',
          'Kilis': 'Kilis',
          'Kocaeli': 'Kocaeli',
          'Konya': 'Konya',
          'Kütahya': 'Kütahya',
          'Malatya': 'Malatya',
          'Manisa': 'Manisa',
          'Mardin': 'Mardin',
          'Mersin': 'Mersin',
          'Muğla': 'Muğla',
          'Muş': 'Muş',
          'Nevşehir': 'Nevşehir',
          'Niğde': 'Niğde',
          'Ordu': 'Ordu',
          'Osmaniye': 'Osmaniye',
          'Rize': 'Rize',
          'Sakarya': 'Sakarya',
          'Samsun': 'Samsun',
          'Siirt': 'Siirt',
          'Sinop': 'Sinop',
          'Sivas': 'Sivas',
          'Şanlıurfa': 'Şanlıurfa',
          'Şırnak': 'Şırnak',
          'Tekirdağ': 'Tekirdağ',
          'Tokat': 'Tokat',
          'Trabzon': 'Trabzon',
          'Tunceli': 'Tunceli',
          'Uşak': 'Uşak',
          'Van': 'Van',
          'Yalova': 'Yalova',
          'Yozgat': 'Yozgat',
          'Zonguldak': 'Zonguldak'
        }
      },
      {
        'key': 'localityname',
        'label': 'City'
      }
    ]
  },
  'TM': {
    'name': 'Turkmenistan'
  },
  'TC': {
    'name': 'Turks and Caicos Islands'
  },
  'TV': {
    'name': 'Tuvalu'
  },
  'VI': {
    'name': 'U.S. Virgin Islands'
  },
  'UG': {
    'name': 'Uganda'
  },
  'UA': {
    'name': 'Ukraine'
  },
  'AE': {
    'name': 'United Arab Emirates'
  },
  'GB': {
    'name': 'United Kingdom',
    'fields': [
      {
        'key': 'thoroughfare',
        'label': 'Address 1'
      },
      {
        'key': 'premise',
        'label': 'Address 2'
      },
      {
        'key': 'localityname',
        'label': 'Town/City'
      },
      {
        'key': 'administrativearea',
        'label': 'County'
      },
      {
        'key': 'postalcode',
        'label': 'Postcode'
      }
    ]
  },
  'UM': {
    'name': 'United States Minor Outlying Islands'
  },
  'UY': {
    'name': 'Uruguay'
  },
  'UZ': {
    'name': 'Uzbekistan'
  },
  'VU': {
    'name': 'Vanuatu'
  },
  'VA': {
    'name': 'Vatican'
  },
  'VE': {
    'name': 'Venezuela'
  },
  'VN': {
    'name': 'Vietnam'
  },
  'WF': {
    'name': 'Wallis and Futuna'
  },
  'EH': {
    'name': 'Western Sahara'
  },
  'YE': {
    'name': 'Yemen'
  },
  'ZM': {
    'name': 'Zambia'
  },
  'ZW': {
    'name': 'Zimbabwe'
  }
};

export default addressFormats;
