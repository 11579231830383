import { Avatar, Box, Link, ListItem, ListItemIcon, Stack, Typography } from '@mui/material';
import { MARKETS_CARD, OFFER_STATUSES } from '../../../static-info';
import { useCallback, useState } from 'react';

import CancelTransactionModal from './cancel';
import { NumberFormat } from '../../../_helpers/formatters';
import { Link as RouterLink } from 'react-router-dom';
import TransactionDetailsToggle from '../../TransactionDetails/toggle';
import TransactionSchema from './schema';
import styles from '../styles';
import { useIsMobile } from '../../../_helpers';

const Transaction = ({ transaction, onCancelTransaction }) => {
  const isMobile = useIsMobile();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const toggleConfirmationModal = useCallback(() => setConfirmModalOpen(!confirmModalOpen), [confirmModalOpen, setConfirmModalOpen]);

  const colorTotalValue = transaction.offerStatus === 'PUBLISHED'
    ? 'text.main'
    : transaction.offerStatus === 'FAILED'
      ? 'error.main'
      : 'success.main';

  return (
    <ListItem sx={styles.ListItem}>
      {!isMobile && (
        <ListItemIcon>
          <Avatar src={transaction.listing.logoUrl} />
        </ListItemIcon>
      )}
      <Box sx={styles.ColumnLeft}>
        <Link
          to={`/marketplace/${transaction.listing.code}`}
          component={RouterLink}
          color='common.black'
          sx={styles.CompanyName}
        >
          {transaction.listing.company.doingBusinessAs}
        </Link>
        <Typography variant='body2' fontWeight="fontWeightRegular" sx={styles.Market}>
          {MARKETS_CARD[transaction.market]}
        </Typography>

      </Box>
      <Stack direction='row' spacing={1}>
        <Box sx={styles.ColumnRight}>
          <Typography variant='body2' color={colorTotalValue} fontWeight='bold'>
            {NumberFormat.cents(transaction.totalValue)}
          </Typography>
          <Typography variant='body2' fontWeight="fontWeightRegular" color={transaction.offerStatus === 'PUBLISHED' ? 'secondary.main' : 'text.light'} sx={styles.Status}>
            {OFFER_STATUSES[transaction.offerStatus]}
          </Typography>
        </Box>
        <TransactionDetailsToggle
          transaction={transaction}
          onOpenConfirmCancel={toggleConfirmationModal}
          onCancelTransaction={onCancelTransaction}
        />
        <CancelTransactionModal
          transaction={transaction}
          onCancelTransaction={onCancelTransaction}
          confirmModalOpen={confirmModalOpen}
          toggleConfirmationModal={toggleConfirmationModal}
        />
      </Stack>
    </ListItem>
  );
};

Transaction.propTypes = TransactionSchema;

export default Transaction;
