import { DateFormat, NumberFormat } from '../../../../_helpers/formatters';
import { TableCell, TableRow, Typography } from '@mui/material';
import { useCallback, useContext } from 'react';

import CurrentRaiseContext from '../context';
import { PRIMARY_ASK_STATUSES } from '../../../../static-info';
import PropTypes from 'prop-types';
import styles from '../styles';

const Raise = ({ raise }) => {
  const {selectedRaise, onChangeSelectedRaise} = useContext(CurrentRaiseContext);
  const handleChangeCurrentRaise = useCallback(() => onChangeSelectedRaise(raise.key), [onChangeSelectedRaise, raise.key]);

  return (
    <TableRow hover selected={raise.key === selectedRaise} onClick={handleChangeCurrentRaise} sx={styles.TableRow}>
      <TableCell>
        <Typography variant='body2' color='common.black'>{DateFormat.short(raise.endDate)}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body2' color='common.black'>{NumberFormat.cents(raise.priceAverage)}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body2' color='common.black'>{NumberFormat.cents(raise.quantityTotal * raise.priceAverage)}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body2' color='common.black'>{NumberFormat.cents(raise.quantityAllocated * raise.priceAverage)}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body2' color='common.black'>{raise.securityType}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body2' color='common.black'>{PRIMARY_ASK_STATUSES[raise.offerStatus]}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body2' color='common.black'>{raise.exemption}</Typography>
      </TableCell>
    </TableRow>
  );
};

Raise.propTypes = {
  raise: PropTypes.shape({
    endDate: PropTypes.string.isRequired,
    exemption: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    offerStatus: PropTypes.string.isRequired,
    priceAverage: PropTypes.number.isRequired,
    quantityAllocated: PropTypes.number.isRequired,
    quantityTotal: PropTypes.number.isRequired,
    securityType: PropTypes.string.isRequired
  }).isRequired,
};

export default Raise;
