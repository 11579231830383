import { useIsMobile, useIsTablet } from '../../_helpers';

import HeaderDesktop from './desktop';
import HeaderMobile from './mobile';

const Header = () => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  return isTablet || isMobile ? <HeaderMobile /> : <HeaderDesktop />;
};

export default Header;
