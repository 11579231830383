const styles = {
  IconContainer: ({ palette }) => ({
    bgcolor: palette.primary.light,
    borderRadius: '0.375rem 0 0 0.375rem',
    '&.MuiBox-root' : {
      padding: '3px 10px',
    }
  }),
  IconCentered: ({ palette }) => ({
    color: palette.common.white,
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  }),
  Root: () => ({
    display: 'flex'
  }),
  TextWrapper: () => ({
    flexGrow: 1,
    width: '100%',
  }),
  TextFieldIcon: () => ({
    '& .MuiInputBase-formControl': {
      borderRadius: '0 0.375rem 0.375rem 0',
    },
  }),
  TextField: () => ({
    width: '100%',
    '& .MuiInputBase-formControl': {
      height: '100%',
      borderRadius: '0.375rem',
    },
    '& .MuiOutlinedInput-input' : {
      height: '33px',
      padding: '7.5px 4px 7.5px 15px',
    }
  }),
  AlignRight: () => ({
    '& .MuiInputBase-input': {
      textAlign: 'right'
    }
  }),
  BoldFocus: () => ({
    '& :focus': {
      fontWeight: 600
    }
  }),
  NoBorder: {
    display: 'block',
    '& :before': {
      borderBottom: 'none !important',
    },
    '& .Mui-focused:after': {
      borderBottom: 'none !important',
    },
    '&:hover .MuiInputBase-input-MuiInput-input:after': {
      borderBottom: 'none !important',
    },
  },
};

export default styles;
