import { Alert, Button, Grid, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useGetUserPersonalInfo, useRequestEmailChange } from '../../_actions/user.actions';

import TextField from '../../netcapital-components/TextField';
import { fetchUserPersonalInfoAttribute } from '../../_state';
import { useRecoilValue } from 'recoil';

const ChangeEmailForm = () => {
  useGetUserPersonalInfo();
  const currentEmail = useRecoilValue(fetchUserPersonalInfoAttribute('email'));
  const [newEmail, setNewEmail] = useState(currentEmail);
  const [hasRequestedChange, setHasRequestedChange] = useState(false);
  const [shouldRequestChange, setShouldRequestChange] = useState(false);
  const handleChangeEmail = useCallback((e) => {
    setNewEmail(e.target.value);
    setShouldRequestChange(e.target.value && e.target.value !== currentEmail);
  }, [currentEmail]);

  const {save: doChangeRequest, saving: waitingForChangeRequest} = useRequestEmailChange();
  const onRequestChangeSuccess = useCallback(() => {
    setHasRequestedChange(true);
  }, []);
  const handleRequestChange = useCallback(() => doChangeRequest(newEmail, onRequestChangeSuccess), [newEmail, doChangeRequest, onRequestChangeSuccess]);

  useEffect(() => {
    if (typeof newEmail === 'undefined' && currentEmail) {
      setNewEmail(currentEmail);
    }
  }, [currentEmail, newEmail]);

  return (
    <Grid container spacing={2} maxWidth='sm'>
      <Grid item xs={12}>
        <Typography variant='h5'>Email</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={newEmail || ''}
          onChange={handleChangeEmail}
          placeholder='Email'
          variant='outlined'
          disabled={hasRequestedChange || waitingForChangeRequest}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          onClick={handleRequestChange}
          disabled={!shouldRequestChange || waitingForChangeRequest || hasRequestedChange}
          variant='contained'
          size='large'
        >
          Request Change
        </Button>
      </Grid>
      {hasRequestedChange && <Alert severity='info'>Please check your email account for instructions to confirm this change.</Alert>}
    </Grid>
  );
};

export default ChangeEmailForm;
