import { Link, MenuItem, Stack } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import Session from '../../../_session';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { exitMimicMode } from '../../../_actions/session.actions';
import { mimicModeState } from '../../../_state';
import styles from './styles';
import { useAlertActions } from '../../../_actions/alert.actions';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

const Settings = () => {
  const alertActions = useAlertActions();
  const navigate = useNavigate();
  const [isMimicMode, setIsMimicMode] = useRecoilState(mimicModeState);

  const handleLogout = useCallback(() => {
    Session.clear();
    window.location.href = `${process.env.REACT_APP_WEB_HOST}/logout`;
  }, []);

  const handleExitMimicMode = useCallback(async () => {
    const res = await exitMimicMode();

    if (res.response && res.response.status > 204) {
      alertActions.error('An error occurred', res.response, '', res.request);
      return;
    }
    if (res && res.status === 204){
      setIsMimicMode(false);
      navigate(0);
    }

    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[alertActions]);

  return (
    <Stack direction='column' spacing={1}>
      {isMimicMode && (
        <MenuItem>
          <Link component='span' onClick={handleExitMimicMode}>Exit Mimic Mode</Link>
        </MenuItem>
      )}
      <MenuItem
        component={RouterLink}
        to={'/settings'}
      >
        <SettingsOutlinedIcon color='primary' sx={styles.SettingsIcon}/> Settings
      </MenuItem>
      <MenuItem
        component='a'
        target='_blank'
        rel='noopener'
        onClick={handleLogout}
      >
        <LogoutOutlinedIcon color='primary' sx={styles.SettingsIcon} /> Logout
      </MenuItem>
    </Stack>
  );
};

export default Settings;
