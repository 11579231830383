import CircularProgress from '@mui/material/CircularProgress';
import { memo } from 'react';
const circularLoading = ({color, ...props}) => <CircularProgress {...props} color={color} />;

export const CircularLoading = memo(circularLoading);

CircularLoading.defaultProps = {
  color: 'secondary',
};

export default CircularLoading;
