/* eslint-disable react/no-children-prop */
import { Avatar, Box, IconButton, Link, Stack, Typography } from '@mui/material';

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Markdown from 'react-markdown';
import styles from './styles';
import { useCallback } from 'react';

const CHAR_MAX = 225;

const Member = ({ openDetails, index, inModal, data }) => {
  const handleViewMore = useCallback(() => openDetails(index), [index, openDetails]);

  return (
    <Box sx={styles.Member}>
      <Stack direction="column" spacing={1}>
        <Avatar
          alt={`${data.firstName} ${data.lastName}`}
          sx={styles.Avatar}
        />
        <Stack direction="row" spacing={2} sx={styles.MemberName} >
          <Typography variant="h6">{data.firstName} {data.lastName}</Typography>
          <Box sx={styles.MemberSocialLinks}>
            {data.linkedinProfile && <IconButton href={data.linkedinProfile} color='primary' target='_blank' rel='noopener' disableRipple edge='start'>
              <LinkedInIcon />
            </IconButton>}
          </Box>
        </Stack>
        <Typography variant="subtitle1" sx={styles.MemberRole}>{data.title}</Typography>
        {data.bio.length > CHAR_MAX && !inModal ? (
          <Typography variant='body2' component='div'>
            <Markdown>
              {`${data.bio.substring(0, CHAR_MAX)}...`}
            </Markdown>
            <Link onClick={handleViewMore}>Show More</Link>
          </Typography>
        ) : (
          data.bio.length > 0 ? <Typography variant='body2' component='div'>
            <Markdown children={data.bio} />
          </Typography> : <Typography variant='caption2' component="span">No bio provided by the company</Typography>
        )}
      </Stack>
    </Box>
  );
};

export default Member;
