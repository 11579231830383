import { Stack, Tab, Tabs } from '@mui/material';
import { useCallback, useState } from 'react';

import Copyright from './copyright';
import Disclaimer from './disclaimer';
import Documents from './documents';
import Privacy from './privacy';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Typography from '@mui/material/Typography';
import { useIsMobile } from '../../_helpers';

export default function Compliance() {
  const [activePanel, setActivePanel] = useState('disclaimer');
  const isMobile = useIsMobile();

  const handleChange = useCallback((event, newValue) => {
    setActivePanel(newValue);
  }, [setActivePanel]);

  return (
    <Stack spacing={4}>
      <Typography variant='h1'>Legal</Typography>
      <TabContext value={activePanel}>
        <Tabs
          value={activePanel}
          onChange={handleChange}
          centered={!isMobile}
          variant={!isMobile ? 'fullWidth' : 'scrollable'}
          scrollButtons
          aria-label="legal documentation"
        >
          <Tab value="disclaimer" label="Disclaimer" />
          <Tab value="privacy-policy" label="Privacy Policy" />
          <Tab value="copyright-policy" label="Copyright Policy" />
          <Tab value="documents" label="Other Documents" />
        </Tabs>
        <TabPanel value="disclaimer"><Disclaimer /></TabPanel>
        <TabPanel value="privacy-policy"><Privacy /></TabPanel>
        <TabPanel value="copyright-policy"><Copyright /></TabPanel>
        <TabPanel value="documents"><Documents /></TabPanel>
      </TabContext>
    </Stack>
  );
}
