import { Grid, Typography } from '@mui/material';

import Market from '../../components/Market';
import NotificationsList from '../../components/NotificationsList';
import PortfolioValue from '../../components/PortfolioValue';
import PortfolioValueHistory from '../../components/PortfolioValueHistory';
import SecuritiesOverview from '../../components/SecuritiesOverview';
import SidebarLayout from '../../layouts/SidebarLayout';
import TransactionsOverview from '../../components/TransactionsOverview';
import { useIsDesktop } from '../../_helpers';

const MarketplacePage = () => {
  const isDesktop = useIsDesktop();

  const centerWell = !isDesktop
    ? [PortfolioValueHistory, SecuritiesOverview, TransactionsOverview, Market]
    : [PortfolioValueHistory, Market];

  const sidebar = !isDesktop
    ? []
    : [SecuritiesOverview, TransactionsOverview];

  return (
    <Grid container spacing={{xs: 2, lg: 3}}>
      <Grid item xs={12}>
        <Typography variant='h1'>Marketplace</Typography>
      </Grid>
      <Grid item xs={12}>
        <NotificationsList />
      </Grid>
      <Grid item xs={12}>
        <SidebarLayout
          topWell={[PortfolioValue]}
          centerWell={centerWell}
          sidebar={sidebar}
        />
      </Grid>
    </Grid>
  );
};

export default MarketplacePage;
