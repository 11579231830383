const styles = {
  Root: () => ({
    backdropFilter: 'blur(5px)',
    zIndex: 9999999999999
  }),
  DialogPaper: () => ({
    backgroundColor: 'unset'
  }),
};

export default styles;
