import { Box, Typography } from '@mui/material';

import Field from './Field';
import LockIcon from '@mui/icons-material/Lock';
import PropTypes from 'prop-types';
import SsnInput from '../../netcapital-components/SsnInput';
import styles from './styles';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

const TinField = ({ recoilState, forceSet }) => {
  const [secureTin, setSecureTin] = useRecoilState(recoilState);
  const handleChangeTin = useCallback(newValue => setSecureTin({...secureTin, tin: newValue}), [secureTin, setSecureTin]);

  return (
    <Field label='SSN or TIN *'>
      <SsnInput
        forceSet={forceSet}
        isSet={Boolean(secureTin?.key)}
        onChange={handleChangeTin}
      />
      <Box color='grey.500'>
        <LockIcon sx={styles.LockIcon} />
        <Typography variant='caption'>For your security, your SSN is encrypted before being processed. It is not used for any purpose other than verifying your identity and preparing your tax documents.</Typography>
      </Box>
    </Field>
  );
};

TinField.defaultProps = {
  forceSet: false,
};

TinField.propTypes = {
  recoilState: PropTypes.shape({key: PropTypes.string.isRequired}).isRequired,
  forceSet: PropTypes.bool,
};

export default TinField;
