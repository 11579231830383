import { Box, Dialog, DialogActions, DialogContent, Link, MobileStepper, Stack, Typography, useMediaQuery } from '@mui/material';
import { useCallback, useState } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import OutlinedButton from '../../netcapital-components/OutlinedButton';
import PropTypes from 'prop-types';
import SolidButton from '../../netcapital-components/SolidButton';
import styles from './styles';
import { useTheme } from '@emotion/react';

const steps = [
  {
    title: 'Welcome to the Marketplace',
    body: 'Welcome to the Marketplace, an online investment platform powered by Templum Markets LLC that connects investors with early-stage and growth-oriented companies. The platform allows investors to browse investment opportunities, review company information and financials, invest directly in companies, and trade with other investors.',
    image: `${process.env.REACT_APP_CDN_IMAGES}/investors-web/onboarding-01.svg`,
  },
  {
    title: 'Buy and sell shares on the secondary market',
    body: 'On Netcapital Marketplace, investors can buy and sell shares in real time through our user-friendly platform, and monitor investments and view updates about companies through their dashboard.',
    image: `${process.env.REACT_APP_CDN_IMAGES}/investors-web/onboarding-02.svg`,
  },
  {
    title: 'Build a diverse portfolio',
    body: 'Netcapital Marketplace enables investors to create a diversified portfolio by offering investment opportunities in a range of companies across various industries and stages of growth. The platform provides tools and resources to help investors research and select the right investments for their portfolio. Investors can track their portfolio\'s performance and receive updates on the companies they\'ve invested in, all in one convenient location.',
    image: `${process.env.REACT_APP_CDN_IMAGES}/investors-web/onboarding-03.svg`,
  },
  {
    title: 'User-friendly',
    body: 'The Marketplace investment process is designed to be straightforward and seamless, enabling investors to complete their investments quickly and easily. The Marketplace provides investors with a range of resources, including educational materials and investor updates through announcements and news feeds.',
    image: `${process.env.REACT_APP_CDN_IMAGES}/investors-web/onboarding-04.svg`,
  },
  {
    title: 'Follow company growth and market sentiment',
    body: 'The Marketplace enables investors to follow the growth and market sentiment of companies they\'ve invested in or are interested in. Investors can access company updates, news, funding history, and more, allowing them to track the progress of their portfolio companies and make informed decisions about their investments.',
    image: `${process.env.REACT_APP_CDN_IMAGES}/investors-web/onboarding-05.svg`,
  },
];

const OnboardingModal = ({ isOpen, onClose }) => {
  const [activeStep, setActiveStep] = useState(0);

  const handleClickNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, []);

  const handleClickPrev = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, []);

  const nextButton = (
    <SolidButton
      color='primary'
      sx={styles.NavButton}
      disableElevation
      onClick={handleClickNext}
    >
      <Typography variant='button' sx={styles.ButtonCTA}>Next</Typography>
      <ArrowForwardIcon sx={styles.ArrowForwardIcon} />
    </SolidButton>
  );

  const finalButton = (
    <SolidButton
      color='primary'
      sx={styles.NavButton}
      disableElevation
      onClick={onClose}
    >
      <Typography variant='button' sx={styles.ButtonCTA}>Start</Typography>
      <ArrowForwardIcon sx={styles.ArrowForwardIcon} />
    </SolidButton>
  );

  const prevButton = (
    <OutlinedButton
      color='primary'
      sx={styles.NavButton}
      disableElevation
      onClick={handleClickPrev}
    >
      <ArrowBackIcon sx={styles.ArrowBackIcon} />
      <Typography variant='button' sx={styles.ButtonCTA}>Previous</Typography>
    </OutlinedButton>
  );

  const theme = useTheme();
  const isMaxWidth = useMediaQuery(theme.breakpoints.down('md'));
  const isMaxHeight = useMediaQuery('(max-height: 714px)');

  return (
    <Dialog
      open={isOpen}
      fullScreen={isMaxWidth || isMaxHeight}
      fullWidth
      maxWidth='md'
    >
      <DialogContent sx={styles.ContainerContainer}>
        <Link onClick={onClose} sx={styles.Skip}>Skip</Link>
        <Stack spacing={3}>
          <Box component='img' src={steps[activeStep].image} sx={styles.Image} />
          <Typography variant='h4' color='primary.main' sx={styles.TextCentered}>{steps[activeStep].title}</Typography>
          <Typography variant='body1' sx={styles.TextCentered}>{steps[activeStep].body}</Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <MobileStepper
          variant='dots'
          steps={steps.length}
          position='static'
          activeStep={activeStep}
          nextButton={activeStep === steps.length-1 ? finalButton : nextButton}
          backButton={activeStep > 0 ? prevButton : <Box />}
          sx={styles.Steps}
        />
      </DialogActions>
    </Dialog>
  );
};

OnboardingModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default OnboardingModal;
