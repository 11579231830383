import { Typography } from '@mui/material';

const Author = (props) => {
  const { author } = props;

  if (!author) {
    return <Typography fontWeight='medium'>Comment Deleted</Typography>;
  }

  return (
    <Typography fontWeight='medium'>
      {author.firstName} {author.lastName}
      {author.isAdmin && <Typography color='grey.500' component='span'> is a Netcapital moderator</Typography>}
      {author.doingBusinessAs && <Typography color='grey.500' component='span'> works at {author.doingBusinessAs}</Typography>}
    </Typography>
  );
};

export default Author;
