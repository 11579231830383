const styles = {
  Progress: {
    position: 'absolute',
    top: 0,
    width: '100%',
    left: 0,
    height: '0.313rem'
  },
  Member: {
    marginRight: '3.125rem',
    '&:last-child': {
      marginRight: 0,
    },
    flex: {
      xs: '0 0 100%',
      sm: '0 0 50%',
      lg: '0 0 33.3333%',
    },
    userSelect: 'none',
  },
  Avatar: { width: 64, height: 64 },
  MemberName: {
    height: '1.5rem',
  },
  MemberSocialLinks: {
    margin: 'auto',
  },
  MemberRole :{
    color: 'rgba(26, 26, 26, 0.8)',
    fontStyle: 'italic'
  },
  Modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  }
};

export default styles;
