import PropTypes from 'prop-types';
import Radio from '@mui/material/Radio';
import React from 'react';

const radioInput = (props) => {
  const { color, size } = props;
  return (
    <Radio
      color={color}
      size={size}
      {...props}
    />
  );
};

export const RadioInput = React.memo(radioInput);

RadioInput.defaultProps = {
  color: 'primary',
  size: 'medium',
};

RadioInput.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'success', 'error', 'info', 'warning', 'grey']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default RadioInput;
