import { APIListingURL, APIs } from './_api-urls';
import { companyPriceGraphState, companyState } from '../_state';
import { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { defineEndDate } from './_utils';
import { useFetchWrapper } from '../_helpers';

export function useGetCompany(listingCode = '') {
  const [company, setCompany] = useRecoilState(companyState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);
  const [prevListingCode, setPrevListingCode] = useState('');

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    function abort() {
      controller.abort();
    }

    if (Object.keys(company).length > 0 && listingCode === prevListingCode) {
      setLoaded(true);
      return abort;
    }

    setPrevListingCode(listingCode);

    const url = APIListingURL(listingCode);
    fetchWrapper.get(url, null, signal)
      .then((response) => {
        if (!response) return; // aborted request gives response === undefined
        setCompany(response);
        setLoaded(true);
      })
      .catch(() => setLoaded(true));

    return abort;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingCode, company]);

  return { loaded };
}

export function useGetCompanyGraph(listingKey, activeDateRange) {
  const setPriceGraph = useSetRecoilState(companyPriceGraphState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    function abort() {
      controller.abort();
    }

    setLoaded(false);

    const today = new Date();
    const startDate = defineEndDate(activeDateRange, today);

    const queryParams = {
      startDate,
      endDate: today
    };

    const url = `${
      APIs.Marketplace
    }/securities/${listingKey}/graph?startdate=${queryParams.startDate.toISOString()}&enddate=${queryParams.endDate.toISOString()}`;
    fetchWrapper
      .get(url, null, signal)
      .then((response) => {
        if (!response) return; // aborted request gives response === undefined

        setPriceGraph(response);
        setLoaded(true);
      })
      .catch(() => {
        setLoaded(true);
      });

    return abort;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingKey, activeDateRange]);

  return { loaded };
}
