import { Stack, Typography } from '@mui/material';

import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { NumberFormat } from '../../_helpers';
import PropTypes from 'prop-types';
import styles from './styles';

const formatters = {
  'number': NumberFormat.thousands,
  'money': NumberFormat.cents,
  'dollars': NumberFormat.dollars,
  'percent': NumberFormat.percent,
};

const Delta = (props) => {
  const {
    variant,
    color,
    value,
    firstValue,
    type,
    forceDecimals,
    formatType,
    justifyContent
  } = props;
  const isUp = value > firstValue;
  const isDown = value < firstValue;
  const percentGain = firstValue ? (value - firstValue)/firstValue : null;
  const formatterOptions = forceDecimals ? {minimumFractionDigits: 2} : {};

  const getValue = {
    'flat': value,
    'gain': value - firstValue,
  };

  const iconStyles = [];
  if (variant === 'body2') {
    iconStyles.push(styles.IconForSmallFont);
  }
  if (isUp) {
    iconStyles.push(styles.GainIcon);
  }

  return (
    <Stack direction='row' justifyContent={justifyContent} alignItems='center' spacing={0.5}>
      <Typography
        color={color ? color : isUp ? 'gain.main' : isDown ? 'loss.main' : 'common.black'}
        variant={variant}
      >
        {formatters[formatType](getValue[type], formatterOptions)}
      </Typography>
      {(isUp || isDown) && (
        <Typography
          color={color ? color : isUp ? 'gain.main' : isDown ? 'loss.main' : 'common.black'}
          variant={variant}
        >
          {`(${formatters.percent(percentGain)})`}
        </Typography>
      )}
      {isUp && (
        <Typography variant={variant} sx={styles.IconContainer}>
          <ArrowCircleDownIcon color={color || 'gain'} sx={iconStyles} />
        </Typography>
      )}
      {isDown && (
        <Typography variant={variant} sx={styles.IconContainer}>
          <ArrowCircleDownIcon color={color || 'loss'} sx={iconStyles} />
        </Typography>
      )}
    </Stack>
  );
};

Delta.defaultProps = {
  formatType: 'number',
  variant: 'body1',
  forceDecimals: false,
  justifyContent: 'flex-end',
  type: 'flat',
  firstValue: 0,
  color: '',
};

Delta.propTypes = {
  type: PropTypes.oneOf(['gain', 'flat']),
  value: PropTypes.number.isRequired,
  firstValue: PropTypes.number,
  forceDecimals: PropTypes.bool,
  justifyContent: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'space-between', 'space-around', 'space-evenly']),
  formatType: PropTypes.oneOf([ 'money', 'percent', 'number', 'dollars' ]),
  variant: PropTypes.oneOf(['body1', 'body2', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  color: PropTypes.string,
};

export default Delta;
