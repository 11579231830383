const styles = {
  ExpandToggleIcon: ({ theme, toggled }) => ({
    color: theme.palette.primary.main,
    transform: toggled ? 'rotate(180deg)' : 'none',
  }),
  UserMenuContainer: {
    display: 'flex',
    cursor: 'pointer',
    borderRadius: '0.625rem',
    paddingRight: 0,
  },
  UserIcon: ({ breakpoints }) => ({
    [breakpoints.up('md')]: {
      marginRight: '1rem',
    }
  }),
  UserName: ({ breakpoints }) => ({
    display: 'none',
    [breakpoints.up('md')]: {
      display: 'block',
      marginRight: '1rem',
      whiteSpace: 'nowrap',
      fontWeight: 'fontWeightMedium',
    }
  }),
  UserSubMenu: {
    padding: '10px',
  },
  UserSubMenuCategory: {
    fontWeight: 'fontWeightMedium',
    paddingLeft: '1rem',
  },
  UserOptions: {
    width: '100%',
  },
  SettingsIcon: {
    marginRight: '10px'
  },
  MenuLink: () => ({
    textDecoration: 'none',
  }),
};

export default styles;
