import { useEffect, useState } from 'react';

import { APIs } from './_api-urls';
import { companyCollectionsState } from '../_state';
import { useFetchWrapper } from '../_helpers';
import { useRecoilState } from 'recoil';

export function useGetCompanyCollections() {
  const [collections, setCollections] = useRecoilState(companyCollectionsState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    function abort(){ controller.abort(); }

    if (typeof collections !== 'undefined') {
      setLoaded(true);
      return abort;
    }

    const url = `${APIs.Listings}/collections?status=published`;
    fetchWrapper.get(url, null, signal)
      .then((response) => {
        if (!response) return; // aborted request gives response === undefined
        setCollections(response.data);
        setLoaded(true);
      });
    return abort;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collections]);

  return { loaded };
}
