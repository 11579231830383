import { NEGATIVE_PAGE_MARGIN_WIDTHS } from '../../layouts/layout-styles';

const styles = {
  CompanyTabs: {
    marginLeft: NEGATIVE_PAGE_MARGIN_WIDTHS,
    marginRight: NEGATIVE_PAGE_MARGIN_WIDTHS,
  },
  Tab: {
    textTransform: 'none',
  },
  TabContent: {
    paddingLeft: 0,
    paddingRight: 0,
  },
};

export default styles;
