import { globalSearchBarState, marketSearchState } from '../_state';
import { prepareQueryString, useFetchWrapper } from '../_helpers';
import { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { APIs } from './_api-urls';

export function useGetListings(collectionKey, queryParams, isGlobalSearch = false) {
  const [market, setMarketResults] = useRecoilState(marketSearchState);
  const setSearchBar = useSetRecoilState(globalSearchBarState);
  const fetchWrapper = useFetchWrapper();
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    function abort() {
      controller.abort();
    }

    if (isGlobalSearch && queryParams.search.length < 3) return abort;

    setLoading(true);
    setLoaded(false);

    const url = `${APIs.Listings}/collections/${collectionKey}/listings?${prepareQueryString(
      queryParams
    )}`;
    fetchWrapper
      .get(url, null, signal)
      .then((response) => {
        if (!response) return; // aborted request gives response === undefined

        setLoading(false);
        setLoaded(true);

        if (isGlobalSearch) {
          setSearchBar({ results: [...response.data], metadata: response.metadata });
          return;
        }

        let data = response.data;
        if (Object.keys(market).length > 0 && queryParams && queryParams.page > 1) {
          data = [...market.results, ...response.data];
        }

        setMarketResults({ metadata: response.metadata, results: data });
      })
      .catch(() => {
        setLoading(false);
        setLoaded(true);
      });

    return abort;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionKey, queryParams.page, queryParams.search]);

  return { loading, loaded };
}
