import { Box, Button, Grid, InputAdornment } from '@mui/material';
import { useCallback, useState } from 'react';

import AddIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import AddressInput from '../../../../../netcapital-components/AddressInput';
import BeneficialOwnerVerificationDocs from './verification-docs';
import DateSelector from '../../../../../netcapital-components/DateSelector';
import { DateTime } from 'luxon';
import Field from '../../../../FormFields/Field';
import PersonRemoveIcon from '@mui/icons-material/PersonRemoveAlt1Outlined';
import SsnInput from '../../../../../netcapital-components/SsnInput';
import TextField from '../../../../../netcapital-components/TextField';
import _ from 'lodash';
import { fetchHoldingEntityBeneficialOwnersAttribute } from '../../../../../_state';
import produce from 'immer';
import styles from '../styles';
import { useDeleteHoldingEntityBeneficialOwnerVerificationDocument } from '../../../../../_actions/holding-entities.actions';
import { useRecoilState } from 'recoil';

const eighteenYearsAgo = DateTime.now().minus({years: 18});
const oneHundredFifteenYearsAgo = DateTime.now().minus({years: 115});

const BeneficialOwner = (props) => {
  const {
    person,
    index,
    handleCreateBeneficialOwner,
    holdingEntityKey,
    fieldNamePrefix,
  } = props;
  const [beneficialOwners, setBeneficialOwners] = useRecoilState(fetchHoldingEntityBeneficialOwnersAttribute('beneficialOwners'));
  const [tinInitialValue,] = useState(person?.secureTin?.tin);

  const handleChange = useCallback((currentIndex, attribute, newValue) => {
    const updatedValues = produce(beneficialOwners, (draft) => {
      _.set(draft[currentIndex], attribute, newValue);
    });
    setBeneficialOwners([...updatedValues]);
  }, [beneficialOwners, setBeneficialOwners]);

  const {destroy: deleteFile} = useDeleteHoldingEntityBeneficialOwnerVerificationDocument(holdingEntityKey);
  const handleRemove = useCallback(() => {
    beneficialOwners?.[index]?.verificationDocuments?.forEach(doc => {
      if (doc.key) {
        deleteFile(doc.key, person.key);
      }
    });
    const ownersCopy = [...beneficialOwners];
    ownersCopy.splice(index, 1);
    setBeneficialOwners([...ownersCopy]);
  }, [beneficialOwners, deleteFile, index, person.key, setBeneficialOwners]);

  const handleChangeFirstName = useCallback(e => handleChange(index, 'firstName', e.target.value), [handleChange, index]);
  const handleChangeLastName = useCallback(e => handleChange(index, 'lastName', e.target.value), [handleChange, index]);
  const handleChangeDateOfBirth = useCallback(newValue => handleChange(index, 'dateOfBirth', newValue), [handleChange, index]);
  const handleChangeTin = useCallback(newValue => handleChange(index, 'secureTin', {...person?.secureTin, tin: newValue}), [handleChange, index, person?.secureTin]);
  const handleChangeAddress = useCallback((attribute, newValue) => handleChange(index, `address.${attribute}`, newValue), [handleChange, index]);
  const handleChangeEmail = useCallback(e => handleChange(index, 'email', e.target.value), [handleChange, index]);
  const handleChangePercentageOwned = useCallback(e => handleChange(index, 'ownershipPercentage', parseInt(e.target.value)), [handleChange, index]);

  return (
    <Grid item xs={12} container spacing={2} sx={styles.PersonContainer} data-beneficial-owner={person.key}>
      <Grid item xs={12} sm={6}>
        <Field label='First Name *'>
          <TextField
            value={person.firstName || ''}
            onChange={handleChangeFirstName}
            placeholder='First Name'
            variant='outlined'
            fieldName={`${fieldNamePrefix}.firstName`}
          />
        </Field>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field label='Last Name *'>
          <TextField
            value={person.lastName || ''}
            onChange={handleChangeLastName}
            placeholder='Last Name'
            variant='outlined'
            fieldName={`${fieldNamePrefix}.lastName`}
          />
        </Field>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field label='Date of Birth *'>
          <DateSelector
            onChange={handleChangeDateOfBirth}
            value={person.dateOfBirth || ''}
            minDate={oneHundredFifteenYearsAgo}
            maxDate={eighteenYearsAgo}
            fieldName={`${fieldNamePrefix}.dateOfBirth`}
          />
        </Field>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field label='SSN or TIN *'>
          <SsnInput
            isSet={Boolean(tinInitialValue)}
            onChange={handleChangeTin}
            fieldName={`${fieldNamePrefix}.secureTin`}
          />
        </Field>
      </Grid>
      <Grid item xs={12} xl={6}>
        <Field label='Address *'>
          <Box name='GridMarginFix'>
            <AddressInput
              value={person.address || {}}
              onChange={handleChangeAddress}
              requirementLabel='*'
              fieldNamePrefix={`${fieldNamePrefix}.address`}
            />
          </Box>
        </Field>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field label='Email *'>
          <TextField
            value={person.email || ''}
            onChange={handleChangeEmail}
            variant='outlined'
            placeholder='Email'
            fieldName={`${fieldNamePrefix}.email`}
          />
        </Field>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field label='Percentage Owned *'>
          <TextField
            value={person.ownershipPercentage || ''}
            onChange={handleChangePercentageOwned}
            variant='outlined'
            type='number'
            InputProps={{ endAdornment: <InputAdornment position='end'>%</InputAdornment>}}
            inputProps={{ min: 10, max: 100 }}
            fieldName={`${fieldNamePrefix}.ownershipPercentage`}
          />
        </Field>
      </Grid>
      <Grid item xs={12}>
        <BeneficialOwnerVerificationDocs
          person={person}
          holdingEntityKey={holdingEntityKey}
          fieldNamePrefix={`${fieldNamePrefix}`}
          beneficialOwnerIndex={index}
        />
      </Grid>

      {beneficialOwners?.length > 1 && (
        <Grid item xs={6}>
          <Button
            onClick={handleRemove}
            color='error'
            startIcon={<PersonRemoveIcon />}
            size='small'
            variant='outlined'
          >
            Remove Person
          </Button>
        </Grid>
      )}

      {beneficialOwners?.length -1 === index && <Grid item xs={6}>
        <Button
          variant='outlined'
          color='success'
          size='small'
          onClick={handleCreateBeneficialOwner}
          startIcon={<AddIcon />}
        >
          Add another person
        </Button>
      </Grid>}
    </Grid>
  );
};

export default BeneficialOwner;
