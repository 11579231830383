import { MAIN_MENU_MARGIN_WIDTHS } from '../../layout-styles';

const activeMenuItemStyles = {
  backgroundColor: 'primary.contrastText',
  color: 'primary.main',
  '.MuiSvgIcon-root, .MuiTypography-root': {
    color: 'primary.main',
    fill: 'primary.main',
  },
};

const styles = {
  Drawer: () => ({
    width: {
      xs: '20rem',
      sm: '26.75rem',
      md: '31.875rem',
    },
  }),
  MainMenu: ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }),
  MainMenuContainer: {
    backgroundColor: 'primary.main',
    paddingLeft: MAIN_MENU_MARGIN_WIDTHS,
    paddingRight: MAIN_MENU_MARGIN_WIDTHS,
  },
  MainMenuContent: ({ breakpoints }) => ({
    color: 'common.white',
    display: 'block',
    flexGrow: 1,
    paddingTop: 4,
    [breakpoints.up('lg')]: {
      paddingTop: 6,
      paddingRight: 0,
    },
  }),
  HeaderMobileMenuLogo: {
    paddingTop: {
      xs: '9px',
      sm: '5px',
    },
    width: {
      xs: '120px',
      sm: '160px',
    }
  },
  HeaderMobileMenuClose: ({ palette }) =>({
    color: palette.common.white,
    paddingLeft: 0,
  }),
  MenuItem: {
    borderRadius: {
      xs: '0.625rem',
      lg: '0.625rem 0 0 0.625rem',
    },
    paddingTop: 2,
    paddingRight: 0,
    paddingBottom: 2,
    paddingLeft: 2,
    marginBottom: 2,
    minHeight: {
      xs: '3.5rem',
    },
    position: 'relative',
    '&:hover': activeMenuItemStyles,
  },
  DisabledOption: {
    borderRadius: '0.625rem 0 0 0.625rem',
    paddingTop: 2,
    paddingRight: 0,
    paddingBottom: 2,
    paddingLeft: 2,
    marginBottom: 2,
    minHeight: {
      xs: '3.5rem',
    },
  },
  MenuItemActive: activeMenuItemStyles,
  MenuLinkContainer: {
    textDecoration: 'none',
    width: 'calc(100% - 1rem)',
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
  },
  MenuLinkActive: ({ typography }) => ({
    fontWeight: typography.fontWeightBold
  }),
  MenuLink: ({ typography }) => ({
    color: 'primary.contrastText',
    fill: 'primary.contrastText',
    fontSize: '14px',
    fontWeight: typography.fontWeightRegular,
    lineHeight: 1.666,
    textDecoration: 'none',
  }),
  ComingSoon: ({ palette }) => ({
    display: 'inline-block',
    color: palette.grey[400],
  }),
  ComingSoonIcon: ({ palette }) => ({
    margin: 'auto 0',
    '& .MuiSvgIcon-root': {
      color: palette.grey[400],
    },
  }),
};

export default styles;
