import { TableCell, TableRow, Typography } from '@mui/material';

const ListingsHeader = () => {
  return (
    <TableRow>
      <TableCell>
        <Typography variant='overline' fontWeight='fontWeightMedium' color='table.header'>
          Company
        </Typography>
      </TableCell>
      <TableCell align='center'>
        <Typography variant='overline' fontWeight='fontWeightMedium' color='table.header'>
          Change
        </Typography>
      </TableCell>
      <TableCell align='center'>
        <Typography variant='overline' fontWeight='fontWeightMedium' color='table.header'>
          Price
        </Typography>
      </TableCell>
      <TableCell />
      <TableCell />
    </TableRow>
  );
};

export default ListingsHeader;
