import { investorState, templumFormKycStatusState, templumInvestorKycStatusState, templumUserKycStatusState, userState } from '../_state';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { APIUserURL } from './_api-urls';
import { useFetchWrapper } from '../_helpers';

export const useGetTemplumFormKycStatus = (entityKey) => {
  const setTemplumKycStatus = useSetRecoilState(templumFormKycStatusState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(true);
  const [isRefresh, setIsRefresh] = useState(false);
  const refreshData = useCallback(() => {
    setIsRefresh(true);
    setShouldFetch(true);
  }, []);
  const user = useRecoilValue(userState);
  const [prevEntityKey, setPrevEntityKey] = useState(entityKey);

  useEffect(() => {
    if (shouldFetch || prevEntityKey !== entityKey) {
      const controller = new AbortController();
      const signal = controller.signal;
      const abort = () => controller.abort();
      setPrevEntityKey(entityKey);

      if (!isRefresh) {
        setLoaded(false);
      }

      const queryString = entityKey ? `?filterEntityKey=${entityKey}` : '';
      const url = `${APIUserURL(user.entityKey)}/kyc${queryString}`;
      fetchWrapper.get(url, null, signal, false)
        .then((response) => {
          if (!response) return;
          setTemplumKycStatus(response);
          setLoaded(true);
          setShouldFetch(false);
        })
        .catch(() => {
          setLoaded(true);
          setShouldFetch(false);
        });

      return abort;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.entityKey, shouldFetch, isRefresh, entityKey, setTemplumKycStatus]);

  return { loaded, refreshData };
};

export const useGetTemplumInvestorKycStatus = () => {
  const user = useRecoilValue(userState);
  const investor = useRecoilValue(investorState);
  const setTemplumKycStatus = useSetRecoilState(templumInvestorKycStatusState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const refreshData = useCallback(() => setIsRefresh(true), []);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const abort = () => controller.abort();

    if (!isRefresh) {
      setLoaded(false);
    }

    const queryString = `?filterEntityKey=${investor?.entityKey}`;
    const url = `${APIUserURL(user.entityKey)}/kyc${queryString}`;
    fetchWrapper.get(url, null, signal, false)
      .then((response) => {
        if (!response) return;
        setTemplumKycStatus(response);
        setLoaded(true);
      })
      .catch(() => {
        setLoaded(true);
      });

    return abort;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.entityKey, isRefresh, investor?.entityKey, setTemplumKycStatus]);

  return { loaded, refreshData };
};

export const useGetTemplumUserKycStatus = () => {
  const setTemplumKycStatus = useSetRecoilState(templumUserKycStatusState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(true);
  const [isRefresh, setIsRefresh] = useState(false);
  const refreshData = useCallback(() => {
    setIsRefresh(true);
    setShouldFetch(true);
  }, []);
  const user = useRecoilValue(userState);

  useEffect(() => {
    if (shouldFetch) {
      const controller = new AbortController();
      const signal = controller.signal;
      const abort = () => controller.abort();

      if (!isRefresh) {
        setLoaded(false);
      }

      const url = `${APIUserURL(user.entityKey)}/kyc`;
      fetchWrapper.get(url, null, signal, false)
        .then((response) => {
          if (!response) return;
          setTemplumKycStatus(response);
          setLoaded(true);
          setShouldFetch(false);
        })
        .catch(() => {
          setLoaded(true);
          setShouldFetch(false);
        });

      return abort;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.entityKey, shouldFetch, isRefresh, setTemplumKycStatus]);

  return { loaded, refreshData };
};

export const useSubmitTemplumKyc = (holdingEntityKey) => {
  const fetchWrapper = useFetchWrapper();
  const [saving, setSaving] = useState(false);
  const user = useRecoilValue(userState);

  const save = useCallback((onSuccess) => {
    const controller = new AbortController();
    const signal = controller.signal;
    const abort = () => controller.abort();

    if (!user.entityKey ) {
      return abort;
    }

    setSaving(true);

    const url = holdingEntityKey
      ? `${APIUserURL(user.entityKey)}/user-entities/${holdingEntityKey}/kyc/submit`
      : `${APIUserURL(user.entityKey)}/kyc/submit`;

    fetchWrapper.post(url, null, signal, false)
      .then(() => {
        setSaving(false);
        if (typeof onSuccess === 'function') onSuccess();
      })
      .catch(() => setSaving(false));

    return abort;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { save, saving };
};
