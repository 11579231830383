import { Button, Grid } from '@mui/material';
import { fetchOfferAttribute, fetchTemplumInvestorKycStatusAttribute, kycTradeModalState } from '../../_state';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import styles from './styles';
import { useCallback } from 'react';

const OfferModalActions = ({ onClose, onValidated }) => {
  const offerType = useRecoilValue(fetchOfferAttribute('offerType'));
  const quantity = useRecoilValue(fetchOfferAttribute('quantity'));
  const pricePerUnit = useRecoilValue(fetchOfferAttribute('pricePerUnit'));

  const templumKycStatus = useRecoilValue(fetchTemplumInvestorKycStatusAttribute('status'));
  const setKycTradeModalIsOpen = useSetRecoilState(kycTradeModalState);

  const handleVerifyKycBeforeTrading = useCallback(() => {
    if (templumKycStatus !== 'KYC_ACCEPTED') {
      setKycTradeModalIsOpen(true);
      return;
    }
    onValidated();
  },[onValidated, setKycTradeModalIsOpen, templumKycStatus]);


  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Button
          variant='outlined'
          onClick={onClose}
          size='large'
          sx={styles.ActionButton}
        >
          Cancel
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          variant='contained'
          color={offerType === 'BID' ? 'gain' : 'loss' }
          onClick={handleVerifyKycBeforeTrading}
          disabled={!pricePerUnit || !quantity}
          size='large'
          sx={styles.ActionButton}
        >
          {offerType === 'BID' ? 'Buy' : 'Sell'} Shares
        </Button>
      </Grid>
    </Grid>
  );
};

export default OfferModalActions;
