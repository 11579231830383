// skipcq: JS-C1003 - yup does not expose itself as an ES Module.
import * as Yup from 'yup';

import { ACCREDITED_INVESTOR_CRITERIA, HOLDING_ENTITY_TRUST_AUTHORITY_TYPES, HOLDING_ENTITY_TYPES, INVESTMENT_FUNDING_SOURCES, LIQUIDITY_NEEDS, QUALIFIED_PURCHASER_CRITERIA, RISK_TOLERANCES } from '../../../../static-info';

import AddressSchema from '../../../../netcapital-components/AddressInput/schema';
import InvestorAffiliationsSchema from '../../InvestorAffiliationsSchema';

const HoldingEntityInfoSchema = Yup.object({
  legalName: Yup.string().required('Required'),
  type: Yup.string()
    .oneOf(HOLDING_ENTITY_TYPES.map(type => type.value))
    .required('Required'),
  otherType: Yup.string()
    .nullable()
    .when('type', {
      is: 'OTHER',
      then: (schema) => schema.required('Required'),
    }),
  trusteeAuthority: Yup.string()
    .oneOf(HOLDING_ENTITY_TRUST_AUTHORITY_TYPES.map(type => type.value))
    .when('type', {
      is: 'TRUST',
      then: (schema) => schema.required('Required'),
    }),
  federalTaxId: Yup.string().required('Required'),
  dateOfFormation: Yup.date()
    .min(new Date(1900, 0, 1))
    .nullable()
    .transform(v => (v instanceof Date && !isNaN(v) ? v : null))
    .required('Required'),
  address: AddressSchema,
  accreditation: Yup.object({
    isAccreditedInvestor: Yup.bool().required('Required'),
    method: Yup.string()
      .nullable()
      .oneOf(ACCREDITED_INVESTOR_CRITERIA.HOLDING_ENTITY.map(type => type.value))
      .when('isAccreditedInvestor', {
        is: true,
        then: (schema) => schema.required('Required'),
      }),
  }),
  isQualifiedPurchaser: Yup.bool()
    .nullable()
    .when('isAccreditedInvestor', {
      is: true,
      then: (schema) => schema.required('Required'),
    }),
  qualifiedPurchaserReason: Yup.string()
    .oneOf(QUALIFIED_PURCHASER_CRITERIA.HOLDING_ENTITY.map(type => type.value))
    .when('isQualifiedPurchaser', {
      is: true,
      then: (schema) => schema.required('Required'),
    }),
  affiliations: InvestorAffiliationsSchema,
  netWorth: Yup.number().required('Required'),
  liquidityNeeds: Yup.string()
    .oneOf(LIQUIDITY_NEEDS.map(type => type.value))
    .required('Required'),
  riskTolerance: Yup.string()
    .oneOf(RISK_TOLERANCES.map(type => type.value))
    .required('Required'),
  investmentFundSources: Yup.object().shape({
    fundSources: Yup.array().of(
      Yup.string().oneOf(INVESTMENT_FUNDING_SOURCES.HOLDING_ENTITY.map(type => type.value))
    ).min(1, 'Required').required('Required'),
    otherSource: Yup.string(),
  }).default(null).nullable(),
});

export default HoldingEntityInfoSchema;
