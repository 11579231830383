import { ACCREDITED_INVESTOR_CRITERIA, ASSET_TYPES, INDUSTRY_EXPERIENCE, INVESTMENT_DECISIONS, INVESTMENT_OBJECTIVES, SOURCES_OF_WEALTH } from '../../../../static-info';
import { getOptionLabelByValue, renderBooleanAnswer } from '../../../../_helpers';

import { Grid } from '@mui/material';
import ReadOnlyField from '../../ReadOnlyField';
import ReadOnlyStyles from '../../ReadOnlyField/styles';
import { fetchUserInvestorInfoAttribute } from '../../../../_state';
import { useRecoilValue } from 'recoil';

const InvestorInfoReadOnly = () => {
  const isAccredited = useRecoilValue(fetchUserInvestorInfoAttribute('accreditation.isAccreditedInvestor'));
  const accreditedReason = useRecoilValue(fetchUserInvestorInfoAttribute('accreditation.method'));
  const isQualifiedPurchaser = useRecoilValue(fetchUserInvestorInfoAttribute('isQualifiedPurchaser'));
  const isOrRelatedToIndustryProfessional = useRecoilValue(fetchUserInvestorInfoAttribute('affiliations.isOrRelatedToIndustryProfessional'));
  const industryProfessionalName = useRecoilValue(fetchUserInvestorInfoAttribute('affiliations.industryProfessionalName'));
  const isOrRelatedToControlPerson = useRecoilValue(fetchUserInvestorInfoAttribute('affiliations.isOrRelatedToControlPerson'));
  const controlPersonCompanyName = useRecoilValue(fetchUserInvestorInfoAttribute('affiliations.controlPersonCompanyName'));
  const hasMilitaryOrGovernmentAffiliation = useRecoilValue(fetchUserInvestorInfoAttribute('affiliations.hasMilitaryOrGovernmentAffiliation'));
  const militaryOrGovernmentAffiliations = useRecoilValue(fetchUserInvestorInfoAttribute('affiliations.militaryOrGovernmentAffiliation'));
  const investmentObjectives = useRecoilValue(fetchUserInvestorInfoAttribute('investmentObjectives'));
  const investmentDecisions = useRecoilValue(fetchUserInvestorInfoAttribute('investmentDecisions'));
  const assetTypesExperience = useRecoilValue(fetchUserInvestorInfoAttribute('assetTypesExperience'));
  const industriesExperience = useRecoilValue(fetchUserInvestorInfoAttribute('industriesExperience'));

  return (
    <Grid item container xs={12} rowSpacing={1}>
      <ReadOnlyField
        label={'Are you an accredited investor?'}
        value={renderBooleanAnswer(isAccredited)}
      />
      {accreditedReason && (
        <ReadOnlyField
          label={'How are you accredited?'}
          value={getOptionLabelByValue(accreditedReason, ACCREDITED_INVESTOR_CRITERIA.INDIVIDUAL)}
        />
      )}
      {isAccredited && (
        <ReadOnlyField
          label={'Are you a qualified purchaser?'}
          value={renderBooleanAnswer(isQualifiedPurchaser)}
        />
      )}
      <ReadOnlyField
        label={'Are you, or is a member of your immediate family, an associated person of a broker-dealer or member of a National Securities Exchange?'}
        value={renderBooleanAnswer(isOrRelatedToIndustryProfessional)}
      />
      {isOrRelatedToIndustryProfessional && (
        <ReadOnlyField
          label={'Name of Broker-Dealer or National Securities Exchange'}
          value={industryProfessionalName}
        />
      )}
      <ReadOnlyField
        label={'Are you, or is a member of your immediate family, a director, 10% stakeholder, or senior officer of a publicly traded company?'}
        value={renderBooleanAnswer(isOrRelatedToControlPerson)}
      />
      {isOrRelatedToControlPerson && (
        <ReadOnlyField
          label={'Name or Ticker Symbol of Company'}
          value={controlPersonCompanyName}
        />
      )}
      <ReadOnlyField
        label={'Are you, or is a member of your immediate family, currently or formerly, either: A senior military, government, or political official?'}
        value={renderBooleanAnswer(hasMilitaryOrGovernmentAffiliation)}
      />
      {hasMilitaryOrGovernmentAffiliation && militaryOrGovernmentAffiliations.map(affiliation => (
        <Grid item xs={12} key={affiliation.employedBy} sx={ReadOnlyStyles.Row}>
          <ReadOnlyField label={'Employed By'} value={affiliation.employedBy} />
          <ReadOnlyField label={'Title / Position'} value={affiliation.position} />
          <ReadOnlyField label={'Government Branch'} value={affiliation.governmentBranch} />
          <ReadOnlyField label={'Country'} value={affiliation.country} />
          <ReadOnlyField label={'Source of Wealth'} value={getOptionLabelByValue(affiliation?.sourceOfWealth, SOURCES_OF_WEALTH)} />
        </Grid>
      ))}
      <ReadOnlyField
        label={'Investment Objectives'}
        value={getOptionLabelByValue(investmentObjectives, INVESTMENT_OBJECTIVES)}
      />
      <ReadOnlyField
        label={'How do you generally make investment decisions?'}
        value={getOptionLabelByValue(investmentDecisions, INVESTMENT_DECISIONS)}
      />
      <ReadOnlyField
        label={'Which of the following asset types do you have experience investing in?'}
        value={getOptionLabelByValue(assetTypesExperience, ASSET_TYPES)}
      />
      <ReadOnlyField
        label={'Which of the following industries do you have experience investing in?'}
        value={getOptionLabelByValue(industriesExperience, INDUSTRY_EXPERIENCE)}
      />
    </Grid>
  );
};

export default InvestorInfoReadOnly;
