import { Box, Typography } from '@mui/material';

import styles from './styles';

const MoneyTooltip = ({ active, payload, color }) => {
  // TODO: figure out a way to pass the key for the data point, to fully have custom data label/keys.
  const valueKey = 'value';

  if (active && payload?.length) {
    return (
      <Box sx={styles.Tooltip(color)}>
        <Typography variant='caption1' fontWeight='fontWeightMedium' color='common.white'>{`$${payload[0][valueKey].toLocaleString()}`}</Typography>
      </Box>
    );
  }

  return null;
};

export default MoneyTooltip;
