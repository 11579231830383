import { useGetUserInvestorInfo, useSaveUserInvestorInfo } from '../../../../_actions/user.actions';

import AccountFormActions from '../../Form/actions';
import CircularLoading from '../../../../netcapital-components/CircularLoading';
import FormContext from '../../../../netcapital-components/FormValidation/FormContext';
import InvestorInfoFields from './fields';
import InvestorInfoSchema from './schema';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import accountFormStyles from '../../Form/styles';
import { useFormValidation } from '../../../../netcapital-components/FormValidation';
import { useSaveAccountForm } from '../../Form/hooks';
import { userInvestorInfoState } from '../../../../_state';

const InvestorInfo = ({ disabled }) => {
  const { loaded } = useGetUserInvestorInfo();
  const {save, saving} = useSaveUserInvestorInfo();
  const {validate, validateField, errors, removeError} = useFormValidation(userInvestorInfoState, InvestorInfoSchema);
  const {saveAccountForm} = useSaveAccountForm(save, validate);

  if (!loaded) return <CircularLoading />;

  return (
    <FormContext.Provider value={{validateField, errors, removeError}}>
      <Stack spacing={3} sx={accountFormStyles.FormContentWell}>
        <InvestorInfoFields
          disabled={disabled}
        />
        <AccountFormActions
          onSubmit={saveAccountForm}
          isSubmitting={saving}
        />
      </Stack>
    </FormContext.Provider>
  );
};

InvestorInfo.defaultProps = {
  disabled: false,
};

InvestorInfo.propTypes = {
  disabled: PropTypes.bool,
};

export default InvestorInfo;
