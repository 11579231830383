import { Grid, Stack, Typography } from '@mui/material';

import BackButton from '../../netcapital-components/BackButton';
import ChangeEmailForm from '../../components/Settings/email';
import ChangeMultifactorAuthentication from '../../components/Settings/mfa';
import ChangePassword from '../../components/Settings/password';
import styles from './styles';
import { useIsMobile } from '../../_helpers';

const SettingsPage = () => {
  const isMobile = useIsMobile();

  return (
    <Grid container spacing={6} sx={styles.Container}>
      <Grid item xs={12}>
        <Stack spacing={1} alignItems={'flex-start'}>
          {isMobile && <BackButton />}
          <Typography variant='h3'>Security</Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={7}>
        <ChangePassword />
      </Grid>
      <Grid item xs={12} sm={7}>
        <ChangeMultifactorAuthentication />
      </Grid>
      <Grid item xs={12} lg={9}>
        <ChangeEmailForm />
      </Grid>
    </Grid>
  );
};

export default SettingsPage;
