import DateSelector from '../../netcapital-components/DateSelector';
import { DateTime } from 'luxon';
import Field from './Field';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

const eighteenYearsAgo = DateTime.now().minus({years: 18});
const oneHundredFifteenYearsAgo = DateTime.now().minus({years: 115});

const DateOfBirthField = ({ recoilState, fieldNamePrefix }) => {
  const [dateOfBirth, setDateOfBirth] = useRecoilState(recoilState);
  const handleChangeDateOfBirth = useCallback(date => setDateOfBirth(date), [setDateOfBirth]);

  return (
    <Field label='Date of Birth *'>
      <DateSelector
        onChange={handleChangeDateOfBirth}
        value={dateOfBirth || ''}
        minDate={oneHundredFifteenYearsAgo}
        maxDate={eighteenYearsAgo}
        fieldName={fieldNamePrefix ? `${fieldNamePrefix}.dateOfBirth` : 'dateOfBirth'}
      />
    </Field>
  );
};

export default DateOfBirthField;
