import { Box, TextField as MUITextField } from '@mui/material';
import { cloneElement, memo } from 'react';

import FormFieldErrorMessage from '../FormValidation/FormFieldErrorMessage';
import PropTypes from 'prop-types';
import styles from './styles';
import { useFormFieldValidation } from '../FormValidation';

const NCTextField = (props) => {
  const { isBoldFocus, isNoBorder, alignRight, icon, fieldName, helperText, error, ...others } = props;
  const {errorMessage, eventListeners} = useFormFieldValidation(fieldName);

  if (!icon) {
    return <MUITextField
      {...others}
      sx={[isNoBorder && styles.NoBorder, alignRight && styles.AlignRight, isBoldFocus && styles.BoldFocus, styles.TextField]}
      helperText={errorMessage || helperText}
      FormHelperTextProps={{ component: FormFieldErrorMessage }}
      error={Boolean(error || errorMessage)}
      {...eventListeners}
    />;
  }

  return (
    <Box sx={styles.Root} name='NC-TextField-root'>
      <Box sx={styles.IconContainer}>
        {cloneElement(icon, {sx: styles.IconCentered})}
      </Box>
      <Box sx={styles.TextWrapper}>
        <MUITextField
          {...others}
          name='NC-TextField-component'
          sx={[isNoBorder && styles.NoBorder, alignRight && styles.AlignRight, isBoldFocus && styles.BoldFocus, styles.TextField, styles.TextFieldIcon]}
          helperText={errorMessage || helperText}
          FormHelperTextProps={{ component: FormFieldErrorMessage }}
          error={Boolean(error || errorMessage)}
        />
      </Box>
    </Box>
  );
};

export const TextField = memo(NCTextField);

TextField.defaultProps = {
  isNoBorder: false,
  isBoldFocus: false,
  alignRight: false,
  helperText: '',
  error: false,
};

TextField.propTypes = {
  isNoBorder: PropTypes.bool,
  isBoldFocus: PropTypes.bool,
  alignRight: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  error: PropTypes.bool,
};


export default memo(TextField);
