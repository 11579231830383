import { fetchRequest, isProd } from '../../../_helpers';
import { markBankAccountAsAuthenticated, syncPlaidAccounts, useGetPlaidPublicToken } from '../../../_actions/plaid.actions';
import { useCallback, useEffect } from 'react';

import PropTypes from 'prop-types';
import { plaidPublicTokenState } from '../../../_state';
import { useGroundfloorAnalytics } from '../../../_actions/groundfloor-analytics.actions';
import { usePlaidLink } from 'react-plaid-link';
import { useRecoilValue } from 'recoil';

const updatePlaidTokenOnSuccess = (publicToken, metadata) => {
  const url = `${process.env.REACT_APP_WEB_HOST}/integrations/plaid/accounts`;
  const body = {
    publicToken,
    metadata,
    microdeposits: true
  };
  const isPublic = true; // URL is not public, but we do this so the legacy web app can assert privileges via cookie/with-credentials
  return fetchRequest('post', url, body, null, isPublic).catch(e => e);
};

const BankAccountMicrodepositsResponse = ({ paymentMethodKey, onSuccess }) => {
  const {loaded} = useGetPlaidPublicToken(paymentMethodKey);
  const plaidPublicToken = useRecoilValue(plaidPublicTokenState);
  const GroundfloorAnalytics = useGroundfloorAnalytics();

  const onPlaidSuccess = useCallback(async (publicToken, metadata) => {
    await updatePlaidTokenOnSuccess(publicToken, metadata);
    await markBankAccountAsAuthenticated(paymentMethodKey);
    onSuccess();
  }, [onSuccess, paymentMethodKey]);

  const onPlaidExit = useCallback(async (error, metadata) => {
    GroundfloorAnalytics.track('PLAID_ERROR', { error, metadata });
    if (error?.error_code === 'INVALID_FIELD') {
      await syncPlaidAccounts(paymentMethodKey);
    }
    if (error?.error_code === 'TOO_MANY_VERIFICATION_ATTEMPTS') {
      // User has failed microdeposits code verification.
      // We need to delete this notification because the
      // user's bank acount is now locked in Plaid.
      onSuccess();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSuccess, paymentMethodKey]);

  const { open, ready } = usePlaidLink({
    token: plaidPublicToken,
    onSuccess: onPlaidSuccess,
    onExit: onPlaidExit,
    clientName: 'Netcapital',
    product: ['auth'],
    env: isProd() ? 'production' : 'sandbox',
  });

  useEffect(() => {
    if (loaded && ready) {
      open();
    }
  }, [loaded, open, ready]);

  return null;
};

BankAccountMicrodepositsResponse.propTypes = {
  paymentMethodKey: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default BankAccountMicrodepositsResponse;
