import { fetchHoldingEntityInfoAttribute, holdingEntityDocumentsState } from '../../../../_state';
import { useCallback, useContext } from 'react';
import { useGetHoldingEntityDocuments, useGetHoldingEntityInfo } from '../../../../_actions/holding-entities.actions';

import AccountFormActions from '../../Form/actions';
import AccountFormContext from '../../Form/context';
import CircularLoading from '../../../../netcapital-components/CircularLoading';
import FormContext from '../../../../netcapital-components/FormValidation/FormContext';
import HoldingEntityDocumentsFields from './fields';
import HoldingEntityDocumentsSchema from './schema';
import { Stack } from '@mui/material';
import accountFormStyles from '../../Form/styles';
import { useEmptySave } from '../../../../_actions/empty.actions';
import { useFormValidation } from '../../../../netcapital-components/FormValidation';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

const HoldingEntityDocuments = ({ holdingEntityKey, disabled }) => {
  const navigate = useNavigate();
  const {loaded: loadedInfo} = useGetHoldingEntityInfo(holdingEntityKey);
  const {loaded: loadedDocs} = useGetHoldingEntityDocuments(holdingEntityKey);
  const entityType = useRecoilValue(fetchHoldingEntityInfoAttribute('type'));
  const documents = useRecoilValue(holdingEntityDocumentsState);
  const {validate, validateField, errors, removeError} = useFormValidation(holdingEntityDocumentsState, HoldingEntityDocumentsSchema);
  const {save, saving} = useEmptySave();
  const {
    nextFormPath,
    onSaveSuccess,
  } = useContext(AccountFormContext);

  const handleSaveSuccess = useCallback((newErrors) => {
    onSaveSuccess();
    if (!newErrors || Object.keys(newErrors).length === 0) {
      navigate(nextFormPath);
    }
  }, [navigate, nextFormPath, onSaveSuccess]);

  const handleSave = useCallback(async () => {
    const validationContext = {
      entityType,
      documents: documents?.reduce((map, doc) => {
        map[doc.usage] = doc.url;
        return map;
      }, {})
    };
    const newErrors = await validate(validationContext);
    save(handleSaveSuccess(newErrors));
  }, [documents, entityType, handleSaveSuccess, save, validate]);

  if (!loadedDocs || !loadedInfo) return <CircularLoading />;

  return (
    <FormContext.Provider value={{validateField, errors, removeError}}>
      <Stack spacing={3} sx={accountFormStyles.FormContentWell}>
        <HoldingEntityDocumentsFields
          holdingEntityKey={holdingEntityKey}
          disabled={disabled}
        />
        <AccountFormActions
          onSubmit={handleSave}
          isSubmitting={saving}
        />
      </Stack>
    </FormContext.Provider>
  );
};

export default HoldingEntityDocuments;
