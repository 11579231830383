const styles = {
  Carousel: {
    overflow: 'hidden',
    position: 'relative',
  },
  CarouselContainer: {
    display: 'flex',
    cursor: 'grab',
  },
  PrevNextButton: {
    backgroundColor: 'grey.600',
    boxShadow: 1,
    color: 'common.white',
    width: '24px',
    height: '24px',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    opacity: 0.5,
    '&:hover': {
      opacity: 1,
    }
  },
  PrevButton: {
    left: 0,
  },
  NextButton: {
    right: 0,
  },
  LargeButtonsContainer: {
    position: 'relative',
  },
  PrevNextButtonLarge: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  Dots: {
    justifyContent: 'center',
    paddingTop: 4,
    paddingBottom: 4,
  },
  Dot: {
    cursor: 'pointer',
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    backgroundColor: 'grey.500',
    '&:hover': {
      backgroundColor: 'grey.700',
    },
  },
  DotActive: {
    backgroundColor: 'primary.main',
  },
};

export default styles;
