import { Grid, Typography } from '@mui/material';

import PropTypes from 'prop-types';
import styles from './styles';

const GridItem = (props) => {
  const { title, content, xs, sm, md, lg, xl } = props;

  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <Typography variant='overline' sx={styles.Title}>{title}</Typography>
      <Typography variant='h5' fontWeight='fontWeightBold' component='div' sx={styles.Content}>{content}</Typography>
    </Grid>
  );
};

GridItem.defaultProps = {
  title: <span>&nbsp;</span>,
  content: <span>&nbsp;</span>,
  xs: 6,
  sm: false,
  md: false,
  lg: 4,
  xl: false,
};

GridItem.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  xs: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  sm: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  md: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  lg: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  xl: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
}
;

export default GridItem;
