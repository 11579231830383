const styles = {
  WalletBalance: {
    padding: 0,
    height: '100%',
    '&.MuiIconButton-root': {
      borderRadius: 0,
    },
  },
  WalletLabel: {
    '&.MuiTypography-root':{
      marginRight: '5px',
    },
  },
  WalletBalanceContent: ({ breakpoints }) => ({
    backgroundColor: 'gain.light',
    height: '100%',
    justifyContent: {
      xs: 'right',
      lg: 'space-between',
    },
    lineHeight: 1,
    [breakpoints.up('lg')]:{
      padding: '6px 10px 7px',
      marginLeft: '5px',
    },
  }),
  WalletBalanceSubcontent: {
    padding: '0.75rem 1.5rem',
  },
  WalletAmount: {
    display: 'block',
    textAlign: 'left',
    width: '100%',
    fontSize: '1rem',
    paddingRight: '1rem',
    lineHeight: '1.334',
    fontWeight: 700,
    letterSpacing: '0em'
  },
  Row: {
    lineHeight: 0,
    whiteSpace: 'nowrap',
  },
  WalletIcon: {
    marginRight: '10px',
  },
  Link: {
    textDecoration: 'none',
  },
  LinkDisabled: ({ palette }) => ({
    color: palette.text.disabled,
    pointerEvents: 'none',
  }),
};

export default styles;
