import AccountForm from '../Form';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import FinancialInfo from './FinancialInfo';
import IdentityFiles from './IdentityVerification';
import InvestorInfo from './InvestorInfo';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import PersonalInfo from './PersonalInfo';
import PsychologyAltOutlinedIcon from '@mui/icons-material/PsychologyAltOutlined';
import ReviewAndSubmit from '../ReviewAndSubmit';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import SavingsIcon from '@mui/icons-material/Savings';

const steps = {
  'basic-info': {
    component: PersonalInfo,
    label: 'Personal Information',
    icon: <PersonOutlinedIcon fontSize='small' />,
  },
  'identity': {
    component: IdentityFiles,
    label: 'Identity Verification',
    icon: <PsychologyAltOutlinedIcon fontSize='small' />,
  },
  'investors-info': {
    component: InvestorInfo,
    label: 'Investor Profile',
    icon: <SavingsIcon fontSize='small' />,
  },
  'financial-info': {
    component: FinancialInfo,
    label: 'Financial Information',
    icon: <AttachMoneyIcon fontSize='small' />,
  },
  'review': {
    component: ReviewAndSubmit,
    label: 'Review and Submit',
    icon: <RocketLaunchOutlinedIcon fontSize='small' />,
  },
};

const UserAccount = () => {
  return (
    <AccountForm
      steps={steps}
    />
  );
};

export default UserAccount;
