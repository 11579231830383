import { fetchUserPersonalInfoAttribute, hasSubmitHoldingEntityKycState, hasSubmitIndividualKycState } from '../../../../_state';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import AddressField from '../../../FormFields/Address';
import DateOfBirthField from '../../../FormFields/DateOfBirth';
import EmployerInfo from '../EmployerInfo';
import Field from '../../../FormFields/Field';
import { Grid } from '@mui/material';
import { PERSON_EMPLOYMENT_STATUSES } from '../../../../static-info';
import PersonName from '../../../FormFields/PersonName';
import PersonalInfoReadOnly from './read-only';
import PropTypes from 'prop-types';
import ResidencyCitizenshipTinFieldset from '../../../FormFields/ResidencyCitizenshipTin';
import Selectbox from '../../../../netcapital-components/Selectbox';
import { useCallback } from 'react';

const PersonalInfoFields = ({ disabled }) => {
  const hasSubmitIndividualKyc = useRecoilValue(hasSubmitIndividualKycState);
  const hasSubmitHoldingEntityKyc = useRecoilValue(hasSubmitHoldingEntityKycState);

  const [employmentStatus, setEmploymentStatus] = useRecoilState(fetchUserPersonalInfoAttribute('employment.status'));
  const setEmployerName = useSetRecoilState(fetchUserPersonalInfoAttribute('employment.employer.name'));
  const setEmployerOccupation = useSetRecoilState(fetchUserPersonalInfoAttribute('employment.employer.occupation'));
  const setEmployerTitle = useSetRecoilState(fetchUserPersonalInfoAttribute('employment.employer.title'));
  const setEmployerAddress = useSetRecoilState(fetchUserPersonalInfoAttribute('employment.employer.address'));

  const handleChangeEmploymentStatus = useCallback((e) => {
    setEmployerName('');
    setEmployerOccupation('');
    setEmployerTitle('');
    setEmployerAddress(null);
    setEmploymentStatus(e.target.value);
  }, [setEmployerName, setEmployerOccupation, setEmployerTitle, setEmployerAddress, setEmploymentStatus]);

  if (disabled || hasSubmitIndividualKyc || hasSubmitHoldingEntityKyc) return <PersonalInfoReadOnly />;

  return (
    <Grid item container xs={12} rowSpacing={4} columnSpacing={2}>
      <Grid item xs={12}>
        <PersonName
          recoilState={{
            firstName: fetchUserPersonalInfoAttribute('firstName'),
            lastName: fetchUserPersonalInfoAttribute('lastName'),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateOfBirthField
          recoilState={fetchUserPersonalInfoAttribute('dateOfBirth')}
        />
      </Grid>
      <ResidencyCitizenshipTinFieldset
        recoilState={{
          residencyStatus: fetchUserPersonalInfoAttribute('residencyStatus'),
          citizenship: fetchUserPersonalInfoAttribute('citizenship'),
          secureTin: fetchUserPersonalInfoAttribute('secureTin'),
        }}
        allowNonUSResidency={false}
      />
      <Grid item xs={12}>
        <AddressField
          label='Address *'
          recoilState={fetchUserPersonalInfoAttribute('address')}
          requirementLabel='*'
          forceCountry='US'
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field label='Employment Status *'>
          <Selectbox
            options={PERSON_EMPLOYMENT_STATUSES}
            value={employmentStatus || ''}
            onChange={handleChangeEmploymentStatus}
            fieldName={'employment.status'}
          />
        </Field>
      </Grid>
      {(employmentStatus === 'EMPLOYED' || employmentStatus === 'SELF_EMPLOYED') && <EmployerInfo />}
    </Grid>
  );
};

PersonalInfoFields.defaultProps = {
  disabled: false,
};

PersonalInfoFields.propTypes = {
  disabled: PropTypes.bool,
};

export default PersonalInfoFields;
