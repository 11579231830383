import Field from './Field';
import { INCOME_NETWORTH_OPTIONS } from '../../static-info';
import PropTypes from 'prop-types';
import Selectbox from '../../netcapital-components/Selectbox';
import { isNumber } from 'lodash';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

const NetWorthField = ({ recoilState, label, options, fieldName }) => {
  const [netWorth, setNetWorth] = useRecoilState(recoilState);
  const handleChangeNetWorth = useCallback(e => setNetWorth(e.target.value), [setNetWorth]);

  return (
    <Field label={label ? label : 'Net Worth'}>
      <Selectbox
        value={isNumber(netWorth) ? netWorth : ''}
        options={options}
        onChange={handleChangeNetWorth}
        placeholder='Net Worth'
        fieldName={fieldName}
      />
    </Field>
  );
};

NetWorthField.defaultProps = {
  options: INCOME_NETWORTH_OPTIONS,
  fieldName: '',
};

NetWorthField.propTypes = {
  label: PropTypes.string,
  recoilState: PropTypes.shape({key: PropTypes.string.isRequired}).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  })),
  fieldName: PropTypes.string,
};

export default NetWorthField;
