import { Box, Divider, Grid, Typography } from '@mui/material';

import AnnouncementsHeader from './header';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { DateFormat } from '../../../_helpers';
import PropTypes from 'prop-types';
import styles from './styles';

const colSizes = [
  {xs: 12, sm: 2},
  {xs: 11, sm: 9},
  {xs: 1},
];

const Announcements = (props) => {
  const {
    announcements,
    onClickAnnouncement,
  } = props;

  return (
    <Grid container spacing={2}>
      <AnnouncementsHeader colSizes={colSizes} />
      <Grid item xs={12}><Divider /></Grid>
      {announcements.map((announcement, index) => (
        <Grid item container spacing={2} key={announcement.key}>
          <Grid item {...colSizes[0]} onClick={onClickAnnouncement(index)} sx={styles.AnnouncementRow}>
            {DateFormat.short(announcement.date)}
          </Grid>
          <Grid item {...colSizes[1]} onClick={onClickAnnouncement(index)} sx={styles.AnnouncementRow}>
            <Box sx={styles.AnnouncementCell}>{announcement.title ? announcement.title : announcement.body}</Box>
          </Grid>
          <Grid item {...colSizes[2]} textAlign='right' onClick={onClickAnnouncement(index)} sx={styles.AnnouncementRow}>
            <ArrowForwardIosIcon fontSize='small' />
          </Grid>
          <Grid item xs={12}><Divider /></Grid>
        </Grid>
      ))}
      {announcements.length === 0 && (
        <Grid item xs={12}>
          <Typography>No announcements to display.</Typography>
        </Grid>
      )}
    </Grid>
  );
};

Announcements.propTypes = {
  announcements: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    linkCta: PropTypes.string,
    linkUrl: PropTypes.string,
    file: PropTypes.shape({
      name: PropTypes.string,
      fileKey: PropTypes.string
    }),
  })).isRequired,
  onClickAnnouncement: PropTypes.func.isRequired,
};

export default Announcements;
