import { Typography } from '@mui/material';

const Disclaimer = () => {
  return (
    <Typography variant='body2'>
      Netcapital Systems LLC (“Netcapital Systems”) is a software development company and is neither a funding portal nor a broker-dealer. Netcapital operates this website. Sections of the website are used by Netcapital Funding Portal Inc. to conduct 4(a)(6) offerings as a funding portal registered with the SEC and member of FINRA. Sections of the website are also used by registered broker-dealers to conduct Reg A+ and 506(c) offerings as a broker-dealer registered with the SEC and member of FINRA. Sections of the website are also used by Templum Markets LLC to enable secondary market trading of certain eligible securities as a broker-dealer registered with the SEC and member of FINRA. None of these companies provide investment advice or make investment recommendations and nothing posted to this website should be construed as such. Use of this website constitutes acceptance of Netcapital’s Terms of Service.</Typography>
  );
};

export default Disclaimer;
