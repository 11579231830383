import { Link, Stack, Typography } from '@mui/material';

import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { NumberFormat } from '../../_helpers';
import styles from './styles';

const WalletBalanceSubcontent = (props) => {
  const { loaded, walletBalance } = props;
  const disableWithdraw = walletBalance.availableAmount === 0;

  if (!loaded) return null;

  return (
    <Stack spacing={1} sx={styles.WalletBalanceSubcontent}>
      <Stack direction='row' justifyContent='space-between' spacing={0.5} sx={styles.Row}>
        <Typography component='span' fontWeight='medium'>Balance available: </Typography>
        <Typography
          color='gain.main'
          component='span'
          fontWeight='medium'
        >
          {NumberFormat.cents(walletBalance.availableAmount, {minimumFractionDigits: 2})}
        </Typography>
      </Stack>
      <Link href={`${process.env.REACT_APP_WEB_HOST}/payments/transfer`} sx={styles.Link}>
        <Stack direction='row' justifyContent='space-between' spacing={0} sx={styles.Row}>
          <Typography component='span'>Transfer funds to Wallet</Typography>
          <ChevronRightOutlinedIcon />
        </Stack>
      </Link>
      <Link
        href={`${process.env.REACT_APP_WEB_HOST}/payments/withdraw`}
        sx={disableWithdraw ? [styles.Link, styles.LinkDisabled] : styles.Link}
      >
        <Stack direction='row' justifyContent='space-between' spacing={0} sx={styles.Row}>
          <Typography component='span' color={disableWithdraw ? 'text.disabled' : 'common.black'}>
            Withdraw funds from Wallet
          </Typography>
          <ChevronRightOutlinedIcon />
        </Stack>
      </Link>
    </Stack>
  );
};

export default WalletBalanceSubcontent;

