import { Box, Grid, Link, Typography } from '@mui/material';

import PropTypes from 'prop-types';
import styles from './styles';

const ReadOnlyImageField = ({ label, src, fileName }) => {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <Typography variant='caption'>{label}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box sx={styles.ImageContainer}>
          <Link href={src} target='_blank' rel='noopener noreferrer'>
            <img src={src} alt={fileName} />
          </Link>
        </Box>
      </Grid>
    </>
  );
};

ReadOnlyImageField.defaultProps = {
  alt: '',
};

ReadOnlyImageField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

export default ReadOnlyImageField;
