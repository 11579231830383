import { useCallback, useEffect, useMemo } from 'react';

import ReceiptModal from '../../components/ReceiptModal';
import { receiptModalState } from '../../_state';
import { useRecoilState } from 'recoil';

const ReceiptLayout = () => {
  const url = useMemo(() => new URL(window.location), []);
  const [receiptOfferKey, setReceiptOfferKey] = useRecoilState(receiptModalState);

  const handleCloseReceipt = useCallback(() => {
    url.search = '';
    window.history.pushState({}, document.title, url);
    setReceiptOfferKey('');
  }, [url, setReceiptOfferKey]);

  useEffect(() => setReceiptOfferKey(url.searchParams.get('receipt')), [url, setReceiptOfferKey]);

  if (!receiptOfferKey) {
    return null;
  }

  return (
    <ReceiptModal
      onClose={handleCloseReceipt}
      offerKey={receiptOfferKey}
    />
  );
};

export default ReceiptLayout;
