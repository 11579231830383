import { atom } from 'recoil';

const marketSearchState = atom({
  key: 'marketSearchState',
  default: {}
});

const globalSearchBarState = atom({
  key: 'globalSearchBarState',
  default: {}
});

export {
  marketSearchState,
  globalSearchBarState
};
