const styles = {
  MaterialChangeReason: {
    border: '1px solid',
    borderColor: 'grey.400',
    borderRadius: '0.25rem',
    paddingLeft: 2,
    paddingRight: 2,
    paddingBottom: 1,
  },
};

export default styles;
