import AnnouncementsTab from './Announcements';
import CompanyProfile from './CompanyProfile';
import DiscussionBoard from './DiscussionBoard';
import FundingHistory from './FundingHistory';
import InvestorTransactionsTab from './InvestorTransactions';
import OverviewTab from './Overview';
import PendingOffersTab from './PendingOffers';

export const TABS = [
  {
    id: 'overview',
    label: 'Overview',
    content: <OverviewTab />,
  },
  {
    id: 'announcements',
    label: 'Announcements',
    content: <AnnouncementsTab />,
  },
  {
    id: 'company-profile',
    label: 'Company Profile',
    content: <CompanyProfile />,
  },
  {
    id: 'pending-offers',
    label: 'Pending Offers',
    content: <PendingOffersTab />,
  },
  {
    id: 'funding-history',
    label: 'Funding History',
    content: <FundingHistory />,
  },
  {
    id: 'related-transactions',
    label: 'Your Transactions',
    content: <InvestorTransactionsTab />,
  },
  {
    id: 'discussion',
    label: 'Discussion Board',
    content: <DiscussionBoard />,
  },
];
