const styles = {
  FormContainer: {
    paddingBottom: 3,
  },
  FormNavContainer: {
    maxWidth: '300px',
    margin: 'auto',
  },
  FormSectionNav: {
    paddingLeft: '0.875rem',
  },
  FormSectionNavItem: {
    whiteSpace: {
      xs: 'nowrap',
      md: 'normal',
    },
    '.Mui-disabled': {
      color: 'grey.400',
      '&.MuiStepLabel-iconContainer': {
        backgroundColor: 'grey.400',
      },
    },
    '.MuiStepLabel-root': {
      flexGrow: 1,
    },
  },
  FormSectionIcon: {
    '.MuiStepLabel-iconContainer': {
      backgroundColor: 'primary.light',
      borderRadius: '50%',
      padding: '0.35rem',
      color: 'common.white',
      position: 'relative',
      left: '-0.15rem',
      marginRight: '0.5rem',
      '&.Mui-active': {
        backgroundColor: 'primary.main',
      }
    }
  },
  FormTitleContainer: ({ breakpoints }) => ({
    [breakpoints.up('md')]: {
      '&.MuiGrid-item': {
        paddingTop: 0,
      },
    },
  }),
  FormTitle: ({ breakpoints }) => ({
    paddingTop: '1rem',
    paddingBottom: '1rem',
    [breakpoints.up('md')]: {
      paddingTop: 0,
    },
  }),
  FormContentWell: ({ breakpoints }) => ({
    padding: 0,
    [breakpoints.up('md')]: {
      paddingLeft: '1rem',
    },
    [breakpoints.up('lg')]: {
      paddingLeft: 0,
      paddingRight: '1rem',
    },
  }),
  LinearProgressContainer: {
    flexGrow: 1,
    justifyContent: 'end',
    width: '100%',
  },
  LinearProgress: {
    flexGrow: 1,
    maxWidth: '18.75rem',
  },
  SaveButtonContainer: {
    justifyContent: {
      xs: 'center',
      sm: 'flex-start',
    },
  },
  SaveButton: {
    height: '100%',
  },
  SubmitButtonContainer: {
    justifyContent: {
      xs: 'center',
      sm: 'flex-end',
    },
  },
  SubmitButton: {
    display: 'inline-flex',
  },
  SubmitStep: {
    '.Mui-disabled': {
      color: 'grey.400',
    },
  },
  MobileFormProgress: {
    marginBottom: 3,
  },
};

export default styles;
