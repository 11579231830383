import { atom, selectorFamily } from 'recoil';

const companyFundingHistoryState = atom({
  key: 'companyFundingHistoryState',
  default: []
});

const companyFundingHistoryGraphState = atom({
  key: 'companyFundingHistoryGraphState',
  default: []
});

const fetchCompanyFundingHistoryAttribute = selectorFamily({
  key: 'fetchCompanyFundingHistoryAttribute',
  get: attribute => ({get}) => get(companyFundingHistoryState)[attribute],
  set: attribute => ({set}, newValue) =>
    set(companyFundingHistoryState, prevState => ({...prevState, [attribute]: newValue})),
});

export {
  companyFundingHistoryState,
  companyFundingHistoryGraphState,
  fetchCompanyFundingHistoryAttribute
};
