import { Stack, Typography } from '@mui/material';

import InfiniteScroll from '../../../netcapital-components/InfiniteScroll';
import InfiniteScrollTable from '../../../netcapital-components/InfiniteScrollTable';
import Listing from './desktop';
import ListingMobile from './mobile';
import ListingsHeader from './header';
import PropTypes from 'prop-types';
import { marketSearchState } from '../../../_state';
import { useCallback } from 'react';
import { useIsMobile } from '../../../_helpers';
import { useRecoilValue } from 'recoil';

const Listings = ({ loadedListings, nextPage }) => {
  const isMobile = useIsMobile();
  const market = useRecoilValue(marketSearchState);

  const fetchNextPage = useCallback(() => {
    if (market?.metadata?.page < market?.metadata?.pageCount) {
      nextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [market.metadata]);

  const hasMore = (market?.metadata?.page * market?.metadata?.limit) < market?.metadata?.totalCount;

  if (isMobile) {
    return (
      <InfiniteScroll
        currentItemsCount={market?.results?.length || 0}
        hasMore={hasMore}
        currentPage={market?.metadata?.page || 1}
        fetchData={fetchNextPage}
        endMessage={<Typography variant='caption' textAlign='center' component='div'>End of results</Typography>}
      >
        <Stack spacing={2}>
          {market?.results?.map((listing) => <ListingMobile listing={listing} isMobile key={listing.key} />) || []}
        </Stack>
      </InfiniteScroll>
    );
  }

  return (
    <InfiniteScrollTable
      loaded={loadedListings}
      currentPage={market?.metadata?.page}
      hasMore={hasMore}
      fetchMore={fetchNextPage}
      header={<ListingsHeader />}
      body={
        market?.results?.map((listing) => <Listing listing={listing} key={listing.key} />) || []
      }
    />
  );
};

Listings.defaultProps = {
  loadedListings: false,
};

Listings.propTypes = {
  loadedListings: PropTypes.bool,
  nextPage: PropTypes.func.isRequired,
};

export default Listings;
