const styles = {
  Card: ({ breakpoints }) => ({
    position: 'relative',
    borderRadius: {
      xs: 0,
      lg: '0.75rem',
    },
    [breakpoints.down('lg')]: {
      '.MuiCardHeader-root, .MuiCardContent-root': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  }),
  expand: (theme) => ({
    fontSize: '0.75rem',
    color: theme.palette.primary.main,
    display: 'contents'
  }),
  expandIcon: (theme) => ({
    color: theme.palette.primary.main,
    fontSize: '2.25rem',
    opacity: 0.5,
  }),
  ProgressTop: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '99%',
    height: 5,
    marginLeft: '3px',
    borderRadius: '3000px 3000px 50px 50px'
  },
  ProgressBottom: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '99%',
    height: 5,
    marginLeft: '3px',
    borderRadius: '50px 50px 3000px 3000px'
  },
};

export default styles;
