import { API_Legacy, APIs } from './_api-urls';
import { availableSharesByCompanyState, fetchWalletAttribute, investorState, offerState } from '../_state';
import { useCallback, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useFetchWrapper } from '../_helpers';

export function useCreateOffer() {
  const offer = useRecoilValue(offerState);
  const investor = useRecoilValue(investorState);
  const setAvailableShares = useSetRecoilState(availableSharesByCompanyState);
  const setWalletBalance = useSetRecoilState(fetchWalletAttribute('availableAmount'));
  const fetchWrapper = useFetchWrapper();
  const [saving, setSaving] = useState(false);

  const save = useCallback((listingKey, onSuccess) => {
    const controller = new AbortController();
    const signal = controller.signal;
    function abort(){ controller.abort();}

    setSaving(true);

    const url = `${APIs.Marketplace}/secondary/${offer.offerType.toLowerCase()}`;
    const payload = {
      listingKey,
      holdingEntityKey: investor.entityKey,
      pricePerUnit: offer.pricePerUnit,
      quantity: offer.quantity,
    };
    fetchWrapper.post(url, payload, signal, false)
      .then((response) => {
        if (!response) return; // aborted request gives response === undefined
        if (typeof response.availableAmount === 'number') {
          setWalletBalance(response.availableAmount);
        }
        if (offer.offerType === 'ASK' && response?.securities?.availableQuantity >= 0){
          setAvailableShares(response.securities.availableQuantity);
        }
        onSuccess();
      })
      .finally(() => setSaving(false));
    return abort;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investor.entityKey, offer.offerType, offer.pricePerUnit, offer.quantity]);

  return { saving, save };
}

export function useCancelOffer(offer) {
  const setWalletBalance = useSetRecoilState(fetchWalletAttribute('availableAmount'));
  const fetchWrapper = useFetchWrapper();
  const [saving, setSaving] = useState(false);

  const cancelOffer = useCallback((onSuccess) => {
    const controller = new AbortController();
    const signal = controller.signal;
    function abort(){ controller.abort();}

    setSaving(true);

    if (offer.market === 'PRIMARY') {
      const url = `${API_Legacy}/offers/${offer.offerKey}`;
      const payload = {
        offerStatus: 'FAILED',
        failureReason: 'WITHDRAWN_OWNER',
      };
      fetchWrapper.patch(url, payload, signal, false)
        .then((response) => {
          if (!response) return; // aborted request gives response === undefined
          onSuccess();
        })
        .finally(() => setSaving(false));

      return abort;
    }

    const url = `${APIs.Marketplace}/secondary/${offer.type.toLowerCase()}/${offer.groupKey}/cancel`;
    fetchWrapper.delete(url, null, signal, false)
      .then((response) => {
        if (!response) return; // aborted request gives response === undefined
        if (typeof response.availableAmount === 'number') {
          setWalletBalance(response.availableAmount);
        }
        onSuccess();
      })
      .finally(() => setSaving(false));

    return abort;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offer]);

  return { saving, cancelOffer };
}
