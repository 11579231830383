import { VERIFICATION_DOC_FILES } from '../static-info';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export const addKeysToListItems = (data) => {
  try {
    Object.keys(data).forEach((prop) => {
      if (Array.isArray(data[prop])) {
        data[prop] = data[prop].map(obj => {
          if (typeof obj === 'object') {
            obj._key = uuidv4();
          }
          return obj;
        });
      }
      else if (typeof data[prop] === 'object' && data[prop] !== null) {
        data[prop] = addKeysToListItems(data[prop]);
      }
    });
  }
  catch (e) {
    return data;
  }

  return data;
};

export const preparePayload = (data) => {
  function deepOmit(deepObj, keysToOmit) {
    const keysToOmitIndex =  _.keyBy(Array.isArray(keysToOmit) ? keysToOmit : [keysToOmit] );

    function omitFromObject(obj) {
      if (obj instanceof Date){
        return obj;
      }

      return _.transform(obj, (result, value, key) => {
        if (key in keysToOmitIndex) {
          return;
        }

        result[key] = _.isObject(value) ? omitFromObject(value) : value;
      });
    }

    return omitFromObject(deepObj);
  }

  return JSON.stringify(deepOmit(data, '_key'));
};

export const prepareQueryString = (data={}) => {
  const EMPTY_VALUES = [null, undefined, ''];
  const UNUSED_PARAMS = ['pageCount', 'totalCount'];
  const filteredParams = {...data};

  Object.keys(filteredParams).forEach(key => {
    if (UNUSED_PARAMS.includes(key) || EMPTY_VALUES.includes(filteredParams[key])) {
      delete filteredParams[key];
    }
  });

  return Object.keys(filteredParams)
    .map(key => `${key}=${encodeURIComponent(filteredParams[key])}`)
    .join('&');
};

export const getOptionLabelByValue = (values, options) => {
  const labels = [];
  if (!Array.isArray(values)) {
    values = [values];
  }
  values.forEach(val => {
    const found = options.find(opt => opt.value === val);
    if (found) {
      labels.push(found.label);
    }
  });
  return labels;
};

export const renderBooleanAnswer = (value) => {
  return value ? 'Yes' : value === false ? 'No' : '';
};

export const getVerificationDocConfigByUsage = (usage) => {
  return Object.keys(VERIFICATION_DOC_FILES).reduce((result, currentDocType) => {
    return VERIFICATION_DOC_FILES[currentDocType].find(config => config.usage === usage) ?? result;
  }, undefined); // skipcq: JS-W1042 - ignore Deepsource `trailing undefined` issue
};
