import { AppBar, Box, Collapse, IconButton, Stack, Toolbar } from '@mui/material';
import { useCallback, useRef, useState } from 'react';

import { AppLogo } from './AppLogo';
import FixedHeaderOffset from './offset';
import Help from './Help';
import MainMenu from './MainMenu';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import NotificationsMenu from './NotificationsMenu';
import SearchIcon from '@mui/icons-material/Search';
import SiteSearch from './SiteSearch';
import UserMenu from './UserMenu';
import WalletBalance from '../../components/WalletBalance';
import styles from './styles';

const HeaderMobile = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const handleToggleSearch = useCallback(() => setIsSearchOpen(!isSearchOpen), [isSearchOpen]);
  const handleToggleMenu = useCallback(() => setIsMenuOpen(!isMenuOpen), [isMenuOpen]);
  const containerRef = useRef(null);

  return (
    <Box ref={containerRef}>
      <AppBar position='fixed' variant='light' elevation={4}>
        <Toolbar sx={[styles.HeaderToolbar, styles.HeaderToolbarMobile]}>
          <Stack direction='row'>
            <IconButton
              color='primary'
              onClick={handleToggleMenu}
              sx={styles.HamburgerButton}
              disableRipple
            >
              <MenuOutlinedIcon />
            </IconButton>
            <IconButton disableRipple>
              <AppLogo />
            </IconButton>
          </Stack>
          <Stack direction='row'>
            <IconButton
              color='primary'
              onClick={handleToggleSearch}
              name='SearchMobile'
              disableRipple
            >
              <SearchIcon />
            </IconButton>
            <NotificationsMenu />
            <Help />
            <UserMenu isDrawer open={isMenuOpen} />
          </Stack>
        </Toolbar>
      </AppBar>
      <FixedHeaderOffset />
      <Box sx={styles.WalletBalanceMobile}>
        <WalletBalance />
      </Box>
      <Collapse direction='bottom' in={isSearchOpen} container={containerRef.current} unmountOnExit>
        <Stack direction='row' alignContent='center' justifyContent='center' sx={styles.SearchBarMobile}>
          <SiteSearch />
        </Stack>
      </Collapse>
      <MainMenu isMobile open={isMenuOpen} handleMobileMenuToggle={handleToggleMenu} />
    </Box>
  );
};

export default HeaderMobile;
