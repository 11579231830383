import { Button, InputAdornment } from '@mui/material';
import { useCallback, useState } from 'react';

import FormFieldErrorMessage from '../FormValidation/FormFieldErrorMessage';
import JSEncrypt from 'jsencrypt';
import { PatternFormat } from 'react-number-format';
import PropTypes from 'prop-types';
import TextField from '../TextField';

const encryptor = new JSEncrypt();
const ENV_KEYS = process.env.REACT_APP_KEYS_PUBLIC || '{}';
const PUBLIC_KEY = JSON.parse(ENV_KEYS)?.PUBLIC_TIN_KEY;
encryptor.setPublicKey(PUBLIC_KEY);

const SsnInput = ({ onChange, isSet, forceSet, variant, fieldName }) => {
  const [displayValue, setDisplayValue] = useState('');
  const handleChangeSsn = useCallback(e => {
    const newValue = e.formattedValue;
    setDisplayValue(newValue);
    onChange(encryptor.encrypt(newValue));
  }, [onChange]);

  const [isEditing, setIsEditing] = useState(!isSet || forceSet);
  const handleReset = useCallback(() => {
    onChange('');
    setIsEditing(true);
  }, [onChange]);

  const [errorMessage, setErrorMessage] = useState();
  const allowedSsnValue = useCallback((values) => {
    const maxChars = 9;
    const { value } = values;
    setErrorMessage(value.length < maxChars ? `Must have exactly ${maxChars} digits` : '');
    return value.length <= maxChars;
  }, []);

  if (!isEditing) {
    return (
      <TextField
        value='•••••••••'
        disabled
        InputProps={{
          endAdornment: <InputAdornment position='end'>
            <Button onClick={handleReset} size='small'>Change</Button>
          </InputAdornment>
        }}
      />
    );
  }

  return (
    <PatternFormat
      format='###-##-####'
      value={displayValue}
      onValueChange={handleChangeSsn}
      placeholder='000-00-0000'
      customInput={TextField}
      helperText={<FormFieldErrorMessage>{errorMessage}</FormFieldErrorMessage>}
      FormHelperTextProps={{ component: 'div' }}
      error={Boolean(errorMessage)}
      isAllowed={allowedSsnValue}
      variant={variant}
      fieldName={fieldName}
    />
  );
};

SsnInput.defaultProps = {
  isSet: false,
  fieldName: '',
  forceSet: false,
  variant: 'outlined',
};

SsnInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  isSet: PropTypes.bool,
  fieldName: PropTypes.string,
  forceSet: PropTypes.bool,
  variant: PropTypes.string,
};

export default SsnInput;
