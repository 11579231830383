import { useCallback, useState } from 'react';

import _ from 'lodash';
import { useRecoilValue } from 'recoil';

const formatErrors = (yupErrors) => {
  const errors = {};
  yupErrors.inner.forEach((innerError) => {
    errors[innerError.path] = innerError.message;
  });
  return errors;
};

export const useValidateSchema = (schema) => {
  const [isValidating, setIsValidating] = useState(false);

  const runValidation = useCallback((values, path, context) => {
    if (isValidating || !schema) {
      return Promise.reject(new Error('Either validation is currently running, or no schema was provided!'));
    }

    setIsValidating(true);

    const validatorOptions = {
      abortEarly: false,
      context: context ? {...values, ...context} : values,
    };

    const promise = path
      ? schema.validateAt(path, values, validatorOptions)
      : schema.validate(values, validatorOptions);

    return new Promise((resolve, reject) => {
      promise.then(
        () => {
          setIsValidating(false);
          resolve();
        },
        (err) => {
          setIsValidating(false);
          if (err.name === 'ValidationError') {
            resolve(formatErrors(err));
          } else {
            // We throw any other errors
            if (process.env.NODE_ENV !== 'production') {
              console.warn(
                'Warning: An unhandled error was caught during validation in hook useValidateSchema',
                err
              );
            }
            reject(err);
          }
        }
      );
    });
  }, [isValidating, schema]);

  const validate = useCallback((values, context) => runValidation(values, null, context), [runValidation]);
  const validateAt = useCallback((path, values, context) => runValidation(values, path, context), [runValidation]);

  return {
    validate,
    validateAt,
    isValidating,
  };
};

export const useFormValidation = (recoilState, schema) => {
  const formData = useRecoilValue(recoilState);
  const {
    validate: validateAllValues,
    validateAt,
    isValidating,
  } = useValidateSchema(schema);

  const [errors, setErrors] = useState();

  const validate = useCallback((context) => {
    return validateAllValues({...formData}, context)
      .then(err => {
        setErrors(err);
        return err;
      })
      .catch(e => console.warn(e));
  }, [formData, validateAllValues]);

  const validateField = useCallback((fieldName, context) => () => {
    return validateAt(fieldName, {...formData}, context)
      .then(err => {
        const copyErrors = {...errors};
        _.merge(copyErrors, err);
        setErrors(copyErrors);
      })
      .catch(e => console.warn(e));
  }, [errors, formData, validateAt]);

  const removeError = useCallback((fieldName) => () => {
    const copyErrors = {...errors};
    _.unset(copyErrors, fieldName);
    setErrors(copyErrors);
  }, [errors]);

  return {
    validate,
    validateField,
    isValidating,
    errors,
    removeError,
  };
};
