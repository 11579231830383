import { HOLDING_ENTITY_TRUST_AUTHORITY_TYPES, HOLDING_ENTITY_TYPES } from '../../../../static-info';

import Field from '../../../FormFields/Field';
import { Grid } from '@mui/material';
import Selectbox from '../../../../netcapital-components/Selectbox';
import TextField from '../../../../netcapital-components/TextField';
import { fetchHoldingEntityInfoAttribute } from '../../../../_state';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

const EntityType = () => {
  const [entityType, setEntityType] = useRecoilState(fetchHoldingEntityInfoAttribute('type'));
  const [trusteeAuthority, setTrusteeAuthority] = useRecoilState(fetchHoldingEntityInfoAttribute('trusteeAuthority'));
  const isEntityTypeOther = entityType === 'OTHER';
  const [entityTypeOther, setEntityTypeOther] = useRecoilState(fetchHoldingEntityInfoAttribute('otherType'));

  const handleChangeEntityType = useCallback((e) => setEntityType(e.target.value), [setEntityType]);
  const handleChangeTrusteeAuthority = useCallback((e) => setTrusteeAuthority(e.target.value), [setTrusteeAuthority]);
  const handleChangeEntityTypeOther = useCallback((e) => setEntityTypeOther(e.target.value), [setEntityTypeOther]);

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Field label='Entity Type *'>
          <Selectbox
            options={HOLDING_ENTITY_TYPES}
            value={entityType || ''}
            onChange={handleChangeEntityType}
            fieldName={'type'}
          />
        </Field>
      </Grid>
      {entityType === 'TRUST' && (
        <Grid item xs={12}>
          <Field label='Authority Type *'>
            <Selectbox
              options={HOLDING_ENTITY_TRUST_AUTHORITY_TYPES}
              value={trusteeAuthority || ''}
              onChange={handleChangeTrusteeAuthority}
              fieldName={'trusteeAuthority'}
            />
          </Field>
        </Grid>
      )}
      {isEntityTypeOther && (
        <Grid item xs={12}>
          <Field label='Please specify your Entity Type *'>
            <TextField
              value={entityTypeOther || ''}
              onChange={handleChangeEntityTypeOther}
              fieldName={'otherType'}
            />
          </Field>
        </Grid>
      )}
    </>
  );
};

export default EntityType;
