import { Box, Stack } from '@mui/material';
import { isAuthenticatedState, userState } from '../../_state';

import AppContent from './AppContent';
import Header from '../Header';
import Loading from '../../components/Loading';
import NotificationFormResponse from '../../components/NotificationResponse/form';
import NotificationPlaidResponse from '../../components/NotificationResponse/plaid';
import styles from './styles';
import { useGetSession } from '../../_actions/session.actions';
import { useRecoilValue } from 'recoil';

function AppContainer() {
  const { loaded } = useGetSession();
  const isAuthenticated = useRecoilValue(isAuthenticatedState);
  const user = useRecoilValue(userState);

  if (loaded && isAuthenticated && user.hasAccessToMarketplace === false) {
    window.location.href = `${process.env.REACT_APP_WEB_HOST}/dashboard`;
    return null;
  }

  return (
    <Box sx={styles.AppContainer} name='AppContainer'>
      <NotificationFormResponse />
      <NotificationPlaidResponse />
      <Stack sx={styles.LayoutContainer} name='LayoutContainer'>
        {loaded && isAuthenticated && (
          <>
            <Header />
            <AppContent />
          </>
        )}
      </Stack>
      <Loading loading={!loaded} />
    </Box>
  );
}

export default AppContainer;
