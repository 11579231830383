import { Stack, Typography } from '@mui/material';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { NumberFormat } from '../../_helpers';
import WalletOutlinedIcon from '@mui/icons-material/WalletOutlined';
import styles from './styles';

const WalletBalanceContent = (props) => {
  const { loaded, walletBalance } = props;

  return (
    <Stack
      alignItems='center'
      direction='row'
      name='WalletBalanceContent'
      sx={styles.WalletBalanceContent}
    >
      <Stack direction='row' sx={styles.Row}>
        <WalletOutlinedIcon color='gain' sx={styles.WalletIcon} />
        {loaded && (
          <Typography color='gain.main' sx={styles.WalletAmount}>
            {NumberFormat.cents(walletBalance.availableAmount, {minimumFractionDigits: 2})}
          </Typography>
        )}
      </Stack>
      <MoreVertIcon color='gain' />
    </Stack>
  );
};

export default WalletBalanceContent;
