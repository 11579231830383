const styles = {
  Title: {
    whiteSpace: 'nowrap',
    fontWeight: 700,
    fontSize: '0.75rem',
    lineHeight: '0.938rem',
    color: 'rgba(26, 26, 26, 0.7)',
    letterSpacing: '0.01em',
    textTransform: 'uppercase',
  },
  Content: {
    marginTop: '0.3125rem',
  },
};

export default styles;
