import { Box, Link, Typography } from '@mui/material';

import AnnouncementTitle from './title';
import Markdown from 'react-markdown';
import PropTypes from 'prop-types';
import styles from '../CompanyTabs/Announcements/styles';

const Announcement = (props) => {
  const { announcement, ...other } = props;
  const hasFileAttachment = Boolean(announcement.file);
  const hasExternalLink = Boolean(announcement.linkUrl && announcement.linkCta);

  return (
    <Box {...other}>
      <AnnouncementTitle announcement={announcement} />
      <Typography variant='body2' component='div'>
        <Markdown>
          {announcement.body}
        </Markdown>
      </Typography>
      {hasExternalLink && (
        <Typography variant='body2' component='div'>
          <Link href={announcement.linkUrl} target='_blank' rel='noopener' sx={styles.Link}>{announcement.linkCta}</Link>
        </Typography>
      )}
      {hasFileAttachment && (
        <Link
          href={announcement.file.url}
          target='_blank'
          rel='noopener'
          sx={styles.File}
        >
          {announcement.file.name}
        </Link>
      )}
    </Box>
  );
};

Announcement.propTypes = {
  announcement: PropTypes.shape({
    key: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    title: PropTypes.string,
    body: PropTypes.string.isRequired,
    linkCta: PropTypes.string,
    linkUrl: PropTypes.string,
    file: PropTypes.shape({
      name: PropTypes.string,
      fileKey: PropTypes.string
    }),
  }).isRequired,
};

export default Announcement;
