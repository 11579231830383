import PropTypes from 'prop-types';

const ListingSchema = {
  listing: PropTypes.shape({
    code: PropTypes.string.isRequired,
    currentPrice: PropTypes.number.isRequired,
    doingBusinessAs: PropTypes.string,
    key: PropTypes.string.isRequired,
    legalName: PropTypes.string.isRequired,
    logoUrl: PropTypes.string,
    percentageDelta: PropTypes.number.isRequired,
    status: PropTypes.shape({
      hasExitedNetcapital: PropTypes.bool,
      hideFromMarketplace: PropTypes.bool,
      isSecondaryMarketOpen: PropTypes.bool,
      netcapitalExitReason: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(null)]),
    }).isRequired,
  }).isRequired,
};

export default ListingSchema;
