import { useCallback, useContext } from 'react';

import AccountFormContext from './context';
import { useNavigate } from 'react-router-dom';

export const useSaveAccountForm = (save, validate) => {
  const navigate = useNavigate();
  const {
    nextFormPath,
    onSaveSuccess,
    canProceedToNextForm,
  } = useContext(AccountFormContext);

  const handleSaveSuccess = useCallback((newErrors) => {
    newErrors = newErrors || {};
    onSaveSuccess();
    if (canProceedToNextForm && Object.keys(newErrors).length === 0) {
      navigate(nextFormPath);
    }
  }, [canProceedToNextForm, navigate, nextFormPath, onSaveSuccess]);

  const handleSave = useCallback(async (context) => {
    const newErrors = await validate(context);
    save(handleSaveSuccess(newErrors));
  }, [handleSaveSuccess, save, validate]);

  return {
    saveAccountForm: handleSave,
  };
};
