const styles = {
  ChartWrapper: {
    // Hacks to get the ResponsiveContainer to respond properly
    // when nested inside a flexbox
    flex: 1,
    minWidth: 0,
    '.recharts-wrapper, .recharts-surface': {
      width: '100%!important'
    }
  },
  TooltipWrapper: {
    outline: 'none'
  },
  Tooltip: (color) => ({
    backgroundColor: color,
    borderRadius: '0.625rem',
    padding: '0.25rem 0.5rem'
  }),
  Options: () => ({
    textTransform: 'capitalize',
    minWidth: 'fit-content',
    padding:'0 1rem',
    height:'2.5rem',
    margin: '0 0.5rem',
    borderRadius: '1.875rem',
    '&:first-of-type': {
      marginLeft: 0,
    },
  }),
};

export default styles;
