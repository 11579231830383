import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Link, Typography } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './styles';

const StepAccordion = ({ label, formId, formComponent, holdingEntityKey }) => {
  const StepForm = formComponent;
  const {search} = useLocation();
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon color='primary' />}>
        <Typography variant='h5' sx={styles.AccordionLabel}>{label}</Typography>
        <AccordionActions sx={styles.AccordionActions}>
          <Link component={RouterLink} to={`../${formId}${search}`} variant='body2'>Edit</Link>
        </AccordionActions>
      </AccordionSummary>
      <AccordionDetails>
        <StepForm holdingEntityKey={holdingEntityKey} disabled />
      </AccordionDetails>
    </Accordion>
  );
};

export default StepAccordion;
