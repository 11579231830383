const styles = {
  SearchBar: ({ breakpoints }) => ({
    width: '100%',
    [breakpoints.up('md')]: {
      width: '50%',
    },
    [breakpoints.up('lg')]: {
      width: '400px',
    },
  }),
  Result: {
    '&.MuiAutocomplete-option[aria-selected="true"]': {
      backgroundColor: 'common.white',
    },
  },
  ResultAvatar: {
    width: '24px',
    height: '24px',
  },
};

export default styles;
