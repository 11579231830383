import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Link, Stack, Typography } from '@mui/material';
import { alertAtom, userState } from '../../_state';

import { AuthAPILoginURL } from '../../_actions/_api-urls';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { history } from '../../_helpers';
import styles from './styles';
import { useAlertActions } from '../../_actions/alert.actions';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

// detect browser to determine browser name for email
function detectBrowser() {
  const userAgent = navigator.userAgent;

  const chrome = new RegExp(/chrome|chromium|crios/i);
  const firefox = new RegExp(/firefox|fxios/i);
  const safari = new RegExp(/safari/i);
  const opra = new RegExp(/opr\//i);
  const edge = new RegExp(/edg/i);

  if (chrome.test(userAgent)) {
    return 'chrome';
  }

  if (firefox.test(userAgent)) {
    return 'firefox';
  }

  if (safari.test(userAgent)) {
    return 'safari';
  }

  if (opra.test(userAgent)) {
    return 'opera';
  }

  if (edge.test(userAgent)) {
    return 'edge';
  }
  return 'No browser detection';
}

function makeEmailUrl(response, user) {
  const userURL = window.location.href;
  const browser = detectBrowser();
  const object = JSON.parse(JSON.stringify({ ...response }));
  if (object.config?.headers?.Authorization) {
    delete object.config.headers.Authorization;
  }
  object.user = {
    entityKey: user?.entityKey,
    role: user?.role,
    scope: user?.scope,
    userURL,
    browser
  };

  const emailBody = encodeURIComponent(JSON.stringify(object).toString());
  return `mailto:help@netcapital.com?cc=dev@netcapital.com&subject=Error%20report%20code:%20${object.data?.trackingNumber}&body=${emailBody}`;
}

function Alert() {
  const alert = useRecoilValue(alertAtom);
  const alertActions = useAlertActions();
  const handleClear = alertActions.clear;
  const user = useRecoilValue(userState);
  const emailUrl = makeEmailUrl(alert?.error?.response, user);

  useEffect(() => {
    const unlisten = history.listen(handleClear);
    return unlisten;
  }, [handleClear]);

  if (alert?.error?.response.status === 401) {
    window.location.href = AuthAPILoginURL(window.location.href);
    return null;
  }

  if (!alert?.error?.response || Object.keys(alert.error.response).length === 0) {
    return null;
  }

  return (
    <Dialog
      open
      maxWidth={'sm'}
      PaperProps={{
        sx: styles.DialogPaper
      }}
    >
      <DialogTitle>
        <Stack spacing={2} sx={styles.Title}>
          <Box sx={styles.TitleIcon}><WarningAmberIcon color='error' /></Box>
          <Typography variant='h4' color='primary'>{alert?.error?.title}</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent sx={styles.Content}>
        <Stack spacing={2}>
          <Typography>{alert?.error?.message}. {alert?.error?.response?.data?.message}</Typography>
          <Typography>
            Please, try again. If the error persists, please contact us at <Link href={emailUrl} target='_blank' re='noopener noreferrer'>help@netcapital.com</Link> and include the error information presented.
          </Typography>
          <Typography>Error code: <Typography component='span' fontWeight={'fontWeightMedium'}>{alert?.error?.response?.data?.trackingNumber || 'No tracking ID'}</Typography></Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={styles.DialogActions}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Button
              variant='contained'
              onClick={handleClear}
              href={emailUrl}
              color='primary'
              sx={styles.Button}
            >
                Contact Us
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              variant='outlined'
              onClick={handleClear}
              color='primary'
              sx={styles.Button}
            >
                Close
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export { Alert };
