import { atom, selector, selectorFamily } from 'recoil';

import _ from 'lodash';
import produce from 'immer';

const templumFormKycStatusState = atom({
  key: 'templumFormKycStatusState',
  default: {}
});

const templumInvestorKycStatusState = atom({
  key: 'templumInvestorKycStatusState',
  default: {}
});

const fetchTemplumInvestorKycStatusAttribute = selectorFamily({
  key: 'fetchTemplumInvestorKycStatusAttribute',
  get: attribute => ({get}) => _.get(get(templumInvestorKycStatusState), attribute),
  set: attribute => ({set}, newValue) => {
    set(templumInvestorKycStatusState,
      prevState => produce(prevState, (draft) => _.set(draft, attribute, newValue))
    );
  },
});

const templumUserKycStatusState = atom({
  key: 'templumUserKycStatusState',
  default: {}
});

const hasSubmitKyc = (status) => {
  return status === 'KYC_UNDER_REVIEW' || status === 'KYC_ACCEPTED';
};

const hasSubmitIndividualKycState = selector({
  key: 'hasSubmitIndividualKycState',
  get: ({get}) => hasSubmitKyc(get(templumUserKycStatusState)?.status)
});

const hasSubmitHoldingEntityKycState = selector({
  key: 'hasSubmitHoldingEntityKycState',
  get: ({get}) => Boolean(get(templumUserKycStatusState)?.holdingEntitiesKYC?.find(entityKyc => hasSubmitKyc(entityKyc.status)))
});

const kycTradeModalState = atom({
  key: 'kycTradeModalState',
  default: false
});

export {
  templumFormKycStatusState,
  templumInvestorKycStatusState,
  templumUserKycStatusState,
  hasSubmitIndividualKycState,
  hasSubmitHoldingEntityKycState,
  kycTradeModalState,
  fetchTemplumInvestorKycStatusAttribute,
};
