import { IconButton, Menu } from '@mui/material';
import { useCallback, useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';
import TransactionDetails from '.';
import TransactionSchema from '../TransactionsOverview/Transaction/schema';
import styles from '../TransactionsOverview/styles';

const TransactionDetailsToggle = (props) => {
  const {
    transaction,
    onOpenConfirmCancel,
    onCancelTransaction,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const isDetailsOpen = Boolean(anchorEl);
  const handleOpenDetails = useCallback((e) => setAnchorEl(e.currentTarget), []);
  const handleCloseDetails = useCallback(() => setAnchorEl(null), []);
  const handleOpenConfirmCancelModal = useCallback(() => {
    onOpenConfirmCancel();
    handleCloseDetails();
  }, [handleCloseDetails, onOpenConfirmCancel]);

  return (
    <>
      <IconButton
        onClick={handleOpenDetails}
        disableRipple
        sx={styles.DetailsToggle}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={isDetailsOpen}
        onClose={handleCloseDetails}
        onClick={handleCloseDetails}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        MenuListProps={{sx: styles.DetailsContainer}}
      >
        <TransactionDetails
          transaction={transaction}
          onCancelTransaction={onCancelTransaction}
          onOpenConfirmCancel={handleOpenConfirmCancelModal}
          onOpenReceipt={handleCloseDetails}
          onClose={handleCloseDetails}
        />
      </Menu>
    </>
  );
};

TransactionDetailsToggle.propTypes = {
  ...TransactionSchema,
  // placement: PropTypes.oneOf(['auto-end','auto-start','auto','bottom-end','bottom-start','bottom','left-end','left-start','left','right-end','right-start','right','top-end','top-start','top']),
  onOpenConfirmCancel: PropTypes.func.isRequired,
};

export default TransactionDetailsToggle;
