import { EXPENSES_TEMPLUM_OPTIONS, INCOME_NETWORTH_OPTIONS, INVESTMENT_FUNDING_SOURCES, LIQUIDITY_NEEDS, RISK_TOLERANCES, TAX_FILING_STATUS, YES_NO } from '../../../../static-info';

import Field from '../../../FormFields/Field';
import FinancialInfoReadOnly from './read-only';
import { Grid } from '@mui/material';
import IncomeField from '../../../FormFields/Income';
import MoneyInput from '../../../../netcapital-components/MoneyInput';
import NetWorthField from '../../../FormFields/NetWorth';
import PropTypes from 'prop-types';
import RadioGroup from '../../../../netcapital-components/RadioGroup';
import Selectbox from '../../../../netcapital-components/Selectbox';
import TextField from '../../../../netcapital-components/TextField';
import { fetchUserFinancialInfoAttribute } from '../../../../_state';
import styles from './styles';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

const FinancialInfoFields = ({ disabled }) => {
  const [taxStatus, setTaxStatus] = useRecoilState(fetchUserFinancialInfoAttribute('taxStatus'));
  const [investmentFundingSources, setInvestmentFundingSources] = useRecoilState(fetchUserFinancialInfoAttribute('investmentFundSources.fundSources'));
  const [otherInvestmentFundingSource, setOtherInvestmentFundingSource] = useRecoilState(fetchUserFinancialInfoAttribute('investmentFundSources.otherSource'));
  const [liquidNetWorth, setLiquidNetWorth] = useRecoilState(fetchUserFinancialInfoAttribute('liquidNetWorth'));
  const [annualExpenses, setAnnualExpenses] = useRecoilState(fetchUserFinancialInfoAttribute('annualExpenses'));
  const [hasIlliquidInvestments, setHasIlliquidInvestments] = useRecoilState(fetchUserFinancialInfoAttribute('hasIlliquidInvestments'));
  const [liquidityNeeds, setLiquidityNeeds] = useRecoilState(fetchUserFinancialInfoAttribute('liquidityNeeds'));
  const [riskTolerance, setRiskTolerance] = useRecoilState(fetchUserFinancialInfoAttribute('riskTolerance'));

  const handleChangeTaxStatus = useCallback((e) => setTaxStatus(e.target.value), [setTaxStatus]);
  const handleChangeInvestmentFundingSources = useCallback((e) => {
    if(Array.isArray(e.target.value) && !e.target.value.includes('OTHER')){
      setOtherInvestmentFundingSource('');
    }
    setInvestmentFundingSources(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value,);
  },[setInvestmentFundingSources, setOtherInvestmentFundingSource]);
  const investmentFundingSourceIsOther = investmentFundingSources?.findIndex(reason => reason === 'OTHER') > -1;
  const handleChangeOtherInvestmentFundingSource = useCallback((e) => setOtherInvestmentFundingSource(e.target.value), [setOtherInvestmentFundingSource]);
  const handleChangeLiquidNetWorth = useCallback((newValue) => setLiquidNetWorth(newValue), [setLiquidNetWorth]);
  const handleChangeAnnualExpenses = useCallback((e) => setAnnualExpenses(e.target.value), [setAnnualExpenses]);
  const handleChangeHasIlliquidInvestments = useCallback((e) => setHasIlliquidInvestments(e.target.value === 'true'), [setHasIlliquidInvestments]);
  const handleChangeLiquidityNeeds = useCallback((e) => setLiquidityNeeds(e.target.value), [setLiquidityNeeds]);
  const handleChangeRiskTolerance = useCallback((e) => setRiskTolerance(e.target.value), [setRiskTolerance]);

  if (disabled) return <FinancialInfoReadOnly />;

  return (
    <Grid item container xs={12} rowSpacing={4} columnSpacing={2}>
      <Grid item xs={12} sm={6}>
        <Field label='Tax Status *'>
          <Selectbox
            options={TAX_FILING_STATUS}
            value={taxStatus || ''}
            onChange={handleChangeTaxStatus}
            fieldName={'taxStatus'}
          />
        </Field>
      </Grid>
      <Grid item xs={12} sm={6}>
        <IncomeField
          label='Annual Income *'
          recoilState={fetchUserFinancialInfoAttribute('annualIncome')}
          options={INCOME_NETWORTH_OPTIONS}
          fieldName={'annualIncome'}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NetWorthField
          label={'Net Worth *'}
          recoilState={fetchUserFinancialInfoAttribute('netWorth')}
          options={INCOME_NETWORTH_OPTIONS}
          fieldName={'netWorth'}
        />
      </Grid>
      <Grid item xs={12} sm={6} sx={styles.MoneyInput}>
        <Field label='Liquid Net Worth (optional)'>
          <MoneyInput
            value={liquidNetWorth}
            onChange={handleChangeLiquidNetWorth}
            fieldName={'liquidNetWorth'}
          />
        </Field>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field label='Annual Expenses (optional)'>
          <Selectbox
            options={EXPENSES_TEMPLUM_OPTIONS}
            value={typeof annualExpenses === 'number' ? annualExpenses : ''}
            onChange={handleChangeAnnualExpenses}
            fieldName={'annualExpenses'}
          />
        </Field>
      </Grid>
      <Grid item xs={12}>
        <Field label='Have you previously invested in illiquid, alternative investments? (optional)'>
          <RadioGroup
            options={YES_NO}
            value={hasIlliquidInvestments}
            onChange={handleChangeHasIlliquidInvestments}
            fieldName={'hasIlliquidInvestments'}
          />
        </Field>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field label='How important are your liquidity needs? *'>
          <Selectbox
            options={LIQUIDITY_NEEDS}
            value={liquidityNeeds || ''}
            onChange={handleChangeLiquidityNeeds}
            fieldName={'liquidityNeeds'}
          />
        </Field>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field label='How would you describe your risk tolerance? *'>
          <Selectbox
            options={RISK_TOLERANCES}
            value={riskTolerance || ''}
            onChange={handleChangeRiskTolerance}
            fieldName={'riskTolerance'}
          />
        </Field>
      </Grid>
      <Grid item xs={12}>
        <Field label='Investment Funding Sources (optional)'>
          <Selectbox
            multiple
            options={INVESTMENT_FUNDING_SOURCES.INDIVIDUAL}
            value={investmentFundingSources || []}
            onChange={handleChangeInvestmentFundingSources}
            placeholder='Please select all that apply'
            fieldName={'investmentFundSources.fundSources'}
          />
        </Field>
      </Grid>
      {investmentFundingSourceIsOther && (
        <Grid item xs={12}>
          <Field label={'As your response above includes \'Other\', please explain: *'}>
            <TextField
              value={otherInvestmentFundingSource || ''}
              onChange={handleChangeOtherInvestmentFundingSource}
              placeholder='Other Funding Source'
              fieldName={'investmentFundSources.otherSource'}
            />
          </Field>
        </Grid>
      )}
    </Grid>
  );
};

FinancialInfoFields.defaultProps = {
  disabled: false,
};

FinancialInfoFields.propTypes = {
  disabled: PropTypes.bool,
};

export default FinancialInfoFields;
