import { Button, Grid } from '@mui/material';
import { GOVERNMENT_BRANCHES, SOURCES_OF_WEALTH } from '../../../static-info';

import CountrySelect from '../../../netcapital-components/AddressInput/CountrySelect';
import Field from '../../FormFields/Field';
import PersonRemoveIcon from '@mui/icons-material/PersonRemoveAlt1Outlined';
import PropTypes from 'prop-types';
import Selectbox from '../../../netcapital-components/Selectbox';
import TextField from '../../../netcapital-components/TextField';
import styles from './styles';
import { useCallback } from 'react';

const MilitaryOrGovernmentAffiliation = ({ person, onChange, onRemove, fieldName }) => {
  const handleChangeGovtOfficialEmployer = useCallback((e) => onChange('employedBy', e.target.value), [onChange]);
  const handleChangeGovtOfficialPosition = useCallback((e) => onChange('position', e.target.value), [onChange]);
  const handleChangeGovtBranch = useCallback((e) => onChange('governmentBranch', e.target.value), [onChange]);
  const handleChangeGovtCountry = useCallback((newValue) => onChange('country', newValue), [onChange]);
  const handleChangeSourceOfWealth = useCallback((e) => onChange('sourceOfWealth', typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value), [onChange]);

  return (
    <Grid item xs={12} container spacing={2} sx={styles.PersonContainer}>
      <Grid item xs={12} sm={6}>
        <Field label='Employed By *'>
          <TextField
            value={person.employedBy || ''}
            onChange={handleChangeGovtOfficialEmployer}
            fieldName={`${fieldName}.employedBy`}
          />
        </Field>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field label='Title / Position *'>
          <TextField
            value={person.position || ''}
            onChange={handleChangeGovtOfficialPosition}
            fieldName={`${fieldName}.position`}
          />
        </Field>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field label='Government Branch *'>
          <Selectbox
            options={GOVERNMENT_BRANCHES}
            value={person.governmentBranch}
            onChange={handleChangeGovtBranch}
            fieldName={`${fieldName}.governmentBranch`}
          />
        </Field>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field label='Country *'>
          <CountrySelect
            value={person.country}
            onChange={handleChangeGovtCountry}
            requirementLabel='*'
            fieldName={`${fieldName}.country`}
          />
        </Field>
      </Grid>
      <Grid item xs={12}>
        <Field label='Source of Wealth *'>
          <Selectbox
            multiple
            options={SOURCES_OF_WEALTH}
            value={person.sourceOfWealth || []}
            onChange={handleChangeSourceOfWealth}
            placeholder='Please select all that apply'
            fieldName={`${fieldName}.sourceOfWealth`}
          />
        </Field>
      </Grid>
      <Grid item xs={6}>
        <Button
          onClick={onRemove}
          color='error'
          startIcon={<PersonRemoveIcon />}
          size='small'
          variant='outlined'
        >
          Remove Person
        </Button>
      </Grid>
    </Grid>
  );
};

MilitaryOrGovernmentAffiliation.defaultProps = {
  person: {
    country: '',
    employedBy: '',
    governmentBranch: '',
    position: '',
    sourceOfWealth: [],
  },
};

MilitaryOrGovernmentAffiliation.propTypes = {
  person: PropTypes.shape({
    country: PropTypes.string,
    employedBy: PropTypes.string,
    governmentBranch: PropTypes.string,
    position: PropTypes.string,
    sourceOfWealth: PropTypes.arrayOf(PropTypes.string),
  }),
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
};

export default MilitaryOrGovernmentAffiliation;
