import Copyright from './copyright';
import FooterLinks from './links';
import { Grid } from '@mui/material';
import Social from './social';
import styles from './styles';

function Footer() {
  return (
    <Grid container spacing={3} sx={styles.Footer}>
      <Grid item xs={12}>
        <FooterLinks />
      </Grid>
      <Grid item xs={12}>
        <Social />
      </Grid>
      <Grid item xs={12}>
        <Copyright />
      </Grid>
    </Grid>
  );
}

export default Footer;
