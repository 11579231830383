import { Avatar as MUIAvatar } from '@mui/material';
import PropTypes from 'prop-types';

const Avatar = (props) => {
  const { firstName, lastName } = props;
  const seed = firstName.charCodeAt(0) * lastName.charCodeAt(0) - (lastName.length > 1 ? lastName.charCodeAt(1) : 0);
  const hslValue = `hsl(${seed % 365}, 83%, 58%)`;

  return (
    <MUIAvatar sx={{backgroundColor: hslValue, fontSize: '0.875rem'}}>
      {firstName[0]}{lastName[0]}
    </MUIAvatar>
  );
};

Avatar.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
};

export default Avatar;
