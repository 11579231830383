import { Box, Link } from '@mui/material';

import Notification from '../../netcapital-components/Notification';
import styles from './styles';
import { templumFormKycStatusState } from '../../_state';
import { useRecoilValue } from 'recoil';

const AccountEditDisclaimer = ({ isHoldingEntity }) => {
  const kycStatus = useRecoilValue(templumFormKycStatusState);
  const isVisible = Boolean(kycStatus?.status === 'KYC_UNDER_REVIEW' || kycStatus?.status === 'KYC_ACCEPTED');

  if (!isVisible) return null;

  return (
    <Box sx={styles.Disclaimer}>
      <Notification
        severity={kycStatus?.status === 'KYC_ACCEPTED' ? 'success' : 'info'}
        description={<>
          {kycStatus?.status === 'KYC_UNDER_REVIEW' && <>Your application is currently under review. You will be informed when the review is complete.</>}
          {kycStatus?.status === 'KYC_ACCEPTED' && <>Your application has been approved. You can now transact on the Marketplace.</>}
          <br/>
          {isHoldingEntity
            ? <>If you need to make any changes to your profile, please contact <Link href='mailto:help@netcapital.com'>help@netcapital.com</Link>.</>
            : <>If you need to make any changes to your profile, please contact <Link href='mailto:help@netcapital.com'>help@netcapital.com</Link>.</>}
        </>}
      />
    </Box>
  );
};

export default AccountEditDisclaimer;
