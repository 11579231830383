import { markBankAccountAsAuthenticated, syncPlaidAccounts, useGetPlaidPublicToken } from '../../../_actions/plaid.actions';
import { useCallback, useEffect } from 'react';

import PropTypes from 'prop-types';
import { isProd } from '../../../_helpers';
import { plaidPublicTokenState } from '../../../_state';
import { useGroundfloorAnalytics } from '../../../_actions/groundfloor-analytics.actions';
import { usePlaidLink } from 'react-plaid-link';
import { useRecoilValue } from 'recoil';

const BankAccountReauthResponse = ({ paymentMethodKey, onSuccess }) => {
  const {loaded} = useGetPlaidPublicToken(paymentMethodKey);
  const plaidPublicToken = useRecoilValue(plaidPublicTokenState);
  const GroundfloorAnalytics = useGroundfloorAnalytics();

  const onPlaidSuccess = useCallback(async () => {
    await markBankAccountAsAuthenticated(paymentMethodKey);
    onSuccess();
  }, [onSuccess, paymentMethodKey]);

  const onPlaidExit = useCallback(async (error, metadata) => {
    if (error?.error_code === 'item-no-error') {
      await markBankAccountAsAuthenticated(paymentMethodKey);
      onSuccess();
    }
    else {
      GroundfloorAnalytics.track('PLAID_ERROR', { error, metadata });
      if (error?.error_code === 'INVALID_FIELD') {
        await syncPlaidAccounts(paymentMethodKey);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSuccess, paymentMethodKey]);

  const { open, ready } = usePlaidLink({
    token: plaidPublicToken,
    onSuccess: onPlaidSuccess,
    onExit: onPlaidExit,
    clientName: 'Netcapital',
    product: ['auth'],
    env: isProd() ? 'production' : 'sandbox',
  });

  useEffect(() => {
    if (loaded && ready) {
      open();
    }
  }, [loaded, open, ready]);

  return null;
};

BankAccountReauthResponse.propTypes = {
  paymentMethodKey: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default BankAccountReauthResponse;
