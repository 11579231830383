import { activeNotificationState, notificationResponseState, userState } from '../_state';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { APIUserURL } from './_api-urls';
import { useFetchWrapper } from '../_helpers';

export const useGetDwollaKycInfo = () => {
  const [notificationResponse, setNotificationResponse] = useRecoilState(notificationResponseState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);
  const user = useRecoilValue(userState);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const abort = () => controller.abort();

    if (Object.keys(notificationResponse).length > 0) {
      setLoaded(true);
      return abort;
    }

    const url = `${APIUserURL(user.entityKey)}/personal-information`;
    fetchWrapper.get(url, null, signal, false)
      .then((response) => {
        if (!response) return;
        setNotificationResponse(response);
        setLoaded(true);
      })
      .catch(() => setLoaded(true));

    return abort;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.entityKey, notificationResponse]);

  return {loaded};
};

export const useSubmitDwollaKycInfo = () => {
  const notification = useRecoilValue(activeNotificationState);
  const notificationResponse = useRecoilValue(notificationResponseState);
  const resetNotificationResponse = useResetRecoilState(notificationResponseState);
  const fetchWrapper = useFetchWrapper();
  const [saving, setSaving] = useState(false);
  const user = useRecoilValue(userState);

  const save = useCallback((onSubmit) => {
    const controller = new AbortController();
    const signal = controller.signal;
    const abort = () => controller.abort();

    if (Object.keys(notificationResponse || {}).length === 0 || !user.entityKey ) {
      return abort;
    }

    setSaving(true);

    const url = `${APIUserURL(user.entityKey)}/dwolla-kyc`;
    const body = {
      ...notificationResponse,
      notification,
    };

    fetchWrapper.post(url, body, signal, false)
      .then((response) => {
        if (!response) return;
        resetNotificationResponse();
        setSaving(false);
        if (typeof onSubmit === 'function') onSubmit();
      })
      .catch(() => setSaving(false));

    return abort;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationResponse]);

  return { save, saving };
};

export const useSubmitDwollaKycDocument = () => {
  const notification = useRecoilValue(activeNotificationState);
  const notificationResponse = useRecoilValue(notificationResponseState);
  const resetNotificationResponse = useResetRecoilState(notificationResponseState);
  const fetchWrapper = useFetchWrapper();
  const [saving, setSaving] = useState(false);

  const save = useCallback((onSubmit) => {
    const controller = new AbortController();
    const signal = controller.signal;
    const abort = () => controller.abort();

    setSaving(true);

    const url = `${APIUserURL(notification.entityKey)}/dwolla-kyc/document`;
    const formData = new FormData();
    formData.append('usage', notificationResponse.docType);
    formData.append('kycType', 'PRIMARY');
    formData.append('notificationKey', notification.key);
    formData.append('verificationKey', notification.verification.key);
    formData.append('file', notificationResponse.file);
    fetchWrapper.post(url, formData, signal, false, 'multipart/form-data')
      .then((response) => {
        if (!response) return;
        resetNotificationResponse();
        setSaving(false);
        if (typeof onSubmit === 'function') onSubmit();
      })
      .catch(() => setSaving(false));

    return abort;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationResponse]);

  return { save, saving };
};
