import { Button, Grid, Stack, Typography } from '@mui/material';
import { useGetDwollaKycInfo, useSubmitDwollaKycInfo } from '../../../_actions/kyc-dwolla.actions';

import AddressField from '../../FormFields/Address';
import CircularLoading from '../../../netcapital-components/CircularLoading';
import DateOfBirthField from '../../FormFields/DateOfBirth';
import PersonNameFieldset from '../../FormFields/PersonName';
import PropTypes from 'prop-types';
import ResidencyCitizenshipTinFieldset from '../../FormFields/ResidencyCitizenshipTin';
import { fetchNotificationResponseAttribute } from '../../../_state';
import { useCallback } from 'react';

const getHeaderContent = (notificationType) => {
  if (notificationType === 'COMPLETE_PROFILE') {
    return (
      <Stack spacing={2}>
        <Typography variant='h4'>Complete Your Profile</Typography>
        <Stack spacing={1}>
          <Typography>Before you can invest, we need to collect the required information below.</Typography>
          <Typography fontStyle='italic'>Your information should match what is on your Government Issued I.D.</Typography>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack spacing={2}>
      <Typography variant='h4'>Review &amp; Confirm Your Info</Typography>
      <Typography>Your information should match what is on your Government Issued I.D.</Typography>
    </Stack>
  );
};

const DwollaKyc = ({ onSuccess, notificationType }) => {
  const {loaded} = useGetDwollaKycInfo();
  const {save, saving} = useSubmitDwollaKycInfo();
  const onSubmit = useCallback(() => save(onSuccess), [save, onSuccess]);

  if (!loaded) return <CircularLoading />;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        {getHeaderContent(notificationType)}
      </Grid>
      <Grid item container spacing={2} xs={12}>
        <Grid item xs={12}>
          <PersonNameFieldset
            recoilState={{
              firstName: fetchNotificationResponseAttribute('firstName'),
              lastName: fetchNotificationResponseAttribute('lastName'),
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <AddressField
          recoilState={fetchNotificationResponseAttribute('address')}
        />
      </Grid>
      <Grid item xs={12}>
        <DateOfBirthField
          recoilState={fetchNotificationResponseAttribute('dateOfBirth')}
        />
      </Grid>
      <Grid item xs={12}>
        <ResidencyCitizenshipTinFieldset
          recoilState={{
            residencyStatus: fetchNotificationResponseAttribute('residencyStatus'),
            citizenship: fetchNotificationResponseAttribute('citizenship'),
            secureTin: fetchNotificationResponseAttribute('secureTin'),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          onClick={onSubmit}
          disabled={saving}
          variant='contained'
        >Confirm</Button>
      </Grid>
    </Grid>
  );
};

DwollaKyc.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  notificationType: PropTypes.oneOf(['COMPLETE_PROFILE', 'RETRY_VERIFICATION']).isRequired,
};

export default DwollaKyc;
