import { Container, Stack, Typography } from '@mui/material';

import styles from './styles';

export default function NotFoundPage() {

  return (
    <Container sx={styles.ErrorPage}>
      <Stack spacing={4}>
        <Typography variant='h1'>Page not found.</Typography>
        <Typography>The page you’re trying to access doesn’t exist. If you typed it, double-check your spelling.</Typography>
      </Stack>
    </Container>
  );
}
