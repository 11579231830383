import { TextField } from '@mui/material';
import styles from './styles';

const UnformattedInput = (props) => {
  const { onChange, value, ...other } = props;

  return (
    <TextField
      name='NC-NumberInputStepper'
      {...other}
      value={value}
      onChange={onChange}
      InputProps={{disableUnderline: true}}
      variant='standard'
      sx={styles.Input}
    />
  );
};

export default UnformattedInput;
