import { Box, Button, Link, Stack, Typography } from '@mui/material';
import { activeNotificationState, notificationResponseState } from '../../../_state';
import { useCallback, useState } from 'react';
import { useGetMaterialChangeResponse, useSubmitMaterialChangeResponse } from '../../../_actions/material-change.actions';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Checkbox from '../../../netcapital-components/Checkbox';
import CircularLoading from '../../../netcapital-components/CircularLoading';
import Markdown from 'react-markdown';
import PropTypes from 'prop-types';
import styles from './styles';
import { useRecoilValue } from 'recoil';

const MaterialChangeResponse = ({ onSuccess }) => {
  const notification = useRecoilValue(activeNotificationState);
  const {loaded} = useGetMaterialChangeResponse(notification?.materialChangeResponse?.key);
  const {save, saving} = useSubmitMaterialChangeResponse(notification?.materialChangeResponse?.key, onSuccess);
  const notificationResponse = useRecoilValue(notificationResponseState);
  const [agree, setAgree] = useState(false);
  const toggleAgree = useCallback(() => setAgree(!agree), [agree]);
  const handleSubmit = useCallback((answer) => () => save(answer, onSuccess), [onSuccess, save]);

  const companyName = notificationResponse?.listing?.company?.doingBusinessAs;
  const offeringPageUrl = `${process.env.REACT_APP_WEB_HOST}/companies/${notificationResponse?.listing?.code}`;
  const respondByDateAndTime = `${notificationResponse?.respondByDate}, 05:00 PM EST`;

  if (!loaded) return  <CircularLoading />;

  return (
    <Stack spacing={4}>
      <Typography variant='h3'>Material Change Confirmation</Typography>
      <Stack direction='row' alignItems='center' flexWrap='wrap' spacing={1}>
        <Link href={offeringPageUrl} target='_blank' rel='noopener' variant='h5'>{companyName} has recently made a material change to its offering</Link>
        <ArrowForwardIcon color='primary' />
      </Stack>
      <Stack spacing={2}>
        <Typography>The following changes have been made:</Typography>
        <Box sx={styles.MaterialChangeReason}>
          <Markdown>{notificationResponse?.reason || 'Loading...'}</Markdown>
        </Box>
        <Typography fontStyle='italic'>Please review the full details of this change on their <Link href={offeringPageUrl} target='_blank' rel='noopener'>offering page</Link>. Once you have read over these changes, select whether you want to reconfirm or withdraw your current investment commitment(s) in this company.</Typography>
        <Typography fontWeight='fontWeightBold' fontStyle='italic'>You have until {respondByDateAndTime} to respond to these changes.</Typography>
      </Stack>
      <Stack direction='row' alignItems='center' spacing={1}>
        <Checkbox
          checked={agree}
          onChange={toggleAgree}
          label={`I understand the material changes that have been made to ${companyName}’s offering.`}
          size='medium'
        />
      </Stack>
      <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
        <Button
          onClick={handleSubmit('APPROVED')}
          disabled={!agree || saving}
          variant='contained'>Reconfirm</Button>
        <Button
          onClick={handleSubmit('REJECTED')}
          disabled={!agree || saving}
          variant='outlined'>Withdraw</Button>
      </Stack>
      <Typography fontStyle='italic'>If we don’t hear a response in <Typography fontWeight='fontWeightBold' component='span'>5 business days</Typography>, we must withdraw your current investment commitment(s) in {companyName}. If any investment commitment is withdrawn, the full amount of the investment commitment will be returned to your account, minus any non-refundable processing fees.</Typography>
    </Stack>
  );
};

MaterialChangeResponse.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default MaterialChangeResponse;
