import { useGetUserPersonalInfo, useSaveUserPersonalInfo } from '../../../../_actions/user.actions';

import AccountFormActions from '../../Form/actions';
import CircularLoading from '../../../../netcapital-components/CircularLoading';
import FormContext from '../../../../netcapital-components/FormValidation/FormContext';
import PersonalInfoFields from './fields';
import PersonalInfoSchema from './schema';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import accountFormStyles from '../../Form/styles';
import { useFormValidation } from '../../../../netcapital-components/FormValidation';
import { useSaveAccountForm } from '../../Form/hooks';
import { userPersonalInfoState } from '../../../../_state';

const PersonalInfo = ({ disabled }) => {
  const { loaded } = useGetUserPersonalInfo();
  const {save, saving} = useSaveUserPersonalInfo();
  const {validate, validateField, errors, removeError} = useFormValidation(userPersonalInfoState, PersonalInfoSchema);
  const {saveAccountForm} = useSaveAccountForm(save, validate);

  if (!loaded) return <CircularLoading />;

  return (
    <FormContext.Provider value={{validateField, errors, removeError}}>
      <Stack spacing={3} sx={accountFormStyles.FormContentWell}>
        <PersonalInfoFields
          disabled={disabled}
        />
        <AccountFormActions
          onSubmit={saveAccountForm}
          isSubmitting={saving}
        />
      </Stack>
    </FormContext.Provider>
  );
};

PersonalInfo.defaultProps = {
  disabled: false,
};

PersonalInfo.propTypes = {
  disabled: PropTypes.bool,
};

export default PersonalInfo;
