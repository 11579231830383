import Field from './Field';
import { INCOME_NETWORTH_OPTIONS } from '../../static-info';
import PropTypes from 'prop-types';
import Selectbox from '../../netcapital-components/Selectbox';
import { isNumber } from 'lodash';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

const IncomeField = ({ recoilState, label, options, fieldName }) => {
  const [income, setIncome] = useRecoilState(recoilState);
  const handleChangeIncome = useCallback(e => setIncome(e.target.value), [setIncome]);

  return (
    <Field label={label ? label : 'Annual Income'}>
      <Selectbox
        options={options}
        value={isNumber(income) ? income : ''}
        onChange={handleChangeIncome}
        placeholder='Annual Income'
        fieldName={fieldName}
      />
    </Field>
  );
};

IncomeField.defaultProps = {
  options: INCOME_NETWORTH_OPTIONS,
  fieldName: '',
};

IncomeField.propTypes = {
  recoilState: PropTypes.shape({key: PropTypes.string.isRequired}).isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  })),
  fieldName: PropTypes.string,
};

export default IncomeField;
