const styles = {
  Label: {
    Default: {
      margin: 0,
      alignItems: 'flex-start',
    },
    Unchecked: () => ({
      color: 'rgba(0, 18, 112, 0.5)'
    }),
  },
  Checkbox: ({ palette }) => ({
    color: palette.grey[300],
    paddingTop: '0.188rem',
    '&.Mui-checked': {
      color: palette.success.main,
    },
    '&.Mui-disabled': {
      color: palette.grey[300],
    },
  }),
};

export default styles;
