import { IconButton, Link, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { useCallback, useState } from 'react';

import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import OnboardingModal from '../../../components/OnboardingModal';
import styles from './styles';

const UserMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleMenuClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const [isOnboardingModalOpen, setIsOnboardingModalOpen] = useState(false);
  const openOnboardingModal = useCallback(() => {
    setIsOnboardingModalOpen(true);
    handleMenuClose();
  }, [handleMenuClose]);
  const closeOnboardingModal = useCallback(() => {
    setIsOnboardingModalOpen(false);
    handleMenuClose();
  }, [handleMenuClose]);

  return (
    <Stack direction='row'>
      <IconButton
        color='primary'
        onClick={handleMenuClick}
        name='HelpMenuContainer'
        disableRipple
        component={Link}
      >
        <HelpOutlineOutlinedIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id='help-menu'
        open={isMenuOpen}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        sx={styles.HelpSubMenu}
      >
        <Typography
          variant='overline'
          display='block'
          sx={styles.HelpSubMenuCategory}
        >
          Help Center
        </Typography>
        <MenuItem component={Link} onClick={openOnboardingModal} variant='body2'>
          Introduction
        </MenuItem>
        <MenuItem component={Link} href={`${process.env.REACT_APP_WEB_HOST}/investors`} variant='body2'>
          Investor FAQ
        </MenuItem>
        <MenuItem component={Link} href='https://info.netcapital.com/issuers-contact-us' variant='body2' sx={styles.MenuLink}>
          Entrepreneur FAQ
        </MenuItem>
        <MenuItem component={Link} href='https://faq.netcapital.com/' variant='body2'>
          Help Center
        </MenuItem>
        <MenuItem component={Link} href={`${process.env.REACT_APP_WEB_HOST}/risks`} variant='body2'>
          Educational Materials &amp; Risks
        </MenuItem>
      </Menu>
      <OnboardingModal isOpen={isOnboardingModalOpen} onClose={closeOnboardingModal} />
    </Stack>
  );
};

export default UserMenu;
