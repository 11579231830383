import Box from '@mui/material/Box';
import MUICircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import styles from './styles';

const CircularProgress = (props) => {
  return (
    <Box sx={styles.Container}>
      <Box sx={styles.CircleContainer}>
        <MUICircularProgress {...props} variant='determinate' sx={styles.BackgroundCircle} value={100} />
        <MUICircularProgress {...props} variant='determinate' color='success' />
      </Box>
      <Box sx={styles.Label}>
        <Typography variant='caption' component='div' color='text.secondary'>
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

CircularProgress.propTypes = {
  value: PropTypes.number.isRequired,
};

export default CircularProgress;
