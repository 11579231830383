import { Box, IconButton, Link, Stack, Typography } from '@mui/material';
import { useCallback, useState } from 'react';

import Button from '../SolidButton';
import { CircularLoading } from '../CircularLoading';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import DropHereIcon from '@mui/icons-material/FileDownloadOutlined';
import FileUploadIcon from '@mui/icons-material/FileUploadOutlined';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import styles from './styles';

const Input = styled('input')(styles.Input);

const preventDefaultWindow = () => {
  window.addEventListener('dragover', e => e.preventDefault(), false);
  window.addEventListener('drop', e => e.preventDefault(), false);
};

const FILE_NAME_MAX_CHARS = 22;

function FileUpload(props) {
  const {
    file,
    fileType,
    allowedFileTypes,
    onChange,
    loading,
    showPreview,
    disabled,
    doesUploadOnSelect,
  } = props;
  const [dragActive, setDragActive] = useState(false);
  const [fileUrl, setFileUrl] = useState(file?.url);

  preventDefaultWindow();

  const changeHandler = useCallback((e) => {
    if (!e || !e.target || !e.target.value || disabled){
      return;
    }

    const fileData = e?.target?.files?.length > 0 ? e.target.files[0] : null;
    onChange(fileData);
    setFileUrl(fileData && showPreview ? URL.createObjectURL(fileData) : '');
    e.preventDefault();
    e.target.value = null;
  }, [disabled, onChange, showPreview]);

  const handleDrag = useCallback((e) => {
    if (disabled) return;
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
      return;
    }

    setDragActive(false);
  }, [setDragActive, disabled]);

  const handleDrop = useCallback((e) => {
    if (disabled) return;
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e?.dataTransfer?.files?.[0]) {
      setFileUrl(showPreview ? URL.createObjectURL(e.dataTransfer.files[0]) : '');
      onChange(e.dataTransfer.files[0]);
      return;
    }
  }, [onChange, setDragActive, disabled, showPreview]);

  const handleFileSelect = useCallback((e) => {
    if (disabled) {
      e.preventDefault();
      return;
    }
  }, [disabled]);

  const dropHereComponent = () => {
    return (
      <Box sx={styles.FileLoaded}>
        <Box sx={styles.DroppingFile}>
          <DropHereIcon sx={styles.Icon.Drop} />
          <Typography sx={styles.DropTypography} variant='button'>Drop your file here</Typography>
        </Box>
      </Box>
    );
  };

  const fileLoadedComponent = () => {
    const fileStatus = doesUploadOnSelect ? 'uploaded' : 'selected';
    const fileName = file.name.length >= FILE_NAME_MAX_CHARS ? `${file.name.slice(0, Math.round(file.name.length/3))}...` : file.name;
    return (
      <Link href={file.url} target='_blank' rel='noopener noreferrer' sx={styles.NoTextDecoration}>
        <Box sx={styles.FileLoaded}>
          <Box sx={styles.Box}>
            <Typography sx={styles.ButtonTypography} variant='button' title={file.name}>
              File {fileStatus}: {fileName}
            </Typography>
          </Box>
          <IconButton color='error' sx={styles.IconButton} onClick={changeHandler} value='delete'>
            <CloseIcon />
          </IconButton>
        </Box>
      </Link>
    );
  };

  const fileLoadingComponent = () => {
    return (
      <Box>
        <CircularLoading />
      </Box>
    );
  };

  const isFilePicked = file && file.name;

  if (showPreview) {
    if (fileUrl) {
      return (
        <Stack spacing={1}>
          <Stack sx={styles.FilePreview}>
            <img src={fileUrl} alt='Preview' />
          </Stack>
          <Button onClick={changeHandler} value='delete' variant='outlined' color='error'>Delete</Button>
        </Stack>
      );
    }
    return (
      <Stack
        spacing={4}
        sx={styles.FilePreview}
        component='label'
        onDrop={handleDrop}
        onDragEnter={handleDrag}
        onDragOver={handleDrag}
        onDragLeave={handleDrag}
        onClick={handleFileSelect}
      >
        <Input
          accept={fileType}
          multiple={false}
          onChange={changeHandler}
          type='file'
        />
        <Typography
          variant='subtitle1'
          color='primary'
          textAlign='center'
        >
          Drag and drop a file here or click to select
        </Typography>
        <CloudUploadOutlinedIcon color='primary' fontSize='large' />
        {allowedFileTypes && (
          <Typography variant='caption' color='grey.500'>Accepted File Types: {allowedFileTypes}</Typography>
        )}
      </Stack>
    );
  }

  return (
    <Box onDrop={handleDrop} onDragEnter={handleDrag} onDragOver={handleDrag} onDragLeave={handleDrag} sx={styles.UploadBox}>
      {dragActive && dropHereComponent()}
      {loading && fileLoadingComponent()}
      {isFilePicked && !loading && fileLoadedComponent()}
      {!isFilePicked && !loading && !dragActive &&
        <Button component='label' sx={styles.PseudoButton} onClick={handleFileSelect} disabled={disabled}>
          <Input
            accept={fileType}
            multiple={false}
            onChange={changeHandler}
            type='file'
          />
          <FileUploadIcon sx={styles.Icon.Upload} />
          <Typography variant='button' sx={styles.UploadButtonTypography}>Upload your file here</Typography>
        </Button>
      }
    </Box>
  );
}

FileUpload.defaultProps = {
  showPreview: false,
  disabled: false,
  doesUploadOnSelect: true,
  file: {
    name: '',
    url: '',
    usage: ''
  },
  allowedFileTypes: '',
};

FileUpload.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
    usage: PropTypes.string
  }),
  fileType: PropTypes.string.isRequired,
  allowedFileTypes: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  showPreview: PropTypes.bool,
  disabled: PropTypes.bool,
  doesUploadOnSelect: PropTypes.bool,
};

export default FileUpload;
