import { ACCREDITED_INVESTOR_CRITERIA, HOLDING_ENTITY_TYPES, QUALIFIED_PURCHASER_CRITERIA, SOURCES_OF_WEALTH } from '../../../../static-info';
import { DateFormat, getOptionLabelByValue, renderBooleanAnswer } from '../../../../_helpers';

import { Grid } from '@mui/material';
import ReadOnlyField from '../../ReadOnlyField';
import ReadOnlyStyles from '../../ReadOnlyField/styles';
import _ from 'lodash';
import { fetchHoldingEntityInfoAttribute } from '../../../../_state';
import { useRecoilValue } from 'recoil';

const HoldingEntityInfoReadOnly = () => {
  const legalName = useRecoilValue(fetchHoldingEntityInfoAttribute('legalName'));
  const entityType = useRecoilValue(fetchHoldingEntityInfoAttribute('type'));
  const otherEntityType = useRecoilValue(fetchHoldingEntityInfoAttribute('otherType'));
  const federalTaxId = useRecoilValue(fetchHoldingEntityInfoAttribute('federalTaxId'));
  const dateOfFormation = useRecoilValue(fetchHoldingEntityInfoAttribute('dateOfFormation'));
  const address = useRecoilValue(fetchHoldingEntityInfoAttribute('address'));
  const isAccredited = useRecoilValue(fetchHoldingEntityInfoAttribute('accreditation.isAccreditedInvestor'));
  const accreditedReason = useRecoilValue(fetchHoldingEntityInfoAttribute('accreditation.method'));
  const isQualifiedPurchaser = useRecoilValue(fetchHoldingEntityInfoAttribute('purchaseQualification.isQualifiedPurchaser'));
  const qualifiedPurchaserReasons = useRecoilValue(fetchHoldingEntityInfoAttribute('purchaseQualification.qualificationMethod'));
  const qualifiedPurchaserOtherReason = useRecoilValue(fetchHoldingEntityInfoAttribute('purchaseQualification.otherQualificationMethod'));
  const hasMilitaryOrGovernmentAffiliation = useRecoilValue(fetchHoldingEntityInfoAttribute('affiliations.hasMilitaryOrGovernmentAffiliation'));
  const militaryOrGovernmentAffiliations = useRecoilValue(fetchHoldingEntityInfoAttribute('affiliations.militaryOrGovernmentAffiliation'));

  return (
    <Grid item container xs={12} rowSpacing={1}>
      <ReadOnlyField
        label={'Entity Name'}
        value={legalName}
      />
      {!otherEntityType && (
        <ReadOnlyField
          label={'Entity Type'}
          value={getOptionLabelByValue(entityType, HOLDING_ENTITY_TYPES)}
        />
      )}
      {otherEntityType && (
        <ReadOnlyField
          label={'Entity Type'}
          value={legalName}
        />
      )}
      <ReadOnlyField
        label={'Federal Tax ID Number'}
        value={federalTaxId}
      />
      <ReadOnlyField
        label={'Date Formed'}
        value={DateFormat.short(dateOfFormation)}
      />
      {Object
        .keys(address || {})
        .filter(addressAttribute => addressAttribute !== 'key')
        .map(addressAttribute => <ReadOnlyField label={_.startCase(addressAttribute)} value={address[addressAttribute]} key={addressAttribute} />)
      }
      <ReadOnlyField
        label={'Does the entity qualify as an accredited investor?'}
        value={renderBooleanAnswer(isAccredited)}
      />
      {isAccredited && (
        <ReadOnlyField
          label={'How is the entity accredited?'}
          value={getOptionLabelByValue(accreditedReason, ACCREDITED_INVESTOR_CRITERIA.HOLDING_ENTITY)}
        />
      )}
      {isAccredited && (
        <ReadOnlyField
          label={'Is the entity a Qualified Purchaser?'}
          value={renderBooleanAnswer(isQualifiedPurchaser)}
        />
      )}
      {isAccredited && isQualifiedPurchaser && (
        <ReadOnlyField
          label={'How does the entity qualify for Qualified Purchaser status?'}
          value={getOptionLabelByValue(qualifiedPurchaserReasons, QUALIFIED_PURCHASER_CRITERIA.HOLDING_ENTITY)}
        />
      )}
      {qualifiedPurchaserOtherReason && (
        <ReadOnlyField
          label={'Other criteria for qualified purchaser status'}
          value={qualifiedPurchaserOtherReason}
        />
      )}
      <ReadOnlyField
        label={'Are you, or is anyone affiliated with this entity, currently or formerly, either: A senior military, government, or political official; or an immediate family member or close associate, either personally or professionally, of a senior military, government, or political official?'}
        value={renderBooleanAnswer(hasMilitaryOrGovernmentAffiliation)}
      />
      {hasMilitaryOrGovernmentAffiliation && militaryOrGovernmentAffiliations.map(affiliation => (
        <Grid item xs={12} key={affiliation.employedBy} sx={ReadOnlyStyles.Row}>
          <ReadOnlyField label={'Employed By'} value={affiliation.employedBy} />
          <ReadOnlyField label={'Title / Position'} value={affiliation.position} />
          <ReadOnlyField label={'Government Branch'} value={affiliation.governmentBranch} />
          <ReadOnlyField label={'Country'} value={affiliation.country} />
          <ReadOnlyField label={'Source of Wealth'} value={getOptionLabelByValue(affiliation?.sourceOfWealth, SOURCES_OF_WEALTH)} />
        </Grid>
      ))}
    </Grid>
  );
};

export default HoldingEntityInfoReadOnly;
