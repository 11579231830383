import { Bar, BarChart, CartesianGrid, Cell, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { DateFormat, NumberFormat } from '../../../../_helpers';
import { companyFundingHistoryGraphState, companyState } from '../../../../_state';
import { useCallback, useContext, useEffect } from 'react';

import BarTooltip from './tooltip';
import CurrentRaiseContext from '../context';
import EmptyStateDisplay from '../../../EmptyStateDisplay';
import { Typography } from '@mui/material';
import styles from './styles';
import { useGetFundingHistoryGraphData } from '../../../../_actions/company-funding-history.actions';
import { useRecoilValue } from 'recoil';
import { useTheme } from '@emotion/react';

const FundingHistoryChart = () => {
  const company = useRecoilValue(companyState);
  const { loaded } = useGetFundingHistoryGraphData(company.key);
  const fundingHistoryGraphData = useRecoilValue(companyFundingHistoryGraphState);
  const reformattedGraphData = fundingHistoryGraphData?.map((dataPoint) => {
    return {...dataPoint, endDate: DateFormat.short(dataPoint.endDate)};
  });

  const {selectedRaise, onChangeSelectedRaise} = useContext(CurrentRaiseContext);
  const handleBarClick = useCallback((eTarget) => {
    onChangeSelectedRaise(eTarget.payload.offerKey);
  },[onChangeSelectedRaise]);

  const theme = useTheme();
  const COLORS = {
    goal: theme.palette.primary.main,
    raisedGoal: theme.palette.gain.main,
  };

  const renderColorfulLegendText = useCallback(value => {
    return <Typography variant='caption' component='span'>{value}</Typography>;
  }, []);

  const formatMoneyTick = useCallback(value => {
    return NumberFormat.cents(value, {notation: 'compact'});
  }, []);

  useEffect(() => {
    // Select default
    if (loaded && !selectedRaise && fundingHistoryGraphData?.length) {
      onChangeSelectedRaise(fundingHistoryGraphData[0].offerKey);
    }
  }, [fundingHistoryGraphData, loaded, onChangeSelectedRaise, selectedRaise]);

  if (loaded && !fundingHistoryGraphData?.length) {
    return <EmptyStateDisplay componentName='FundingHistoryChart' message='No funding history to display.' />;
  }

  return (
    <ResponsiveContainer height={260}>
      <BarChart
        cursor='pointer'
        data={reformattedGraphData}
        margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
        barGap={0}
      >
        <CartesianGrid strokeDasharray='1' vertical={false} />
        <Bar
          type='monotone'
          name='Raise Goal'
          dataKey='goal'
          onClick={handleBarClick}
          fill={COLORS.goal}
        >
          {fundingHistoryGraphData?.map((entry) => (
            <Cell cursor='pointer' key={`cell-${entry.goal}`} opacity={entry.offerKey === selectedRaise ? 1 : 0.35} />
          ))}
        </Bar>
        <Bar
          type='monotone'
          name='Amount Raised'
          dataKey='raisedGoal'
          onClick={handleBarClick}
          fill={COLORS.raisedGoal}
        >
          {fundingHistoryGraphData?.map((entry) => (
            <Cell cursor='pointer' key={`cell-${entry.raisedGoal}`} opacity={entry.offerKey === selectedRaise ? 1 : 0.35} />
          ))}
        </Bar>
        <XAxis
          dataKey='endDate'
          fontSize='0.75rem'
          tickLine={false}
          padding={{ left: 16, right: 16 }}
        />
        <YAxis
          dataKey={'raisedGoal'}
          fontSize='0.75rem'
          tickLine={false}
          axisLine={false}
          tickFormatter={formatMoneyTick}
        />
        <Tooltip
          content={BarTooltip}
          wrapperStyle={styles.TooltipWrapper}
          color={theme.palette.primary.main}
          cursor={{ stroke: 'none', fill: 'none' }}
        />
        <Legend
          formatter={renderColorfulLegendText}
          iconType='circle'
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default FundingHistoryChart;
