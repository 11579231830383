import { Grid, Stack, Typography } from '@mui/material';
import { useCallback, useContext, useState } from 'react';

import AnnouncementsQueryContext from './context';
import SortIcon from '../../SortIcon';
import styles from './styles';

const AnnouncementsHeader = ({ colSizes }) => {
  const {queryParams, setQueryParams} = useContext(AnnouncementsQueryContext);
  const [sortBy, setSortBy] = useState('date');
  const [sortDirection, setSortDirection] = useState('desc');
  const handleSort = useCallback((sortByCol) => () => {
    setSortBy(sortByCol);
    const newSortDirection = sortDirection === 'desc' ? 'asc' : 'desc';
    setSortDirection(newSortDirection);
    setQueryParams({
      ...queryParams,
      orderby: `${sortByCol}:${newSortDirection}`
    });
  }, [queryParams, setQueryParams, sortDirection]);

  return (
    <Grid item container spacing={2} sx={styles.AnnouncementsHeader}>
      <Grid item {...colSizes[0]} onClick={handleSort('date')} sx={styles.AnnouncementRow}>
        <Stack direction='row' spacing={1}>
          <Typography variant='body2' fontWeight='fontWeightMedium' color='primary'>Date</Typography>
          {sortBy === 'date' && <SortIcon direction={sortDirection} />}
        </Stack>
      </Grid>
      <Grid item {...colSizes[1]} onClick={handleSort('title')} sx={styles.AnnouncementRow}>
        <Stack direction='row' spacing={1}>
          <Typography variant='body2' fontWeight='fontWeightMedium' color='primary'>Announcement</Typography>
          {sortBy === 'announcement' && <SortIcon direction={sortDirection} />}
        </Stack>
      </Grid>
      <Grid item {...colSizes[2]}>{/* icon column */}</Grid>
    </Grid>
  );
};

export default AnnouncementsHeader;
