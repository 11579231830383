import { Grid, Stack, Typography } from '@mui/material';
import { VERIFICATION_DOC_FILES, VERIFICATION_DOC_FILE_TYPES, VERIFICATION_DOC_OPTIONS } from '../../../../static-info';
import { fetchUserVerificationDocument, hasSubmitHoldingEntityKycState, hasSubmitIndividualKycState, userVerificationDocumentsState } from '../../../../_state';
import { useCallback, useContext, useEffect, useState } from 'react';

import AccountFormActions from '../../Form/actions';
import AccountFormContext from '../../Form/context';
import CircularLoading from '../../../../netcapital-components/CircularLoading';
import FormContext from '../../../../netcapital-components/FormValidation/FormContext';
import IdentityFilesSchema from './schema';
import IdentityVerificationReadOnly from './read-only';
import PropTypes from 'prop-types';
import RadioGroup from '../../../../netcapital-components/RadioGroup';
import { ReactComponent as SvgIdDoc1 } from '../../../../assets/id-doc-example-1.svg';
import { ReactComponent as SvgIdDoc2 } from '../../../../assets/id-doc-example-2.svg';
import { ReactComponent as SvgIdDoc3 } from '../../../../assets/id-doc-example-3.svg';
import { ReactComponent as SvgPassportDoc1 } from '../../../../assets/passport-doc-example-1.svg';
import { ReactComponent as SvgPassportDoc2 } from '../../../../assets/passport-doc-example-2.svg';
import { ReactComponent as SvgPassportDoc3 } from '../../../../assets/passport-doc-example-3.svg';
import { ReactComponent as SvgSelfie1 } from '../../../../assets/selfie-example-1.svg';
import { ReactComponent as SvgSelfie2 } from '../../../../assets/selfie-example-2.svg';
import { ReactComponent as SvgSelfie3 } from '../../../../assets/selfie-example-3.svg';
import VerificationDocField from '../../VerificationDocField';
import accountFormStyles from '../../Form/styles';
import { useEmptySave } from '../../../../_actions/empty.actions';
import { useFormValidation } from '../../../../netcapital-components/FormValidation';
import { useGetUserVerificationDocuments } from '../../../../_actions/user.actions';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

/* eslint-disable react/jsx-max-depth */

const IdentityFiles = ({ disabled }) => {
  const { loaded } = useGetUserVerificationDocuments();
  const hasSubmitIndividualKyc = useRecoilValue(hasSubmitIndividualKycState);
  const hasSubmitHoldingEntityKyc = useRecoilValue(hasSubmitHoldingEntityKycState);
  const docs = useRecoilValue(userVerificationDocumentsState);
  const [verificationDocType, setVerificationDocType] = useState();
  const isDocTypeFieldDisabled = Boolean(docs?.find(doc => doc.usage !== 'SELFIE'));
  const handleChangeVerificationDocType = useCallback((e) => setVerificationDocType(e.target.value), []);
  const navigate = useNavigate();
  const {validate, validateField, errors, removeError} = useFormValidation(userVerificationDocumentsState, IdentityFilesSchema);
  const {save, saving} = useEmptySave();
  const {
    nextFormPath,
    onSaveSuccess,
  } = useContext(AccountFormContext);

  const handleSaveSuccess = useCallback((newErrors) => {
    onSaveSuccess();
    if (!newErrors || Object.keys(newErrors).length === 0) {
      navigate(nextFormPath);
    }
  }, [navigate, nextFormPath, onSaveSuccess]);

  const handleSave = useCallback(async () => {
    const validationContext = {
      verificationDocType,
      verificationDocuments: docs?.reduce((map, doc) => {
        map[doc.usage] = doc.url;
        return map;
      }, {})
    };
    const newErrors = await validate(validationContext);
    save(handleSaveSuccess(newErrors));
  }, [docs, handleSaveSuccess, save, validate, verificationDocType]);

  useEffect(() => {
    // Automatically set verificationDocType based on a file of its usage being present.
    if (docs?.length && !verificationDocType) {
      let docType = '';

      docs.forEach((currentDoc) => {
        const foundType = Object.keys(VERIFICATION_DOC_FILES).reduce((result, currentDocType) => {
          return (VERIFICATION_DOC_FILES[currentDocType].find(config => config.usage === currentDoc.usage))
            ? currentDocType
            : result;
        }, undefined); // skipcq: JS-W1042 - ignore Deepsource `trailing undefined` issue
        if (foundType) {
          docType = foundType;
          return false;
        }
      });
      if (docType) {
        setVerificationDocType(docType);
      }
    }
  }, [docs, verificationDocType]);

  if (!loaded) return <CircularLoading />;

  if (disabled || hasSubmitIndividualKyc || hasSubmitHoldingEntityKyc) return <IdentityVerificationReadOnly />;

  return (
    <FormContext.Provider value={{validateField, errors, removeError}}>
      <Stack spacing={3} sx={accountFormStyles.FormContentWell}>
        <Grid item container rowSpacing={8}>
          <Grid item container columnSpacing={2} rowSpacing={4}>
            <Grid item xs={12}>
              <Typography>
                <Typography fontWeight='fontWeightBold' component='span'>STEP 1</Typography> - Upload a photo of your identification document *
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <RadioGroup
                options={VERIFICATION_DOC_OPTIONS}
                onChange={handleChangeVerificationDocType}
                value={verificationDocType}
                fieldName={'verificationDocType'}
                disabled={isDocTypeFieldDisabled}
              />
            </Grid>
            {verificationDocType === 'PASSPORT' && (
              <Grid item xs={12}>
                <Typography>Upload a photo of your passport. The photo should be:</Typography>
                <ul>
                  <li><b>bright and clear</b> (good quality)</li>
                  <li><b>uncut</b> (all corners of the document should be visible)</li>
                </ul>
              </Grid>
            )}
            {verificationDocType === 'LICENSE' && (
              <Grid item xs={12}>
                <Typography><Typography fontWeight='fontWeightBold' component='span'>Driver’s license should require photo of front and back.</Typography> The photo should be:</Typography>
                <ul>
                  <li><b>bright and clear</b> (good quality)</li>
                  <li><b>uncut</b> (all corners of the document should be visible)</li>
                </ul>
              </Grid>
            )}
            {verificationDocType === 'ID_CARD' && (
              <Grid item xs={12}>
                <Typography><Typography fontWeight='fontWeightBold' component='span'>ID Card should require photo of front and back.</Typography> The photo should be:</Typography>
                <ul>
                  <li><b>bright and clear</b> (good quality)</li>
                  <li><b>uncut</b> (all corners of the document should be visible)</li>
                </ul>
              </Grid>
            )}
            {verificationDocType === 'PASSPORT' && (
              <Grid item xs={12}>
                <Stack spacing={2} direction='row'>
                  <SvgPassportDoc1 />
                  <SvgPassportDoc2 />
                  <SvgPassportDoc3 />
                </Stack>
              </Grid>
            )}
            {(verificationDocType === 'LICENSE' || verificationDocType === 'ID_CARD') && (
              <Grid item xs={12}>
                <Stack spacing={2} direction='row'>
                  <SvgIdDoc1 />
                  <SvgIdDoc2 />
                  <SvgIdDoc3 />
                </Stack>
              </Grid>
            )}
            {verificationDocType && (
              <Grid item container spacing={4}>
                {VERIFICATION_DOC_FILES[verificationDocType]?.map(fileConfig => (
                  <Grid item xs={12} lg={VERIFICATION_DOC_FILES[verificationDocType]?.length > 1 ? 6 : 12} xl={10} key={fileConfig.usage}>
                    <VerificationDocField
                      recoilState={fetchUserVerificationDocument(fileConfig.usage)}
                      usage={fileConfig.usage}
                      key={fileConfig.usage}
                      label={fileConfig.label}
                      fieldName={`verificationDocuments.${fileConfig.usage}`}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
          <Grid item container columnSpacing={2} rowSpacing={4}>
            <Grid item xs={12}>
              <Typography>
                <Typography fontWeight='fontWeightBold' component='span'>STEP 2</Typography> - Upload a photo of yourself *
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>The image should be clear, well-lit, and have no other individuals in the frame.</Typography>
              <Typography>We recommend that you remove your glasses to avoid any delays in approval.</Typography>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={2} direction='row'>
                <SvgSelfie1 />
                <SvgSelfie2 />
                <SvgSelfie3 />
              </Stack>
            </Grid>
            <Grid item xs={12} xl={10}>
              <VerificationDocField
                recoilState={fetchUserVerificationDocument('SELFIE')}
                usage={'SELFIE'}
                allowedFileTypes={VERIFICATION_DOC_FILE_TYPES.fileTypes.filter(type => type !== 'PDF').join(',')}
                fileType={VERIFICATION_DOC_FILE_TYPES.contentTypes.filter(type => type !== 'application/pdf').join(',')}
                fieldName={'verificationDocuments.SELFIE'}
              />
            </Grid>
          </Grid>
        </Grid>

        <AccountFormActions
          onSubmit={handleSave}
          isSubmitting={saving}
        />
      </Stack>
    </FormContext.Provider>
  );
};

IdentityFiles.defaultProps = {
  disabled: false,
  beneficialOwnerKey: false,
};

IdentityFiles.propTypes = {
  disabled: PropTypes.bool,
  beneficialOwnerKey: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default IdentityFiles;
