import { companyFundingHistoryGraphState, companyFundingHistoryState } from '../_state';
import { prepareQueryString, useFetchWrapper } from '../_helpers';
import { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { APIs } from './_api-urls';

export function useGetFundingHistory(listingKey, queryParams) {
  const [fundingHistory, setFundingHistory] = useRecoilState(companyFundingHistoryState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    function abort(){ controller.abort(); }

    setLoaded(false);

    const url = `${APIs.Offerings}/funding-history/${listingKey}/raises-to-date?${prepareQueryString(queryParams)}`;
    fetchWrapper.get(url, null, signal)
      .then((response) => {
        if (!response) return; // aborted request gives response === undefined
        let data = response.data;

        if(fundingHistory?.data?.length > 0 && queryParams?.page > 1){
          data = [...fundingHistory.data, ...response.data];
        }

        setFundingHistory({ data, metadata: response.metadata });
        setLoaded(true);
      })
      .catch(() => {
        setLoaded(true);
      });

    return abort;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingKey, queryParams.page]);

  return { loaded };
}

export function useGetFundingHistoryGraphData(listingKey){
  const setGraphData = useSetRecoilState(companyFundingHistoryGraphState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    function abort(){ controller.abort(); }

    setLoaded(false);

    const url = `${APIs.Offerings}/funding-history/${listingKey}/rounds-graph`;
    fetchWrapper.get(url, null, signal)
      .then((response) => {
        if (!response) return; // aborted request gives response === undefined
        setGraphData(response);
        setLoaded(true);
      })
      .catch(() => {
        setLoaded(true);
      });

    return abort;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingKey]);

  return { loaded };
}
