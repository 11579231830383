import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { IconButton } from '@mui/material';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
  const navigate = useNavigate();
  const handleGoBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <IconButton onClick={handleGoBack} color='primary' disableRipple edge='start'>
      <ArrowBackOutlinedIcon />
    </IconButton>
  );
};

export default BackButton;
