import { Grid, Tab, Tabs } from '@mui/material';
import { useRecoilState, useSetRecoilState } from 'recoil';

import Offer from '../Offer';
import { fetchOfferAttribute } from '../../_state';
import styles from './styles';
import { useCallback } from 'react';

const OfferModalContent = ({ onClose }) => {
  const [offerType, setOfferType] = useRecoilState(fetchOfferAttribute('offerType'));
  const setQuantity = useSetRecoilState(fetchOfferAttribute('quantity'));
  const setPricePerUnit = useSetRecoilState(fetchOfferAttribute('pricePerUnit'));

  const handleClickToggleOfferType = useCallback(() => {
    setOfferType(offerType === 'BID' ? 'ASK' : 'BID');
    setQuantity(0);
    setPricePerUnit(0);
  }, [offerType, setOfferType, setQuantity, setPricePerUnit]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Tabs
          value={offerType}
          onChange={handleClickToggleOfferType}
          centered
          TabIndicatorProps={{
            sx: { backgroundColor: offerType === 'BID' ? 'gain.main' : 'loss.main' },
          }}
        >
          <Tab value='BID' label='Buy' sx={[styles.Tab, styles.TabBid]} disableRipple />
          <Tab value='ASK' label='Sell' sx={[styles.Tab, styles.TabAsk]} disableRipple />
        </Tabs>
      </Grid>
      <Grid item xs={12}>
        <Offer onClose={onClose} />
      </Grid>
    </Grid>
  );
};

export default OfferModalContent;
