import { useGetFundingHistory, useGetFundingHistoryGraphData } from '../../../../_actions/company-funding-history.actions';

import { DEFAULT_QUERY_PARAMS } from '../../../../_actions/_api-urls';
import FundingHistoryChart from './chart';
import FundingHistoryStats from './stats';
import Section from '../../../Section';
import SelectedRoundContext from './context';
import { Stack } from '@mui/material';
import { companyState } from '../../../../_state';
import { useRecoilValue } from 'recoil';
import { useState } from 'react';

const FundingHistoryOverview = () => {
  const company = useRecoilValue(companyState);
  const { loaded: loadedFundingHistoryGraphData } = useGetFundingHistoryGraphData(company.key);
  const { loaded: loadedFundingHistory } = useGetFundingHistory(company.key, DEFAULT_QUERY_PARAMS);
  const [currentSelectedRaiseIndex, setCurrentSelectedRaiseIndex] = useState(0);

  return (
    <SelectedRoundContext.Provider value={{currentSelectedRaiseIndex, setCurrentSelectedRaiseIndex}}>
      <Section
        componentName={'FundingHistoryOverviewChart'}
        content={
          <Stack spacing={4}>
            <FundingHistoryStats currentSelectedRaiseIndex={currentSelectedRaiseIndex} />
            <FundingHistoryChart currentSelectedRaiseIndex={currentSelectedRaiseIndex} />
          </Stack>
        }
        title='Funding History'
        loaded={loadedFundingHistoryGraphData && loadedFundingHistory}
      />
    </SelectedRoundContext.Provider>
  );
};

export default FundingHistoryOverview;
