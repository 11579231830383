import { APIListingURL, DEFAULT_QUERY_PARAMS } from './_api-urls';
import { commentState, commentsState, fetchComments } from '../_state';
import { prepareQueryString, useFetchWrapper } from '../_helpers';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import produce from 'immer';

export function useGetComments(listingKey, queryParams=DEFAULT_QUERY_PARAMS) {
  const setComments = useSetRecoilState(commentsState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const abort = () => controller.abort();

    setLoaded(false);

    const url = `${APIListingURL(listingKey)}/comments?${prepareQueryString(queryParams)}`;
    fetchWrapper.get(url, null, signal)
      .then((response) => {
        if (!response) return; // aborted request gives response === undefined
        setComments({
          data: response.data,
          metadata: response.metadata
        });
        setLoaded(true);
      })
      .catch(() => {
        setLoaded(true);
      });

    return abort;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingKey, queryParams]);

  return { loaded };
}

export function useGetComment(listingKey, commentKey) {
  const [comment, setComment] = useRecoilState(commentState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    function abort() {
      controller.abort();
    }

    if (!commentKey || Object.keys(comment).length > 0) {
      setLoaded(true);
      return abort;
    }

    setLoaded(false);

    const url = `${APIListingURL(listingKey)}/comments/${commentKey}`;
    fetchWrapper.get(url, null, signal)
      .then((response) => {
        if (!response) return; // aborted request gives response === undefined
        setComment(response);
      })
      .finally(() => setLoaded(true));

    return abort;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingKey, commentKey]);

  return { loaded };
}

export function useCreateComment(listingKey) {
  const [comments, setComments] = useRecoilState(fetchComments);
  const fetchWrapper = useFetchWrapper();
  const [saving, setSaving] = useState(false);

  const save = useCallback((body, parentCommentKey, onSuccess) => {
    const controller = new AbortController();
    const signal = controller.signal;
    function abort() {
      controller.abort();
    }

    setSaving(true);

    const url = `${APIListingURL(listingKey)}/comments`;
    const payload = {body, parentCommentKey};
    fetchWrapper.post(url, payload, signal, false)
      .then((response) => {
        if (!response) return; // aborted request gives response === undefined
        const newComment = response;
        if (parentCommentKey) {
          const parentCommentIndex = comments.findIndex(comment => comment.key === parentCommentKey);
          const updatedParent = produce(comments[parentCommentIndex], (draft) => {
            draft.replies.push(newComment);
          });
          const updatedComments = produce(comments, (draft) => {
            draft[parentCommentIndex] = updatedParent;
          });
          setComments(updatedComments);
        }
        setSaving(false);
        onSuccess();
      })
      .catch(() => {
        setSaving(false);
      });
    return abort;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingKey, comments]);

  return { saving, save };
}

export function useDeleteComment(listingKey) {
  const [comments, setComments] = useRecoilState(fetchComments);
  const fetchWrapper = useFetchWrapper();
  const [deleting, setDeleting] = useState(false);

  const destroy = useCallback((commentKey, parentCommentKey) => {
    const controller = new AbortController();
    const signal = controller.signal;
    function abort() {
      controller.abort();
    }

    setDeleting(true);

    const url = `${APIListingURL(listingKey)}/comments/${commentKey}`;
    fetchWrapper.delete(url, null, signal, false)
      .then(() => {
        if (parentCommentKey) {
          // Deleted a reply
          const parentCommentIndex = comments.findIndex(comment => comment.key === parentCommentKey);
          const childCommentIndex = comments[parentCommentIndex].replies.findIndex(comment => comment.key === commentKey);
          const updatedParent = produce(comments[parentCommentIndex], (draft) => {
            draft.replies.splice(childCommentIndex, 1);
          });
          const updatedComments = produce(comments, (draft) => {
            draft[parentCommentIndex] = updatedParent;
          });
          setComments(updatedComments);
          return;
        }

        const commentIndex = comments.findIndex(comment => comment.key === commentKey);
        if (comments[commentIndex].replies.length) {
          // Deleted a comment that has replies
          const updatedComments = produce(comments, (draft) => {
            draft[commentIndex] = { ...draft[commentIndex], body: 'Comment Deleted'};
          });
          setComments(updatedComments);
          return;
        }

        // Deleted a comment with no replies
        const updatedComments = produce(comments, (draft) => {
          draft.splice(commentIndex, 1);
        });
        setComments(updatedComments);
      })
      .then(() => {
        setDeleting(false);
      });
    return abort;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingKey, comments]);

  return { deleting, destroy };
}
