import { useEffect, useState } from 'react';

import { APIListingURL } from './_api-urls';
import { companyTeamState } from '../_state';
import { useFetchWrapper } from '../_helpers';
import { useSetRecoilState } from 'recoil';

export function useGetCompanyTeam(listingKey) {
  const setTeam = useSetRecoilState(companyTeamState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    function abort(){ controller.abort();}

    const url = `${APIListingURL(listingKey)}/key-people`;
    fetchWrapper.get(url, null, signal)
      .then((response) => {
        if (!response) return; // aborted request gives response === undefined

        setTeam(response);
      }).finally(() => setLoaded(true));
    return abort;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingKey]);

  return { loaded };
}
