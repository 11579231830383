import InputStepper from '../InputStepper';
import { NumberFormatCents } from '../NumberFormat';
import PropTypes from 'prop-types';
import UnformattedInput from '../InputStepper/input';

const MoneyInputStepper = (props) => {
  const { onChange, value, validateValue } = props;

  return (
    <InputStepper
      value={value}
      onChange={onChange}
      input={
        <NumberFormatCents
          isAllowed={validateValue}
          customInput={UnformattedInput}
          value={value}
          onChange={onChange}
        />
      }
    />
  );
};

MoneyInputStepper.defaultProps = {
  value: 0,
  validateValue: () => { return true; },
};

MoneyInputStepper.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  validateValue: PropTypes.func,
};

export default MoneyInputStepper;
