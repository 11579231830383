import { List, Typography } from '@mui/material';
import { fetchSecurities, investorState } from '../../_state';
import { useCallback, useEffect, useState } from 'react';

import { DEFAULT_QUERY_PARAMS } from '../../_actions/_api-urls';
import EmptyStateDisplay from '../EmptyStateDisplay';
import InfiniteScroll from '../../netcapital-components/InfiniteScroll';
import Section from '../Section';
import SecuritiesFilters from './filters';
import Security from './Security';
import { useGetSecurities } from '../../_actions/securities.actions';
import { useRecoilValue } from 'recoil';

const securitiesExpansionStorage = localStorage.getItem('securitiesExpansionStorage') === 'true' || true;

const SecuritiesOverview = () => {
  const [expanded, setExpanded] = useState(securitiesExpansionStorage);
  const handleCardExpansion = useCallback((value) => {
    setExpanded(value);
    localStorage.setItem('securitiesExpansionStorage', value);
  }, [setExpanded]);

  const investor = useRecoilValue(investorState);
  const [activeFilter, setActiveFilter] = useState('active');
  const [queryParams, setQueryParams] = useState({
    ...DEFAULT_QUERY_PARAMS,
    status: activeFilter
  });
  const { loaded: loadedSecurities } = useGetSecurities(investor.entityKey, queryParams);
  const securities = useRecoilValue(fetchSecurities('data'));
  const securitiesMeta = useRecoilValue(fetchSecurities('metadata'));
  const hasMoreSecurities = securitiesMeta?.limit * securitiesMeta?.page < securitiesMeta?.totalCount;
  const emptyStateMessageMap = {
    'acquired': 'You do not own any securities of a company that has experienced a merger, acquisition, public listing, or similar exit.',
    'active': 'You do not own any securities that are eligible for secondary market trading.',
    'closed': 'You do not own any securities of a company that has discontinued operations.',
    'disabled': 'You do not own any securities that are ineligible for secondary market trading.',
  };

  const fetchMoreSecurities = useCallback(() => {
    setQueryParams({...queryParams, page: queryParams.page + 1});
  },[queryParams]);

  const handleSelectFilter = useCallback((filterIdentifier) => {
    setActiveFilter(filterIdentifier);
  }, []);

  //back to page 1 if we change currentInvestor
  useEffect(() => {
    setQueryParams({
      ...DEFAULT_QUERY_PARAMS,
      page: 1
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[investor.entityKey]);

  //update queryParams if activeFilter changes
  useEffect(() => {
    setQueryParams({
      ...DEFAULT_QUERY_PARAMS,
      status: activeFilter
    });
  },[activeFilter]);

  return (
    <Section
      content={securities?.length ?
        <>
          <SecuritiesFilters activeFilter={activeFilter} setActiveFilter={handleSelectFilter} />
          <InfiniteScroll
            currentItemsCount={securities.length}
            currentPage={queryParams.page}
            endMessage={<Typography variant='caption' textAlign='center' component='div'>End of results</Typography>}
            fetchData={fetchMoreSecurities}
            hasMore={hasMoreSecurities}
            scrollHeight={400}
          >
            <List disablePadding>
              {securities.map(security => (
                <Security
                  key={security._key}
                  security={security}
                />
              ))}
            </List>
          </InfiniteScroll>
        </>
        :
        <>
          <SecuritiesFilters activeFilter={activeFilter} setActiveFilter={setActiveFilter} />
          {(loadedSecurities && !securities?.length) && <EmptyStateDisplay componentName={'Securities'} message={emptyStateMessageMap[activeFilter]} />}
        </>
      }
      expanded={expanded}
      onAction={handleCardExpansion}
      loaded={loadedSecurities}
      title='Positions'
    />
  );
};

export default SecuritiesOverview;
