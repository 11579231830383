import { activeNotificationState, notificationsState, userState } from '../../_state';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { NOTIFICATIONS } from '../../static-info';
import Notification from '../../netcapital-components/Notification';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import { useCallback } from 'react';
import { useGetNotifications } from '../../_actions/notifications.actions';
import { useNavigate } from 'react-router-dom';

const NotificationsList = ({ toggleDrawer }) => {
  const user = useRecoilValue(userState);
  const navigate = useNavigate();
  const { loaded } = useGetNotifications(user?.entityKey);
  const notifications = useRecoilValue(notificationsState);

  const setActiveNotification = useSetRecoilState(activeNotificationState);
  const handleOpenResponse = useCallback((notification) => (e) => {
    if (notification?.type === 'KYC_CALL_TO_ACTION') {
      const redirectUrl = (notification.entityKey.toString() === user.entityKey.toString())
        ? '/account/basic-info'
        : `/account/basic-info?entity=${notification.entityKey}`;
      navigate(redirectUrl);
    } else {
      setActiveNotification(notification);
    }

    toggleDrawer(e);
  }, [navigate, setActiveNotification, toggleDrawer, user]);

  if (!loaded) return null;

  return (
    <Stack spacing={1}>
      {notifications?.map(notification => {
        return (
          <Notification
            key={notification.key}
            severity={NOTIFICATIONS[notification.type].severity}
            description={NOTIFICATIONS[notification.type]?.message || notification.customMessage || ''}
            title={NOTIFICATIONS[notification.type]?.title}
            onAction={(e) => handleOpenResponse(notification)(e)}
            ctaText={NOTIFICATIONS[notification.type]?.cta}
          />
        );
      })}
    </Stack>
  );
};

NotificationsList.defaultProps = {
  toggleDrawer: () => { return; },
};

NotificationsList.propTypes = {
  toggleDrawer: PropTypes.func,
};

export default NotificationsList;
