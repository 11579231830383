import { Grid, Link, Typography } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import Field from '../../../FormFields/Field';
import { HOLDING_ENTITY_DOCS_TEMPLUM } from '../../../../static-info';
import HoldingEntityDocument from '../../../FormFields/HoldingEntityDocument';
import HoldingEntityDocumentsReadOnly from './read-only';
import PropTypes from 'prop-types';
import { fetchHoldingEntityInfoAttribute } from '../../../../_state';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

const HoldingEntityDocumentsFields = ({ holdingEntityKey, disabled }) => {
  const entityType = useRecoilValue(fetchHoldingEntityInfoAttribute('type'));
  const isCorporation = Boolean(entityType === 'S_CORP' || entityType === 'C_CORP');
  const isLlc = Boolean(entityType === 'LLC');
  const isLp = Boolean(entityType === 'LP');
  const isTrust = Boolean(entityType === 'TRUST');
  const isOther = Boolean(entityType === 'OTHER');

  const renderFileField = useCallback((usage) => {
    const docOption = HOLDING_ENTITY_DOCS_TEMPLUM.find(option => option.value === usage);
    return (
      <Grid item xs={12} name={`VerificationDoc_${usage}`}>
        <Field label={docOption.label}>
          <HoldingEntityDocument
            usage={usage}
            holdingEntityKey={holdingEntityKey}
            fieldName={usage}
          />
        </Field>
      </Grid>
    );
  }, [holdingEntityKey]);

  const {search} = useLocation();

  if (disabled) return <HoldingEntityDocumentsReadOnly />;

  if (!entityType) return <Grid item xs={12}><Typography color='grey'>Please first specify your holding company‘s company type in the <Link to={`../basic-info${search}`} component={RouterLink}>Holding Information form.</Link></Typography></Grid>;

  return (
    <Grid item container rowSpacing={4} columnSpacing={2}>
      {isCorporation && renderFileField('CERTIFICATE_OF_INCORPORATION')}
      {(isLlc || isLp || isTrust || isOther) && renderFileField('CERTIFICATE_OF_FORMATION')}
      {!isTrust && renderFileField('OPERATING_AGREEMENT')}
      {isTrust && renderFileField('TRUST_DOCUMENT')}
      {renderFileField('EIN_EVIDENCE')}
    </Grid>
  );
};

HoldingEntityDocumentsFields.defaultProps = {
  disabled: false,
};

HoldingEntityDocumentsFields.propTypes = {
  holdingEntityKey: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default HoldingEntityDocumentsFields;
