import { APIs, AuthAPILoginURL, AuthAPIVerifyURL } from './_api-urls';
import { investorState, sessionState, userState } from '../_state';
import { useEffect, useState } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';

import axios from 'axios';
import { fetchRequest } from '../_helpers';

function fetchVerifyToken(signal) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
    signal
  };

  return axios(AuthAPIVerifyURL, requestOptions).catch(e => {
    if (e?.code !== 'ERR_CANCELED') {
      // do something?
    }
  });
}

export function useGetSession() {
  const [loaded, setLoaded] = useState(false);
  const [session, setSession] = useRecoilState(sessionState);
  const resetSessionState = useResetRecoilState(sessionState);
  const [user, setUser] = useRecoilState(userState);
  const resetUserState = useResetRecoilState(userState);
  const [investor, setInvestor] = useRecoilState(investorState);
  const resetInvestorState = useResetRecoilState(investorState);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    function abort(){ controller.abort(); }

    const haveSession = Object.keys(session ?? {}).length > 0;
    const haveUser = Object.keys(user ?? {}).length > 0;
    const haveInvestor = Object.keys(investor ?? {}).length > 0;

    if (haveSession) {
      setLoaded(true);
      return abort;
    }

    fetchVerifyToken(signal)
      .then((response) => {
        if (!response) return;
        if (!response.data.authenticated){
          resetSessionState();
          resetInvestorState();
          resetUserState();
          window.location.href = AuthAPILoginURL(window.location.href);
          return;
        }

        setSession({
          accessToken: response.data.user.accessToken,
          accessTokenExpiresAt: response.data.user.accessTokenExpiresAt,
          isAuthenticated: response.data.authenticated,
          mimicMode: response.data.mimicMode
        });

        // User may have logged out in a different app
        // and logged into a different account.
        const isDifferentUser = user?.entityKey !== response.data.user.entityKey;

        // Set defaults for user & investor only if we need to
        if (!haveUser || isDifferentUser) {
          setUser({
            entityKey: response.data.user.entityKey,
            firstName: response.data.user.firstName,
            lastName: response.data.user.lastName,
            role: response.data.user.role,
            hasAccessToMarketplace: response.data.user.hasAccessToMarketplace,
          });
        }

        if (!haveInvestor || isDifferentUser) {
          setInvestor({
            entityKey: response.data.user.entityKey,
            name: `${response.data.user.firstName} ${response.data.user.lastName}`,
          });
        }

        setLoaded(true);
      });

    return abort;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loaded };
}

export async function exitMimicMode(){
  const response = await fetchRequest(
    'delete',
    `${APIs.Auth}/mimic`,
    null,
    null,
    true
  ).catch((error) => error);

  return response;
}
