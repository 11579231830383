import { DateFormat } from '../../_helpers';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

const AnnouncementTitle = ({ announcement }) => {
  if (!announcement.title) {
    return (
      <Typography variant='h4'>{DateFormat.abbreviated(announcement.date)}</Typography>
    );
  }

  return (
    <>
      <Typography variant='h4'>{announcement.title}</Typography>
      <Typography variant='caption1'>{DateFormat.abbreviated(announcement.date)}</Typography>
    </>
  );
};

AnnouncementTitle.propTypes = {
  announcement: PropTypes.shape({
    key: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    title: PropTypes.string,
    body: PropTypes.string.isRequired,
    linkCta: PropTypes.string,
    linkUrl: PropTypes.string,
    file: PropTypes.shape({
      name: PropTypes.string,
      fileKey: PropTypes.string
    }),
  }).isRequired,
};

export default AnnouncementTitle;
