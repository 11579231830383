import { activeCompanyCollectionState, marketSearchState } from '../../_state';
import { useCallback, useState } from 'react';

import Categories from './Categories';
import { DEFAULT_QUERY_PARAMS } from '../../_actions/_api-urls';
import Listings from './Listings';
import MarketplaceSearch from './search';
import Section from '../Section';
import { Stack } from '@mui/material';
import { useGetCompanyCollections } from '../../_actions/company-collections.actions';
import { useGetListings } from '../../_actions/market-search.actions';
import { useIsMobile } from '../../_helpers';
import { useRecoilValue } from 'recoil';

const Market = () => {
  const isMobile = useIsMobile();
  const [queryParams, setQueryParams] = useState({ ...DEFAULT_QUERY_PARAMS, limit: 15 });

  const market = useRecoilValue(marketSearchState);
  const activeCollection = useRecoilValue(activeCompanyCollectionState);

  const { loaded: loadedCategories } = useGetCompanyCollections();
  const { loaded: loadedListings } = useGetListings(activeCollection, queryParams);

  const nextPage = useCallback(() => {
    setQueryParams({
      ...queryParams,
      page: market?.metadata?.page + 1
    });
  }, [queryParams, market.metadata]);

  const onSearch = useCallback((search) =>  {
    setQueryParams({ ...queryParams, page: 1, search });
  }, [queryParams]);

  const resetQueryParams = useCallback(() => setQueryParams(DEFAULT_QUERY_PARAMS), []);

  return (
    <Section
      loaded={loadedCategories && loadedListings}
      title='Secondary Market'
      action={!isMobile && <MarketplaceSearch onSearch={onSearch} search={queryParams.search} />}
      content={
        <Stack spacing={2} >
          {isMobile && <MarketplaceSearch onSearch={onSearch} search={queryParams.search} />}
          <Categories resetQueryParams={resetQueryParams} />
          <Listings loadedListings={loadedListings} nextPage={nextPage} />
        </Stack>
      }
    />
  );
};

export default Market;
