import { formatConfirmModalDescription, formatSuccessModalDescription } from '../utils';
import { useCallback, useState } from 'react';

import ConfirmationDialog from '../../../netcapital-components/ConfirmationDialog';
import Modal from '../../../netcapital-components/Modal';
import { useCancelOffer } from '../../../_actions/offer.actions';

const CancelTransactionModal = ({ transaction, onCancelTransaction, confirmModalOpen, toggleConfirmationModal }) => {
  const confirmModalDescription = formatConfirmModalDescription(transaction);
  const successModalDescription = formatSuccessModalDescription(transaction);

  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const toggleSuccessModal = useCallback(() => setSuccessModalOpen(!successModalOpen), [successModalOpen, setSuccessModalOpen]);
  const { cancelOffer, saving } = useCancelOffer(transaction);

  const handleCancelationSuccess = useCallback(() => {
    onCancelTransaction(transaction);
    toggleConfirmationModal();
    toggleSuccessModal();
  }, [onCancelTransaction, transaction, toggleConfirmationModal, toggleSuccessModal]);

  const handleCancelTransaction = useCallback(() => {
    if (!saving) {
      cancelOffer(handleCancelationSuccess);
    }
  }, [cancelOffer, handleCancelationSuccess, saving]);

  return (
    <>
      <ConfirmationDialog
        open={confirmModalOpen}
        onClose={toggleConfirmationModal}
        title={'Are you sure you want to cancel this order?'}
        content={confirmModalDescription}
        ctaDisabled={saving}
        saving={saving}
        ctaConfirm={'Yes, Withdraw'}
        onConfirm={handleCancelTransaction}
        ctaCancel={'Don’t withdraw'}
        onCancel={toggleConfirmationModal}
      />
      <Modal
        open={successModalOpen}
        onClose={toggleSuccessModal}
        color='primary'
        descriptionColor='common'
        title='Investment commitment withdrawn'
        description={successModalDescription}
        primaryBtnTitle='Close'
        primaryBtnColor='primary'
        handlePrimaryAction={toggleSuccessModal}
      />
    </>
  );
};

export default CancelTransactionModal;
