import { Grid, Stack, Typography } from '@mui/material';
import { VERIFICATION_DOC_FILES, VERIFICATION_DOC_OPTIONS } from '../../../../../static-info';
import { useCallback, useEffect } from 'react';

import BeneficialOwnerVerificationDocField from './verification-doc';
import PropTypes from 'prop-types';
import RadioGroup from '../../../../../netcapital-components/RadioGroup';
import { ReactComponent as SvgIdDoc1 } from '../../../../../assets/id-doc-example-1.svg';
import { ReactComponent as SvgIdDoc2 } from '../../../../../assets/id-doc-example-2.svg';
import { ReactComponent as SvgIdDoc3 } from '../../../../../assets/id-doc-example-3.svg';
import { ReactComponent as SvgPassportDoc1 } from '../../../../../assets/passport-doc-example-1.svg';
import { ReactComponent as SvgPassportDoc2 } from '../../../../../assets/passport-doc-example-2.svg';
import { ReactComponent as SvgPassportDoc3 } from '../../../../../assets/passport-doc-example-3.svg';
import { fetchHoldingEntityBeneficialOwnerVerificationDocType } from '../../../../../_state';
import { useRecoilState } from 'recoil';

const BeneficialOwnerVerificationDocs = ({ person, beneficialOwnerIndex, holdingEntityKey, fieldNamePrefix }) => {
  const [verificationDocType, setVerificationDocType] = useRecoilState(fetchHoldingEntityBeneficialOwnerVerificationDocType(beneficialOwnerIndex));
  const handleChangeVerificationDocType = useCallback((e) => setVerificationDocType(e.target.value), [setVerificationDocType]);

  const getFileByUsage = useCallback((usage) => person?.verificationDocuments?.find(doc => doc.usage === usage), [person?.verificationDocuments]);
  const getFileIndexByUsage = useCallback((usage) => person?.verificationDocuments?.findIndex(doc => doc.usage === usage), [person?.verificationDocuments]);

  useEffect(() => {
    // Automatically set verificationDocType based on a file of its usage being present.
    if (person?.verificationDocuments?.length && !verificationDocType) {
      let docType = '';

      person.verificationDocuments.forEach((currentDoc) => {
        const foundType = Object.keys(VERIFICATION_DOC_FILES).reduce((result, currentDocType) => {
          return (VERIFICATION_DOC_FILES[currentDocType].find(config => config.usage === currentDoc.usage))
            ? currentDocType
            : result;
        }, undefined); // skipcq: JS-W1042 - ignore Deepsource `trailing undefined` issue
        if (foundType) {
          docType = foundType;
        }
      });
      if (docType) {
        setVerificationDocType(docType);
      }
    }
  }, [person?.verificationDocuments, setVerificationDocType, verificationDocType]);

  return (
    <Grid item container rowSpacing={8}>
      <Grid item container columnSpacing={2} rowSpacing={4}>
        <Grid item xs={12}>
          <Typography>Upload a photo of their identification document *</Typography>
        </Grid>
        <Grid item xs={12}>
          <RadioGroup
            options={VERIFICATION_DOC_OPTIONS}
            onChange={handleChangeVerificationDocType}
            value={verificationDocType}
            fieldName={`${fieldNamePrefix}.verificationDocType`}
          />
        </Grid>
        {verificationDocType === 'PASSPORT' && (
          <Grid item xs={12}>
            <Typography>Upload a photo of their passport. The photo should be:</Typography>
            <ul>
              <li><b>bright and clear</b> (good quality)</li>
              <li><b>uncut</b> (all corners of the document should be visible)</li>
            </ul>
          </Grid>
        )}
        {verificationDocType === 'LICENSE' && (
          <Grid item xs={12}>
            <Typography><Typography fontWeight='fontWeightBold' component='span'>Driver’s license should require photo of front and back.</Typography> The photo should be:</Typography>
            <ul>
              <li><b>bright and clear</b> (good quality)</li>
              <li><b>uncut</b> (all corners of the document should be visible)</li>
            </ul>
          </Grid>
        )}
        {verificationDocType === 'ID_CARD' && (
          <Grid item xs={12}>
            <Typography><Typography fontWeight='fontWeightBold' component='span'>ID Card should require photo of front and back.</Typography> The photo should be:</Typography>
            <ul>
              <li><b>bright and clear</b> (good quality)</li>
              <li><b>uncut</b> (all corners of the document should be visible)</li>
            </ul>
          </Grid>
        )}
        {verificationDocType === 'PASSPORT' && (
          <Grid item xs={12}>
            <Stack spacing={2} direction='row'>
              <SvgPassportDoc1 />
              <SvgPassportDoc2 />
              <SvgPassportDoc3 />
            </Stack>
          </Grid>
        )}
        {(verificationDocType === 'LICENSE' || verificationDocType === 'ID_CARD') && (
          <Grid item xs={12}>
            <Stack spacing={2} direction='row'>
              <SvgIdDoc1 />
              <SvgIdDoc2 />
              <SvgIdDoc3 />
            </Stack>
          </Grid>
        )}
        {verificationDocType && (
          <Grid item container spacing={4}>
            {VERIFICATION_DOC_FILES[verificationDocType]?.map((fileConfig, index) => {
              let docIndex = getFileIndexByUsage(fileConfig.usage);
              if (docIndex === -1) {
                docIndex = index;
              }
              return (
                <Grid item xs={12} lg={VERIFICATION_DOC_FILES[verificationDocType]?.length > 1 ? 6 : 12} xl={10} key={fileConfig.usage}>
                  <BeneficialOwnerVerificationDocField
                    fileObj={getFileByUsage(fileConfig.usage) || {}}
                    usage={fileConfig.usage}
                    key={fileConfig.usage}
                    label={fileConfig.label}
                    beneficialOwnerKey={person.key}
                    beneficialOwnerIndex={beneficialOwnerIndex}
                    holdingEntityKey={holdingEntityKey}
                    fieldName={`${fieldNamePrefix}.verificationDocuments[${docIndex}]`}
                  />
                </Grid>
              );}
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

BeneficialOwnerVerificationDocs.defaultProps = {
  disabled: false,
  handleRefreshData: () => { return; },
  fieldNamePrefix: '',
};

BeneficialOwnerVerificationDocs.propTypes = {
  disabled: PropTypes.bool,
  handleRefreshData: PropTypes.func,
  holdingEntityKey: PropTypes.string.isRequired,
  fieldNamePrefix: PropTypes.string,
  beneficialOwnerIndex: PropTypes.number.isRequired,
};

export default BeneficialOwnerVerificationDocs;
