import {
  FormControl,
  FormLabel,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Typography,
} from '@mui/material';

import HoldingEntities from './HoldingEntities';
import Settings from './settings';
import styles from './styles';
import { useGetUserListingEntities } from '../../../_actions/listing-entities.actions';
import { useRecoilValue } from 'recoil';
import { userListingsState } from '../../../_state';

const UserSubMenu = (props) => {
  const {
    user,
    investor,
    anchorEl,
    handleClose,
    open,
  } = props;
  const { loaded: loadedListingEntities } = useGetUserListingEntities(user.entityKey);
  const listingEntities = useRecoilValue(userListingsState);

  return (
    <Menu
      anchorEl={anchorEl}
      id='account-menu'
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      sx={styles.UserSubMenu}
    >
      <MenuList>
        <FormControl>
          <FormLabel id='current-investor-radio-group'>
            <Typography
              variant='overline'
              color='primary.dark'
              display='block'
              sx={styles.UserSubMenuCategory}
            >
              Account
            </Typography>
          </FormLabel>
          <HoldingEntities user={user} investor={investor} />
        </FormControl>
      </MenuList>
      {(loadedListingEntities && listingEntities?.length > 0) && (
        <MenuList>
          <Typography
            variant='overline'
            display='block'
            sx={styles.UserSubMenuCategory}
          >
            Company Dashboard
          </Typography>
          <Stack direction='column' spacing={1}>
            {listingEntities.map((listingEntity) => {
              if(listingEntity.offers && listingEntity.offers.length){
                const mostRecentOffering = listingEntity.offers[0]; //offers sorted DESC by API
                return (
                  <MenuItem
                    key={listingEntity.key}
                    component='a'
                    href={`${process.env.REACT_APP_WEB_HOST}/dashboard/offerings/${mostRecentOffering.key}`}
                    target='_blank'
                    rel='noopener'
                  >
                    {listingEntity.doingBusinessAs ? listingEntity.doingBusinessAs : listingEntity.legalName}
                  </MenuItem>
                );
              }
              return null;
            })}
          </Stack>
        </MenuList>
      )}
      <MenuList>
        <FormControl sx={styles.UserOptions}>
          <FormLabel id='options'>
            <Typography
              variant='overline'
              color='primary.dark'
              display='block'
              sx={styles.UserSubMenuCategory}
            >
              Settings
            </Typography>
            <Settings />
          </FormLabel>
        </FormControl>
      </MenuList>
    </Menu>
  );
};

export default UserSubMenu;
