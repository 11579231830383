const styles = {
  DisableDivider: {
    '+ .MuiDivider-root': {
      display: 'none',
    },
  },
  CommunityStats: {
    marginLeft: {
      md: 5,
    },
  },
  CheckCircleIcon: {
    marginRight: '0.3rem'
  },
  FollowButton: {
    borderRadius: 8,
    width: {
      xs: '100%',
      md: 'auto',
    },
  },
  PriceTitle: ({ palette }) => ({
    color: palette.grey.main,
  }),
};

export default styles;
