import { Box, Stack, Typography } from '@mui/material';
import { companyState, fetchTransactions, investorState } from '../../../_state';
import { useCallback, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import CircularLoading from '../../../netcapital-components/CircularLoading';
import { DEFAULT_QUERY_PARAMS } from '../../../_actions/_api-urls';
import InvestorTransactions from './Transactions';
import InvestorTransactionsQueryContext from './context';
import Pagination from '../../../netcapital-components/Pagination';
import { STICKY_HEADER_HEIGHT } from '../../../layouts/layout-styles';
import produce from 'immer';
import styles from './styles';
import { useGetTransactions } from '../../../_actions/transactions.actions';

const InvestorTransactionsTab = () => {
  const investor = useRecoilValue(investorState);
  const company = useRecoilValue(companyState);
  const [queryParams, setQueryParams] = useState({
    ...DEFAULT_QUERY_PARAMS,
    holdingEntityKey: investor.entityKey,
    listingKey: company.key
  });
  const appendOnFetch = false;
  const { loaded } = useGetTransactions(queryParams, appendOnFetch);
  const [transactions, setTransactions] = useRecoilState(fetchTransactions('data'));
  const transactionsMetadata = useRecoilValue(fetchTransactions('metadata'));
  const scrollPointRef = useRef();

  const onCancelTransaction = useCallback((transaction) => {
    const transactionIndex = transactions.findIndex((tx) => tx.offerKey === transaction.offerKey);
    const updatedTransaction = produce(transactions[transactionIndex], (draft) => {
      draft.offerStatus = 'FAILED';
    });
    const updatedTransactions = produce(transactions, (draft) => {
      draft[transactionIndex] = updatedTransaction;
    });
    setTransactions(updatedTransactions);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactions]);

  const handleChangePage = useCallback((newPage) => {
    setQueryParams({
      ...queryParams,
      page: newPage
    });
    window.scrollTo(0, scrollPointRef.current.offsetTop - STICKY_HEADER_HEIGHT);
  }, [queryParams]);

  const handleChangeItemsPerPage = useCallback((newItemsPerPage) => {
    setQueryParams({
      ...queryParams,
      page: 1,
      limit: newItemsPerPage
    });
    window.scrollTo(0, scrollPointRef.current.offsetTop - STICKY_HEADER_HEIGHT);
  }, [queryParams]);

  return (
    <InvestorTransactionsQueryContext.Provider value={{queryParams, setQueryParams}}>
      <Stack spacing={4}>
        {transactions?.length > 0 && (
          <>
            <Box ref={scrollPointRef} sx={styles.TransactionsContainer}>
              {!loaded && (
                <Stack justifyContent={'center'} alignItems={'center'} sx={styles.Loading}>
                  <CircularLoading />
                </Stack>
              )}
              <InvestorTransactions
                transactions={transactions || []}
                onCancelTransaction={onCancelTransaction}
              />
            </Box>
            <Pagination
              onChangePage={handleChangePage}
              onChangeItemsPerPage={handleChangeItemsPerPage}
              totalPages={transactionsMetadata?.pageCount}
            />
          </>
        )}
        {transactions?.length === 0 && <Typography>You have not initiated any transactions associated with this company.</Typography>}
      </Stack>
    </InvestorTransactionsQueryContext.Provider>
  );
};

export default InvestorTransactionsTab;
