const styles = {
  CompanyLogoGridItem: {
    height: '100%',
  },
  CompanyLogoContainer: ({spacing}) => ({
    backgroundColor: 'grey.100',
    borderColor: 'grey.300',
    borderRadius: '0.625rem',
    borderStyle: 'solid',
    borderWidth: '1px',
    padding: spacing(2),
    position: 'relative',
    '&:after': {
      content: '""',
      display: 'block',
      paddingBottom: '100%',
    },
    '.company-logo, svg': {
      display: 'block',
      maxHeight: `calc(100% - ${spacing(2)})`,
      maxWidth: `calc(100% - ${spacing(2)})`,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    },
  }),
  LinkOut: {
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  BlockQuoteContainer: {
    padding: 2,
  },
  BlockQuote: {
    borderLeft: '4px solid',
    borderColor: 'primary.main',
    paddingLeft: 2,
  },
  NoWrap: {
    whiteSpace: 'nowrap',
  },
};

export default styles;
