import { Box, Stack, useTheme } from '@mui/material';
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import DateFilters from './date-filters';
import MoneyTooltip from './tooltip';
import { NumberFormat } from '../../_helpers/formatters';
import PropTypes from 'prop-types';
import styles from './styles';

const formatMoneyTick = (value) => {
  return NumberFormat.dollars(value, {notation: 'standard'});
};

const ChartMoney = ({ data, xKey, yKey, setActiveDateRange, activeDateRange }) => {
  const theme = useTheme();

  const strokeColor = data[data.length - 1]?.y < data[0]?.y ? theme.palette.loss.main : theme.palette.gain.main;

  return (
    <Stack spacing={4}>
      <DateFilters setActiveDateRange={setActiveDateRange} activeDateRange={activeDateRange} />
      <Box sx={styles.ChartWrapper}>
        <ResponsiveContainer height={260}>
          <LineChart data={data} margin={{ top: 5, right: 20, bottom: 5, left: 4 }}>
            <Line
              type='monotone'
              dataKey='y'
              stroke={strokeColor}
              strokeWidth={2}
            />
            <XAxis
              dataKey={xKey}
              fontSize='0.75rem'
              tickLine={false}
              padding={{ left: 16, right: 16 }}
            />
            <YAxis
              dataKey={yKey}
              fontSize='0.75rem'
              tickLine={false}
              axisLine={false}
              tickFormatter={formatMoneyTick}
            />
            <Tooltip
              content={MoneyTooltip}
              wrapperStyle={styles.TooltipWrapper}
              color={strokeColor}
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Stack>
  );
};

ChartMoney.defaultProps = {
  xKey: 'name',
  yKey: 'value',
};

ChartMoney.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape),
  xKey: PropTypes.string,
  yKey: PropTypes.string,
};

export default ChartMoney;
