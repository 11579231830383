import { Link, useMediaQuery } from '@mui/material';

import NetcapitalLogo from '../../../netcapital-components/NetcapitalLogo';
import styles from './styles';
import { useTheme } from '@emotion/react';

export const AppLogo = () => {
  const theme = useTheme();
  const color = useMediaQuery(theme.breakpoints.up('lg')) ? 'common.white' : 'primary';
  const showInitialLogo = useMediaQuery('(max-width: 359px)');

  return (
    <Link href={process.env.REACT_APP_WEB_HOST} sx={styles.LogoLink}>
      <NetcapitalLogo initial={showInitialLogo} sx={styles.LogoContainer} color={color} />
    </Link>
  );
};
