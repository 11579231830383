import { TableCell, TableRow, Typography } from '@mui/material';

import PropTypes from 'prop-types';
import styles from './styles';

const ReceiptLineItem = ({ label, value }) => {
  return (
    <TableRow sx={styles.LineItem}>
      <TableCell>
        <Typography variant='body2'>{label}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body2' fontWeight='fontWeightMedium'>{value}</Typography>
      </TableCell>
    </TableRow>
  );
};

ReceiptLineItem.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default ReceiptLineItem;
