const styles = {
  AnnouncementsContainer: {
    position: 'relative',
  },
  AnnouncementsHeader: ({ breakpoints }) => ({
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  }),
  AnnouncementRow: {
    cursor: 'pointer',
  },
  AnnouncementCell: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  File: ({ breakpoints, palette }) => ({
    backgroundImage: 'none',
    border: `1px solid ${palette.grey[300]}`,
    borderRadius: '0.25rem',
    display: 'block',
    clear: 'both',
    margin: '1.5rem 0',
    padding: '1.5rem 1.5rem 1.5rem 4.5rem',
    position: 'relative',
    transition: 'border-color 70ms ease',
    width: '100%',
    [breakpoints.up('md')]: {
      width: '50%'
    },
    '&::before': {
      content: '""',
      display: 'inline-block',
      backgroundImage: 'url("data:image/svg+xml;charset=utf8,%3Csvg width=\'25px\' height=\'32px\' viewBox=\'0 0 25 32\' version=\'1.1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\'%3E%3Cg id=\'Template\' stroke=\'none\' stroke-width=\'1\' fill=\'none\' fill-rule=\'evenodd\'%3E%3Cg id=\'Company-Profile-_Template_NetcapBrand_Neutral\' transform=\'translate(-969.000000, -13447.000000)\' stroke=\'%236C4EA5\' stroke-width=\'2\'%3E%3Cg id=\'Body-Content\' transform=\'translate(0.000000, 905.902784)\'%3E%3Cg id=\'Slide-Deck\' transform=\'translate(330.000000, 12125.144192)\'%3E%3Cg id=\'Bitmap\' transform=\'translate(0.000000, 55.000000)\'%3E%3Cg id=\'basic-elaboration-document-download\' transform=\'translate(640.632447, 361.953884)\'%3E%3Cg id=\'Group\'%3E%3Cpolygon id=\'Path\' points=\'6.69565217 0 22 0 22 29.6521739 0 29.6521739 0 6.69565217\'%3E%3C/polygon%3E%3Cpolyline id=\'Path\' points=\'0 6.69565217 6.69565217 6.69565217 6.69565217 0\'%3E%3C/polyline%3E%3C/g%3E%3Cpolyline id=\'Path\' stroke-linejoin=\'bevel\' points=\'14.826087 17.2173913 11 21.0434783 7.17391304 17.2173913\'%3E%3C/polyline%3E%3Cpath d=\'M11,21.0434783 L11,11.4782609\' id=\'Path\'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E")',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '0 center',
      backgroundSize: 'contain',
      width: '25px',
      height: '32px',
      position: 'absolute',
      left: '1.5rem',
      top: '50%',
      marginTop: '-16px'
    }
  }),
  Link: {
    wordWrap: 'break-word'
  },
  Loading: {
    position: 'absolute',
    background: 'rgba(255,255,255,0.85)',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
};

export default styles;
