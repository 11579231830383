import { DateFormat, NumberFormat } from '../../../_helpers/formatters';
import { Grid, Typography } from '@mui/material';

import CurrentRaiseContext from './context';
import { fetchCompanyFundingHistoryAttribute } from '../../../_state/company-funding-history';
import { useContext } from 'react';
import { useRecoilValue } from 'recoil';

const FundingHistoryOverview = () => {
  const fundingHistory = useRecoilValue(fetchCompanyFundingHistoryAttribute('data'));
  const {selectedRaise} = useContext(CurrentRaiseContext);
  const raiseData = fundingHistory?.find(offer => offer.key === selectedRaise);

  return (
    <Grid item xs={12} container spacing={4}>
      <Grid item xs={6} md={3}>
        <Typography variant='overline'>Average Share Price</Typography>
        <Typography variant='body1' fontWeight='medium'>{raiseData ? NumberFormat.cents(raiseData.priceAverage) : <span>&nbsp;</span>}</Typography>
      </Grid>
      <Grid item xs={6} md={3}>
        <Typography variant='overline'>Raise Goal</Typography>
        <Typography variant='body1' fontWeight='medium'>{raiseData ? NumberFormat.cents(raiseData.priceAverage * raiseData.quantityTotal) : <span>&nbsp;</span>}</Typography>
      </Grid>
      <Grid item xs={6} md={3}>
        <Typography variant='overline'>Amount Raised</Typography>
        <Typography variant='body1' fontWeight='medium'>{raiseData ? NumberFormat.cents(raiseData.priceAverage * raiseData.quantityAllocated) : <span>&nbsp;</span>}</Typography>
      </Grid>
      <Grid item xs={6} md={3}>
        <Typography variant='overline'>Close Date</Typography>
        <Typography variant='body1' fontWeight='medium'>{raiseData ? DateFormat.short(raiseData.endDate) : <span>&nbsp;</span>}</Typography>
      </Grid>
    </Grid>
  );
};

export default FundingHistoryOverview;
