import { Buffer } from 'buffer/';
import _ from 'lodash';

const STORAGE_KEY = 'nc_investors_web_session';

const encodeFromJSON = (json) => {
  if (!json) return null;
  return Buffer.from(JSON.stringify(json)).toString('base64');
};

const decodeToJSON = (encodedString) => {
  if (!encodedString || encodedString === 'null') return null;
  return JSON.parse(Buffer.from(encodedString, 'base64').toString('utf8') ?? null);
};

const toJSON = () => {
  return decodeToJSON(sessionStorage.getItem(STORAGE_KEY));
};

const get = (attribute) => {
  return _.get(toJSON(), attribute);
};

const set = (data) => {
  const session = toJSON();
  const encodedData = encodeFromJSON({ ...session, ...data });
  sessionStorage.setItem(STORAGE_KEY, encodedData);
};

const clear = () => sessionStorage.setItem(STORAGE_KEY, null);

const Session = {
  STORAGE_KEY,
  toJSON,
  get,
  set,
  clear
};

export default Session;
