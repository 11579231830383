import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

import Field from './Field';
import PropTypes from 'prop-types';
import { RESIDENCY_STATUSES } from '../../static-info';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

const ResidencyField = ({ recoilState, onChange, allowNonUSResidency }) => {
  const [residencyStatus, setResidencyStatus] = useRecoilState(recoilState);
  const handleChangeResidencyStatus = useCallback(e => {
    const newValue = e.target.value;
    setResidencyStatus(newValue);
    onChange(newValue);
  }, [setResidencyStatus, onChange]);

  const residencyOptions = RESIDENCY_STATUSES;
  if (!allowNonUSResidency) {
    delete residencyOptions.OTHER;
  }

  return (
    <Field label='Residency Status *'>
      <RadioGroup onChange={handleChangeResidencyStatus}>
        {Object.keys(residencyOptions).map((key) => (
          <FormControlLabel
            label={residencyOptions[key]}
            value={key}
            control={<Radio checked={residencyStatus === key} />}
            key={key}
          />
        ))}
      </RadioGroup>
    </Field>
  );
};

ResidencyField.defaultProps = {
  allowNonUSResidency: true,
  onChange: () => { return; },
};

ResidencyField.propTypes = {
  allowNonUSResidency: PropTypes.bool,
  recoilState: PropTypes.shape({key: PropTypes.string.isRequired}).isRequired,
  onChange: PropTypes.func,
};

export default ResidencyField;
