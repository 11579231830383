import { Container, Link, Stack, Typography } from '@mui/material';

import styles from './styles';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export default function NoPermissionsPage(){
  const navigate = useNavigate();
  const handleRedirection = useCallback(() => {
    navigate(-1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <Container sx={styles.ErrorPage}>
      <Stack spacing={4}>
        <Typography variant='h1' sx={styles.ErrorTitle}>You need permission.</Typography>
        <Typography variant='body1'>We received a request that your account is not allowed to make. Are you logged in to the right account? Are you sure you should have access? If you’re supposed to have access, <Link target='_blank' rel='noopener noreferrer' href='mailto:help@netcapital.com'>send us an email</Link>. Otherwise, <Link target='_blank' rel='noopener noreferrer' onClick={handleRedirection} sx={styles.RedirectLink}>go back to the previous page.</Link></Typography>
      </Stack>
    </Container>
  );
}
