import { Grid, Stack, Table, TableContainer } from '@mui/material';
import { companyState, fetchCompanyFundingHistoryAttribute } from '../../../../_state';
import { useCallback, useRef, useState } from 'react';

import CircularLoading from '../../../../netcapital-components/CircularLoading';
import { DEFAULT_QUERY_PARAMS } from '../../../../_actions/_api-urls';
import FundingHistoryBody from './body';
import FundingHistoryHeader from './header';
import FundingHistoryQueryContext from './context';
import Pagination from '../../../../netcapital-components/Pagination';
import { STICKY_HEADER_HEIGHT } from '../../../../layouts/layout-styles';
import styles from '../styles';
import { useGetFundingHistory } from '../../../../_actions/company-funding-history.actions';
import { useRecoilValue } from 'recoil';

const Raises = () => {
  const company = useRecoilValue(companyState);
  const [queryParams, setQueryParams] = useState(DEFAULT_QUERY_PARAMS);
  const {loaded} = useGetFundingHistory(company.key, queryParams);
  const fundingHistoryMetadata = useRecoilValue(fetchCompanyFundingHistoryAttribute('metadata'));
  const scrollPointRef = useRef();

  const handleChangePage = useCallback((newPage) => {
    setQueryParams({
      ...queryParams,
      page: newPage
    });
    window.scrollTo(0, scrollPointRef.current.offsetTop - STICKY_HEADER_HEIGHT);
  }, [queryParams]);

  const handleChangeItemsPerPage = useCallback((newItemsPerPage) => {
    setQueryParams({
      ...queryParams,
      page: 1,
      limit: newItemsPerPage
    });
    window.scrollTo(0, scrollPointRef.current.offsetTop - STICKY_HEADER_HEIGHT);
  }, [queryParams]);

  return (
    <FundingHistoryQueryContext.Provider value={{ queryParams, setQueryParams }}>
      <Grid item xs={12} container spacing={4} ref={scrollPointRef} sx={styles.Container}>
        {!loaded && (
          <Stack justifyContent={'center'} alignItems={'center'} sx={styles.Loading}>
            <CircularLoading />
          </Stack>
        )}
        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <FundingHistoryHeader />
              <FundingHistoryBody />
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <Pagination
            onChangePage={handleChangePage}
            onChangeItemsPerPage={handleChangeItemsPerPage}
            totalPages={fundingHistoryMetadata?.pageCount}
          />
        </Grid>
      </Grid>
    </FundingHistoryQueryContext.Provider>
  );
};

export default Raises;
