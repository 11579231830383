import { Table, TableBody, TableContainer, TableHead } from '@mui/material';

import InvestorTransaction from '../Transaction';
import InvestorTransactionsHeader from '../header';

const InvestorTransactions = ({ transactions, onCancelTransaction }) => {

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <InvestorTransactionsHeader />
        </TableHead>
        <TableBody>
          {transactions.map((transaction) => (
            <InvestorTransaction
              key={transaction.offerKey}
              onCancelTransaction={onCancelTransaction}
              transaction={transaction}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InvestorTransactions;
