import { useCallback, useEffect, useState } from 'react';

import { APIs } from './_api-urls';
import { pendingOffersState } from '../_state';
import { useFetchWrapper } from '../_helpers';
import { useSetRecoilState } from 'recoil';

export function useGetPendingOffers(listingKey) {
  const setOffers = useSetRecoilState(pendingOffersState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(true);
  const refreshData = useCallback(() => {
    setShouldFetch(true);
  }, []);

  useEffect(() => {
    const doFetch = () => {
      const controller = new AbortController();
      const signal = controller.signal;
      const abort = () => controller.abort();
      if (shouldFetch) {
        setLoaded(false);

        const url = `${APIs.Offerings}/pending-offers/${listingKey}`;
        fetchWrapper.get(url, null, signal, true)
          .then((response) => {
            if (!response) return; // aborted request gives response === undefined
            setOffers(response);
            setLoaded(true);
            setShouldFetch(false);
          })
          .catch(() => {
            setLoaded(true);
            setShouldFetch(false);
          });
      }

      return abort;
    };

    return doFetch();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingKey, shouldFetch]);

  return { loaded, refreshData };
}
