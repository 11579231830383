import { atom } from 'recoil';

const receiptModalState = atom({
  key: 'receiptModalState',
  default: undefined
});

export {
  receiptModalState,
};
