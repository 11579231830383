export const formatSocialLinks = (link) => {
  let formattedLink = '';
  const isNakedDomain = !link.startsWith('https://') && !link.startsWith('http://') && !link.startsWith('www.');
  const startsWithSubdomain = link.startsWith('www.');

  if(isNakedDomain){
    formattedLink = `https://www.${link}`;
  } else if(startsWithSubdomain){
    formattedLink = `https://${link}`;
  } else {
    formattedLink = link;
  }

  return formattedLink;
};

export const getClickableLink = (link) => {
  return link.startsWith('http://') || link.startsWith('https://') ? link : `//${link}`;
};

export const getCompanySocialLinks = (company) => {
  return {
    facebook: company.facebook,
    linkedIn: company.linkedIn,
    twitter: company.twitter,
  };
};
