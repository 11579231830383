import {
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
} from '@mui/material';
import { holdingEntitiesState, investorState, transactionsState } from '../../../_state';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import Loading from '../../../netcapital-components/CircularLoading';
import { useCallback } from 'react';
import { useGetUserHoldingEntities } from '../../../_actions/holding-entities.actions';

const HoldingEntities = ({ user, investor }) => {
  const userNameFormatted = `${user.firstName} ${user.lastName}`;
  const resetTransactions = useResetRecoilState(transactionsState);
  const setInvestor = useSetRecoilState(investorState);
  const { loaded } = useGetUserHoldingEntities();
  const holdingEntities = useRecoilValue(holdingEntitiesState);

  const handleChangeActiveInvestor = useCallback((e) => {
    const matchingUserEntity = holdingEntities.find((holdingEntity) => holdingEntity.entityKey === e.target.value);

    if (matchingUserEntity) {
      setInvestor({
        ...matchingUserEntity,
        name: matchingUserEntity.legalName ? matchingUserEntity.legalName : 'Unnamed Entity',
      });
      resetTransactions();
      return;
    }

    setInvestor({
      ...user,
      name: `${user.firstName} ${user.lastName}`,
    });

    resetTransactions();

  },[holdingEntities, resetTransactions, setInvestor, user]);

  return (
    <RadioGroup onChange={handleChangeActiveInvestor}>
      <MenuItem>
        <FormControlLabel
          label={userNameFormatted}
          value={user.entityKey}
          control={<Radio checked={investor.entityKey === user.entityKey} />}
        />
      </MenuItem>
      {loaded ? holdingEntities?.map((holdingEntity) => (
        <MenuItem key={holdingEntity.entityKey}>
          <FormControlLabel
            label={holdingEntity.legalName || 'Unnamed Entity'}
            value={holdingEntity.entityKey}
            control={<Radio checked={investor.entityKey === holdingEntity.entityKey} />}
          />
        </MenuItem>
      ))
        :
        <MenuItem>
          <Loading />
        </MenuItem>
      }
    </RadioGroup>
  );
};

export default HoldingEntities;
