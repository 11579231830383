import { TEN_MEGABYTES, VERIFICATION_DOC_FILE_TYPES } from '../../../../../static-info';
import { useCallback, useState } from 'react';
import { useDeleteHoldingEntityBeneficialOwnerVerificationDocument, useSaveHoldingEntityBeneficialOwnerVerificationDocument } from '../../../../../_actions/holding-entities.actions';

import Field from '../../../../FormFields/Field';
import FileUpload from '../../../../../netcapital-components/FileUpload';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { useFormFieldValidation } from '../../../../../netcapital-components/FormValidation';

const BeneficialOwnerVerificationDocField = (props) => {
  const {
    fileObj,
    usage,
    label,
    fileType,
    allowedFileTypes,
    beneficialOwnerKey,
    beneficialOwnerIndex,
    holdingEntityKey,
    fieldName,
  } = props;
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(fileObj);
  const {save, saving} = useSaveHoldingEntityBeneficialOwnerVerificationDocument(holdingEntityKey, beneficialOwnerIndex);
  const {destroy: deleteFile, deleting} = useDeleteHoldingEntityBeneficialOwnerVerificationDocument(holdingEntityKey, beneficialOwnerIndex);
  const [errorMessage, setErrorMessage] = useState();
  const {errorMessage: validationErrorMessage, eventListeners} = useFormFieldValidation(fieldName);

  const handleUpdateFile = useCallback(selectedFile => {
    eventListeners.onFocus();
    setLoading(true);
    if (!selectedFile) {
      if (file?.key) {
        deleteFile(file.key, beneficialOwnerKey);
      }
      setFile(null);
      setLoading(false);
      return;
    }

    if (selectedFile.size > TEN_MEGABYTES) {
      setErrorMessage('File cannot be larger than 10 MB');
      setLoading(false);
      return;
    }

    setFile(selectedFile);
    save(usage, beneficialOwnerKey, selectedFile);
    setLoading(false);
    setErrorMessage('');

  }, [beneficialOwnerKey, deleteFile, eventListeners, file?.key, save, usage]);

  return (
    <Field label={label}>
      <FileUpload
        fileType={fileType || VERIFICATION_DOC_FILE_TYPES.contentTypes.join(',')}
        allowedFileTypes={allowedFileTypes || VERIFICATION_DOC_FILE_TYPES.fileTypes.join(', ')}
        file={file}
        onChange={handleUpdateFile}
        loading={loading || saving || deleting}
        disabled={saving || deleting}
        showPreview
        maxSize={TEN_MEGABYTES}
      />
      <Typography color='error.main' variant='body2'>{errorMessage || validationErrorMessage}</Typography>
    </Field>
  );
};

BeneficialOwnerVerificationDocField.defaultProps = {
  label: '',
  allowedFileTypes: '',
  fileType: '',
  onSuccess: () => { return; },
  fieldName: '',
  fileObj: {
    name: '',
    url: '',
    usage: ''
  },
};

BeneficialOwnerVerificationDocField.propTypes = {
  fileObj: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
    usage: PropTypes.string
  }),
  usage: PropTypes.string.isRequired,
  label: PropTypes.string,
  fileType: PropTypes.string,
  allowedFileTypes: PropTypes.string,
  beneficialOwnerKey: PropTypes.string.isRequired,
  beneficialOwnerIndex: PropTypes.number.isRequired,
  holdingEntityKey: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
  fieldName: PropTypes.string,
};

export default BeneficialOwnerVerificationDocField;
