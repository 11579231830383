import { Grid, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDeleteHoldingEntityBeneficialOwnerVerificationDocument, useGetHoldingEntityBeneficialOwners, useSaveHoldingEntityBeneficialOwners } from '../../../../_actions/holding-entities.actions';

import BeneficialOwner from './BeneficialOwner';
import BeneficialOwnersReadOnly from './read-only';
import CircularLoading from '../../../../netcapital-components/CircularLoading';
import Field from '../../../FormFields/Field';
import RadioGroup from '../../../../netcapital-components/RadioGroup';
import { YES_NO } from '../../../../static-info';
import { fetchHoldingEntityBeneficialOwnersAttribute } from '../../../../_state';
import { useRecoilState } from 'recoil';
import { v4 as uuidv4 } from 'uuid';

const BeneficialOwnersFields = ({ holdingEntityKey, disabled }) => {
  const {loaded, refreshData} = useGetHoldingEntityBeneficialOwners(holdingEntityKey);
  const [shouldSave, setShouldSave] = useState(false);
  const {save, saving} = useSaveHoldingEntityBeneficialOwners(holdingEntityKey);
  const {destroy: deleteFile} = useDeleteHoldingEntityBeneficialOwnerVerificationDocument(holdingEntityKey);
  const [hasBeneficialOwners, setHasBeneficialOwners] = useRecoilState(fetchHoldingEntityBeneficialOwnersAttribute('hasBeneficialOwners'));
  const [beneficialOwners, setBeneficialOwners] = useRecoilState(fetchHoldingEntityBeneficialOwnersAttribute('beneficialOwners'));

  const handleCreateBeneficialOwner = useCallback(() => {
    setBeneficialOwners([...(beneficialOwners || []), { _key: uuidv4() } ]);
    setShouldSave(true);
  }, [beneficialOwners, setBeneficialOwners]);

  const handleChangeHasBeneficialOwners = useCallback((e) => {
    const hasOwners = e.target.value === 'true';
    setHasBeneficialOwners(hasOwners);

    if (!hasOwners) {
      beneficialOwners?.forEach(owner => {
        owner?.verificationDocuments?.forEach(doc => {
          if (doc.key) {
            deleteFile(doc.key, owner.key);
          }
        });
      });
      setBeneficialOwners([]);
      setShouldSave(true);
    }
    else if (beneficialOwners?.length === 0) {
      handleCreateBeneficialOwner();
    }
  }, [setHasBeneficialOwners, beneficialOwners, setBeneficialOwners, handleCreateBeneficialOwner, deleteFile]);

  const handleRefreshData = useCallback(() => {
    refreshData();
  }, [refreshData]);

  useEffect(() => {
    if (shouldSave && !saving) {
      save(refreshData);
      setShouldSave(false);
    }
  }, [refreshData, save, saving, shouldSave]);

  if (!loaded) return <CircularLoading />;

  if (disabled) return <BeneficialOwnersReadOnly />;

  return (
    <Grid item container xs={12} rowSpacing={4}>
      <Grid item xs={12}>
        <Typography>The following information must be provided for every person who owns 10% or more of the entity.</Typography>
      </Grid>
      <Grid item xs={12}>
        <Field
          label='Does any person own 10% or more of the entity? *'
        >
          <RadioGroup
            options={YES_NO}
            value={hasBeneficialOwners}
            onChange={handleChangeHasBeneficialOwners}
            fieldName={'hasBeneficialOwners'}
          />
        </Field>
      </Grid>
      {hasBeneficialOwners !== false && (
        <Grid item container xs={12} rowSpacing={8}>
          {beneficialOwners?.map((owner, index) => (
            <Grid item xs={12} key={owner._key}>
              <BeneficialOwner
                person={owner}
                index={index}
                handleCreateBeneficialOwner={handleCreateBeneficialOwner}
                handleRefreshData={handleRefreshData}
                holdingEntityKey={holdingEntityKey}
                fieldNamePrefix={`beneficialOwners[${index}]`}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
};

export default BeneficialOwnersFields;
