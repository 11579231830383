import { LinearProgress, Stack, Step, StepButton, Stepper, Typography, useMediaQuery } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import AccountFormContext from './context';
import CircularProgress from '../../../netcapital-components/CircularProgress';
import FormProgress from './Progress/progress';
import styles from './styles';
import { useContext } from 'react';
import { useTheme } from '@emotion/react';

const getFormProgressNodeKey = (formId, isHoldingEntity) => {
  const holdingEntityForms = {
    'basic-info': 'holdingInformation',
    'holding-documents': 'holdingsVerificationDocuments',
    'authorized-representative': 'authorizedRepresentative',
    'authorized-representative-verification': 'authorizedRepresentativeDocuments',
    'beneficial-owners': 'beneficialOwners',
  };

  const individualForms = {
    'basic-info': 'personalInformation',
    'identity': 'verificationDocuments',
    'investors-info': 'investorsInformation',
    'financial-info': 'financialInformation',
  };
  return isHoldingEntity ? holdingEntityForms[formId] : individualForms[formId];
};

const AccountFormStepsNav = () => {
  const {
    activeStepNumber,
    canSubmitFinalStep,
    completion,
    formId,
    formProgress,
    finalStepNumber,
    holdingEntityKey,
    steps,
  } = useContext(AccountFormContext);
  const theme = useTheme();
  const isBelowMediumBreakpoint = useMediaQuery(theme.breakpoints.down('md'));
  const {search} = useLocation();

  if (isBelowMediumBreakpoint && formId) {
    return <LinearProgress value={completion} variant='determinate' color='success' sx={styles.MobileFormProgress} />;
  }

  return (
    <Stack spacing={3} sx={styles.FormNavContainer}>
      <Stack spacing={2} direction={'row'} alignItems='center'>
        <CircularProgress value={completion} size={56} thickness={5} />
        <Typography variant='subtitle2'>{holdingEntityKey ? 'Entity' : 'Individual'} Account</Typography>
      </Stack>
      <Stepper
        nonLinear
        orientation='vertical'
        activeStep={activeStepNumber}
        sx={styles.FormSectionNav}
      >
        {Object.keys(steps).map((stepId, index) => (
          <Step
            disabled={index === finalStepNumber && !canSubmitFinalStep}
            sx={styles.FormSectionNavItem}
            key={stepId}
          >
            <StepButton
              LinkComponent={RouterLink}
              to={!formId ? `${stepId}${search}` : `../${stepId}${search}`}
              icon={steps[stepId].icon}
              sx={styles.FormSectionIcon}
            >
              {steps[stepId].label}
              {index < finalStepNumber && <FormProgress value={formProgress?.[getFormProgressNodeKey(stepId, Boolean(holdingEntityKey))]} />}
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
};

export default AccountFormStepsNav;
