import { useCallback, useState } from 'react';

import OfferModal from '../../OfferModal';
import PendingOffers from '../../PendingOffers';
import { fetchOfferAttribute } from '../../../_state';
import { useSetRecoilState } from 'recoil';

const PendingOffersTab = () => {
  const setPricePerUnit = useSetRecoilState(fetchOfferAttribute('pricePerUnit'));
  const setOfferType = useSetRecoilState(fetchOfferAttribute('offerType'));
  const [isOfferModalOpen, setIsOfferModalOpen] = useState(false);
  const handleOpenOfferModal = useCallback(() => setIsOfferModalOpen(true), []);
  const handleCloseOfferModal = useCallback(() => setIsOfferModalOpen(false), []);
  const handleClickPickPrice = useCallback((price, offerType) => {
    setPricePerUnit(parseFloat(price));
    setOfferType(offerType);
    handleOpenOfferModal();
  }, [handleOpenOfferModal, setOfferType, setPricePerUnit]);

  return (
    <>
      <PendingOffers onSelectPrice={handleClickPickPrice} />
      {isOfferModalOpen && <OfferModal onClose={handleCloseOfferModal} open={isOfferModalOpen} />}
    </>
  );
};

export default PendingOffersTab;
