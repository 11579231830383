import { Box, IconButton, Menu } from '@mui/material';
import { useCallback, useState } from 'react';

import WalletBalanceContent from './content';
import WalletBalanceSubcontent from './subcontent';
import styles from './styles';
import { useGetWalletBalance } from '../../_actions/wallet.actions';
import { useRecoilValue } from 'recoil';
import { walletState } from '../../_state';

const WalletBalance = () => {
  const { loaded } = useGetWalletBalance();
  const walletBalance = useRecoilValue(walletState);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <Box name='WalletBalance'>
      <IconButton
        onClick={handleClick}
        sx={styles.WalletBalance}
        disableRipple
      >
        <WalletBalanceContent
          loaded={loaded}
          walletBalance={walletBalance}
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id='wallet-balance-subcontent-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <WalletBalanceSubcontent
          loaded={loaded}
          walletBalance={walletBalance}
        />
      </Menu>
    </Box>
  );
};

export default WalletBalance;
