const styles = {
  CompanyPageContainer: {
    paddingBottom: {
      xs: 12,
      lg: 4,
    },
  },
};

export default styles;
