import ChartMoney from '../ChartMoney';

const ChartSharePrice = ({ data, setActiveDateRange, activeDateRange }) => {
  return (
    <ChartMoney
      data={data}
      xKey="x"
      yKey="y"
      setActiveDateRange={setActiveDateRange}
      activeDateRange={activeDateRange}
    />
  );
};

export default ChartSharePrice;
