import { NEGATIVE_PAGE_MARGIN_WIDTHS, PAGE_MARGIN_WIDTHS } from '../../../../layouts/layout-styles';

const styles = {
  AnnouncementsCard: ({palette}) => ({
    backgroundColor: palette.primary.light,
    marginLeft: {
      xs: NEGATIVE_PAGE_MARGIN_WIDTHS.xs,
      sm: NEGATIVE_PAGE_MARGIN_WIDTHS.sm,
      md: NEGATIVE_PAGE_MARGIN_WIDTHS.md,
      lg: 0,
    },
    marginRight: {
      xs: NEGATIVE_PAGE_MARGIN_WIDTHS.xs,
      sm: NEGATIVE_PAGE_MARGIN_WIDTHS.sm,
      md: NEGATIVE_PAGE_MARGIN_WIDTHS.md,
      lg: 0,
    },
    paddingLeft: PAGE_MARGIN_WIDTHS,
    paddingRight: PAGE_MARGIN_WIDTHS,
  }),
  AnnouncementSlide: {
    flex: '0 0 100%',
    maxHeight: {
      xs: 'none',
      lg: '330px',
    },
    overflow: 'hidden',
    paddingLeft: {
      xs: 3,
      md: 6,
      lg: 0,
      xl: 4,
    },
    paddingRight: {
      xs: 3,
      md: 6,
      lg: 0,
      xl: 4,
    },
  },
};

export default styles;
