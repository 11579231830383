import { Grid, Stack, Typography } from '@mui/material';
import { availableSharesByCompanyState, companyState, fetchOfferAttribute, fetchTemplumInvestorKycStatusAttribute, investorState } from '../../_state';

import AvailableShares from './available-shares';
import OfferInputs from './inputs';
import OfferOwner from './owner';
import OfferSummary from './summary';
import PendingOffersAlert from './pending-offers';
import PropTypes from 'prop-types';
import { useGetSecuritiesByListingKey } from '../../_actions/securities.actions';
import { useRecoilValue } from 'recoil';

const Offer = () => {
  const company = useRecoilValue(companyState);
  const offerType = useRecoilValue(fetchOfferAttribute('offerType'));
  const investor = useRecoilValue(investorState);
  const templumKycStatus = useRecoilValue(fetchTemplumInvestorKycStatusAttribute('status'));
  useGetSecuritiesByListingKey(investor.entityKey, company.key);
  const availableShares = useRecoilValue(availableSharesByCompanyState);

  if (templumKycStatus !== 'KYC_ACCEPTED') return null;

  return (
    <Grid item container spacing={3}>
      <Grid item xs={12}>
        <PendingOffersAlert />
      </Grid>

      <Grid item xs={12}>
        <Stack spacing={2}>
          <Typography variant='h2' textAlign='center' fontWeight={500}>
            Request to {offerType === 'BID'
              ? <Typography color='gain.main' variant='h2' component='span'>Buy</Typography>
              : <Typography color='loss.main' variant='h2' component='span'>Sell</Typography>} Shares of
          </Typography>
          <AvailableShares />
        </Stack>
      </Grid>

      <Grid item container rowSpacing={4} justifyContent='space-between'>
        <Grid item xs={12}>
          <OfferInputs maxQuantity={offerType === 'ASK' ? availableShares : false} />
        </Grid>
        <Grid item xs={12}>
          <OfferSummary />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <OfferOwner />
      </Grid>
    </Grid>
  );
};

Offer.defaultProps = {
  onClose: () => { return; },
};

Offer.propTypes = {
  onClose: PropTypes.func,
};

export default Offer;
