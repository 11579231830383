import { DateFormat, getOptionLabelByValue } from '../../../../_helpers';

import EmployerInfoReadOnly from '../EmployerInfo/read-only';
import { Grid } from '@mui/material';
import { PERSON_EMPLOYMENT_STATUSES } from '../../../../static-info';
import ReadOnlyField from '../../ReadOnlyField';
import _ from 'lodash';
import { fetchUserPersonalInfoAttribute } from '../../../../_state';
import { useRecoilValue } from 'recoil';

const PersonalInfoReadOnly = () => {
  const firstName = useRecoilValue(fetchUserPersonalInfoAttribute('firstName'));
  const lastName = useRecoilValue(fetchUserPersonalInfoAttribute('lastName'));
  const dateOfBirth = useRecoilValue(fetchUserPersonalInfoAttribute('dateOfBirth'));
  const residencyStatus = useRecoilValue(fetchUserPersonalInfoAttribute('residencyStatus'));
  const citizenship = useRecoilValue(fetchUserPersonalInfoAttribute('citizenship'));
  const secureTin = useRecoilValue(fetchUserPersonalInfoAttribute('secureTin'));
  const address = useRecoilValue(fetchUserPersonalInfoAttribute('address'));
  const employmentStatus = useRecoilValue(fetchUserPersonalInfoAttribute('employment.status'));

  return (
    <Grid item container xs={12} rowSpacing={1}>
      <ReadOnlyField label={'First Name'} value={firstName} />
      <ReadOnlyField label={'Last Name'} value={lastName} />
      <ReadOnlyField label={'Date of Birth'} value={DateFormat.short(dateOfBirth)} />
      <ReadOnlyField label={'Residency Status'} value={residencyStatus} />
      <ReadOnlyField label={'Citizenship'} value={citizenship} />
      {(residencyStatus === 'US_CITIZEN' || residencyStatus === 'US_RESIDENT') && (
        <ReadOnlyField label={'SSN or TIN'} value={secureTin?.key ? '*********' : ''} />
      )}
      {Object
        .keys(address || {})
        .filter(addressAttribute => addressAttribute !== 'key')
        .map(addressAttribute => <ReadOnlyField label={_.startCase(addressAttribute)} value={address[addressAttribute]} key={addressAttribute} />)
      }
      <ReadOnlyField
        label={'Employment Status'}
        value={getOptionLabelByValue(employmentStatus, PERSON_EMPLOYMENT_STATUSES)}
      />
      {(employmentStatus === 'EMPLOYED' || employmentStatus === 'SELF_EMPLOYED') && <EmployerInfoReadOnly />}
    </Grid>
  );
};

export default PersonalInfoReadOnly;
