import { useCallback, useState } from 'react';

import { Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ConfirmationDialog from '../../../netcapital-components/ConfirmationDialog';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PropTypes from 'prop-types';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import styles from './styles';
import { useDeleteHoldingEntity } from '../../../_actions/holding-entities.actions';
import { useNavigate } from 'react-router-dom';

const DeleteHoldingEntity = ({ holdingEntityKey, isTablet }) => {
  const navigate = useNavigate();
  const {destroy: deleteEntity, deleting} = useDeleteHoldingEntity(holdingEntityKey);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const toggleConfirmModal = useCallback(() => setConfirmModalOpen(!confirmModalOpen), [confirmModalOpen]);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const toggleSuccessModal = useCallback(() => setSuccessModalOpen(!successModalOpen), [successModalOpen]);

  const exitForm = useCallback(() => navigate('../'), [navigate]);

  const handleSuccess = useCallback(() => {
    toggleConfirmModal();
    toggleSuccessModal();
  }, [toggleConfirmModal, toggleSuccessModal]);

  const handleConfirmSubmit = useCallback(() => {
    deleteEntity(handleSuccess);
  }, [handleSuccess, deleteEntity]);

  return (
    <>
      <Button
        startIcon={<DeleteIcon />}
        onClick={toggleConfirmModal}
        color='error'
        disabled={deleting}
        variant='outlined'
        size='large'
        sx={styles.SaveButton}
      >
        {isTablet ? 'Delete' : 'Delete Entity'}
      </Button>
      <ConfirmationDialog
        startIcon={<DeleteIcon />}
        open={confirmModalOpen}
        onClose={toggleConfirmModal}
        title={'Confirm Entity Deletion'}
        titleIcon={<WarningAmberOutlinedIcon color='primary' />}
        content={'You‘re about to delete this entity. Please review and confirm if you‘re certain, this action cannot be undone. Are you sure you want to proceed?'}
        ctaConfirm={'Confirm & Delete'}
        onConfirm={handleConfirmSubmit}
        onCancel={toggleConfirmModal}
      />
      <ConfirmationDialog
        open={successModalOpen}
        onClose={toggleSuccessModal}
        title={'Entity Successfully Deleted'}
        titleIcon={<CheckIcon color='success' />}
        content={'We‘ve taken care of that deletion for you. If there‘s anything else you need, don‘t hesitate to let us know!'}
        onConfirm={exitForm}
        ctaConfirm={'Ok'}
        canCancel={false}
      />
    </>
  );
};

DeleteHoldingEntity.propTypes = {
  holdingEntityKey: PropTypes.string.isRequired,
};

export default DeleteHoldingEntity;
