import { Box, Typography } from '@mui/material';

import InfiniteScrollComponent from 'react-infinite-scroll-component';
import PropTypes from 'prop-types';
import styles from './styles';
import { v4 as uuidv4 } from 'uuid';

function InfiniteScroll(props) {
  const {
    children,
    currentItemsCount,
    currentPage,
    hasMore,
    endMessage,
    fetchData,
    scrollHeight
  } = props;

  const scrollAreaId = uuidv4();

  return (
    <Box id={scrollAreaId} sx={[styles.ScrollContainer, {maxHeight: scrollHeight}]}>
      <InfiniteScrollComponent
        next={fetchData}
        dataLength={currentItemsCount}
        hasMore={hasMore}
        scrollableTarget={scrollAreaId}
        endMessage={
          <Box sx={styles.EndMessage}>
            {currentPage > 1 && endMessage}
          </Box>
        }
      >
        {children}
      </InfiniteScrollComponent>
    </Box>
  );
}

InfiniteScroll.defaultProps = {
  endMessage: <Typography variant='body2' textAlign='center'>End of results</Typography>,
  scrollHeight: 400
};

InfiniteScroll.propTypes = {
  children: PropTypes.node.isRequired,
  currentItemsCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  hasMore: PropTypes.bool.isRequired,
  loadingMessage: PropTypes.node,
  endMessage: PropTypes.node,
  fetchData: PropTypes.func.isRequired,
  scrollHeight: PropTypes.number
};

export default InfiniteScroll;


