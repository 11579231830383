// reference: https://www.figma.com/file/RQ4gohnkryzsdGejCbKQmY/MUI-v5.4-for-NetCapital?type=design&node-id=8682-86229&mode=design&t=esJSQvK3OsOda5ez-4
const palette = {
  mode: 'light',
  primary: {
    main: '#674FA0',
    dark: '#2A095A',
    light: '#E2DCED',
    contrastText: '#FFFFFF'
  },
  secondary: {
    main: '#F2C94C', // TODO: speak with Paulo about this color too (see error section below)
    light: '#F8E4A5',
    lighter: '#FCF4DB',
    lightest: '#FDF7E4'
  },
  error: {
    main: '#D32F2F', // Todo: discuss this color with Paulo, per frame style guide seems different than here: https://www.figma.com/file/RQ4gohnkryzsdGejCbKQmY/MUI-v5.4-for-NetCapital?type=design&node-id=8682-84147&mode=design&t=esJSQvK3OsOda5ez-4
    light: '#F5ABAB',
    lighter: '#EB5757',
    lightest: '#FDEEEE'
  },
  common: {
    black: '#1a1a1a',
    white: '#FFFFFF'
  },
  warning: {
    main: '#ed6c02',
  },
  info: {
    'main': '#0288D1',
  },
  success: {
    main: '#2E7D32',
    light: '#A5D7A7',
  },
  grey: {
    0: '#FFFFFF',
    50: '#F8F8F8',
    100: '#F0F1F3',
    200: '#E1E3E7',
    300: '#C4C8CF',
    400: '#A6ACB8',
    450: '#8991A0',
    500: '#6B7588',
    main: '#24242499',
    600: '#505866',
    700: '#353B44',
  },
  table: {
    header: '#1A1A1AB2'
  },
  shadows: {
    primary: 'rgba(0, 16, 101, 0.27)'
  },
  text: {
    primary: '#2A095A',
    title: '#24094B',
    white: '#fff',
    main: '#242424',
    light: 'rgba(36, 36, 36, 0.6)', // text.main 60% opacity
    secondary: 'rgba(0, 0, 0, 0.6)',
    disabled: 'rgba(0, 0, 0, 0.38)'
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    active: 'rgba(108, 78, 165, 0.15)',
    default: '#fff',
    paper: '#fff',
    tableCell: 'rgba(108, 78, 165, 0.03)',
    tableHeader: 'rgba(108, 78, 165, 0.1)'
  },
  gain: {
    main: '#219653',
    light: '#e8f4ed',
    dark: '#1b7843',
    contrastText: '#FFFFFF',
  },
  loss: {
    main: '#EB5757',
    contrastText: '#FFFFFF',
  },
};

export default palette;
