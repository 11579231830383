import { Grid } from '@mui/material';
import { HOLDING_ENTITY_DOCS_TEMPLUM } from '../../../../static-info';
import ReadOnlyImageField from '../../ReadOnlyField/image';
import { holdingEntityDocumentsState } from '../../../../_state';
import { useRecoilValue } from 'recoil';

const HoldingEntityDocumentsReadOnly = () => {
  const docs = useRecoilValue(holdingEntityDocumentsState);

  return docs?.map(doc => {
    const docTypeConfig = HOLDING_ENTITY_DOCS_TEMPLUM.find(config => config.value === doc.usage);

    if (!docTypeConfig) return null;

    return (
      <Grid item container xs={12} rowSpacing={1} key={doc.usage}>
        <ReadOnlyImageField label={docTypeConfig.label} src={doc.url} fileName={doc.name} />
      </Grid>
    );
  });
};

export default HoldingEntityDocumentsReadOnly;
