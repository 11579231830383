import { Box, IconButton } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import RemoveIcon from '@mui/icons-material/Remove';
import styles from './styles';
import { useCallback } from 'react';

const InputStepper = (props) => {
  const { onChange, value, input } = props;

  const decrementValue = useCallback(() => onChange(Math.max(value-1, 0)), [onChange, value]);
  const incrementValue = useCallback(() => onChange(value+1), [onChange, value]);

  return (
    <Box sx={styles.Container}>
      <IconButton onClick={decrementValue} size='small' sx={[styles.Button, styles.ButtonLeft]}>
        <RemoveIcon />
      </IconButton>
      {input}
      <IconButton onClick={incrementValue} size='small' sx={[styles.Button, styles.ButtonRight]}>
        <AddIcon />
      </IconButton>
    </Box>
  );
};

InputStepper.defaultProps = {
  value: 0,
};

InputStepper.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  input: PropTypes.node.isRequired,
};

export default InputStepper;
