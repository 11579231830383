const styles = {
  ItemsPerPageLabel: {
    whiteSpace: 'nowrap',
  },
  ItemsPerPage: {
    fontSize: '0.875rem',
    '.MuiSelect-outlined': {
      padding: 1,
    }
  },
};

export default styles;
