import { Box, Stack } from '@mui/material';
import { useCallback, useContext, useRef } from 'react';

import CircularLoading from '../../../netcapital-components/CircularLoading';
import CommentsQueryContext from './context';
import Pagination from '../../../netcapital-components/Pagination';
import ParentComment from './parent-comment';
import PropTypes from 'prop-types';
import { STICKY_HEADER_HEIGHT } from '../../../layouts/layout-styles';
import { commentsState } from '../../../_state';
import styles from './styles';
import { useGetComments } from '../../../_actions/company-discussion.actions';
import { useRecoilValue } from 'recoil';

const Comments = ({ listingKey }) => {
  const {queryParams, setQueryParams} = useContext(CommentsQueryContext);
  const {loaded} = useGetComments(listingKey, queryParams);
  const comments = useRecoilValue(commentsState);
  const scrollPointRef = useRef();

  const fetchPage = useCallback((newPageNumber) => {
    setQueryParams({
      ...queryParams,
      page: newPageNumber,
    });
    window.scrollTo(0, scrollPointRef.current.offsetTop - STICKY_HEADER_HEIGHT);
  }, [queryParams, setQueryParams]);

  const handleChangeItemsPerPage = useCallback((newItemsPerPage) => {
    setQueryParams({
      ...queryParams,
      page: 1,
      limit: newItemsPerPage,
    });
    window.scrollTo(0, scrollPointRef.current.offsetTop - STICKY_HEADER_HEIGHT);
  }, [queryParams, setQueryParams]);

  return (
    <Stack spacing={4} ref={scrollPointRef}>
      <Box sx={styles.CommentsContainer}>
        <Stack spacing={2}>
          {comments?.data.map(comment => <ParentComment comment={comment} listingKey={listingKey} key={comment.key} />)}
        </Stack>
        {!loaded && (
          <Stack justifyContent={'center'} alignItems={'center'} sx={styles.Loading}>
            <CircularLoading />
          </Stack>
        )}
      </Box>
      {comments?.data?.length > 0 && (
        <Pagination
          onChangePage={fetchPage}
          onChangeItemsPerPage={handleChangeItemsPerPage}
          totalPages={comments?.metadata?.pageCount}
        />
      )}
    </Stack>
  );
};

Comments.propTypes = {
  listingKey: PropTypes.string.isRequired,
};

export default Comments;
