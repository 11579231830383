import { Box, Stack, Typography } from '@mui/material';
import { companyPriceGraphState, companyState } from '../../_state';
import { useCallback, useState } from 'react';

import ChartSharePrice from './chart';
import Section from '../Section';
import { useGetCompanyGraph } from '../../_actions/company.actions';
import { useRecoilValue } from 'recoil';

const SharePriceHistory = () => {
  const [activeDateRange, setActiveDateRange] = useState('today');
  const company = useRecoilValue(companyState);

  const priceGraph = useRecoilValue(companyPriceGraphState);
  const { loaded } = useGetCompanyGraph(company.key, activeDateRange);

  const handleDateRange = useCallback((dateIdentifier) => {
    setActiveDateRange(dateIdentifier);
  }, []);

  return (
    <Section
      content={
        <ChartSharePrice
          data={priceGraph}
          setActiveDateRange={handleDateRange}
          activeDateRange={activeDateRange}
        />
      }
      canCollapse={false}
      loaded={loaded}
      title={
        <Stack direction='column'>
          <Box>
            <Typography variant="cardTitle">Price Per Share</Typography>
          </Box>
        </Stack>
      }
    />
  );
};

export default SharePriceHistory;
