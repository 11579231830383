import { Dialog, DialogContent } from '@mui/material';
import { activeNotificationState, notificationResponseState, notificationsState } from '../../_state';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { notificationResponseForms } from './utils';
import styles from './styles';
import { useCallback } from 'react';

const NotificationFormResponse = () => {
  const notification = useRecoilValue(activeNotificationState);
  const resetActiveNotification = useResetRecoilState(activeNotificationState);
  const resetResponse = useResetRecoilState(notificationResponseState);
  const resetNotifications = useResetRecoilState(notificationsState);
  const closeResponseForm = useCallback(() => {
    resetResponse();
    resetActiveNotification();
  }, [resetResponse, resetActiveNotification]);
  const handleSuccess = useCallback(() => {
    closeResponseForm();
    resetNotifications();
  }, [closeResponseForm, resetNotifications]);

  const ResponseForm = notificationResponseForms[notification?.type];

  if (!notification?.key || !ResponseForm) return null;

  return (
    <Dialog
      open={Boolean(notification?.key)}
      onClose={closeResponseForm}
      maxWidth='md'
      PaperProps={{
        sx: styles.NotificationPaper
      }}
    >
      <DialogContent>
        <ResponseForm onSuccess={handleSuccess} notificationType={notification?.type} />
      </DialogContent>
    </Dialog>
  );
};

export default NotificationFormResponse;
