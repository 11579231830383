import { Bar, BarChart, CartesianGrid, Cell, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { DateFormat, NumberFormat } from '../../../../_helpers';

import BarTooltip from '../../FundingHistory/chart/tooltip';
import EmptyStateDisplay from '../../../EmptyStateDisplay';
import { Typography } from '@mui/material';
import { companyFundingHistoryGraphState } from '../../../../_state';
import styles from './styles';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { useTheme } from '@emotion/react';

const FundingHistoryChart = () => {
  const fundingHistoryGraphData = useRecoilValue(companyFundingHistoryGraphState);
  const reformattedGraphData = fundingHistoryGraphData?.map((dataPoint) => {
    return {...dataPoint, endDate: DateFormat.short(dataPoint.endDate)};
  });

  const theme = useTheme();
  const COLORS = {
    goal: theme.palette.primary.main,
    raisedGoal: theme.palette.gain.main,
  };

  const renderColorfulLegendText = useCallback(value => {
    return <Typography variant='caption' component='span'>{value}</Typography>;
  }, []);

  const formatMoneyTick = useCallback(value => {
    return NumberFormat.cents(value, {notation: 'compact'});
  }, []);

  if (!fundingHistoryGraphData?.length) {
    return <EmptyStateDisplay componentName='FundingHistoryChart' message='No funding history to display.' />;
  }

  return (
    <ResponsiveContainer height={322}>
      <BarChart
        cursor='pointer'
        data={reformattedGraphData}
        margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
        barGap={0}
      >
        <CartesianGrid strokeDasharray='1' vertical={false} />
        <Bar
          type='monotone'
          name='Raise Goal'
          dataKey='goal'
          fill={COLORS.goal}
        >
          {fundingHistoryGraphData?.map((entry) => (
            <Cell key={`cell-${entry.goal}`} />
          ))}
        </Bar>
        <Bar
          type='monotone'
          name='Amount Raised'
          dataKey='raisedGoal'
          fill={COLORS.raisedGoal}
        >
          {fundingHistoryGraphData?.map((entry) => (
            <Cell key={`cell-${entry.raisedGoal}`} />
          ))}
        </Bar>
        <XAxis
          dataKey='endDate'
          fontSize='0.75rem'
          tickLine={false}
          padding={{ left: 16, right: 16 }}
        />
        <YAxis
          dataKey={'raisedGoal'}
          fontSize='0.75rem'
          tickLine={false}
          axisLine={false}
          tickFormatter={formatMoneyTick}
        />
        <Tooltip
          content={BarTooltip}
          wrapperStyle={styles.TooltipWrapper}
          color={theme.palette.primary.main}
          cursor={{ stroke: 'none', fill: 'none' }}
        />
        <Legend
          formatter={renderColorfulLegendText}
          iconType='circle'
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default FundingHistoryChart;
