// skipcq: JS-C1003 - yup does not expose itself as an ES Module.
import * as Yup from 'yup';

import { VERIFICATION_DOC_FILES, VERIFICATION_DOC_FILE_USAGES } from '../../../../static-info';

import AddressSchema from '../../../../netcapital-components/AddressInput/schema';

const HoldingEntityBeneficialOwnersSchema = Yup.object({
  hasBeneficialOwners: Yup.boolean().required('Required'),
  beneficialOwners: Yup.array().of(Yup.object({
    address: AddressSchema,
    citizenship: Yup.string().required('Required'),
    dateOfBirth: Yup.string().required('Required'),
    email: Yup.string().email('Must be a valid email address').required('Required'),
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    ownershipPercentage: Yup.number()
      .min(10, 'Must be at least 10%')
      .max(100, 'Cannot exceed 100%')
      .transform((value) => (isNaN(value) || value === null || value === undefined) ? 0 : value)
      .required('Required'),
    residencyStatus: Yup.string().required('Required'),
    secureTin: Yup.object({
      tin: Yup.string().required('Required'),
    }).required('Required'),
    verificationDocType: Yup.string().oneOf(Object.keys(VERIFICATION_DOC_FILES)).required('Required'),
    verificationDocuments: Yup.array(),
    'verificationDocuments[0]': Yup.object({
      usage: Yup.string().oneOf(VERIFICATION_DOC_FILE_USAGES),
    }).when('verificationDocuments', ([verificationDocuments]) => {
      if (!verificationDocuments || verificationDocuments?.length === 0) {
        return Yup.object().required('Required');
      }
      return Yup.object().notRequired();
    }),
    'verificationDocuments[1]': Yup.object({
      usage: Yup.string().oneOf(VERIFICATION_DOC_FILE_USAGES),
    }).when('verificationDocType', ([verificationDocType]) => {
      if (['LICENSE', 'ID_CARD'].includes(verificationDocType)) {
        return Yup.object().required('Required');
      }
      return Yup.object().notRequired();
    })
  })),
});

export default HoldingEntityBeneficialOwnersSchema;
