import { Stack, Tab, Tabs } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import { useCallback, useEffect, useState } from 'react';

import { TABS } from './tabs';
import styles from './styles';
import { transactionsState } from '../../_state';
import { useLocation } from 'react-router-dom';
import { useResetRecoilState } from 'recoil';

const CompanyTabs = () => {
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState(TABS[0]?.id);
  const resetTransactions = useResetRecoilState(transactionsState);

  const handleChangeTab = useCallback((event, newCurrentTab) => {
    history.replaceState(undefined, undefined, `#${event.currentTarget.id}`);
    setCurrentTab(newCurrentTab);
    resetTransactions();
  },[resetTransactions]);

  useEffect(() => {
    const tab = TABS.find(t => t.id === location.hash.split('#')[1]);
    if (tab) {
      setCurrentTab(tab.id);
    }
  }, [location.hash]);

  return (
    <TabContext value={currentTab}>
      <Stack spacing={3}>
        <Tabs
          value={currentTab}
          onChange={handleChangeTab}
          scrollButtons
          allowScrollButtonsMobile
          variant='scrollable'
          sx={styles.CompanyTabs}
          name='CompanyTabs'
        >
          {TABS.map(tab => (
            <Tab
              value={tab.id}
              label={tab.label}
              key={`tab-toggle_${tab.label}`}
              id={tab.id}
              sx={styles.Tab}
            />
          ))}
        </Tabs>
        {TABS.map((tab) => (
          <TabPanel
            value={tab.id}
            hidden={currentTab !== tab.id}
            key={tab.id}
            sx={styles.TabContent}
          >
            {currentTab === tab.id && tab.content}
          </TabPanel>
        ))}
      </Stack>
    </TabContext>
  );
};

export default CompanyTabs;
