const styles = {
  cta: () => ({
    '&.MuiButtonBase-root:hover': {
      backgroundColor: 'transparent',
    },
    padding: 0,
  }),
};

export default styles;
