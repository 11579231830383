import BalanceIcon from '@mui/icons-material/Balance';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import { MenuItem } from '@mui/material';
import MenuLink from './MenuLink';
import RecentActorsOutlinedIcon from '@mui/icons-material/RecentActorsOutlined';
import TrendingUpSharpIcon from '@mui/icons-material/TrendingUpSharp';
import styles from './styles';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

const menuLinks = [
  {
    name: 'News',
    enabled: false,
    url: '/news',
    icon: <GridViewOutlinedIcon />
  },
  {
    name: 'Marketplace',
    url: '/marketplace',
    enabled: true,
    icon: <TrendingUpSharpIcon />
  },
  {
    name: 'Primary Offerings',
    url: `${process.env.REACT_APP_WEB_HOST}/companies`,
    enabled: true,
    icon: <LabelOutlinedIcon />
  },
  {
    name: 'Account',
    enabled: true,
    url: '/account',
    icon: <RecentActorsOutlinedIcon />
  },
  {
    name: 'Legal',
    enabled: true,
    url: '/legal',
    icon: <BalanceIcon />
  },
];

const Items = ({ handleMobileMenuToggle }) => {
  const location = useLocation();
  const isItemSelected = useCallback((urlPath) => location.pathname.startsWith(urlPath),[location]);

  return menuLinks.map((link) => {
    const styling = isItemSelected(link.url) ? [styles.MenuItemActive, styles.MenuItem] : styles.MenuItem;

    return (
      <MenuItem sx={link.enabled ? styling : styles.DisabledOption} key={link.name}>
        <MenuLink handleMobileMenuToggle={handleMobileMenuToggle} function={link.function} link={link} isSelected={isItemSelected(link.url)} />
      </MenuItem>
    );
  });
};

export default Items;
