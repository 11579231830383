import { FormControl, RadioGroup as MUIRadioGroup } from '@mui/material';

import FormFieldErrorMessage from '../FormValidation/FormFieldErrorMessage';
import PropTypes from 'prop-types';
import Radio from '../Radio';
import { memo } from 'react';
import { useFormFieldError } from '../FormValidation';

const DEFAULT_VALUE = 'initial';

const NCRadioGroup = (props) => {
  const { disabled, error, fieldName, onChange, options, row, value } = props;
  const errorMessage = useFormFieldError(fieldName);

  return (
    <FormControl variant='standard' error={Boolean(error || errorMessage)}>
      <MUIRadioGroup
        onChange={onChange}
        row={row}
        value={value}
      >
        {options.map((radio) => {
          return (
            <Radio
              key={radio.label}
              color={(error || errorMessage) ? 'error' : radio.color || 'primary'}
              disabled={disabled}
              label={radio.label}
              size={radio.size || 'medium'}
              value={radio.value}
            />
          );
        })}
      </MUIRadioGroup>
      <FormFieldErrorMessage>{errorMessage}</FormFieldErrorMessage>
    </FormControl>
  );
};

export const RadioGroup = memo(NCRadioGroup);

RadioGroup.defaultProps = {
  disabled: false,
  error: false,
  fieldName: '',
  row: true,
  value: DEFAULT_VALUE,
};

RadioGroup.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  fieldName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    color: PropTypes.oneOf(['primary', 'secondary', 'success', 'error', 'info', 'warning', 'grey']),
    label: PropTypes.string,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    value: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  })).isRequired,
  row: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
};

export default RadioGroup;
