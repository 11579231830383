export const defineEndDate = (activeDateRange, startDate) => {
  const day = new Date(startDate.getTime());

  if (activeDateRange === 'last-week') {
    day.setDate(startDate.getDate() - 7);
    return day;
  }

  if (activeDateRange === 'last-month') {
    day.setDate(startDate.getDate() - 30);
    return day;
  }

  if (activeDateRange === 'last-year') {
    day.setDate(startDate.getDate() - 365);
    return day;
  }

  day.setDate(startDate.getDate() - 1);
  return day;
};
