import { Box, Button, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { NumberFormat, pluralize } from '../../_helpers';
import { availableSharesByCompanyState, companyState, fetchCompanyAttribute, fetchOfferAttribute, fetchTemplumInvestorKycStatusAttribute, investorState, kycTradeModalState } from '../../_state';
import { useCallback, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import OfferModal from '../OfferModal';
import styles from './styles';
import { useGetSecuritiesByListingKey } from '../../_actions/securities.actions';

const OfferButtons = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const setOfferType = useSetRecoilState(fetchOfferAttribute('offerType'));
  const handleOpenOfferModal = useCallback((offerType) => {
    setIsModalOpen(true);
    setOfferType(offerType);
  }, [setOfferType]);
  const handleCloseOfferModal = useCallback(() => setIsModalOpen(false), []);
  const company = useRecoilValue(companyState);
  const investor = useRecoilValue(investorState);
  const {loading: loadingAvailableShares} = useGetSecuritiesByListingKey(investor.entityKey, company.key);
  const availableShares = useRecoilValue(availableSharesByCompanyState);
  const currentPrice = useRecoilValue(fetchCompanyAttribute('currentPrice'));
  const isSecondaryMarketOpen = useRecoilValue(fetchCompanyAttribute('status.isSecondaryMarketOpen'));

  const templumKycStatus = useRecoilValue(fetchTemplumInvestorKycStatusAttribute('status'));
  const setKycTradeModalIsOpen = useSetRecoilState(kycTradeModalState);
  const handleVerifyKycBeforeTrading = useCallback((offerType) => () => {
    if (templumKycStatus !== 'KYC_ACCEPTED') {
      setKycTradeModalIsOpen(true);
      return;
    }
    handleOpenOfferModal(offerType);
  },[templumKycStatus, handleOpenOfferModal, setKycTradeModalIsOpen]);

  return (
    <Grid item xs={12} container rowSpacing={2} columnSpacing={3} sx={styles.DisableDivider}>
      <Grid item xs={6} sx={styles.PriceAndPosition}>
        <Typography variant='body2' textTransform='uppercase'>Your Position</Typography>
        <Box>
          <Typography variant='h3' component='span'>
            {loadingAvailableShares && <CircularProgress size='1rem' />}
            {availableShares ? NumberFormat.thousands(availableShares) : 0} {pluralize('Share', availableShares)}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={6} sx={styles.PriceAndPosition}>
        <Typography variant='body2' textTransform='uppercase'>Last Price</Typography>
        <Typography variant='h3' color='gain.main'>
          {NumberFormat.cents(currentPrice, {minimumFractionDigits: 2})}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Stack>
          <Button
            onClick={handleVerifyKycBeforeTrading('ASK')}
            color='loss'
            variant='contained'
            disabled={!availableShares || !isSecondaryMarketOpen}
            size='large'
          >
            Sell
          </Button>
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Stack>
          <Button
            onClick={handleVerifyKycBeforeTrading('BID')}
            disabled={!isSecondaryMarketOpen}
            color='gain'
            variant='contained'
            size='large'
          >
            Buy
          </Button>
        </Stack>
      </Grid>
      {isModalOpen && <OfferModal onClose={handleCloseOfferModal} open={isModalOpen} />}
    </Grid>
  );
};

export default OfferButtons;
