import { Alert, Grid, Snackbar } from '@mui/material';
import { useCallback, useState } from 'react';
import { useGetTemplumFormKycStatus, useGetTemplumUserKycStatus } from '../../../_actions/kyc-templum.actions';
import { useLocation, useParams } from 'react-router-dom';

import AccountFormContent from './content';
import AccountFormContext from './context';
import AccountFormStepsNav from './nav';
import CircularLoading from '../../../netcapital-components/CircularLoading';
import { DateFormat } from '../../../_helpers';
import PropTypes from 'prop-types';
import styles from './styles';
import { templumFormKycStatusState } from '../../../_state';
import { useRecoilValue } from 'recoil';

const AccountForm = ({ steps, holdingEntityKey }) => {
  const {formId} = useParams();
  const {loaded: loadedGlobalKycStatus} = useGetTemplumUserKycStatus();
  const {loaded: loadedKycStatus} = useGetTemplumFormKycStatus(holdingEntityKey);
  const kycStatus = useRecoilValue(templumFormKycStatusState);
  const completion = kycStatus?.completion ? kycStatus?.completion : 0;
  const formProgress = kycStatus?.formProgress ? kycStatus?.formProgress : {};
  const [saved, setSaved] = useState(false);
  const activeStepNumber = Object.keys(steps).findIndex(stepId => stepId === formId);
  const activeStep = activeStepNumber > -1 ? steps[Object.keys(steps)[activeStepNumber]] : null;
  const hasNotSubmitKyc = Boolean(kycStatus?.status === 'KYC_NOT_SUBMITTED' || kycStatus?.status === 'KYC_CALL_TO_ACTION');
  const canSubmitFinalStep = Boolean(completion === 100 && hasNotSubmitKyc);
  const canDeleteHoldingEntity = Boolean(holdingEntityKey && hasNotSubmitKyc);
  const nextFormId = Object.keys(steps)[activeStepNumber+1];
  const finalStepNumber = Object.keys(steps)?.length-1;
  const isFinalStep = Boolean(activeStepNumber === finalStepNumber);
  const canProceedToNextForm = activeStepNumber+1 < finalStepNumber;
  const handleCloseSuccess = useCallback(() => setSaved(false), []);
  const handleSaveSuccess = useCallback(() => setSaved(true), []);
  const {search} = useLocation();

  const context = {
    activeStepNumber,
    canDeleteHoldingEntity,
    canProceedToNextForm,
    canSubmitFinalStep,
    completion,
    formProgress,
    onSaveSuccess: handleSaveSuccess,
    hasNotSubmitKyc,
    holdingEntityKey,
    finalStepNumber,
    isFinalStep,
    formId,
    nextFormId,
    nextFormPath: `../${nextFormId}${search}`,
    steps,
  };

  if (!loadedKycStatus || !loadedGlobalKycStatus) return <Grid container item xs={12} justifyContent={'center'}><CircularLoading /></Grid>;

  return (
    <AccountFormContext.Provider value={context}>
      <Grid container spacing={{md: 3}} item sx={styles.FormContainer}>
        <Grid item xs={12} md={2.5}>
          <AccountFormStepsNav />
        </Grid>
        {activeStep && (
          <Grid item xs={12} md={9.5}>
            <AccountFormContent
              component={activeStep.component}
              label={activeStep.label}
              disabled={!hasNotSubmitKyc}
            />
          </Grid>
        )}
        <Snackbar open={saved} autoHideDuration={6000} onClose={handleCloseSuccess}>
          <Alert severity='success' variant='filled'>Your changes were saved at {DateFormat.time(new Date().toISOString())}</Alert>
        </Snackbar>
      </Grid>
    </AccountFormContext.Provider>
  );
};

AccountForm.defaultProps = {
  holdingEntityKey: '',
};

AccountForm.propTypes = {
  holdingEntityKey: PropTypes.string,
  steps: PropTypes.objectOf(PropTypes.shape({
    component: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
  })).isRequired,
};

export default AccountForm;
