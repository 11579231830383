import { Link, Stack, Typography } from '@mui/material';
import { NumberFormat, pluralize } from '../../_helpers';
import {
  formatVerbiageByTransactionAttributes,
  getColoringByTransactionAttributes
} from '../TransactionsOverview/utils';

import PropTypes from 'prop-types';
import TransactionSchema from '../TransactionsOverview/Transaction/schema';
import { receiptModalState } from '../../_state';
import styles from '../TransactionsOverview/styles';
import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

const TransactionDetails = ({ transaction, onOpenReceipt, onOpenConfirmCancel }) => {
  const isPublished = transaction.offerStatus === 'PUBLISHED';
  const transactionVerbiage = formatVerbiageByTransactionAttributes(transaction.market, transaction.type, transaction.offerStatus);
  const conditionalTransactionColoring = getColoringByTransactionAttributes(transaction.market, transaction.type, transaction.offerStatus);

  const offerKey = transaction.market === 'SECONDARY' ? transaction.groupKey : transaction.offerKey;
  const setReceiptOfferKey = useSetRecoilState(receiptModalState);
  const handleOpenReceipt = useCallback(() => {
    onOpenReceipt();
    const url = new URL(window.location);
    url.searchParams.set('receipt', offerKey);
    window.history.pushState({}, '', url);
    setReceiptOfferKey(offerKey);
  }, [offerKey, setReceiptOfferKey, onOpenReceipt]);

  return (
    <Stack spacing={1}>
      <Typography
        variant='body2'
        color={conditionalTransactionColoring}
        sx={styles.Shares}
      >
        {transactionVerbiage} {NumberFormat.thousands(transaction.totalQuantity)} {pluralize('share', transaction.totalQuantity)}
      </Typography>
      <Typography variant='body2' component='div'>
        <Link onClick={handleOpenReceipt}>View Receipt</Link>
      </Typography>
      {isPublished && (
        <Typography variant='body2' component='div'>
          <Link onClick={onOpenConfirmCancel}>Cancel Transaction</Link>
        </Typography>
      )}
    </Stack>
  );
};

TransactionDetails.defaultProps = {
  onOpenReceipt: () => { return; },
  onOpenConfirmCancel: () => { return; },
};

TransactionDetails.propTypes = {
  ...TransactionSchema,
  onOpenReceipt: PropTypes.func,
  onOpenConfirmCancel: PropTypes.func,
};

export default TransactionDetails;
