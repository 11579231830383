import { Box, Stack, Typography } from '@mui/material';
import { activeCompanyCollectionState, companyCollectionsState, marketSearchState } from '../../../_state';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import BalanceIcon from '@mui/icons-material/BalanceOutlined';
import BrushIcon from '@mui/icons-material/BrushOutlined';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenterOutlined';
import CableIcon from '@mui/icons-material/CableOutlined';
import Card from '../../../netcapital-components/Card';
import Carousel from '../../../netcapital-components/Carousel';
import CommuteIcon from '@mui/icons-material/CommuteOutlined';
import DeckIcon from '@mui/icons-material/DeckOutlined';
import DiamondIcon from '@mui/icons-material/DiamondOutlined';
import DryCleaningIcon from '@mui/icons-material/DryCleaningOutlined';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjectsOutlined';
import EngineeringIcon from '@mui/icons-material/EngineeringOutlined';
import FactoryIcon from '@mui/icons-material/FactoryOutlined';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenterOutlined';
import GppGoodIcon from '@mui/icons-material/GppGoodOutlined';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafetyOutlined';
import ImageIcon from '@mui/icons-material/ImageOutlined';
import LocalLibraryIcon from '@mui/icons-material/LocalLibraryOutlined';
import LocalMoviesIcon from '@mui/icons-material/LocalMoviesOutlined';
import MemoryIcon from '@mui/icons-material/MemoryOutlined';
import ParkIcon from '@mui/icons-material/ParkOutlined';
import TrendingUpIcon from '@mui/icons-material/TrendingUpOutlined';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import styles from '../styles';
import { useCallback } from 'react';

const CATEGORY_ICONS = {
  'Balance': <BalanceIcon />,
  'Brush': <BrushIcon />,
  'BusinessCenter': <BusinessCenterIcon />,
  'Cable': <CableIcon />,
  'Commute': <CommuteIcon />,
  'Deck': <DeckIcon />,
  'Diamond': <DiamondIcon />,
  'DryCleaning': <DryCleaningIcon />,
  'EmojiObjects': <EmojiObjectsIcon />,
  'Engineering': <EngineeringIcon />,
  'Factory': <FactoryIcon />,
  'FitnessCenter': <FitnessCenterIcon />,
  'GppGood': <GppGoodIcon />,
  'HealthAndSafety': <HealthAndSafetyIcon />,
  'LocalLibrary': <LocalLibraryIcon />,
  'LocalMovies': <LocalMoviesIcon />,
  'Memory': <MemoryIcon />,
  'Park': <ParkIcon />,
  'TrendingUp': <TrendingUpIcon />,
  'WorkspacePremium': <WorkspacePremiumIcon />,
};

const getCategoryIcon = (iconName) => {
  return CATEGORY_ICONS[iconName] || <ImageIcon />;
};

const Categories = ({ resetQueryParams }) => {
  const categories = useRecoilValue(companyCollectionsState);
  const [activeCategory, setActiveCategory] = useRecoilState(activeCompanyCollectionState);
  const resetResults = useResetRecoilState(marketSearchState);
  const handleClickCategory = useCallback((e) => {
    if (e.currentTarget.id !== activeCategory) {
      resetResults();
      resetQueryParams();
      setActiveCategory(e.currentTarget.id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCategory]);

  if (!categories?.length) {
    return null;
  }

  return (
    <Carousel>
      {categories.map(category => (
        <Card elevation={0} onClick={handleClickCategory} id={category.key} name={category.name} key={category.key} sx={activeCategory === category.key ? [styles.Category, styles.ActiveCategory] : styles.Category}>
          <Stack direction="column" alignItems="center" justifyContent="center" sx={{ width: '100%' }}>
            <Box sx={activeCategory === category.key ? [styles.CategoryIconContainer, styles.ActiveCategoryIconContainer] : styles.CategoryIconContainer}>
              {getCategoryIcon(category.icon)}
            </Box>
            <Typography variant='subtitle2' sx={styles.CategoryName}>{category.name}</Typography>
          </Stack>
        </Card>
      ))}
    </Carousel>
  );
};

export default Categories;
