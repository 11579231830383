const styles = {
  TransactionsContainer: {
    position: 'relative',
  },
  DetailsContainer: {
    backgroundColor: 'common.white',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'grey.300',
    boxShadow: 1,
    padding: 2,
    '.MuiTooltip-arrow:before': {
      backgroundColor: 'common.white',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'grey.300',
      boxShadow: 1,
    },
  },
  DetailsToggle: {
    alignItems: 'start',
    padding: 0,
    paddingTop: '2px',
  },
  NoWrap: {
    whiteSpace: 'nowrap'
  },
  Progress: {
    position: 'absolute',
    top: 0,
    width: '100%',
    left: 0,
    height: '0.313rem'
  },
  TableCell: ({ columnName }) => {
    const headerWidthMap = {
      'Market': '150px',
      'No. of Shares': '150px',
      'Price Per Unit': '150px',
      'Total Amount': '150px',
      'Status': '150px',
      'Date/Time': '180px'
    };

    const cellStyles = {
      cursor: 'pointer',
      '&.MuiTableCell-head': {
        padding: '0px 16px 16px 0px',
      },
      '&.MuiTableCell-body': {
        padding: '16px 16px 16px 0px',
      }
    };

    cellStyles['&.MuiTableCell-head'].width = headerWidthMap[columnName];

    return cellStyles;
  },
  Loading: {
    position: 'absolute',
    background: 'rgba(255,255,255,0.85)',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
};

export default styles;
