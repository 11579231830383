import { Autocomplete, Avatar, Stack, TextField, Typography } from '@mui/material';
import { globalSearchBarState, staticCompanyCollections } from '../../../_state';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { DEFAULT_QUERY_PARAMS } from '../../../_actions/_api-urls';
import styles from './styles';
import { useGetListings } from '../../../_actions/market-search.actions';
import { useNavigate } from 'react-router-dom';

const SiteSearch = () => {
  const resultsSearchBar = useRecoilValue(globalSearchBarState);
  const resetResults = useResetRecoilState(globalSearchBarState);
  const navigate = useNavigate();

  const [queryParams, setQueryParams] = useState(DEFAULT_QUERY_PARAMS);
  const { loading } = useGetListings(staticCompanyCollections.TOP_TRADED.key, queryParams, true);
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState(null);

  const handleSearch = useCallback((event, newValue) => {
    setInputValue(newValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearSearch = useCallback(() => {
    setInputValue('');
    resetResults();
    setQueryParams({ ...queryParams, page: 1, search: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelect = useCallback((event, newValue) => {
    if (!newValue) {
      return;
    }

    setValue(newValue);
    resetResults();
    navigate(`/marketplace/${newValue?.code}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inputValue === '' || inputValue.length < 3) {
      return;
    }

    if (value?.doingBusinessAs === inputValue) {
      return;
    }

    setQueryParams({ ...queryParams, page: 1, search: inputValue });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, value, queryParams.search]);

  const getOptionLabel = useCallback((option) => (typeof option === 'string' ? option : option.doingBusinessAs), []);
  const isOptionEqualToValue = useCallback(() => {
    // Suppress warning about there being no valid matches,
    // since we want to display all options returned from API.
    return true;
  }, []);
  const showAll = useCallback(x => x, []); // prevents "No results" from displaying when it shouldn't
  const renderOption = useCallback((props, option) => (
    <Stack {...props} key={option.code} component='li' spacing={1} direction='row' sx={styles.Result}>
      <Avatar src={option.logoUrl} sx={styles.ResultAvatar} />
      <Typography variant='body2'>{option.doingBusinessAs}</Typography>
    </Stack>
  ), []);
  const renderInput = useCallback((params) => (
    <TextField
      {...params}
      variant='outlined'
      size='small'
      placeholder='Search...'
      inputProps={{
        ...params.inputProps,
        autoComplete: 'new-password' // disable autocomplete and autofill
      }}
    />
  ), []);

  return (
    <Autocomplete
      id='search-netcapital-bar'
      sx={styles.SearchBar}
      options={resultsSearchBar?.results || []}
      onInputChange={handleSearch}
      onChange={onSelect}
      value={inputValue || null}
      filterOptions={showAll}
      includeInputInList
      autoHighlight
      clearOnBlur
      clearOnEscape
      blurOnSelect
      onBlur={clearSearch}
      loading={loading}
      loadingText='Searching...'
      noOptionsText='No results'
      isOptionEqualToValue={isOptionEqualToValue}
      open={resultsSearchBar?.results?.length > 0 || loading || inputValue.length >= 3}
      popupIcon={null}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      renderInput={renderInput}
    />
  );
};

export default SiteSearch;
