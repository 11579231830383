import { FormControlLabel } from '@mui/material';
import PropTypes from 'prop-types';
import RadioInput from './input';
import React from 'react';
import styles from './styles';

const radio = (props) => {
  const { color, disabled, error, label, size, value } = props;
  return (
    <FormControlLabel
      control={<RadioInput color={color} size={size} />}
      disabled={disabled}
      label={label}
      value={value}
      sx={error ? styles.RadioError : {}}
    />
  );
};

export const Radio = React.memo(radio);

Radio.defaultProps = {
  color: 'primary',
  disabled: false,
  label: 'initial',
  size: 'medium',
  value: 'initial',
};

Radio.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'success', 'error', 'info', 'warning', 'grey']),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  value: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
};

export default Radio;
