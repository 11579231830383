import { NumberFormat, pluralize } from '../../_helpers';
import { Stack, Typography } from '@mui/material';

import { STF_TRANSACTION_FEE } from '../../static-info';
import { fetchOfferAttribute } from '../../_state';
import styles from './styles';
import { useRecoilValue } from 'recoil';

const OfferSummary = () => {
  const quantity = useRecoilValue(fetchOfferAttribute('quantity'));
  const pricePerUnit = useRecoilValue(fetchOfferAttribute('pricePerUnit'));
  const offerType = useRecoilValue(fetchOfferAttribute('offerType'));
  const isBid = offerType === 'BID';

  return (
    <Stack spacing={1}>
      <Stack direction='row'>
        <Typography variant='body1' sx={styles.RowLabel}>
          {NumberFormat.thousands(quantity)} {pluralize('share', quantity)} × {NumberFormat.cents(pricePerUnit, {minimumFractionDigits: 2})}
        </Typography>
        {NumberFormat.cents(quantity * pricePerUnit, {minimumFractionDigits: 2})}
      </Stack>
      <Stack direction='row' justifyContent="space-between">
        <Typography variant='body1' sx={styles.RowLabel}>{STF_TRANSACTION_FEE * 100}% Trade Commision*</Typography>
        {NumberFormat.cents(quantity * pricePerUnit * STF_TRANSACTION_FEE, {minimumFractionDigits: 2})}
      </Stack>
      <Typography variant='body2' color='grey.main' sx={styles.RowBottom}>{'* Paid to Templum Markets'}</Typography>
      <Stack direction='row' sx={styles.RowTotal}>
        <Typography variant='subtitle2' fontWeight='bold' sx={styles.RowLabel}>{isBid ? 'Total Order Amount' : 'Net Proceeds'}</Typography>
        {isBid ?
          NumberFormat.cents((quantity * pricePerUnit) + (quantity * pricePerUnit * STF_TRANSACTION_FEE), {minimumFractionDigits: 2})
          :
          NumberFormat.cents((quantity * pricePerUnit) - (quantity * pricePerUnit * STF_TRANSACTION_FEE), {minimumFractionDigits: 2})
        }
      </Stack>
    </Stack>
  );
};

export default OfferSummary;
