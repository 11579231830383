import { Tab, Tabs } from '@mui/material';
import { holdingEntitiesState, userState } from '../../_state';

import { Link as RouterLink } from 'react-router-dom';
import styles from './styles';
import { useRecoilValue } from 'recoil';

const AccountNav = ({ currentTab }) => {
  const holdingEntities = useRecoilValue(holdingEntitiesState);
  const user = useRecoilValue(userState);

  if (typeof holdingEntities === 'undefined') return null;

  return (
    <Tabs
      value={currentTab}
      variant='scrollable'
      allowScrollButtonsMobile
      sx={styles.AccountsNav}
    >
      <Tab
        value={'user'}
        label={`${user.firstName} ${user.lastName}`}
        LinkComponent={RouterLink}
        to={'/account'}
      />
      {holdingEntities?.length > 0 && holdingEntities.map(holdingEntity => (
        <Tab
          value={holdingEntity.entityKey}
          label={holdingEntity.legalName || 'Unnamed Entity'}
          LinkComponent={RouterLink}
          to={`/account?entity=${holdingEntity.entityKey}`}
          key={holdingEntity.entityKey}
        />
      ))}
    </Tabs>
  );
};

export default AccountNav;
