import { DateFormat, getVerificationDocConfigByUsage, renderBooleanAnswer } from '../../../../_helpers';

import { Grid } from '@mui/material';
import ReadOnlyField from '../../ReadOnlyField';
import ReadOnlyImageField from '../../ReadOnlyField/image';
import ReadOnlyStyles from '../../ReadOnlyField/styles';
import _ from 'lodash';
import { fetchHoldingEntityBeneficialOwnersAttribute } from '../../../../_state';
import { useRecoilValue } from 'recoil';

const BeneficialOwnersReadOnly = () => {
  const hasBeneficialOwners = useRecoilValue(fetchHoldingEntityBeneficialOwnersAttribute('hasBeneficialOwners'));
  const beneficialOwners = useRecoilValue(fetchHoldingEntityBeneficialOwnersAttribute('beneficialOwners'));

  return (
    <Grid item container xs={12} rowSpacing={1}>
      <ReadOnlyField
        label={'Does any person own more than 25% of the entity?'}
        value={renderBooleanAnswer(hasBeneficialOwners)}
      />
      {beneficialOwners?.map(person => (
        <Grid item xs={12} key={`${person.firstName}_${person.lastName}`} sx={ReadOnlyStyles.Row}>
          <ReadOnlyField
            label={'Name'}
            value={`${person.firstName} ${person.lastName}`}
          />
          <ReadOnlyField
            label={'Date of Birth'}
            value={DateFormat.short(person.dateOfBirth)}
          />
          <ReadOnlyField
            label={'SSN or TIN'}
            value={'*********'}
          />
          <ReadOnlyField
            label={'SSN or TIN'}
            value={'*********'}
          />
          {Object
            .keys(person.address || {})
            .filter(addressAttribute => addressAttribute !== 'key')
            .map(addressAttribute => <ReadOnlyField label={_.startCase(addressAttribute)} value={person.address[addressAttribute]} key={addressAttribute} />)
          }
          <ReadOnlyField
            label={'Email'}
            value={person.email}
          />
          {person?.verificationDocuments?.map(doc => (
            <Grid item container xs={12} rowSpacing={1} key={doc.usage}>
              <ReadOnlyImageField label={getVerificationDocConfigByUsage(doc.usage)?.label} src={doc.url} fileName={doc.name} />
            </Grid>
          ))}
        </Grid>
      ))}
    </Grid>
  );
};

export default BeneficialOwnersReadOnly;
