import { investorPortfolioGraphState, investorPortfolioValueState } from '../_state';
import { useEffect, useState } from 'react';

import { APIs } from './_api-urls';
import { defineEndDate } from './_utils';
import { useFetchWrapper } from '../_helpers';
import { useSetRecoilState } from 'recoil';

export function useGetPortfolioValue(investorKey) {
  const setPortfolioValue = useSetRecoilState(investorPortfolioValueState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    function abort() {
      controller.abort();
    }

    setLoaded(false);

    const url = `${
      APIs.Marketplace
    }/portfolio/${investorKey}/return`;
    fetchWrapper
      .get(url, null, signal)
      .then((response) => {
        if (!response) return; // aborted request gives response === undefined

        setPortfolioValue(response);
        setLoaded(true);
      })
      .catch(() => {
        setLoaded(true);
      });

    return abort;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investorKey]);

  return { loaded };
}

export function useGetPortfolioGraph(investorKey, activeDateRange) {
  const setPortfolioGraph = useSetRecoilState(investorPortfolioGraphState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    function abort() {
      controller.abort();
    }

    setLoaded(false);

    const today = new Date();
    const startDate = defineEndDate(activeDateRange, today);

    const queryParams = {
      startDate,
      endDate: today
    };

    const url = `${
      APIs.Marketplace
    }/portfolio/${investorKey}/graph?startdate=${queryParams.startDate.toISOString()}&enddate=${queryParams.endDate.toISOString()}`;
    fetchWrapper
      .get(url, null, signal)
      .then((response) => {
        if (!response) return; // aborted request gives response === undefined

        setPortfolioGraph(response);
        setLoaded(true);
      })
      .catch(() => {
        setLoaded(true);
      });

    return abort;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investorKey, activeDateRange]);

  return { loaded };
}
