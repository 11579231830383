import { Box, Stack, Typography } from '@mui/material';

import MoneyInputStepper from '../../netcapital-components/MoneyInputStepper';
import NumberInputStepper from '../../netcapital-components/NumberInputStepper';
import PropTypes from 'prop-types';
import { fetchOfferAttribute } from '../../_state';
import styles from './styles';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

const OfferInputs = ({ maxQuantity }) => {
  const [quantity, setQuantity] = useRecoilState(fetchOfferAttribute('quantity'));
  const handleChangeQuantity = useCallback((newValue) => {
    const isValidValue = newValue <= maxQuantity || maxQuantity === false;
    setQuantity(isValidValue ? newValue : maxQuantity);
  }, [setQuantity, maxQuantity]);

  const validQuantityValue = useCallback((values) => {
    const { floatValue } = values;
    if (maxQuantity === false) return true; // no limit
    if (floatValue === undefined) return true; // allow user to clear field
    return floatValue <= maxQuantity;
  }, [maxQuantity]);

  const [pricePerUnit, setPricePerUnit] = useRecoilState(fetchOfferAttribute('pricePerUnit'));
  const handleChangePricePerUnit = useCallback((newValue) => {
    setPricePerUnit(Math.max(newValue, 0));
    return null;
  }, [setPricePerUnit]);

  return (
    <Stack direction={{xs: 'column', sm: 'row'}} justifyContent='center' spacing={{xs: 1, sm: 2}} sx={styles.OfferValues}>
      <Box>
        <NumberInputStepper
          onChange={handleChangeQuantity}
          value={quantity}
          validateValue={validQuantityValue}
        />
        <Typography sx={styles.InputLabel}>Share(s)</Typography>
      </Box>
      <Typography sx={styles.At}>at</Typography>
      <Box>
        <MoneyInputStepper
          onChange={handleChangePricePerUnit}
          value={pricePerUnit}
        />
        <Typography sx={styles.InputLabel}>Price</Typography>
      </Box>
    </Stack>
  );
};

OfferInputs.defaultProps = {
  maxQuantity: false,
};

OfferInputs.propTypes = {
  maxQuantity: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

export default OfferInputs;
