import { TEN_MEGABYTES, VERIFICATION_DOC_FILE_TYPES } from '../../../static-info';
import { useCallback, useState } from 'react';
import { useDeleteVerificationDocument, useSaveVerificationDocument } from '../../../_actions/user.actions';

import Field from '../../FormFields/Field';
import FileUpload from '../../../netcapital-components/FileUpload';
import FormFieldErrorMessage from '../../../netcapital-components/FormValidation/FormFieldErrorMessage';
import PropTypes from 'prop-types';
import { useFormFieldValidation } from '../../../netcapital-components/FormValidation';
import { useRecoilState } from 'recoil';

const VerificationDocField = ({ recoilState, usage, label, fileType, allowedFileTypes, fieldName }) => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useRecoilState(recoilState);
  const {save, saving} = useSaveVerificationDocument();
  const {destroy: deleteFile, deleting} = useDeleteVerificationDocument();
  const [errorMessage, setErrorMessage] = useState();
  const {errorMessage: validationErrorMessage, eventListeners} = useFormFieldValidation(fieldName);

  const handleUpdateFile = useCallback(selectedFile => {
    eventListeners.onFocus();
    setLoading(true);
    if (!selectedFile) {
      setFile(null);
      if (file?.key) {
        deleteFile(file.key);
      }
      setLoading(false);
      return;
    }

    if (selectedFile.size > TEN_MEGABYTES) {
      setErrorMessage('File cannot be larger than 10 MB');
      setLoading(false);
      return;
    }

    setFile(selectedFile);
    save(usage, selectedFile);
    setLoading(false);
    setErrorMessage('');

  }, [deleteFile, eventListeners, file?.key, save, setFile, usage]);

  return (
    <Field label={label} name={`VerificationDoc_${usage}`}>
      <FileUpload
        fileType={fileType || VERIFICATION_DOC_FILE_TYPES.contentTypes.join(',')}
        allowedFileTypes={allowedFileTypes || VERIFICATION_DOC_FILE_TYPES.fileTypes.join(', ')}
        file={file}
        onChange={handleUpdateFile}
        loading={loading || saving || deleting}
        disabled={saving || deleting}
        showPreview
        maxSize={TEN_MEGABYTES}
      />
      <FormFieldErrorMessage>{errorMessage || validationErrorMessage}</FormFieldErrorMessage>
    </Field>
  );
};

VerificationDocField.defaultProps = {
  label: '',
  allowedFileTypes: '',
  fileType: '',
  fieldName: '',
};

VerificationDocField.propTypes = {
  recoilState: PropTypes.shape({key: PropTypes.string.isRequired}).isRequired,
  usage: PropTypes.string.isRequired,
  label: PropTypes.string,
  fileType: PropTypes.string,
  allowedFileTypes: PropTypes.string,
  fieldName: PropTypes.string,
};

export default VerificationDocField;
