import { Box, Link, Stack, Typography } from '@mui/material';
import { companyState, fetchOfferAttribute, fetchTemplumInvestorKycStatusAttribute, kycTradeModalState, offerState } from '../../../_state';
import { useCallback, useState } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import ListingSchema from './schema';
import { NumberFormat } from '../../../_helpers';
import OfferButton from './OfferButton';
import OfferModal from '../../OfferModal';
import { Link as RouterLink } from 'react-router-dom';
import styles from '../styles';

const Listing = ({ listing }) => {
  const setOfferType = useSetRecoilState(fetchOfferAttribute('offerType'));
  const setOfferPrice = useSetRecoilState(fetchOfferAttribute('pricePerUnit'));
  const setCompany = useSetRecoilState(companyState);
  const resetCompany = useResetRecoilState(companyState);
  const resetOfferState = useResetRecoilState(offerState);
  const templumKycStatus = useRecoilValue(fetchTemplumInvestorKycStatusAttribute('status'));
  const setKycTradeModalIsOpen = useSetRecoilState(kycTradeModalState);
  const [isOfferModalOpen, setIsOfferModalOpen] = useState(false);

  const handleOpenOfferModal = useCallback((offerType) => {
    resetOfferState();
    setOfferType(offerType);
    setOfferPrice(0);
    setCompany(listing);
    setIsOfferModalOpen(true);
  }, [setOfferPrice, setOfferType, resetOfferState, listing, setCompany]);

  const handleCloseOfferModal = useCallback(() => {
    resetOfferState();
    resetCompany();
    setIsOfferModalOpen(false);
  }, [resetOfferState, resetCompany]);

  const handleVerifyKycBeforeTrading = useCallback((offerType) => () => {
    if(templumKycStatus !== 'KYC_ACCEPTED'){
      setKycTradeModalIsOpen(true);
      return;
    }
    handleOpenOfferModal(offerType);
  },[templumKycStatus, handleOpenOfferModal, setKycTradeModalIsOpen]);

  const isDown = listing.percentageDelta < 0;
  const isUp = listing.percentageDelta > 0;

  return (
    <Box>
      <Link to={`/marketplace/${listing.code}`} component={RouterLink} color='common.black' sx={styles.ListingName}>
        {listing.doingBusinessAs}
      </Link>
      <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
        <Stack direction='row' spacing={1}>
          <Typography
            color='common.black'
            variant='body2'
            fontWeight='fontWeightLight'
          >
            {NumberFormat.cents(listing.currentPrice, {minimumFractionDigits: 2})}
          </Typography>
          <Typography
            color={isUp ? 'gain.main' : isDown ? 'loss.main' : 'common.black'}
            variant='body2'
            fontWeight='fontWeightLight'
          >
            {listing.percentageDelta === 0 ? '-' : NumberFormat.percent(listing.percentageDelta)}
          </Typography>
        </Stack>
        <Stack direction='row' spacing={1}>
          <OfferButton
            offerType='BID'
            onClick={handleVerifyKycBeforeTrading('BID')}
            isSecondaryMarketOpen={listing.status.isSecondaryMarketOpen}
            size='small'
          />
          <OfferButton
            offerType='ASK'
            onClick={handleVerifyKycBeforeTrading('ASK')}
            isSecondaryMarketOpen={listing.status.isSecondaryMarketOpen}
            size='small'
          />
        </Stack>
      </Stack>
      <OfferModal onClose={handleCloseOfferModal} open={isOfferModalOpen} />
    </Box>
  );
};

Listing.propTypes = ListingSchema;

export default Listing;
