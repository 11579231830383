import { NEGATIVE_PAGE_MARGIN_WIDTHS } from '../layout-styles';

const styles = {
  SidebarLayoutContainer: {
    paddingBottom: {
      xs: 9,
      lg: 4,
    },
  },
  LayoutSlot: {
    '& .MuiDivider-root': {
      marginLeft: NEGATIVE_PAGE_MARGIN_WIDTHS,
      marginRight: NEGATIVE_PAGE_MARGIN_WIDTHS,
    },
  },
  MobileDivider: {
    borderBottomWidth: '10px',
    marginTop: 4,
    display: {
      lg: 'none',
    },
  },
};

export default styles;
