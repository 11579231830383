import { atom, selectorFamily } from 'recoil';

const announcementsState = atom({
  key: 'announcementsState',
  default: []
});

const fetchAnnouncements = selectorFamily({
  key: 'fetchAnnouncements',
  get: field => ({get}) => get(announcementsState)[field],
  set: field => ({set}, newValue) =>
    set(announcementsState, prevState => ({...prevState, [field]: newValue})),
});

export {
  announcementsState,
  fetchAnnouncements
};
