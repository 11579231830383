import { useCallback, useContext, useState } from 'react';

import FormContext from './FormContext';
import _ from 'lodash';

export const useFormFieldError = (fieldName) => {
  const {errors} = useContext(FormContext);
  return _.get(errors, fieldName);
};

export const useFormFieldValidator = (fieldName) => {
  const {validateField} = useContext(FormContext);
  return validateField(fieldName);
};

export const useFormFieldErrorReset = (fieldName) => {
  const {removeError} = useContext(FormContext);
  return removeError(fieldName);
};

export const useFormFieldValidation = (fieldName) => {
  const validateField = useFormFieldValidator(fieldName);
  const removeError = useFormFieldErrorReset(fieldName);
  const [fieldTouched, setFieldTouched] = useState(false);
  const handleFieldBlur = useCallback(() => validateField(), [validateField]);
  const handleFieldFocus = useCallback(() => {
    setFieldTouched(true);
    removeError();
  }, [removeError]);

  return {
    errorMessage: useFormFieldError(fieldName),
    validateField,
    isTouched: fieldTouched,
    eventListeners: {
      onFocus: handleFieldFocus,
      onBlur: handleFieldBlur,
    },
  };
};
