import { Box, Chip, FormControl, MenuItem, Select, Typography } from '@mui/material';

import FormFieldErrorMessage from '../FormValidation/FormFieldErrorMessage';
import PropTypes from 'prop-types';
import styles from './styles';
import { useCallback } from 'react';
import { useFormFieldValidation } from '../FormValidation';

function Selectbox(props) {
  const {
    onChange,
    options,
    value,
    placeholder,
    multiple,
    error,
    fieldName,
    size,
    ...other
  } = props;
  const {errorMessage, eventListeners} = useFormFieldValidation(fieldName);

  const setSelectRenderValues = useCallback((selected) => {
    //default state (no selected)
    if (selected?.length === 0) {
      return <Typography variant='body1' sx={styles.Placeholder}>{placeholder}</Typography>;
    }

    //multi-select
    if (multiple) {
      const selectedOptions = options.filter(option => selected.includes(option.value));
      return (
        <Box sx={styles.MultiSelectValues}>
          {selectedOptions.map((option) => (
            <Chip key={option.value} label={option.label} />
          ))}
        </Box>
      );
    }

    //single-select
    const displayValue = options.find(option => option.value === selected);
    if (displayValue) {
      return displayValue.label;
    }

    return selected;
  },[placeholder, options, multiple]);

  if (!options.length) return null;

  return (
    <FormControl error={error || Boolean(errorMessage)} sx={styles.FormControl} size={size}>
      <Select
        value={value}
        onChange={onChange}
        displayEmpty
        multiple={multiple || false}
        renderValue={setSelectRenderValues}
        sx={multiple ? styles.SelectMultiple : styles.Select}
        MenuProps={{
          sx: styles.SelectMenu
        }}
        {...eventListeners}
        {...other}
      >
        {options.map((option) => (
          <MenuItem
            value={option.value}
            sx={styles.MenuItemTextWrap}
            key={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormFieldErrorMessage>{errorMessage}</FormFieldErrorMessage>
    </FormControl>
  );
}

Selectbox.defaultProps = {
  options: [],
  value: '',
  placeholder: '',
  multiple: false,
  fieldName: '',
  error: false,
  size: 'medium',
};

Selectbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string.isRequired
  })),
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  ]),
  multiple: PropTypes.bool,
  fieldName: PropTypes.string,
  error: PropTypes.bool,
  size: PropTypes.string,
};

export default Selectbox;
