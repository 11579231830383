import { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { APIs } from './_api-urls';
import { notificationResponseState } from '../_state';
import { useFetchWrapper } from '../_helpers';

export const useGetMaterialChangeResponse = (materialChangeResponseKey) => {
  const [notificationResponse, setNotificationResponse] = useRecoilState(notificationResponseState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const abort = () => controller.abort();

    if (!materialChangeResponseKey) return null;

    if (Object.keys(notificationResponse).length > 0) {
      setLoaded(true);
      return abort;
    }

    const url = `${APIs.Offerings}/material-change-responses/${materialChangeResponseKey}`;
    fetchWrapper.get(url, null, signal, false)
      .then((response) => {
        if (!response) return;
        setNotificationResponse(response);
        setLoaded(true);
      })
      .catch(() => setLoaded(true));

    return abort;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materialChangeResponseKey]);

  return {loaded};
};

export const useSubmitMaterialChangeResponse = () => {
  const notificationResponse = useRecoilValue(notificationResponseState);
  const resetNotificationResponse = useResetRecoilState(notificationResponseState);
  const fetchWrapper = useFetchWrapper();
  const [saving, setSaving] = useState(false);

  const save = useCallback((answer, onSubmit) => {
    const controller = new AbortController();
    const signal = controller.signal;
    const abort = () => controller.abort();

    setSaving(true);

    const url = `${APIs.Offerings}/material-change-responses/${notificationResponse?.key}/process`;
    const body = {
      ...notificationResponse,
      response: answer,
    };
    fetchWrapper.post(url, body, signal, false)
      .then((response) => {
        if (!response) return;
        resetNotificationResponse();
        setSaving(false);
        if (typeof onSubmit === 'function') onSubmit();
      })
      .catch(() => setSaving(false));

    return abort;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationResponse]);

  return { save, saving };
};
