import { Box, Dialog, DialogContent, IconButton } from '@mui/material';
import { useCallback, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import ParentComment from './parent-comment';
import { commentState } from '../../../_state';
import styles from './styles';
import { useGetComment } from '../../../_actions/company-discussion.actions';
import { useRecoilValue } from 'recoil';
import { useSearchParams } from 'react-router-dom';

const CommentModal = (props) => {
  const {listingKey} = props;
  const [searchParams] = useSearchParams();
  const commentKey = searchParams.get('commentkey');
  const {loaded} = useGetComment(listingKey, commentKey);
  const comment = useRecoilValue(commentState);
  const [modalOpen, setModalOpen] = useState(true);
  const closeModal = useCallback(() => setModalOpen(false), []);

  if (!commentKey || Object.keys(comment).length === 0) {
    return null;
  }

  return (
    <Dialog open={modalOpen} onClose={closeModal}>
      {loaded && (
        <Box>
          <IconButton onClick={closeModal} sx={styles.CloseButton}>
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <ParentComment comment={comment} listingKey={listingKey} onReply={closeModal} />
          </DialogContent>
        </Box>
      )}
    </Dialog>
  );
};

export default CommentModal;
