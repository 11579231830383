const styles = {
  PersonContainer: {
    padding: 2,
    paddingLeft: 0,
  },
  GovtAffiliationsContainer: {
    '&.MuiGrid-root':{
      paddingTop: '1.25rem',
      paddingBottom: '1rem',
    },
  },
};

export default styles;
