const styles = {
  ErrorMessage: {
    alignItems: 'center',
  },
  ErrorMessageIcon: {
    fontSize: '0.875rem',
  },
};

export default styles;
