import { IconButton, InputAdornment } from '@mui/material';
import { useCallback, useContext, useRef, useState } from 'react';

import AnnouncementsQueryContext from './context';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import TextField from '../../../netcapital-components/TextField';

const SearchAnnouncements = () => {
  const {queryParams, setQueryParams} = useContext(AnnouncementsQueryContext);
  const [searchTerms, setSearchTerms] = useState('');
  const prevSearch = useRef('');
  const handleChangeSearchTerm = useCallback((e) => setSearchTerms(e.target.value), []);
  const handleSearch = useCallback((e) => {
    e.preventDefault();
    const sanitized = searchTerms.trim();
    setSearchTerms(sanitized);
    prevSearch.current = sanitized;
    setQueryParams({
      ...queryParams,
      search: sanitized,
      page: 1,
    });
  }, [queryParams, searchTerms, setQueryParams]);
  const clearSearch = useCallback(() => {
    setSearchTerms('');
    setQueryParams({
      ...queryParams,
      search: '',
      page: 1,
    });
  }, [queryParams, setQueryParams]);

  return (
    <form onSubmit={handleSearch}>
      <TextField
        value={searchTerms}
        onChange={handleChangeSearchTerm}
        variant='outlined'
        placeholder='Search...'
        inputProps={{
          autoComplete: 'new-password' // disable autocomplete and autofill
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              {searchTerms.length > 0 && (
                <IconButton onClick={clearSearch}>
                  <CancelOutlinedIcon />
                </IconButton>
              )}
              {searchTerms.length === 0 && <SearchOutlinedIcon />}
            </InputAdornment>
          ),
        }}
      />
    </form>
  );
};

export default SearchAnnouncements;
