import AccountForm from '../Form';
import BeneficialOwners from './BeneficialOwners';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import HoldingEntityDocuments from './HoldingDocuments';
import HoldingEntityInfo from './HoldingInfo';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import IdentityFiles from '../User/IdentityVerification';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import PersonalInfo from '../User/PersonalInfo';
import PropTypes from 'prop-types';
import PsychologyAltOutlinedIcon from '@mui/icons-material/PsychologyAltOutlined';
import ReviewAndSubmit from '../ReviewAndSubmit';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';

const steps = {
  'basic-info': {
    component: HoldingEntityInfo,
    label: 'Entity Information',
    icon: <HomeWorkOutlinedIcon fontSize='small' />,
  },
  'holding-documents': {
    component: HoldingEntityDocuments,
    label: 'Entity Documents',
    icon: <InsertDriveFileOutlinedIcon fontSize='small' />,
  },
  'authorized-representative': {
    component: PersonalInfo,
    label: 'Personal Information',
    icon: <PsychologyAltOutlinedIcon fontSize='small' />,
  },
  'authorized-representative-verification': {
    component: IdentityFiles,
    label: 'Identity Verification',
    icon: <FingerprintIcon fontSize='small' />,
  },
  'beneficial-owners': {
    component: BeneficialOwners,
    label: 'Beneficial Owners',
    icon: <SentimentSatisfiedAltIcon fontSize='small' />,
  },
  'review': {
    component: ReviewAndSubmit,
    label: 'Review and Submit',
    icon: <RocketLaunchOutlinedIcon fontSize='small' />,
  },
};

const HoldingEntityAccount = ({ holdingEntityKey }) => {

  return (
    <AccountForm
      holdingEntityKey={holdingEntityKey}
      steps={steps}
    />
  );
};

HoldingEntityAccount.propTypes = {
  holdingEntityKey: PropTypes.string.isRequired,
};

export default HoldingEntityAccount;
