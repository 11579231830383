const styles = {
  Security: {
    margin: '0.5rem 0',
    paddingLeft: 0,
  },
  DisabledExemption: ({ palette }) => ({
    backgroundColor: palette.error.light,
    width: '1.25rem',
    height: '1.25rem',
    padding: '0.25rem',
    borderRadius: '1.25rem',
    color: palette.error.main
  }),
  EnabledExemption: ({ palette }) => ({
    backgroundColor: palette.success.light,
    width: '1.25rem',
    height: '1.25rem',
    padding: '0.25rem',
    borderRadius: '1.25rem',
    color: palette.success.main
  }),
  CompanyName: () => ({
    textDecoration: 'none',
  }),
  ColumnLeft: {
    flexGrow: 1,
  },
  ColumnRight: {
    textAlign: 'right',
  },
  Shares: ({ breakpoints, palette }) => ({
    marginLeft: '0.5rem',
    color: palette.text.light,
    fontSize: '0.75rem',
    weight: '400',
    [breakpoints.down('md')]: {
      marginLeft: 0,
    },
  }),
  FiltersBox: () => ({
    marginBottom: '0.5rem'
  }),
  FiltersButtons: () => ({
    textTransform: 'capitalize',
    minWidth: 'fit-content',
    padding:'0 1rem',
    height:'2.5rem',
    margin: '0 0.5rem',
    borderRadius: '1.875rem',
    '&:first-of-type': {
      marginLeft: 0,
    },
  }),
};

export default styles;
