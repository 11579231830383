import { ReactComponent as ChartSvg } from '../../assets/chart.svg';
import { ReactComponent as CreditCardSvg } from '../../assets/credit-card.svg';
import { ReactComponent as EmptyBoxSvg } from '../../assets/empty-box.svg';
import { ReactComponent as NullResultsSvg } from '../../assets/null-results.svg';
import { ReactComponent as WalletSvg } from '../../assets/wallet.svg';

export const SVG_COMPONENTS_EMPTY_STATES = {
  'Announcements': <NullResultsSvg />,
  'FundingHistoryChart': <ChartSvg />,
  'InvestorTransactions': <CreditCardSvg />,
  'Notifications': <NullResultsSvg />,
  'PendingOffers': <EmptyBoxSvg />,
  'Securities': <WalletSvg />,
  'Transactions': <CreditCardSvg />,
};
