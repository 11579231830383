import {
  Box,
  Collapse,
  LinearProgress,
  Link,
  CardContent as MUICardContent,
  CardHeader as MUICardHeader,
  Typography as MUITypography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import Card from '../../netcapital-components/Card';
import EmptyStateDisplay from '../EmptyStateDisplay';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PropTypes from 'prop-types';
import styles from './styles';
import { useIsDesktop } from '../../_helpers';

const ExpandAction = ({ action, canCollapse, isExpanded, handleExpandClick }) => {
  if (action) {
    return <Box display='flex' justifyContent='center'>{action}</Box>;
  }

  if (!canCollapse){
    return null;
  }

  return (
    <Box display='flex' justifyContent='center'>
      <Link onClick={handleExpandClick} sx={styles.expand}>
        {isExpanded ? <KeyboardArrowUpIcon sx={styles.expandIcon} /> : <KeyboardArrowDownIcon sx={styles.expandIcon} />}
      </Link>
    </Box>
  );
};

const CardContent = ({ loaded, content, componentName, emptyMessage }) => {
  if (loaded && content){
    return content;
  }

  // Need to use EmptyStateDisplay directly inside Securities component instead of Card/CardContent to retain ability to see securities filters. For other components that use Card, just show EmptyState component when loaded && !content.
  if (loaded && !content && componentName !== 'Securities'){
    return <EmptyStateDisplay componentName={componentName} message={emptyMessage} />;
  }

  return content;
};

const Section = (props) => {
  const {
    action,
    canCollapse,
    componentName,
    content,
    emptyMessage,
    expanded,
    loaded,
    onAction,
    sxCard,
    sxContent,
    title
  } = props;

  const [isExpanded, setExpanded] = useState(expanded);
  const handleExpandClick = useCallback(() => {
    if (!canCollapse) return;
    onAction(!isExpanded);
    setExpanded(!isExpanded);
  }, [isExpanded, canCollapse, onAction]);

  useEffect(() => {
    setExpanded(expanded);
  }, [expanded]);

  let cardStyles = [];
  if (Array.isArray(sxCard)){
    cardStyles = [styles.Card, ...sxCard];
  }else{
    cardStyles = [styles.Card, sxCard];
  }

  const isDesktop = useIsDesktop();
  const cardElevation = isDesktop ? 1 : 0;

  return (
    <Card sx={cardStyles} elevation={cardElevation}>
      {!loaded && <LinearProgress sx={styles.ProgressTop} />}
      {title && (
        <MUICardHeader
          action={<ExpandAction canCollapse={canCollapse} action={action} isExpanded={isExpanded} handleExpandClick={handleExpandClick} />}
          title={typeof title === 'string'
            ? <MUITypography variant='cardTitle'>{title}</MUITypography>
            : title
          }
        />
      )}
      <Collapse in={isExpanded} timeout='auto' unmountOnExit>
        <MUICardContent sx={sxContent}>
          <CardContent loaded={loaded} content={content} componentName={componentName} emptyMessage={emptyMessage} />
        </MUICardContent>
      </Collapse>
      {!loaded && <LinearProgress sx={styles.ProgressBottom} />}
    </Card>
  );
};

Section.defaultProps = {
  action: false,
  canCollapse: true,
  componentName: '',
  content: false,
  emptyMessage: '',
  expanded: true,
  onAction: () => { return; },
  sxCard: () => { return; },
  sxContent: () => { return; },
  title: ''
};

Section.propTypes = {
  action: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  canCollapse: PropTypes.bool,
  componentName: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  emptyMessage: PropTypes.string,
  expanded: PropTypes.bool,
  loaded: PropTypes.bool.isRequired,
  onAction: PropTypes.func,
  sxCard: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.func)]),
  sxContent: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.func)]),
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

export default Section;
