import { atom, selector } from 'recoil';

const commentsState = atom({
  key: 'commentsState',
  default: {
    data: [],
    metaData: {},
  }
});

const fetchComments = selector({
  key: 'fetchComments',
  get: ({get}) => get(commentsState).data,
  set: ({set}, newValue) =>
    set(commentsState, prevState => ({...prevState, data: newValue})),
});

const fetchCommentsMetaData = selector({
  key: 'fetchCommentsMetaData',
  get: ({get}) => get(commentsState).metadata,
  set: ({set}, newValue) =>
    set(commentsState, prevState => ({...prevState, metadata: newValue})),
});

const commentState = atom({
  key: 'commentState',
  default: {}
});

export {
  commentsState,
  commentState,
  fetchComments,
  fetchCommentsMetaData,
};
