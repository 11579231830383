const styles = {
  OfferModalContent: {
    padding: {
      xs: 2,
      sm: 4,
    },
    margin: '0 auto',
    maxWidth: 'md',
  },
  Tab: {
    paddingTop: 0,
  },
  TabAsk: {
    width: '50%',
    '&.Mui-selected': {
      color: 'loss.main',
    },
  },
  TabBid: {
    width: '50%',
    '&.Mui-selected': {
      color: 'gain.main',
    },
  },
  OfferModalActions: ({ spacing }) => ({
    margin: '0 auto',
    maxWidth: 'md',
    width: '100%',
    padding: {
      xs: 2,
      sm: 4,
    },
    paddingTop: 2,
    justifyContent: 'center',
    gap: spacing(3),
  }),
  ActionButton: {
    width: '100%',
  },
};

export default styles;
