const format = (value=0, options={}) => {
  return new Intl.NumberFormat('en-US', options).format(value);
};

const dollars = (value=0, options={}) => {
  const defaultOptions = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: value % 1 !== 0 ? 2 : 0,
  };
  return format(value, {...defaultOptions, ...options});
};

const cents = (value=0, options={}) => {
  return dollars(value / 100, options);
};

const thousands = (value=0, options={}) => {
  const defaultOptions = {
    style: 'decimal',
  };
  return format(value, {...defaultOptions, ...options});
};

const percent = (value=0, options={}) => {
  const defaultOptions = {
    style: 'percent',
  };
  return format(value, {...defaultOptions, ...options});
};

export {
  dollars,
  cents,
  thousands,
  percent,
};
