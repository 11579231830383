import { Box, Drawer, IconButton, Stack } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import MainMenuContent from './content';
import NetcapitalLogo from '../../../netcapital-components/NetcapitalLogo';
import { styled } from '@mui/material/styles';
import styles from './styles';

const DrawerHeader = styled(Box)(styles.MainMenu);

const MainMenu = ({ isMobile, open, handleMobileMenuToggle }) => {
  if (!isMobile) {
    return <MainMenuContent />;
  }

  return (
    <Drawer
      open={open}
      onClose={handleMobileMenuToggle}
      variant='temporary'
      anchor='left'
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      PaperProps={{
        sx: styles.Drawer,
      }}
    >
      <DrawerHeader sx={styles.MainMenuContainer}>
        <Stack spacing={2} direction='row'>
          <IconButton onClick={handleMobileMenuToggle} sx={styles.HeaderMobileMenuClose} disableRipple>
            <CloseIcon />
          </IconButton>
          <NetcapitalLogo sx={styles.HeaderMobileMenuLogo} color='common.white' />
        </Stack>
      </DrawerHeader>
      <MainMenuContent isMobile={isMobile} handleMobileMenuToggle={handleMobileMenuToggle} />
    </Drawer>
  );
};

export default MainMenu;
