import { Link, Stack, TextField } from '@mui/material';
import { memo, useCallback, useContext, useState } from 'react';

import CommentsQueryContext from './context';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { companyState } from '../../../_state';
import { useCreateComment } from '../../../_actions/company-discussion.actions';
import { useRecoilValue } from 'recoil';

const CommentForm = (props) => {
  const { parentCommentKey, onSuccess } = props;
  const isReply = Boolean(parentCommentKey);

  const [newCommentBody, setNewCommentBody] = useState('');
  const handleChangeComment = useCallback((e) => setNewCommentBody(e.currentTarget.value), [setNewCommentBody]);

  const company = useRecoilValue(companyState);
  const { save, saving } = useCreateComment(company.key);
  const {setQueryParams} = useContext(CommentsQueryContext);

  const handleSuccess = useCallback(() => {
    setNewCommentBody('');
    onSuccess();
    if (!parentCommentKey) {
      // This is not a reply, so refresh comments from the API.
      setQueryParams({page: 1});
    }
  }, [onSuccess, parentCommentKey, setQueryParams]);

  const handleCancel = useCallback(() => {
    handleSuccess();
  }, [handleSuccess]);

  const handleFormSubmit = useCallback(() => {
    if (!saving) {
      save(newCommentBody, parentCommentKey, handleSuccess);
    }
  }, [saving, save, newCommentBody, parentCommentKey, handleSuccess]);

  return (
    <Stack spacing={2}>
      <TextField
        value={newCommentBody}
        onChange={handleChangeComment}
        name='comment'
        multiline
        rows={10}
        placeholder='Type your question or comment here'
      />
      <Stack direction='row' spacing={2} alignItems='center'>
        <LoadingButton
          onClick={handleFormSubmit}
          disabled={newCommentBody.length === 0 || saving}
          loading={saving}
          variant='contained'
        >
          Post {isReply ? 'Reply' : 'Comment'}
        </LoadingButton>
        {newCommentBody.length > 0 && <Link onClick={handleCancel} color='grey.500'>Cancel</Link>}
      </Stack>
    </Stack>
  );
};

CommentForm.defaultProps = {
  parentCommentKey: null,
  onSuccess: () => { return; },
};

CommentForm.propTypes = {
  parentCommentKey: PropTypes.string,
  onSuccess: PropTypes.func,
};

export default memo(CommentForm);
