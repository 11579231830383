import PropTypes from 'prop-types';

const TransactionSchema = {
  onCancelTransaction: PropTypes.func.isRequired,
  transaction: PropTypes.shape({
    failureReason: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    groupKey: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    listing: PropTypes.shape({
      code: PropTypes.string.isRequired,
      company: PropTypes.shape({
        doingBusinessAs: PropTypes.string,
        key: PropTypes.string.isRequired,
        legalName: PropTypes.string.isRequired,
      }),
      logoUrl: PropTypes.string
    }).isRequired,
    market: PropTypes.string.isRequired,
    modified: PropTypes.string.isRequired,
    offerKey: PropTypes.string.isRequired,
    offerStatus: PropTypes.string.isRequired,
    pricePerUnit: PropTypes.number.isRequired,
    totalQuantity: PropTypes.number.isRequired,
    totalValue: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired
  }).isRequired
};

export default TransactionSchema;
