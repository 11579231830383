import { useEffect, useState } from 'react';

import { APIs } from './_api-urls';
import { useFetchWrapper } from '../_helpers';
import { useRecoilState } from 'recoil';
import { walletState } from '../_state';

export function useGetWalletBalance(entityKey='') {
  const [walletBalance, setWalletBalance] = useRecoilState(walletState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    function abort(){ controller.abort();}

    if (walletBalance !== undefined) {
      setLoaded(true);
      return abort;
    }

    const url = `${APIs.Marketplace}/portfolio/wallet`;
    fetchWrapper.get(url, null, signal, false)
      .then((response) => {
        if (!response) return; // aborted request gives response === undefined
        setWalletBalance(response);
        setLoaded(true);
      })
      .catch(() => {
        setWalletBalance(0);
        setLoaded(true);
      });

    return abort;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityKey, walletBalance]);

  return { loaded };
}
