import { useCallback, useEffect, useState } from 'react';
import { useResetRecoilState, useSetRecoilState } from 'recoil';

import { APIUserURL } from './_api-urls';
import { companyIsFollowedState } from '../_state';
import { useFetchWrapper } from '../_helpers';

export function useGetCompanyIsFollowed(userKey, listingKey) {
  const setIsFollowed = useSetRecoilState(companyIsFollowedState);
  const resetIsFollowed = useResetRecoilState(companyIsFollowedState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    function abort(){ controller.abort();}

    resetIsFollowed();

    const url = `${APIUserURL(userKey)}/followed-listings/${listingKey}`;
    fetchWrapper.get(url, null, signal)
      .then((response) => {
        if (!response) return; // aborted request gives response === undefined
        setIsFollowed(response.isFollowed);
        setLoaded(true);
      })
      .catch(() => setLoaded(true));
    return abort;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userKey, listingKey]);

  return { loaded };
}

export function usePutCompanyIsFollowed(userKey, listingKey) {
  const setIsFollowed = useSetRecoilState(companyIsFollowedState);
  const fetchWrapper = useFetchWrapper();
  const [saving, setSaving] = useState(false);

  const save = useCallback(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    function abort(){ controller.abort();}

    setSaving(true);

    const url = `${APIUserURL(userKey)}/followed-listings/${listingKey}`;
    fetchWrapper.put(url, null, signal)
      .then((response) => {
        if (!response) return; // aborted request gives response === undefined
        setIsFollowed(response.isFollowed);
        setSaving(false);
      });
    return abort;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userKey, listingKey]);

  return { saving, save };
}
