import { prepareQueryString, useFetchWrapper } from '../_helpers';
import { useEffect, useState } from 'react';

import { APIListingURL } from './_api-urls';
import { announcementsState } from '../_state';
import { useRecoilState } from 'recoil';

export function useGetAnnouncements(listingKey, queryParams, appendOnFetch=true) {
  const [announcements, setAnnouncements] = useRecoilState(announcementsState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    function abort(){ controller.abort(); }

    setLoaded(false);

    const url = `${APIListingURL(listingKey)}/announcements?${prepareQueryString(queryParams)}`;
    fetchWrapper.get(url, null, signal)
      .then((response) => {
        if (!response) return; //aborted request gives response === undefined
        let data = response.data;

        if (appendOnFetch && announcements?.data?.length && queryParams?.page > 1) {
          data = [...announcements.data, ...response.data];
        }

        setAnnouncements({ data, metadata: response.metadata });
        setLoaded(true);
      })
      .catch(() => {
        setLoaded(true);
      });

    return abort;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingKey, queryParams, appendOnFetch]);

  return { loaded };
}
