import BUTTONS from './buttons';
import palette from '../palette';
import typography from '../typography';

const components = {
  MuiAppBar: {
    variants: [
      {
        props: { variant: 'light' },
        style: ({ ownerState, theme }) => ({
          backgroundColor: palette.common.white,
          boxShadow: theme.shadows[ownerState.elevation]
        })
      }
    ],
  },
  MuiButton: {
    variants: BUTTONS,
  },
  MuiTableHead: {
    styleOverrides: {
      root: {
        '& .MuiTableCell-root': {
          paddingBottom: 0,
        },
      },
    },
  },
  MuiCard: {
    defaultProps: {
      elevation: 3,
    },
    styleOverrides: {
      root: {
        borderRadius: '10px',
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        cursor: 'pointer',
        fontWeight: typography.fontWeightMedium,
      }
    }
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: '10px',
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px',
        },
        '& .MuiOutlinedInput-input::placeholder': {
          color: theme.palette.common.black,
        },
      }),
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: ({theme}) => ({
        padding: theme.spacing(2.5),
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
          padding: theme.spacing(4),
          paddingBottom: theme.spacing(3),
        },
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(6),
          paddingBottom: theme.spacing(3),
        },
      }),
    }
  },
};

export default components;
