import palette from './palette';

const typography = {
  htmlFontSize: 16,
  color: palette.common.black,
  fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
  fontStyle: 'normal',
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    color: palette.primary.main,
    fontSize: '2.25rem',
    fontWeight: 700,
    lineHeight: '120%', /* 2.7rem */
    letterSpacing: '-0.09375rem',
  },
  h2: {
    fontWeight: 500,
    fontSize: '1.75rem',
    lineHeight: '120%', /* 2.1rem */
    letterSpacing: '-0.0625rem',
  },
  h3: {
    fontWeight: 700,
    fontSize: '1.5rem',
    lineHeight: '123.5%', /* 1.8525rem */
    letterSpacing: '0.01563rem',
  },
  h4: {
    fontWeight: 700,
    fontSize: '1.25rem',
    lineHeight: '130%',  /* 1.625rem */
    letterSpacing: '0.0625rem',
  },
  h5: {
    fontWeight: 400,
    fontSize: '1.25rem',
    lineHeight: '130%', /* 1.625rem */
  },
  h6: {
    fontWeight: 700,
    fontSize: '1rem',
    lineHeight: '160%',  /* 1.8525rem */
    letterSpacing: '0.00938rem',
  },
  subtitle1: {
    fontWeight: 400,
    fontSize: '1.1875rem',
    lineHeight: '150%', /* 1.78125rem */
    letterSpacing: '0.00938rem',
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: '1.0625rem',
    lineHeight: '157%', /* 1.66813rem */
    letterSpacing: '0.00625rem',
  },
  body1: {
    color: palette.common.black,
    fontSize: '1rem',
    lineHeight: 1.5,
    letterSpacing: '0.00938em'
  },
  body2: {
    color: palette.common.black,
    fontSize: '0.875rem',
    lineHeight: 1.43,
    letterSpacing: '0.00714em'
  },
  button: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: 0,
    letterSpacing: '0.02857em',
    textTransform: 'uppercase',
  },
  caption1: {
    color: palette.grey[600],
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 1.66,
    letterSpacing: '0.025rem'
  },
  caption2: {
    color: palette.grey[600],
    fontWeight: 400,
    fontSize: '0.55rem',
    fontStyle: 'italic',
    lineHeight: 1.66,
    letterSpacing: '0.03333em'
  },
  cardTitle: {
    color: palette.primary.main,
    fontWeight: 700,
    fontSize: '1rem',
    fontStyle: 'normal',
    lineHeight: '150%', /* 1.5rem */
    letterSpacing: '0.00938rem',
    textTransform: 'uppercase',
  },
};

export default typography;
