const styles = {
  ErrorPage: {
    paddingTop: 6,
  },
  RedirectLink: {
    cursor: 'pointer'
  }
};

export default styles;
