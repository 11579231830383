import { Box, Link, Stack, Tooltip } from '@mui/material';
import { NumberFormat, pluralize } from '../../../../_helpers';

import PropTypes from 'prop-types';

const TransactionQuantity = (props) => {
  const {
    allocatedQuantity,
    totalQuantity,
    type,
    offerStatus,
  } = props;

  if (allocatedQuantity === totalQuantity || offerStatus !== 'PUBLISHED') {
    return (
      <Box>
        {NumberFormat.thousands(totalQuantity)} {pluralize('share', totalQuantity)}
      </Box>
    );
  }

  return (
    <Stack direction='row' spacing={0.5}>
      <Tooltip
        arrow
        title={`So far you have ${type === 'ASK' ? 'sold' : 'bought'} ${NumberFormat.thousands(allocatedQuantity)} shares`}
        placement='top-start'
      >
        <Link>{NumberFormat.thousands(allocatedQuantity)} / {NumberFormat.thousands(totalQuantity)}</Link>
      </Tooltip>
      <Box>{pluralize('share', totalQuantity)}</Box>
    </Stack>
  );
};

TransactionQuantity.propTypes = {
  allocatedQuantity: PropTypes.number.isRequired,
  totalQuantity: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['BID', 'ASK']).isRequired,
  offerStatus: PropTypes.string.isRequired,
};

export default TransactionQuantity;
