import BankAccountMicrodepositsResponse from './BankAccountMicrodeposits';
import BankAccountReauthResponse from './BankAccountReauth';
import DwollaKyc from './DwollaKyc';
import DwollaKycDocument from './DwollaKycDocument';
import DwollaKycTin from './DwollaKycTin';
import MaterialChangeResponse from './MaterialChange';

export const notificationResponseForms = {
  'COMPLETE_PROFILE': DwollaKyc,
  'RETRY_VERIFICATION': DwollaKyc,
  'TIN_MISSING': DwollaKycTin,
  'DOCUMENT_NEEDED_VERIFICATION': DwollaKycDocument,
  'CONFIRM_MATERIAL_CHANGE': MaterialChangeResponse,
};

export const notificationPlaidFlows = {
  'REAUTH_BANK_ACC': BankAccountReauthResponse,
  'CONFIRM_MICRO_DEPOSITS': BankAccountMicrodepositsResponse,
};
