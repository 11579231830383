const styles = {
  LogoLink: ({ breakpoints }) => ({
    display: 'block',
    position: 'relative',
    zIndex: 1,
    [breakpoints.between(360, 'sm')]: {
      width: '90px',
    },
    [breakpoints.up('sm')]: {
      width: '120px',
    },
    [breakpoints.up('lg')]: {
      width: '160px',
      marginTop: '21px',
    },
  }),
  LogoContainer: {
    lineHeight: 0,
  },
};

export default styles;
