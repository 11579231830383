import { Typography } from '@mui/material';

const Documents = () => {
  return (<>
    <Typography variant='subtitle1'>
      Legal documents
    </Typography>
    <Typography variant='body2'>
    Links to the latest public version of many of our legal documents and policies appear in the list below. You may have executed a different version of one of these documents, or, depending on how you work with us, other agreements may apply. If you need a copy of the exact documents that apply to you, or if there‘s a document you‘d like to review that isn‘t listed, <a href="mailto:help@netcapital.com">please email us</a>.
    </Typography>
    <Typography variant='body2'>
- <a href={`${process.env.REACT_APP_WEB_HOST}/terms`} target="_blank" rel="noopener noreferrer">Terms of Service</a>
    </Typography>
    <Typography variant='body2'>
- <a href={`${process.env.REACT_APP_WEB_HOST}/privacy`} target="_blank" rel="noopener noreferrer">Privacy Policy</a>
    </Typography>
    <Typography variant='body2'>
    - <a href={`${process.env.REACT_APP_WEB_HOST}/legal/arbitration`} target="_blank" rel="noopener noreferrer">Arbitration Agreement</a>
    </Typography>
    <Typography variant='body2'>
    - <a href={`${process.env.REACT_APP_WEB_HOST}/legal/record-owner`} target="_blank" rel="noopener noreferrer">Record Ownership and Voting Agreement</a>
    </Typography>
    <Typography variant='body2'>
    - <a href={`${process.env.REACT_APP_WEB_HOST}/legal/issuer`} target="_blank" rel="noopener noreferrer">Issuer Agreement</a>
    </Typography>
    <Typography variant='body2'>
    - <a href={`${process.env.REACT_APP_WEB_HOST}/legal/investor`} target="_blank" rel="noopener noreferrer">Master Investor Agreement</a>
    </Typography>
    <Typography variant='body2'>
    - <a href={`${process.env.REACT_APP_CDN_PDF}/legal/Transfer-Agent-Agreement_FORM.pdf`} target="_blank" rel="noopener noreferrer">Transfer Agent Agreement</a>
    </Typography>
    <Typography variant='body2'>
    - <a href={`${process.env.REACT_APP_WEB_HOST}/legal/waiver-letter`} target="_blank" rel="noopener noreferrer">Waiver Letter</a>
    </Typography><br /><br />
    <Typography variant='subtitle1'>
      Archived documents
    </Typography>
    <Typography variant='body2'>
    We’re always looking for ways to streamline our process. Sometimes we’re able to eliminate a document entirely. We provide links to older documents below for easy reference; however, it‘s unlikely you‘ll encounter any of them through normal use of our services. In time, these documents will be removed from our web site.
    </Typography>
    <Typography variant='body2'>
    - <a href={`${process.env.REACT_APP_WEB_HOST}/legal/custodian/1620951740`} target="_blank" rel="noopener noreferrer">Custodial and Voting Agreement for offerings that commenced during the period May 18, 2021 to August 4, 2021</a>
    </Typography>
    <Typography variant='body2'>
    - <a href={`${process.env.REACT_APP_CDN_PDF}/legal/TA-agreement-051821-071521.pdf`} target="_blank" rel="noopener noreferrer">Transfer Agent Agreement for offerings that commenced during the period May 18, 2021 to July 15, 2021</a>
    </Typography>
    <Typography variant='body2'>
    - <a href={`${process.env.REACT_APP_WEB_HOST}/legal/transfer/1519862400`} target="_blank" rel="noopener noreferrer">Transfer Agent Agreement for offerings that commenced during the period March 3, 2018 to May 18, 2021</a>
    </Typography>
    <Typography variant='body2'>
    - <a href={`${process.env.REACT_APP_CDN_PDF}/legal/Custodial-and-Voting-Agreement-2018-2021.pdf`} target="_blank" rel="noopener noreferrer">Custodial and Voting Agreement for offerings that commenced during the period March 3, 2018 to May 18, 2021</a>
    </Typography>
    <Typography variant='body2'>
    - <a href={`${process.env.REACT_APP_WEB_HOST}/legal/transfer/1499371247`} target="_blank" rel="noopener noreferrer">Transfer Agent Agreement for offerings that commenced prior to March 3, 2018</a>
    </Typography>
    <Typography variant='body2'>
    - <a href={`${process.env.REACT_APP_WEB_HOST}/legal/custodian/1519862400`} target="_blank" rel="noopener noreferrer">Record Ownership and Voting Agreement for offerings that commenced prior to March 3, 2018</a>
    </Typography>
    <Typography variant='body2'>
    - <a href={`${process.env.REACT_APP_WEB_HOST}/legal/investor/1594328013`} target="_blank" rel="noopener noreferrer">Issuer Agreement for offerings that commenced prior to March 3, 2018</a>
    </Typography>
    <Typography variant='body2'>
    - <a href={`${process.env.REACT_APP_WEB_HOST}/legal/securities-purchase-4a6`} target="_blank" rel="noopener noreferrer">Securities Purchase Agreement for 4(a)(6) Transactions</a>
    </Typography>
    <Typography variant='body2'>
    - <a href={`${process.env.REACT_APP_WEB_HOST}/legal/securities-purchase-506c`} target="_blank" rel="noopener noreferrer">Securities Purchase Agreement for 506(c) Transactions</a>
    </Typography>
    <Typography variant='body2'>
    - <a href={`${process.env.REACT_APP_WEB_HOST}/legal/escrow`} target="_blank" rel="noopener noreferrer">CrowdPay Escrow Agreement</a>
    </Typography>
    <Typography variant='body2'>
    - <a href={`${process.env.REACT_APP_WEB_HOST}/legal/portal-fee`} target="_blank" rel="noopener noreferrer">Portal Fee Agreement</a>
    </Typography>
  </>);
};

export default Documents;
