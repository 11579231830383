import { IconButton, Link, Menu, Typography } from '@mui/material';
import { useCallback, useState } from 'react';

import { MoreVert } from '@mui/icons-material';
import styles from './styles';

const PendingOfferDetails = ({ offer, onSelectPrice }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isDetailsOpen = Boolean(anchorEl);
  const handleOpenDetails = useCallback((e) => setAnchorEl(e.currentTarget), []);
  const handleCloseDetails = useCallback(() => setAnchorEl(null), []);
  const handlePickPrice = useCallback(() =>
    onSelectPrice(offer.pricePerUnit, offer.type),
  [onSelectPrice, offer.pricePerUnit, offer.type]
  );

  return (
    <Typography variant='body2' color={offer.type === 'BID' ? 'gain.main' : 'loss.main'}>
      {offer.type === 'BID' ? 'Buying' : 'Selling'}
      {onSelectPrice && (
        <IconButton onClick={handleOpenDetails} disableRipple>
          <MoreVert />
        </IconButton>
      )}
      <Menu
        anchorEl={anchorEl}
        id='pending-offers-menu'
        open={isDetailsOpen}
        onClose={handleCloseDetails}
        onClick={handleCloseDetails}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        MenuListProps={{sx: styles.DetailsContainer}}
      >
        <Typography variant='body2' component='div'>
          <Link onClick={handlePickPrice}>Select price</Link>
        </Typography>
      </Menu>
    </Typography>
  );
};

export default PendingOfferDetails;
