import { fetchRequest, useFetchWrapper } from '../_helpers';
import { useEffect, useState } from 'react';

import { API_Legacy } from './_api-urls';
import { plaidPublicTokenState } from '../_state';
import { useSetRecoilState } from 'recoil';

export const useGetPlaidPublicToken = (paymentMethodKey) => {
  const fetchWrapper = useFetchWrapper();
  const setPlaidPublicToken = useSetRecoilState(plaidPublicTokenState);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    function abort() {
      controller.abort();
    }

    const isPublic = true; // URL is not public, but we do this so the legacy web app can assert privileges via cookie/with-credentials
    fetchWrapper.post(`${process.env.REACT_APP_WEB_HOST}/integrations/plaid/accounts/refreshpublictoken`, {paymentMethodKey}, signal, isPublic)
      .then((response) => {
        if (!response) return;
        setPlaidPublicToken(response.publicToken);
        setLoaded(true);
      })
      .catch(() => setLoaded(true));

    return abort;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethodKey]);

  return { loaded };
};

export const markBankAccountAsAuthenticated = (paymentMethodKey) => {
  // Update needsAuthentication to false on updated payment methods
  // this API call operates on one paymentMethod, but will update
  // ALL payment methods associated with this bank
  const url = `${API_Legacy}/payment-methods/${paymentMethodKey}`;
  const body = {needsAuthentication: 0};
  return fetchRequest('patch', url, body).catch(e => e);
};

export const syncPlaidAccounts = (paymentMethodKey) => {
  const url = `${API_Legacy}/api/plaid/sync-accounts/${paymentMethodKey}`;
  const isPublic = true; // URL is not public, but we do this so the legacy web app can assert privileges via cookie/with-credentials
  return fetchRequest('post', url, null, null, isPublic).catch(e => e);
};
