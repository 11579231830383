import { prepareQueryString, useFetchWrapper } from '../_helpers';
import { securitiesByCompanyState, securitiesState } from '../_state';
import { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { APIs } from './_api-urls';

export function useGetSecurities(investorKey, queryParams) {
  const [securities, setSecurities] = useRecoilState(securitiesState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    function abort(){ controller.abort(); }

    setLoaded(false);

    const url = `${APIs.Marketplace}/portfolio/${investorKey}/securities?${prepareQueryString(queryParams)}`;
    fetchWrapper.get(url, null, signal)
      .then((response) => {
        if (!response) return; //aborted request gives response === undefined
        let data = response.data;

        if(securities?.data?.length && queryParams?.page > 1){
          data = [...securities.data, ...response.data];
        }

        setSecurities({ data, metadata: response.metadata });
        setLoaded(true);
      })
      .catch(() => {
        setLoaded(true);
      });

    return abort;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investorKey, queryParams.page, queryParams.status]);

  return { loaded };
}

export function useGetSecuritiesByListingKey(investorKey, listingKey) {
  const setSecuritiesInfo = useSetRecoilState(securitiesByCompanyState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);
  const [prevInvestorKey, setPrevInvestorKey] = useState('');
  const [prevListingKey, setPrevListingKey] = useState('');

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    function abort(){ controller.abort(); }

    if ((investorKey === prevInvestorKey && listingKey === prevListingKey) || !investorKey || !listingKey) {
      setLoaded(true);
      return abort;
    }

    setPrevInvestorKey(investorKey);
    setPrevListingKey(listingKey);
    setLoaded(false);

    const url = `${APIs.Marketplace}/portfolio/${investorKey}/listings/${listingKey}`;
    fetchWrapper.get(url, null, signal)
      .then((response) => {
        if (!response) return; //aborted request gives response === undefined
        setSecuritiesInfo(response);

        setLoaded(true);
      }).catch(() => {
        setLoaded(true);
      });

    return abort;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investorKey, listingKey]);

  return { loaded };
}
