import { Grid, Stack } from '@mui/material';
import { pendingAskOffersState, pendingBidOffersState } from '../../_state/pending-offers';

import CircularLoading from '../../netcapital-components/CircularLoading';
import EmptyStateDisplay from '../EmptyStateDisplay';
import PendingOffersTable from './table';
import PropTypes from 'prop-types';
import { companyState } from '../../_state';
import { useGetPendingOffers } from '../../_actions/pending-offers.actions';
import { useRecoilValue } from 'recoil';

const PendingOffers = ({ isDense, onSelectPrice }) => {
  const company = useRecoilValue(companyState);
  const { loaded: loadedPendingOffers } = useGetPendingOffers(company.key);
  const askOffers = useRecoilValue(pendingAskOffersState);
  const bidOffers = useRecoilValue(pendingBidOffersState);

  if(!askOffers?.length && !bidOffers?.length){
    return (
      <EmptyStateDisplay
        componentName='PendingOffers'
        message='There are no pending offers associated with this company.'
      />
    );
  }

  return (
    <>
      {!loadedPendingOffers && <Stack alignItems={'center'}><CircularLoading /></Stack>}
      {loadedPendingOffers && (askOffers?.length || bidOffers?.length) && (
        <Grid container spacing={{xs: 4, md: 3, lg: 4}} justifyContent={'space-between'}>
          <Grid item xs={12} md={isDense ? 12 : 6}>
            <PendingOffersTable offers={bidOffers} onSelectPrice={onSelectPrice} />
          </Grid>
          <Grid item xs={12} md={isDense ? 12 : 6}>
            <PendingOffersTable offers={askOffers} onSelectPrice={onSelectPrice} />
          </Grid>
        </Grid>
      )}
    </>
  );
};

PendingOffers.defaultProps = {
  isDense: false,
  onSelectPrice: () => { return; },
};

PendingOffers.propTypes = {
  isDense: PropTypes.bool,
  onSelectPrice: PropTypes.func,
};

export default PendingOffers;
