import { Navigate, useLocation } from 'react-router-dom';

import AccountPage from './account';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';

const DEFAULT_FORM = 'basic-info';

const AccountIndexPage = () => {
  const {search} = useLocation();
  const theme = useTheme();
  const isBelowMediumBreakpoint = useMediaQuery(theme.breakpoints.down('md'));
  if (isBelowMediumBreakpoint) {
    return <AccountPage />;
  }
  return <Navigate to={`${DEFAULT_FORM}${search}`} replace />;
};

export default AccountIndexPage;
