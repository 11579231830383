import Field from './Field';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import TextField from '../../netcapital-components/TextField';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

const PersonNameFieldset = ({ recoilState, fieldName }) => {
  const [firstName, setFirstName] = useRecoilState(recoilState.firstName);
  const handleChangeFirstName = useCallback((e) => setFirstName(e.target.value), [setFirstName]);
  const [lastName, setLastName] = useRecoilState(recoilState.lastName);
  const handleChangeLastName = useCallback((e) => setLastName(e.target.value), [setLastName]);

  return (
    <Grid container item spacing={2} xs={12}>
      <Grid item xs={12} sm={6}>
        <Field label='First Name *'>
          <TextField
            value={firstName || ''}
            onChange={handleChangeFirstName}
            placeholder='First Name'
            variant='outlined'
            fieldName={fieldName ? `${fieldName}.firstName` : 'firstName'}
          />
        </Field>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field label='Last Name *'>
          <TextField
            value={lastName || ''}
            onChange={handleChangeLastName}
            placeholder='Last Name'
            variant='outlined'
            fieldName={fieldName ? `${fieldName}.lastName` : 'lastName'}
          />
        </Field>
      </Grid>
    </Grid>
  );
};

PersonNameFieldset.defaultProps = {
  fieldName: '',
};

PersonNameFieldset.propTypes = {
  recoilState: PropTypes.shape({
    firstName: PropTypes.shape({key: PropTypes.string.isRequired}).isRequired,
    lastName: PropTypes.shape({key: PropTypes.string.isRequired}).isRequired,
  }).isRequired,
  fieldName: PropTypes.string,
};

export default PersonNameFieldset;
