import { Box, Button, Stack, Typography } from '@mui/material';
import { NumberFormat, pluralize, useIsMobile } from '../../_helpers';
import { availableSharesByCompanyState, companyState, fetchCompanyAttribute, investorState } from '../../_state';

import PropTypes from 'prop-types';
import styles from './styles';
import { useGetSecuritiesByListingKey } from '../../_actions/securities.actions';
import { useRecoilValue } from 'recoil';

const OfferBarContent = ({ onClick }) => {
  const investor = useRecoilValue(investorState);
  const company = useRecoilValue(companyState);
  useGetSecuritiesByListingKey(investor.entityKey, company.key);
  const availableShares = useRecoilValue(availableSharesByCompanyState);
  const currentPrice = useRecoilValue(fetchCompanyAttribute('currentPrice'));
  const isMobile = useIsMobile();

  return (
    <Stack direction='row' spacing={{xs: 2, sm: 4, md: 6}} alignItems='center' sx={styles.MobileBarContent}>
      <Stack direction='row' spacing={2}>
        <Box>
          <Typography variant='body2' textTransform='uppercase'>Your Position</Typography>
          <Box>
            <Typography fontWeight='medium' component='span'>
              {availableShares ? NumberFormat.thousands(availableShares) : 0}
            </Typography>
            {' '}
            <Typography fontWeight='medium' component='span'>
              {pluralize('Share', availableShares)}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography variant='body2' textTransform='uppercase'>Last Price</Typography>
          <Typography fontWeight='medium'>
            {NumberFormat.cents(currentPrice, {minimumFractionDigits: 2})}
          </Typography>
        </Box>
      </Stack>
      <Stack sx={styles.MobileTradeAction}>
        <Button
          onClick={onClick}
          disabled={!company?.status?.isSecondaryMarketOpen}
          variant='contained'
          color='success'
          size={isMobile ? 'medium' : 'large'}
          sx={styles.MobileTradeButton}
        >
        Trade
        </Button>
      </Stack>
    </Stack>
  );
};

OfferBarContent.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default OfferBarContent;
