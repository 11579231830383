const styles = {
  LoadingContainer: {
    alignItems: 'center',
    height: '100%',
  },
  LoadingSpinner: {
    flexGrow: 1,
    justifyContent: 'center',
  },
  HeaderRightContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  HeaderRight: {
    height: '100%',
    justifyContent: {
      xs: 'flex-start',
      md: 'flex-end',
    },
  },
  OrderId: {
    color: 'grey.500',
    alignSelf: {
      xs: 'flex-start',
      md: 'center',
    },
  },
  LineItemContainer: {
    tableLayout: 'fixed',
  },
  LineItem: {
    borderColor: 'grey.100',
  },
  ReceiptLink: {
    minHeight: 0,
    padding: 0,
    justifyContent: 'start',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  ReceiptLinkUnavailable: {
    pointerEvents: 'none',
  },
  BottomDisclaimer: {
    fontStyle: 'italic',
    marginTop: '2rem',
  },
};

export default styles;
