import { templumFormKycStatusState, userFinancialInfoState, userInvestorInfoState, userMfaState, userPersonalInfoState, userState, userVerificationDocumentsState } from '../_state';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { APIUserURL } from './_api-urls';
import { useFetchWrapper } from '../_helpers';

export const useGetUserPersonalInfo = () => {
  const user = useRecoilValue(userState);
  const setUserPersonalInfo = useSetRecoilState(userPersonalInfoState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const abort = () => controller.abort();
    if (!user) {
      return abort;
    }

    setLoaded(false);

    const url = `${APIUserURL(user.entityKey)}/personal-information`;
    fetchWrapper.get(url, null, signal, false)
      .then((response) => {
        if (!response) return;
        setUserPersonalInfo(response);
        setLoaded(true);
      })
      .catch(() => setLoaded(true));

    return abort;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.entityKey]);

  return { loaded };
};

export const useSaveUserPersonalInfo = () => {
  const user = useRecoilValue(userState);
  const personalInfo = useRecoilValue(userPersonalInfoState);
  const fetchWrapper = useFetchWrapper();
  const [saving, setSaving] = useState(false);
  const setTemplumKycStatus = useSetRecoilState(templumFormKycStatusState);

  const save = useCallback((onSuccess) => {
    const controller = new AbortController();
    const signal = controller.signal;
    const abort = () => controller.abort();

    setSaving(true);

    const url = `${APIUserURL(user.entityKey)}/personal-information`;
    fetchWrapper.put(url, personalInfo, signal)
      .then((response) => {
        if (!response) return;
        setTemplumKycStatus(response.kyc);
        setSaving(false);
        if (typeof onSuccess === 'function') onSuccess();
      })
      .catch(() => setSaving(false));

    return abort;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalInfo]);

  return { save, saving };
};

export const useGetUserInvestorInfo = () => {
  const user = useRecoilValue(userState);
  const setUserInvestorInfo = useSetRecoilState(userInvestorInfoState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const abort = () => controller.abort();

    setLoaded(false);

    const url = `${APIUserURL(user.entityKey)}/investor-information`;
    fetchWrapper.get(url, null, signal, false)
      .then((response) => {
        if (!response) return;
        setUserInvestorInfo(response);
        setLoaded(true);
      })
      .catch(() => setLoaded(true));

    return abort;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.entityKey]);

  return { loaded };
};

export const useSaveUserInvestorInfo = () => {
  const user = useRecoilValue(userState);
  const investorInfo = useRecoilValue(userInvestorInfoState);
  const fetchWrapper = useFetchWrapper();
  const [saving, setSaving] = useState(false);
  const setTemplumKycStatus = useSetRecoilState(templumFormKycStatusState);

  const save = useCallback((onSuccess, onError) => {
    const controller = new AbortController();
    const signal = controller.signal;
    const abort = () => controller.abort();

    setSaving(true);

    const url = `${APIUserURL(user.entityKey)}/investor-information`;
    fetchWrapper.put(url, investorInfo, signal)
      .then((response) => {
        if (!response) return;
        setTemplumKycStatus(response.kyc);
        setSaving(false);
        if (typeof onSuccess === 'function') onSuccess();
      })
      .catch(() => {
        setSaving(false);
        if (typeof onError === 'function') onError();
      });

    return abort;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investorInfo]);

  return { save, saving };
};

export const useGetUserFinancialInfo = () => {
  const user = useRecoilValue(userState);
  const setUserIdentityInfo = useSetRecoilState(userFinancialInfoState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const abort = () => controller.abort();

    setLoaded(false);

    const url = `${APIUserURL(user.entityKey)}/financial-information`;
    fetchWrapper.get(url, null, signal, false)
      .then((response) => {
        if (!response) return;
        setUserIdentityInfo(response);
        setLoaded(true);
      })
      .catch(() => setLoaded(true));

    return abort;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.entityKey]);

  return { loaded };
};

export const useSaveUserFinancialInfo = () => {
  const user = useRecoilValue(userState);
  const identityInfo = useRecoilValue(userFinancialInfoState);
  const fetchWrapper = useFetchWrapper();
  const [saving, setSaving] = useState(false);
  const setTemplumKycStatus = useSetRecoilState(templumFormKycStatusState);

  const save = useCallback((onSuccess, onError) => {
    const controller = new AbortController();
    const signal = controller.signal;
    const abort = () => controller.abort();

    setSaving(true);

    const url = `${APIUserURL(user.entityKey)}/financial-information`;
    fetchWrapper.put(url, identityInfo, signal)
      .then((response) => {
        if (!response) return;
        setTemplumKycStatus(response.kyc);
        setSaving(false);
        if (typeof onSuccess === 'function') onSuccess();
      })
      .catch(() => {
        setSaving(false);
        if(typeof onError === 'function') onError();
      });

    return abort;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identityInfo]);

  return { save, saving };
};

export const useGetUserVerificationDocuments = () => {
  const user = useRecoilValue(userState);
  const setVerificationDocs = useSetRecoilState(userVerificationDocumentsState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const abort = () => controller.abort();

    setLoaded(false);

    const url = `${APIUserURL(user.entityKey)}/verification-documents?kycType=SECONDARY`;
    fetchWrapper.get(url, null, signal, false)
      .then((response) => {
        if (!response) return;
        setVerificationDocs(response);
        setLoaded(true);
      })
      .catch(() => setLoaded(true));

    return abort;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.entityKey]);

  return { loaded };
};

export const useSaveVerificationDocument = () => {
  const user = useRecoilValue(userState);
  const [docs, setDocs] = useRecoilState(userVerificationDocumentsState);
  const fetchWrapper = useFetchWrapper();
  const [saving, setSaving] = useState(false);
  const setTemplumKycStatus = useSetRecoilState(templumFormKycStatusState);

  const save = useCallback((usage, file, onSuccess) => {
    const controller = new AbortController();
    const signal = controller.signal;
    const abort = () => controller.abort();

    setSaving(true);

    const url = `${APIUserURL(user.entityKey)}/verification-documents`;
    const formData = new FormData();
    formData.append('usage', usage);
    formData.append('kycType', 'SECONDARY');
    formData.append('file', file);
    fetchWrapper.post(url, formData, signal, false, 'multipart/form-data')
      .then((response) => {
        if (!response) return;
        const newFile = response.files;
        const docIndex = docs.findIndex(doc => doc.usage === usage);
        if (docIndex === -1) {
          setDocs([ ...docs, newFile ]);
        }
        else {
          const docsCopy = [...docs];
          docsCopy[docIndex] = newFile;
          setDocs(docsCopy);
        }
        setTemplumKycStatus(response.kyc);
        setSaving(false);
        if (typeof onSuccess === 'function') onSuccess();
      })
      .catch(() => setSaving(false));

    return abort;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docs]);

  return { save, saving };
};

export const useDeleteVerificationDocument = () => {
  const user = useRecoilValue(userState);
  const [verificationDocs, setVerificationDocs] = useRecoilState(userVerificationDocumentsState);
  const fetchWrapper = useFetchWrapper();
  const [deleting, setDeleting] = useState(false);
  const setTemplumKycStatus = useSetRecoilState(templumFormKycStatusState);

  const destroy = useCallback((fileKey, onSuccess) => {
    const controller = new AbortController();
    const signal = controller.signal;
    const abort = () => controller.abort();

    setDeleting(true);

    const url = `${APIUserURL(user.entityKey)}/verification-documents/${fileKey}`;
    fetchWrapper.delete(url, null, signal)
      .then((response) => {
        if (!response) return;
        setVerificationDocs((docs) => docs.filter((doc) => doc.key !== fileKey));
        setTemplumKycStatus(response);
        setDeleting(false);
        if (typeof onSuccess === 'function') onSuccess();
      })
      .catch(() => setDeleting(false));

    return abort;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationDocs]);

  return { destroy, deleting };
};

export const useGetUserMfaSettings = () => {
  const user = useRecoilValue(userState);
  const setMfaData = useSetRecoilState(userMfaState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const abort = () => controller.abort();

    setLoaded(false);

    const url = `${APIUserURL(user.entityKey)}/mfa`;
    fetchWrapper.get(url, null, signal, false)
      .then((response) => {
        if (!response) return;
        setMfaData(response);
        setLoaded(true);
      })
      .catch(() => setLoaded(true));

    return abort;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.entityKey]);

  return { loaded };
};

export const useRequestEmailChange = () => {
  const user = useRecoilValue(userState);
  const fetchWrapper = useFetchWrapper();
  const [saving, setSaving] = useState(false);

  const save = useCallback((newEmail, onSuccess) => {
    const controller = new AbortController();
    const signal = controller.signal;
    const abort = () => controller.abort();

    setSaving(true);

    const url = `${APIUserURL(user.entityKey)}/email`;
    const body = {email: newEmail};

    fetchWrapper.post(url, body, signal)
      .then((response) => {
        if (!response) return;
        setSaving(false);
        if (typeof onSuccess === 'function') onSuccess();
      })
      .catch(() => setSaving(false));

    return abort;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.entityKey]);

  return { save, saving };
};

export const useRequestPasswordChange = () => {
  const user = useRecoilValue(userState);
  const fetchWrapper = useFetchWrapper();
  const [saving, setSaving] = useState(false);

  const save = useCallback((onSuccess) => {
    const controller = new AbortController();
    const signal = controller.signal;
    const abort = () => controller.abort();

    setSaving(true);

    const url = `${APIUserURL(user.entityKey)}/pwd`;
    fetchWrapper.post(url, null, signal)
      .then((response) => {
        if (!response) return;
        setSaving(false);
        if (typeof onSuccess === 'function') onSuccess();
      })
      .catch(() => setSaving(false));

    return abort;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.entityKey]);

  return { save, saving };
};
