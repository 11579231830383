import { atom, selector, selectorFamily } from 'recoil';

import Session from '../_session';
import _ from 'lodash';
import produce from 'immer';

const userStateSessionStorageSync = storageKey => ({onSet}) => {
  onSet((newValue, oldValue, isReset) => {
    isReset
      ? delete sessionStorage[storageKey].user
      : Session.set({ user: newValue });
  });
};

const userState = atom({
  key: 'userState',
  default: Session.get('user'),
  effects: [
    userStateSessionStorageSync(Session.STORAGE_KEY),
  ]
});

const userRoleState = selector({
  key: 'userRoleState',
  get: ({get}) => get(userState)?.role,
  set: ({get, set}, newValue) => set(userState, { ...get(userState), ...{role: newValue}})
});

const isAdminState = selector({
  key: 'isAdminState',
  get: ({get}) => get(userState)?.role === 'ADMIN'
});

const userListingsState = atom({
  key: 'userListingsState',
  default: []
});

const userPersonalInfoState = atom({
  key: 'userPersonalInfoState',
  default: {}
});

const fetchUserPersonalInfoAttribute = selectorFamily({
  key: 'fetchUserPersonalInfoAttribute',
  get: attribute => ({get}) => _.get(get(userPersonalInfoState), attribute),
  set: attribute => ({set}, newValue) => {
    set(userPersonalInfoState,
      prevState => produce(prevState, (draft) => _.set(draft, attribute, newValue))
    );
  },
});

const userInvestorInfoState = atom({
  key: 'userInvestorInfoState',
  default: {}
});

const fetchUserInvestorInfoAttribute = selectorFamily({
  key: 'fetchUserInvestorInfoAttribute',
  get: attribute => ({get}) => _.get(get(userInvestorInfoState), attribute),
  set: attribute => ({set}, newValue) => {
    set(userInvestorInfoState,
      prevState => produce(prevState, (draft) => _.set(draft, attribute, newValue))
    );
  },
});

const userFinancialInfoState = atom({
  key: 'userFinancialInfoState',
  default: {}
});

const fetchUserFinancialInfoAttribute = selectorFamily({
  key: 'fetchUserFinancialInfoAttribute',
  get: attribute => ({get}) => _.get(get(userFinancialInfoState), attribute),
  set: attribute => ({set}, newValue) => {
    set(userFinancialInfoState,
      prevState => produce(prevState, (draft) => _.set(draft, attribute, newValue))
    );
  },
});

const userVerificationDocumentsState = atom({
  key: 'userVerificationDocumentsState',
  default: undefined
});

const fetchUserVerificationDocument = selectorFamily({
  key: 'fetchUserVerificationDocument',
  get: usage => ({get}) => {
    const files = get(userVerificationDocumentsState) || [];
    return files.find(file => file.usage === usage);
  },
  set: usage => ({get, set}, newFile) => {
    const files = get(userVerificationDocumentsState) || [];

    if (!newFile) {
      set(userVerificationDocumentsState, files.filter(file => file.usage !== usage));
    }
    else {
      const fileIndex = Math.max(files.findIndex(file => file.usage === usage), files.length);
      const updatedFiles = produce(files, (draft) => {
        draft[fileIndex] = newFile;
      });
      set(userVerificationDocumentsState, updatedFiles);
    }
  }
});

const userMfaState = atom({
  key: 'userMfaState',
  default: undefined
});

export {
  userState,
  userRoleState,
  isAdminState,
  userListingsState,
  userPersonalInfoState,
  userInvestorInfoState,
  fetchUserInvestorInfoAttribute,
  fetchUserPersonalInfoAttribute,
  userFinancialInfoState,
  fetchUserFinancialInfoAttribute,
  userVerificationDocumentsState,
  fetchUserVerificationDocument,
  userMfaState,
};
