import { Box } from '@mui/material';
import Comment from './comment';
import Replies from './replies';

const ParentComment = (props) => {
  const {comment, listingKey, onReply} = props;

  return (
    <Box>
      <Comment comment={comment} listingKey={listingKey} onReply={onReply} />
      <Replies parentCommentKey={comment.key} replies={comment.replies} listingKey={listingKey} />
    </Box>
  );
};

export default ParentComment;
