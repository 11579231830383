import Comment from './comment';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import styles from './styles';

const Replies = (props) => {
  const { parentCommentKey, replies, listingKey } = props;

  if (!replies || replies.length === 0) return null;

  return (
    <Stack spacing={4} sx={styles.RepliesContainer}>
      {replies.map(reply => <Comment key={reply.key} comment={reply} parentCommentKey={parentCommentKey} listingKey={listingKey} />)}
    </Stack>
  );
};

Replies.propTypes = {
  listingKey: PropTypes.string.isRequired,
};

export default Replies;
