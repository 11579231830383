const styles = {
  SortIcon: {
    fontSize: '0.85rem',
  },
  SortIconAsc: {
    transform: 'rotate(180deg)',
  },
};

export default styles;
