import { Grid, Stack, Typography } from '@mui/material';

import CircularLoading from '../../netcapital-components/CircularLoading';
import NetcapitalLogo from '../../netcapital-components/NetcapitalLogo';
import PropTypes from 'prop-types';
import ReceiptDetails from './ReceiptDetails';
import ReceiptsList from './ReceiptsList';
import styles from './styles';
import { transactionDetailsState } from '../../_state';
import { useGetTransactionDetails } from '../../_actions/transactions.actions';
import { useRecoilValue } from 'recoil';

const Receipt = ({ offerKey }) => {
  const {loaded} = useGetTransactionDetails(offerKey);
  const transactionDetails = useRecoilValue(transactionDetailsState);

  if (!loaded || typeof transactionDetails === 'undefined') {
    return (
      <Stack sx={styles.LoadingContainer}>
        <Stack sx={styles.LoadingSpinner}>
          <CircularLoading />
        </Stack>
      </Stack>
    );
  }

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={6} md={4}>
          <NetcapitalLogo />
        </Grid>
        <Grid item xs={12} md={8}>
          <Stack spacing={1} direction={{xs: 'column', sm: 'row'}} sx={styles.HeaderRight}>
            <Typography sx={styles.OrderId}>Order ID: </Typography>
            <Typography sx={styles.OrderId}>{offerKey}</Typography>
          </Stack>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Stack spacing={4}>
          <Typography variant='h4' color='primary'>Resume of Order</Typography>
          <ReceiptDetails
            transactionDetails={transactionDetails}
          />
        </Stack>
      </Grid>

      <Grid item xs={12}>
        <Stack spacing={4}>
          <Typography variant='h4' color='primary'>Transactions</Typography>
          <ReceiptsList
            transactionDetails={transactionDetails}
          />
        </Stack>
      </Grid>

    </Grid>
  );
};

Receipt.propTypes = {
  offerKey: PropTypes.string.isRequired,
};

export default Receipt;
