import { useCallback, useEffect } from 'react';

import CitizenshipField from './Citizenship';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import ResidencyField from './Residency';
import TinField from './Tin';
import { useRecoilState, } from 'recoil';

const ResidencyCitizenshipTinFieldset = (props) => {
  const {
    recoilState,
    rowSpacing,
    columnSpacing,
    forceSetTin,
    allowNonUSResidency,
  } = props;
  const [citizenship, setCitizenship] = useRecoilState(recoilState.citizenship);
  const [residencyStatus, setResidencyStatus] = useRecoilState(recoilState.residencyStatus);
  const handleChangeResidencyStatus = useCallback(newValue => {
    if (newValue === 'US_CITIZEN') {
      setCitizenship('US');
    }
  }, [setCitizenship]);

  const handleChangeCitizenship = useCallback(newValue => {
    if (newValue === 'US') {
      setResidencyStatus('US_CITIZEN');
    }
  }, [setResidencyStatus]);

  // Set citizenship when residencyStatus === US_CITIZEN
  useEffect(() => {
    if (residencyStatus === 'US_CITIZEN' && citizenship !== 'US') {
      setCitizenship('US');
    }
  }, [citizenship, residencyStatus, setCitizenship]);

  return (
    <Grid container item xs={12} rowSpacing={rowSpacing || 4} columnSpacing={columnSpacing || 2}>
      <Grid item xs={12} sm={6} lg={3}>
        <ResidencyField
          recoilState={recoilState.residencyStatus}
          onChange={handleChangeResidencyStatus}
          allowNonUSResidency={allowNonUSResidency}
        />
      </Grid>
      {residencyStatus !== 'US_CITIZEN' && (
        <Grid item xs={12} sm={6} lg={4}>
          <CitizenshipField
            recoilState={recoilState.citizenship}
            disabled={residencyStatus === 'US_CITIZEN'}
            onChange={handleChangeCitizenship}
          />
        </Grid>
      )}
      {(residencyStatus === 'US_CITIZEN' || residencyStatus === 'US_RESIDENT') && (
        <Grid item xs={12} sm={6} lg={5}>
          <TinField
            recoilState={recoilState.secureTin}
            forceSet={forceSetTin}
          />
        </Grid>
      )}
    </Grid>
  );
};

ResidencyCitizenshipTinFieldset.defaultProps = {
  allowNonUSResidency: true,
  rowSpacing: 4,
  columnSpacing: 2,
  forceSetTin: false,
};

ResidencyCitizenshipTinFieldset.propTypes = {
  allowNonUSResidency: PropTypes.bool,
  recoilState: PropTypes.shape({
    citizenship: PropTypes.shape({key: PropTypes.string.isRequired}).isRequired,
    residencyStatus: PropTypes.shape({key: PropTypes.string.isRequired}).isRequired,
    secureTin: PropTypes.shape({key: PropTypes.string.isRequired}).isRequired,
  }).isRequired,
  rowSpacing: PropTypes.number,
  columnSpacing: PropTypes.number,
  forceSetTin: PropTypes.bool,
};

export default ResidencyCitizenshipTinFieldset;
