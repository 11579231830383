import { useCallback, useState } from 'react';

import { Autocomplete } from '@mui/material';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import TextField from '../TextField';
import countries from './countries';
import styles from './styles';

const findCountry = value => countries.find(countryOption => countryOption.code === value);

function CountrySelect(props) {
  const { onChange, value, disabled, requirementLabel, fieldName } = props;
  const [ country, setCountry ] = useState(findCountry(value) || null);

  const isOptionEqualToValue = useCallback(() => {
    // Suppress warning about there being no valid matches,
    // since we want to display all options returned from API.
    return true;
  }, []);

  const renderCountryField = useCallback((params) => (
    <TextField
      {...params}
      inputProps={{ ...params.inputProps }}
      placeholder={`Country ${requirementLabel}`}
      fieldName={fieldName}
    />
  ), [fieldName, requirementLabel]);
  const getCountryOptionLabel = useCallback((option) => option.label || '', []);
  const renderCountryOption = useCallback((params, option) => (
    <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...params}>
      <img loading='lazy' width='20' src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`} alt='' />
      {option.label} ({option.code}) +{option.phone}
    </Box>
  ), []);

  const handleChange = useCallback((e, newValue) => {
    setCountry(newValue);
    onChange((newValue && typeof(newValue) === 'object') ? newValue.code : newValue);
  }, [onChange, setCountry]);

  return (
    <Autocomplete
      disablePortal
      autoComplete
      autoHighlight
      autoSelect
      options={countries}
      onChange={handleChange}
      value={country || null}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getCountryOptionLabel}
      renderOption={renderCountryOption}
      renderInput={renderCountryField}
      sx={styles.AutoComplete}
      disabled={disabled}
    />
  );
}

CountrySelect.defaultProps = {
  value: '',
  disabled: false,
  requirementLabel: '',
  fieldName: 'country',
};

CountrySelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  requirementLabel: PropTypes.string,
  fieldName: PropTypes.string,
};

export default CountrySelect;
