import { FormControlLabel, Stack, Switch, Typography } from '@mui/material';

import { APIs } from '../../_actions/_api-urls';
import CircularLoading from '../../netcapital-components/CircularLoading';
import { useCallback } from 'react';
import { useGetUserMfaSettings } from '../../_actions/user.actions';
import { useRecoilValue } from 'recoil';
import { userMfaState } from '../../_state';

const ChangeMultifactorAuthentication = () => {
  const {loaded} = useGetUserMfaSettings();
  const mfaInfo = useRecoilValue(userMfaState);
  const isMfaEnabled = mfaInfo?.length > 0;
  const mfaAction = isMfaEnabled ? 'disable' : 'enroll';
  const currentUrl = window.location.href;
  const mfaActionUrl = `${APIs.Auth}/login?actions=mfa:${mfaAction}&returnTo=${currentUrl}`;
  const handleChangeMfa = useCallback(() => window.location.href = mfaActionUrl, [mfaActionUrl]);

  if (!loaded) return <CircularLoading />;

  return (
    <Stack spacing={2}>
      <Typography variant='h5'>Multi-factor Authentication</Typography>
      <FormControlLabel
        control={
          <Switch
            checked={isMfaEnabled}
            onChange={handleChangeMfa}
          />
        }
        label={isMfaEnabled ? 'Enabled' : 'Not enabled'}
      />
    </Stack>
  );
};

export default ChangeMultifactorAuthentication;
