import { Avatar, Box, Link, ListItem, ListItemIcon, Stack, Tooltip, Typography } from '@mui/material';
import { NumberFormat, pluralize, useIsMobile } from '../../_helpers';

import BalanceIcon from '@mui/icons-material/Balance';
import Delta from '../Delta';
import { EXEMPTIONS } from '../../static-info';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import styles from './styles';

const Security = ({ security }) => {
  const isMobile = useIsMobile();
  const exemption = EXEMPTIONS[security.exemption];

  return (
    <ListItem sx={styles.Security}>
      {!isMobile && (
        <ListItemIcon sx={{display: 'block'}}>
          <Avatar src={security.listing.logoUrl} />
        </ListItemIcon>
      )}
      <Box sx={styles.ColumnLeft}>
        <Link
          to={`/marketplace/${security.listing.code}`}
          component={RouterLink}
          color='common.black'
          sx={styles.CompanyName}
        >
          <Typography variant='body1' fontWeight='fontWeightBold'>{security.listing.doingBusinessAs}</Typography>
        </Link>
        <Stack direction='row' alignItems='center' spacing={1}>
          <Stack direction='row' alignItems='center'>
            <Tooltip
              title={exemption.explanation}
              sx={exemption.enabled ? styles.EnabledExemption : styles.DisabledExemption}
            >
              <BalanceIcon />
            </Tooltip>
          </Stack>
          <Typography variant='body2' sx={styles.Shares}>
            {NumberFormat.thousands(security.totalQuantity)} {pluralize('share', security.totalQuantity)}
          </Typography>
        </Stack>
      </Box>
      <Box sx={styles.ColumnRight}>
        <Typography variant='body1' fontWeight='fontWeightBold'>{NumberFormat.cents(security.currentValue)}</Typography>
        {security.currentValue && (
          <Delta
            value={security.currentValue || 0}
            firstValue={security.valueAtTimeOfPurchase}
            type='gain'
            formatType='money'
            display='inline'
            variant='body2'
          />
        )}
      </Box>
    </ListItem>
  );
};

Security.propTypes = {
  security: PropTypes.shape({
    currentPrice: PropTypes.number.isRequired,
    currentValue: PropTypes.number.isRequired,
    exemption: PropTypes.string.isRequired,
    issueDate: PropTypes.string.isRequired,
    listing: PropTypes.shape({
      code: PropTypes.string.isRequired,
      doingBusinessAs: PropTypes.string,
      key: PropTypes.string.isRequired,
      legalName: PropTypes.string.isRequired,
      logoUrl: PropTypes.string,
      status: PropTypes.shape({
        hasExitedNetcapital: PropTypes.bool,
        hideFromMarketplace: PropTypes.bool,
        isSecondaryMarketOpen: PropTypes.bool,
        netcapitalExitReason: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(null)]),
      }).isRequired
    }).isRequired,
    percentageDelta: PropTypes.number.isRequired,
    priceDelta: PropTypes.number.isRequired,
    totalQuantity: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    valueAtTimeOfPurchase: PropTypes.number.isRequired,
  }).isRequired,
};

export default Security;
