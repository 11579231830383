import { Navigate, Route, Routes } from 'react-router-dom';
import { withProfiler as withSentryProfiler, withSentryReactRouterV6Routing } from '@sentry/react';

import AccountIndexPage from './pages/Account';
import AccountPage from './pages/Account/account';
import { Alert } from './components/Alert';
import AppContainer from './layouts/AppContainer';
import CompanyPage from './pages/Company';
import CompliancePage from './pages/Compliance';
import CssBaseline from '@mui/material/CssBaseline';
import MarketplacePage from './pages/Marketplace';
import NoPermissionsPage from './pages/Error/NoPermissions';
import NotFoundPage from './pages/Error/NotFound';
import ScrollToTop from './netcapital-components/ScrollToTop';
import SettingsPage from './pages/Settings';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';

function App() {
  const SentryRoutes = withSentryReactRouterV6Routing(Routes);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Alert />
      <ScrollToTop />
      <SentryRoutes>
        <Route path="/" element={<AppContainer />}>
          <Route path="/marketplace/:listingCode" element={<CompanyPage />} />
          <Route path="/marketplace" element={<MarketplacePage />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/account">
            <Route path=":formId" element={<AccountPage />} />
            <Route index element={<AccountIndexPage />} />
          </Route>
          <Route path="/legal" element={<CompliancePage />} />
          <Route index element={<Navigate to="/marketplace" replace />} />
          <Route path="/no-permission" element={<NoPermissionsPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </SentryRoutes>
    </ThemeProvider>
  );
}

export default withSentryProfiler(App);
