import { prepareQueryString, useFetchWrapper } from '../_helpers';
import { transactionDetailsState, transactionsByListingState, transactionsState } from '../_state';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { APIs } from './_api-urls';

export function useGetTransactions(queryParams, appendOnFetch=true) {
  const [transactions, setTransactions] = useRecoilState(transactionsState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(true);
  const [isRefresh, setIsRefresh] = useState(false);
  const refreshData = useCallback(() => {
    setIsRefresh(true);
    setShouldFetch(true);
  }, []);

  useEffect(() => {
    const doFetch = () => {
      const controller = new AbortController();
      const signal = controller.signal;
      const abort = () => controller.abort();
      if (shouldFetch) {
        if (!isRefresh) {
          setLoaded(false);
        }

        const url = `${APIs.Offerings}/user-offers?${prepareQueryString(queryParams)}`;
        fetchWrapper.get(url, null, signal)
          .then((response) => {
            if (!response) return; // aborted request gives response === undefined
            let data = response.data;

            if (appendOnFetch && transactions?.data?.length && queryParams?.page > 1) {
              data = [...transactions.data, ...response.data];
            }

            setTransactions({ data, metadata: response.metadata });
            setLoaded(true);
            setShouldFetch(false);
          })
          .catch(() => {
            setLoaded(true);
            setShouldFetch(false);
          });
      }

      return abort;
    };

    return doFetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams, isRefresh, shouldFetch]);

  useEffect(() => {
    setShouldFetch(true);
  }, [queryParams]);

  return { loaded, refreshData };
}

export function useGetTransactionDetails(offerKey) {
  const [transactionDetails, setTransactionDetails] = useRecoilState(transactionDetailsState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    function abort(){ controller.abort(); }

    if (Object.keys(transactionDetails).length > 0) {
      return abort;
    }

    setLoaded(false);

    const url = `${APIs.Offerings}/user-offers/${offerKey}`;
    fetchWrapper.get(url, null, signal)
      .then((response) => {
        if (!response) return; // aborted request gives response === undefined
        setTransactionDetails(response);
        setLoaded(true);
      })
      .catch(() => {
        setLoaded(true);
      });

    return abort;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerKey, transactionDetails]);

  return { loaded };
}

export function useGetPendingTransactionsByListingKey(investorKey, listingKey) {
  const setTransactions = useSetRecoilState(transactionsByListingState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);
  const [prevInvestorKey, setPrevInvestorKey] = useState('');
  const [prevListingKey, setPrevListingKey] = useState('');

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    function abort(){ controller.abort(); }

    if (investorKey === prevInvestorKey && listingKey === prevListingKey) {
      setLoaded(true);
      return abort;
    }

    setPrevInvestorKey(investorKey);
    setPrevListingKey(listingKey);
    setLoaded(false);

    const queryParams = {
      holdingEntityKey: investorKey,
      listingKey,
      status: 'PUBLISHED',
    };
    const url = `${APIs.Offerings}/user-offers?${prepareQueryString(queryParams)}`;
    fetchWrapper.get(url, null, signal)
      .then((response) => {
        if (!response) return; // aborted request gives response === undefined
        setTransactions(response.data);
        setLoaded(true);
      }).catch(() => {
        setLoaded(true);
      });

    return abort;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investorKey, listingKey]);

  return { loaded };
}
