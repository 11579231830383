import { LinearProgress, Stack, Typography } from '@mui/material';

import PropTypes from 'prop-types';
import styles from '../styles';

const FormProgress = ({ value, color }) => {

  return (
    <Stack direction='row' spacing={1} sx={styles.LinearProgressContainer} alignItems='center'>
      <LinearProgress value={value} color={color} variant='determinate' sx={styles.LinearProgress} />
      <Typography variant='caption' color={color}>{value}%</Typography>
    </Stack>
  );
};

FormProgress.defaultProps = {
  value: 0,
  color: 'success',
};

FormProgress.propTypes = {
  value: PropTypes.number,
  color: PropTypes.string,
};

export default FormProgress;
