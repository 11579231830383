import { atom, selectorFamily } from 'recoil';

import _ from 'lodash';

const companyState = atom({
  key: 'companyState',
  default: {}
});

const fetchCompanyAttribute = selectorFamily({
  key: 'fetchCompanyAttribute',
  get: attribute => ({get}) => _.get(get(companyState), attribute),
  set: attribute => ({set}, newValue) =>
    set(companyState, prevState => ({...prevState, [attribute]: newValue})),
});

const companyPriceGraphState = atom({
  key: 'companyPriceGraphState',
  default: []
});

export {
  companyState,
  fetchCompanyAttribute,
  companyPriceGraphState
};
