import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
  styled
} from '@mui/material';
import { fetchTemplumInvestorKycStatusAttribute, holdingEntitiesState, investorState, kycTradeModalState, userState } from '../../_state';
import { formulateSteps, generateModalStepControls } from './utils';
import { useCallback, useEffect, useState } from 'react';
import { useCreateHoldingEntity, useGetUserHoldingEntities } from '../../_actions/holding-entities.actions';
import { useIsMobile, useIsTablet } from '../../_helpers';
import { useRecoilState, useRecoilValue } from 'recoil';

import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import styles from './styles';
import { useGetTemplumInvestorKycStatus } from '../../_actions/kyc-templum.actions';
import { useNavigate } from 'react-router-dom';

const StepLabelSubText = styled(Typography)(styles.StepLabelSubText);

const KycTradeModal = () => {
  const investor = useRecoilValue(investorState);
  const { loaded: loadedTemplumKycStatus, refreshData: refreshKycStatus } = useGetTemplumInvestorKycStatus();
  const user = useRecoilValue(userState);
  const { loaded: loadedHoldingEntities } = useGetUserHoldingEntities();
  const holdingEntities = useRecoilValue(holdingEntitiesState);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const navigate = useNavigate();
  const [open, setOpen] = useRecoilState(kycTradeModalState);
  const templumKycStatus = useRecoilValue(fetchTemplumInvestorKycStatusAttribute('status'));
  const [activeStep, setActiveStep] = useState();
  const steps = formulateSteps(templumKycStatus, user?.firstName);
  const { save } = useCreateHoldingEntity();

  const handleClose = useCallback(() => {
    setOpen(false);
  },[setOpen]);

  const handleNavigateToPersonalKyc = useCallback(() => {
    navigate('/account/basic-info');
    handleClose();
  },[handleClose, navigate]);

  const handleNavigateToEntityKyc = useCallback(async () => {
    if(holdingEntities?.length >= 1){
      navigate(`/account/basic-info?entity=${holdingEntities[0].entityKey}`);
    } else if(holdingEntities?.length === 0){
      save((newEntity) => {
        navigate(`/account/basic-info?entity=${newEntity.entityKey}`);
      });
    } else {
      navigate('/account/basic-info');
    }
    handleClose();
  },[handleClose, holdingEntities, navigate, save]);

  const modalButtons = generateModalStepControls(activeStep, templumKycStatus, handleNavigateToPersonalKyc, handleNavigateToEntityKyc, handleClose, investor);

  useEffect(() => {
    //Step 1 handles KYC_NOT_SUBMITTED, KYC_REJECTED, KYC_CALL_TO_ACTION
    if(templumKycStatus !== 'KYC_ACCEPTED' && templumKycStatus !== 'KYC_UNDER_REVIEW'){
      setActiveStep(1);
    }
    if(templumKycStatus === 'KYC_UNDER_REVIEW'){
      setActiveStep(2);
    }
  },[templumKycStatus]);

  // to ensure it always gets the latest status of the selected user account
  useEffect(() => refreshKycStatus(),[refreshKycStatus, investor]);

  if(!loadedTemplumKycStatus && !loadedHoldingEntities) return null;

  return (
    <Dialog
      open={open}
      fullScreen={isMobile}
      maxWidth='md'
      sx={styles.DialogPaper}
    >
      <DialogContent sx={styles.ContentContainer}>
        <Stack spacing={2}>
          {steps[activeStep].image}
          <Typography variant='h1' sx={styles.ModalTitle}>{steps[activeStep].title}</Typography>
          <Stepper
            orientation='horizontal'
            activeStep={activeStep}
          >
            {steps.map((step, i) => {
              if(i === 0){
                return (
                  <Step completed key={step.label} index={i} sx={styles.Step}>
                    <StepLabel sx={[styles.StepLabel, styles.Completed]}>Create Account</StepLabel>
                    <StepLabelSubText variant='caption' sx={styles.Completed}>Done</StepLabelSubText>
                  </Step>
                );
              }
              if(i === 1){
                return (
                  <Step key={step.label} index={i} sx={[styles.Step, styles.StepLargerWidth]}>
                    {templumKycStatus === 'KYC_NOT_SUBMITTED' ?
                      <StepLabel
                        sx={styles.StepLabel}
                      >
                        {steps[i].label}
                      </StepLabel>
                      :
                      <StepLabel
                        icon={<WarningIcon color={templumKycStatus === 'KYC_REJECTED' ? 'error' : 'secondary'} />}
                        sx={templumKycStatus === 'KYC_REJECTED' ? [styles.StepLabel, styles.Error] : [styles.StepLabel, styles.Reviewing]}
                      >
                        {steps[i].label}
                      </StepLabel>
                    }
                    <StepLabelSubText
                      variant='caption'
                      sx={templumKycStatus === 'KYC_REJECTED' ? styles.Error : templumKycStatus === 'KYC_NOT_SUBMITTED' ? null : styles.Reviewing}
                    >
                      {steps[i].labelSubText}
                    </StepLabelSubText>
                  </Step>
                );
              }
              return (
                <Step key={step.label} index={i} sx={styles.Step}>
                  <StepLabel sx={[styles.StepLabel, styles.Incomplete]}>Account Approval</StepLabel>
                  <StepLabelSubText variant='caption' sx={styles.Incomplete}>Start Trading</StepLabelSubText>
                </Step>
              );
            })}
          </Stepper>
          {steps[activeStep].body}
        </Stack>
        <DialogActions sx={styles.DialogActions}>
          <Stack direction='column' sx={styles.DialogActionsCtas} spacing={1}>
            {modalButtons}
            {(templumKycStatus === 'KYC_NOT_SUBMITTED' && (isMobile || isTablet))
              ?
              <Button
                component='span'
                onClick={handleClose}
                sx={styles.ButtonFullWidth}
              >
                {'Skip, I\'ll do this later'}
              </Button>
              :
              (templumKycStatus === 'KYC_NOT_SUBMITTED' && (!isMobile && !isTablet))
                ?
                <Box sx={styles.SmallSkipBtn}>
                  <Typography variant='caption' sx={styles.SmallSkipText}>{'Skip, I\'ll do this later'}</Typography>
                  <IconButton onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </Box>
                :
                null
            }
          </Stack>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default KycTradeModal;
