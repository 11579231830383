import { Typography } from '@mui/material';

const Copyright = () => {
  return (<>
    <Typography variant='subtitle2'>
      Notification
    </Typography>
    <Typography variant='body2'>
        If you are a copyright owner or an agent thereof and believe that any content infringes upon your copyrights, you may submit a notification pursuant to the Digital Millennium Copyright Act (“DMCA”) by providing our Copyright Agent with the following information in writing (see 17 U.S.C 512(c)(3) for further detail):
    </Typography>
    <Typography variant='body2'>
    - A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed
    </Typography>
    <Typography variant='body2'>
- Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online address are covered by a single notification, a representative list of such works at that online address
    </Typography>
    <Typography variant='body2'>
- Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled and information reasonably sufficient to permit the service provider, as defined by the DMCA, to locate the material
    </Typography>
    <Typography variant='body2'>
- Information reasonably sufficient to permit the service provider, as defined by the DMCA, to contact you, such as an address, telephone number, and, if available, an email address
    </Typography>
    <Typography variant='body2'>
- A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law
    </Typography>
    <Typography variant='body2'>
- A statement that the information in the notification is accurate, and under penalty of perjury, that you are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed
    </Typography>
    <Typography variant='body2'>
You may direct copyright infringement notifications to our DMCA Agent at: Paul Riss c/o CodeLaw, Box 301, Hingham, MA, 02043 and copyright@netcapital.com. You acknowledge that if you fail to comply with all of the requirements of this section, your DMCA notice may not be valid.
    </Typography><br /><br />
    <Typography variant='subtitle2'>
Counter Notice
    </Typography>
    <Typography variant='body2'>
    If you believe that your content that was removed (or to which access was disabled) is not infringing, or that you have the authorization from the copyright owner, the copyright owner’s agent, or pursuant to the law, to post and use the material in your Content, you may send a counter-notice containing the following information to the Copyright Agent:
    </Typography>
    <Typography variant='body2'>
- Your physical or electronic signature
    </Typography>
    <Typography variant='body2'>
- Identification of the content that has been removed or to which access has been disabled and the location at which the content appeared before it was removed or disabled
    </Typography>
    <Typography variant='body2'>
- A statement that you have a good faith belief that the content was removed or disabled as a result of mistake or a misidentification of the content
    </Typography>
    <Typography variant='body2'>
- Your name, address, telephone number, and e-mail address, a statement that you consent to the jurisdiction of the federal court in Delaware, and a statement that you will accept service of process from the person who provided notification of the alleged infringement
    </Typography>
    <Typography variant='body2'>
    If a counter-notice is received by the Copyright Agent, Netcapital may send a copy of the counter-notice to the original complaining party informing that person that it may replace the removed content or cease disabling it in 10 business days. Unless the copyright owner files an action seeking a court order against the content provider, member or user, the removed content may be replaced, or access to it restored, in 10 to 14 business days or more after receipt of the counter-notice, at Netcapital’s sole discretion.
    </Typography><br /><br /><br />
    <Typography variant='caption2'>
Last updated July 7, 2017
    </Typography>
  </>);
};

export default Copyright;
