import { atom } from 'recoil';

const notificationsState = atom({
  key: 'notificationsState',
  default: undefined
});

const notificationsStorageKey = 'nc_investors_web_notification_fetch';
const sessionStorageSync = storageKey => ({onSet}) => {
  onSet((newValue, _, isReset) => {
    isReset
      ? delete sessionStorage[storageKey]
      : sessionStorage.setItem(storageKey, JSON.stringify(newValue));
  });
};
const notificationsFetchState = atom({
  key: 'notificationsFetchState',
  default: JSON.parse(sessionStorage.getItem(notificationsStorageKey)),
  effects: [ sessionStorageSync(notificationsStorageKey) ],
});

const activeNotificationState = atom({
  key: 'activeNotificationState',
  default: {}
});

export {
  notificationsState,
  notificationsFetchState,
  activeNotificationState,
};
