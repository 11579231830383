import { MAIN_MENU_WIDTH, PAGE_MARGIN_WIDTHS } from '../layout-styles';

const styles = {
  FixedHeaderOffset: () => ({
    margin: 0,
  }),
  HeaderDesktop: {
    width: {
      lg: `calc(100% - ${MAIN_MENU_WIDTH.lg}px)`,
      xl: `calc(100% - ${MAIN_MENU_WIDTH.xl}px)`,
    },
    marginLeft: {
      lg: `${MAIN_MENU_WIDTH.lg}px`,
      xl: `${MAIN_MENU_WIDTH.xl}px`,
    },
  },
  HeaderToolbar: ({ spacing }) => ({
    paddingLeft: PAGE_MARGIN_WIDTHS,
    paddingRight: PAGE_MARGIN_WIDTHS,
    minHeight: {
      xs: spacing(7),
      sm: spacing(8),
    },
  }),
  HeaderToolbarDesktop: {
    flexGrow: 1,
    paddingRight: 0,
  },
  HeaderToolbarMobile: {
    display: 'flex',
    justifyContent: {
      xs: 'space-between',
      lg: 'center',
    },
  },
  HeaderToolbarTools: {
    flexGrow: 1,
    justifyContent: 'end',
  },
  MenuDesktop: {
    width: {
      lg: `${MAIN_MENU_WIDTH.lg}px`,
      xl: `${MAIN_MENU_WIDTH.xl}px`,
    },
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: {
        lg: `${MAIN_MENU_WIDTH.lg}px`,
        xl: `${MAIN_MENU_WIDTH.xl}px`,
      },
      boxSizing: 'border-box',
    },
    '& > .MuiPaper-elevation0': {
      border: 0,
    },
  },
  WalletBalanceMobile: {
    backgroundColor: 'gain.light',
    position: 'relative',
    paddingTop: 2,
    paddingBottom: 2,
    paddingRight: PAGE_MARGIN_WIDTHS,
    textAlign: 'right',
  },
  HamburgerButton: {
    paddingLeft: 0,
  },
  SearchBarMobile: {
    marginBottom: '1rem'
  },
  Sidebar: {
    backgroundColor: 'primary.main',
    color: 'common.white',
    display: 'block',
  },
};

export default styles;
