import { Box, IconButton, Link, Stack, Typography } from '@mui/material';
import { useCallback, useState } from 'react';

import Author from './author';
import Avatar from '../../Avatar';
import CommentForm from './form';
import { DateFormat } from '../../../_helpers';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Markdown from 'react-markdown';
import Modal from '../../../netcapital-components/Modal';
import ReplyIcon from '@mui/icons-material/Reply';
import { isAdminState } from '../../../_state';
import styles from './styles';
import { useDeleteComment } from '../../../_actions/company-discussion.actions';
import { useRecoilValue } from 'recoil';

const Comment = (props) => {
  const { comment, parentCommentKey, listingKey, onReply } = props;
  const isAdmin = useRecoilValue(isAdminState);
  const [isReplyFormOpen, setIsReplyFormOpen] = useState(false);
  const toggleReplyForm = useCallback(() => setIsReplyFormOpen(!isReplyFormOpen), [isReplyFormOpen]);
  const [modalOpen, setModalOpen] = useState(false);
  const toggleConfirmationModal = useCallback(() => {
    setModalOpen(!modalOpen);
  }, [modalOpen]);
  const { destroy } = useDeleteComment(listingKey);

  const handleReplySuccess = useCallback(() => {
    toggleReplyForm();
    if (typeof onReply === 'function') {
      onReply();
    }
  }, [onReply, toggleReplyForm]);

  const handleDeleteComment = useCallback(() => {
    destroy(comment.key, parentCommentKey);
    toggleConfirmationModal();
  }, [comment.key, destroy, parentCommentKey, toggleConfirmationModal]);

  return (
    <Stack spacing={2} direction='row' sx={parentCommentKey ? styles.CommentReply : null}>
      <Stack spacing={1}>
        <Avatar
          firstName={comment.author ? comment.author.firstName : ''}
          lastName={comment.author ? comment.author.lastName : ''}
        />
        {isAdmin && (
          <>
            <IconButton onClick={toggleConfirmationModal}><DeleteIcon color='error' /></IconButton>
            <Modal
              open={modalOpen}
              onClose={toggleConfirmationModal}
              color='warning'
              descriptionColor='common'
              title='Please, confirm'
              description={'Are you sure you want to delete this comment? This action cannot be undone.'}
              primaryBtnTitle='Delete'
              primaryBtnColor='error'
              handlePrimaryAction={handleDeleteComment}
              secondaryBtnTitle='Go Back'
              secondaryBtnColor='grey'
              handleSecondaryAction={toggleConfirmationModal}
            />
          </>
        )}
      </Stack>
      <Stack spacing={2} sx={styles.CommentContent}>
        <Box>
          <Author author={comment.author} />
          <Typography variant='caption'>{DateFormat.timeAgo(comment.created)}</Typography>
          <Typography variant='body2' component='div'>
            <Markdown>{comment.body}</Markdown>
          </Typography>
        </Box>
        {!parentCommentKey && !isReplyFormOpen && (
          <Link onClick={toggleReplyForm} sx={styles.ReplyLink}>
            <ReplyIcon sx={styles.ReplyIcon} /> Reply
          </Link>
        )}
        {isReplyFormOpen && (
          <CommentForm parentCommentKey={comment.key} onSuccess={handleReplySuccess} />
        )}
      </Stack>
    </Stack>
  );
};

export default Comment;
