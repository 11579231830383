import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';

import Announcement from '../Announcement';

const AnnouncementModal = ({ announcement, onClose, open }) => {
  return (
    <Dialog
      aria-labelledby='responsive-dialog-title'
      maxWidth='md'
      onClose={onClose}
      open={open}
    >
      <DialogContent>
        <Announcement
          announcement={announcement}
        />
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AnnouncementModal;
