import { Grid, List, ListItem, Typography } from '@mui/material';

import PropTypes from 'prop-types';
import styles from './styles';

const noValue = <Typography color='error' component='span'>Not answered</Typography>;

const ReadOnlyField = ({ label, value }) => {
  return (
    <Grid item xs={12} container spacing={2} sx={styles.Row}>
      <Grid item xs={12} sm={6}>
        <Typography variant='caption'>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant='body2' fontWeight='fontWeightMedium' component='div'>
          {!Array.isArray(value) && value}
          {Array.isArray(value) && (
            <List disablePadding>
              {value.map(val => <ListItem sx={styles.ListItem} key={val}>{val}</ListItem>)}
            </List>
          )}
        </Typography>
      </Grid>
    </Grid>
  );
};

ReadOnlyField.defaultProps = {
  value: noValue,
};

ReadOnlyField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.array]),
};

export default ReadOnlyField;
