const styles = {
  Button: () => ({
    width: '181px',
    padding: '14px',
  }),
  LockIcon: {
    display: 'inline-block',
    position: 'relative',
    top: '5px',
    marginRight: '4px',
  },
  MarginBottom: {
    marginBottom: '2rem'
  },
  MilitaryOrGovernmentOfficalTitle: {
    display: 'block',
    fontSize: '18px',
  },
};

export default styles;
