import { Grid } from '@mui/material';
import ReadOnlyField from '../../ReadOnlyField';
import { fetchUserPersonalInfoAttribute } from '../../../../_state';
import { useRecoilValue } from 'recoil';

const EmployerInfoReadOnly = () => {
  const employerName = useRecoilValue(fetchUserPersonalInfoAttribute('employment.employer.name'));
  const employerTitle = useRecoilValue(fetchUserPersonalInfoAttribute('employment.employer.title'));
  const employerOccupation = useRecoilValue(fetchUserPersonalInfoAttribute('employment.employer.occupation'));
  const employerStreetLine1 = useRecoilValue(fetchUserPersonalInfoAttribute('employment.employer.address.streetLine1'));
  const employerStreetLine2 = useRecoilValue(fetchUserPersonalInfoAttribute('employment.employer.address.streetLine2'));
  const employerCity = useRecoilValue(fetchUserPersonalInfoAttribute('employment.employer.address.city'));
  const employerState = useRecoilValue(fetchUserPersonalInfoAttribute('employment.employer.address.state'));
  const employerZipCode = useRecoilValue(fetchUserPersonalInfoAttribute('employment.employer.address.zipCode'));
  const employerCountry = useRecoilValue(fetchUserPersonalInfoAttribute('employment.employer.address.country'));

  return (
    <Grid item container xs={12} rowSpacing={1}>
      <ReadOnlyField label={'Employer Name'} value={employerName} />
      <ReadOnlyField label={'Job Title'} value={employerTitle} />
      <ReadOnlyField label={'Occupation'} value={employerOccupation} />
      <ReadOnlyField label={'Employer Street Line 1'} value={employerStreetLine1} />
      <ReadOnlyField label={'Employer Street Line 2'} value={employerStreetLine2} />
      <ReadOnlyField label={'Employer City'} value={employerCity} />
      <ReadOnlyField label={'Employer State'} value={employerState} />
      <ReadOnlyField label={'Employer Zip Code'} value={employerZipCode} />
      <ReadOnlyField label={'Employer Country'} value={employerCountry} />
    </Grid>
  );
};

export default EmployerInfoReadOnly;
