const styles = {
  CommentsContainer: {
    position: 'relative',
  },
  Loading: {
    position: 'absolute',
    background: 'rgba(255,255,255,0.85)',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  CommentReply: {
    paddingLeft: 6,
  },
  CommentContent: {
    width: '100%',
  },
  RepliesContainer: {
    paddingTop: 4,
  },
  ReplyIcon: {
    position: 'relative',
    top: '4px',
  },
  ReplyLink: {
    textDecoration: 'none',
  },
  Progress: {
    position: 'absolute',
    top: 0,
    width: '100%',
    left: 0,
    height: '0.313rem'
  },
  CloseButton: {
    position: 'absolute',
    top: '6px',
    right: '6px',
  },
  LoadMoreButton: {
    margin: 'auto',
  },
};

export default styles;
