import { Button, Stack } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useCallback, useContext } from 'react';
import { useIsMobile, useIsTablet } from '../../../_helpers';

import AccountFormContext from './context';
import DeleteHoldingEntity from './DeleteHoldingEntity';
import PropTypes from 'prop-types';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import styles from './styles';
import { templumFormKycStatusState } from '../../../_state';
import { useRecoilValue } from 'recoil';

const AccountFormActions = (props) => {
  const {
    isSubmitting,
    onSubmit,
  } = props;

  const {
    canDeleteHoldingEntity,
    canProceedToNextForm,
    canSubmitFinalStep,
    holdingEntityKey,
    isFinalStep,
  } = useContext(AccountFormContext);

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const kycStatus = useRecoilValue(templumFormKycStatusState);

  const handleSave = useCallback(() => onSubmit(), [onSubmit]);

  const {search} = useLocation();

  if (kycStatus?.status === 'KYC_ACCEPTED' || kycStatus?.status === 'KYC_REJECTED' || kycStatus?.status === 'KYC_UNDER_REVIEW') {
    return null;
  }

  return (
    <Stack
      spacing={{ xs: 1, sm: 6}}
      direction={{xs: 'column', sm: 'row'}}
      justifyContent={isMobile ? 'flex-end' : 'space-between'}
    >
      {canDeleteHoldingEntity && <DeleteHoldingEntity isTablet={isTablet} holdingEntityKey={holdingEntityKey} />}
      {!isFinalStep && (
        <>
          <Button
            startIcon={<SaveOutlinedIcon />}
            onClick={handleSave}
            disabled={isSubmitting}
            variant='outlined'
            size='large'
            sx={styles.SaveButton}
            color="success"
          >
            {isTablet ? 'Save' : `Save ${canProceedToNextForm ? '& Continue' : ''}`}
          </Button>
          <Button
            startIcon={<RocketLaunchOutlinedIcon />}
            component={RouterLink}
            to={`../review${search}`}
            onClick={handleSave}
            disabled={!canSubmitFinalStep}
            variant='contained'
            size='large'
            sx={styles.SubmitButton}
          >
            {isTablet ? 'Submit' : 'Review & Submit'}
          </Button>
        </>
      )}
    </Stack>
  );
};

AccountFormActions.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AccountFormActions;
