const styles = {
  AutoComplete: () => ({
    '& .MuiInputBase-root':{
      minHeight: '48px',
      borderRadius: '0.375rem',
      padding: '0px 15px',
      '& .MuiAutocomplete-input':{
        paddingLeft: 0
      }
    },
  }),
};

export default styles;
