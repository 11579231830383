import { Stack, TableCell, TableRow, Typography, styled } from '@mui/material';
import { useCallback, useContext, useState } from 'react';

import InvestorTransactionsQueryContext from './context';
import SortIcon from '../../SortIcon';
import styles from './styles';

const COLUMNS = [
  {
    label: 'Market',
    sortId: 'market',
  },
  {
    label: 'No. of Shares',
    sortId: 'allocatedQuantity',
  },
  {
    label: 'Price Per Unit',
    sortId: 'pricePerUnit',
  },
  {
    label: 'Total Amount',
    sortId: 'totalValue',
  },
  {
    label: 'Status',
    sortId: 'offerStatus',
  },
  {
    label: 'Date/Time',
    sortId: 'modified',
  },
  {
    label: 'Action',
    sortId: 'type',
  },
];

const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'columnName'
})(styles.TableCell);

const InvestorTransactionsHeader = () => {
  const {queryParams, setQueryParams} = useContext(InvestorTransactionsQueryContext);
  const [sortByColLabel, setSortBy] = useState('Market');
  const [sortDirection, setSortDirection] = useState('desc');
  const handleSort = useCallback((colLabel) => () => {
    setSortBy(colLabel);
    const newSortDirection = sortDirection === 'desc' ? 'asc' : 'desc';
    setSortDirection(newSortDirection);
    const colSortId = COLUMNS.find(col => col.label === colLabel)?.sortId;
    if (colSortId) {
      setQueryParams({
        ...queryParams,
        orderby: `${colSortId}:${newSortDirection}`
      });
    }
  }, [queryParams, setQueryParams, sortDirection]);

  return (
    <TableRow>
      {COLUMNS.map(column => (
        <StyledTableCell key={column.label} columnName={column.label}>
          <Stack spacing={1} direction='row'>
            <Typography
              onClick={handleSort(column.label)}
              variant='body2'
              color='primary'
              fontWeight='fontWeightMedium'
              sx={styles.NoWrap}
            >
              {column.label}
            </Typography>
            {sortByColLabel === column.label && <SortIcon direction={sortDirection} />}
          </Stack>
        </StyledTableCell>
      ))}
    </TableRow>
  );
};

export default InvestorTransactionsHeader;
