const styles = {
  ColumnLeft: {
    flexGrow: 1,
  },
  ColumnRight: {
    textAlign: 'right',
  },
  Shares: {
    fontWeight: '600',
  },
  CompanyName: {
    textDecoration: 'none'
  },
  Market: ({ palette }) => ({
    textTransform: 'lowercase',
    fontSize: '0.75rem',
    color: palette.text.light,
    '&::first-letter': {
      textTransform: 'capitalize'
    }
  }),
  DetailsToggle: {
    alignItems: 'start',
    padding: 0,
    paddingTop: '2px',
  },
  DetailsContainer: {
    padding: 2,
  },
  Status: () => ({
    fontSize: '0.75rem'
  }),
  ListItem: {
    paddingLeft: 0,
  },
};

export default styles;
