import { companyState, fetchAnnouncements } from '../../../../_state';

import Announcement from '../../../Announcement';
import Carousel from '../../../../netcapital-components/Carousel';
import { DEFAULT_QUERY_PARAMS } from '../../../../_actions/_api-urls';
import Section from '../../../Section';
import styles from './styles';
import { useGetAnnouncements } from '../../../../_actions/announcements.actions';
import { useRecoilValue } from 'recoil';
import { useState } from 'react';

const AnnouncementsOverview = () => {
  const company = useRecoilValue(companyState);
  const [queryParams,] = useState({...DEFAULT_QUERY_PARAMS, orderby: 'date:desc'});
  const {loaded: loadedAnnouncements} = useGetAnnouncements(company.key, queryParams);
  const announcements = useRecoilValue(fetchAnnouncements('data'));

  return (
    <Section
      content={
        <Carousel
          dots
          options={{
            align: 'center',
          }}
        >
          {announcements?.length > 0 && announcements.map(announcement => (
            <Announcement
              announcement={announcement}
              sx={styles.AnnouncementSlide}
              key={announcement.key}
            />
          ))}
        </Carousel>
      }
      title='Announcements'
      loaded={loadedAnnouncements}
      sxCard={styles.AnnouncementsCard}
    />
  );
};

export default AnnouncementsOverview;
