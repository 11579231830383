import { AppBar, Box, Drawer, Stack, Toolbar } from '@mui/material';

import { AppLogo } from './AppLogo';
import Help from './Help';
import MainMenu from './MainMenu';
import NotificationsMenu from './NotificationsMenu';
import SiteSearch from './SiteSearch';
import UserMenu from './UserMenu';
import WalletBalance from '../../components/WalletBalance';
import styles from './styles';

const HeaderDesktop = () => {
  return (
    <Box>
      <AppBar position='fixed' variant='light' sx={styles.HeaderDesktop}>
        <Stack direction='row'>
          <Toolbar sx={[styles.HeaderToolbar, styles.HeaderToolbarDesktop]}>
            <SiteSearch />
            <Stack direction='row' sx={styles.HeaderToolbarTools}>
              <NotificationsMenu />
              <Help />
              <UserMenu />
            </Stack>
          </Toolbar>
          <WalletBalance />
        </Stack>
      </AppBar>
      <Drawer
        sx={styles.MenuDesktop}
        variant='permanent'
        anchor='left'
        elevation={0}
      >
        <Toolbar sx={[styles.Sidebar, styles.HeaderToolbar]}>
          <AppLogo />
        </Toolbar>
        <MainMenu />
      </Drawer>
    </Box>
  );
};

export default HeaderDesktop;
