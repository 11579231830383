import { Button, Stack } from '@mui/material';
import { useCallback, useContext } from 'react';

import CurrentRaiseContext from './context';
import { companyFundingHistoryGraphState } from '../../../_state';
import styles from './styles';
import { useIsMobile } from '../../../_helpers';
import { useRecoilValue } from 'recoil';

const RaiseFilters = () => {
  const isMobile = useIsMobile();
  const fundingHistoryGraphData = useRecoilValue(companyFundingHistoryGraphState);
  const {selectedRaise, onChangeSelectedRaise} = useContext(CurrentRaiseContext);
  const handleClickFilter = useCallback((offerKey) => () => onChangeSelectedRaise(offerKey), [onChangeSelectedRaise]);

  return (
    <Stack direction='row' spacing={2}>
      {fundingHistoryGraphData.map((raise, index) => (
        <Button
          key={raise.offerKey}
          onClick={handleClickFilter(raise.offerKey)}
          variant={selectedRaise === raise.offerKey ? 'contained' : 'outlined'}
          size='small'
          sx={styles.FiltersButton}
        >
          {isMobile ? `R${index+1}` : `Raise ${index+1}`}
        </Button>
      ))}
    </Stack>
  );
};

export default RaiseFilters;
