import { useRecoilValue, useResetRecoilState } from 'recoil';

import { activeNotificationState } from '../../_state';
import { notificationPlaidFlows } from './utils';
import { useCallback } from 'react';
import { useDeleteNotification } from '../../_actions/notifications.actions';

const NotificationPlaidResponse = () => {
  const notification = useRecoilValue(activeNotificationState);
  const resetActiveNotification = useResetRecoilState(activeNotificationState);
  const {destroy: deleteNotification} = useDeleteNotification();
  const handleSuccess = useCallback(() => {
    deleteNotification(notification?.key);
    resetActiveNotification();
  }, [deleteNotification, notification, resetActiveNotification]);

  const PlaidFlow = notificationPlaidFlows[notification?.type];

  if (!notification?.key || !PlaidFlow) return null;

  return (
    <PlaidFlow onSuccess={handleSuccess} paymentMethodKey={notification?.paymentMethod?.key} />
  );
};

export default NotificationPlaidResponse;
