import { Alert, Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { companyState, fetchOfferAttribute, pendingOffersState } from '../../_state';
import { useCallback, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import PendingOffers from '../PendingOffers';
import styles from './styles';
import { useGetPendingOffers } from '../../_actions/pending-offers.actions';

const PendingOffersAlert = () => {
  const [showPendingOffers, setShowPendingOffers] = useState(false);
  const company = useRecoilValue(companyState);
  const { loaded: loadedPendingOffers } = useGetPendingOffers(company?.key);
  const pendingOffers = useRecoilValue(pendingOffersState);
  const setPricePerUnit = useSetRecoilState(fetchOfferAttribute('pricePerUnit'));
  const setOfferType = useSetRecoilState(fetchOfferAttribute('offerType'));
  const togglePendingOffersOpen = useCallback(() => setShowPendingOffers(!showPendingOffers), [showPendingOffers]);

  const handleClickPickPrice = useCallback((price, offerType) => {
    setPricePerUnit(parseFloat(price));
    setOfferType(offerType);
    setShowPendingOffers(false);
  }, [setOfferType, setPricePerUnit]);

  if (!loadedPendingOffers || pendingOffers?.length === 0) {
    return false;
  }

  return (
    <>
      <Alert
        severity='info'
        action={<Button variant='text' onClick={togglePendingOffersOpen}>View</Button>}
        sx={styles.PendingOffersLink}
      >
        There are pending transactions
      </Alert>
      <Dialog
        open={showPendingOffers}
        onClose={togglePendingOffersOpen}
        aria-labelledby="dialog-pending-offers-title"
        aria-describedby="dialog-pending-offers-description"
      >
        <DialogTitle id="dialog-pending-offers-title">Current Pending Offers</DialogTitle>
        <DialogContent id="dialog-pending-offers-description">
          <PendingOffers isDense onSelectPrice={handleClickPickPrice} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PendingOffersAlert;
