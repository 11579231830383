import { AppBar, Slide, Toolbar } from '@mui/material';
import { fetchTemplumInvestorKycStatusAttribute, kycTradeModalState } from '../../_state';
import { useCallback, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import OfferBarContent from './content';
import OfferModal from '../OfferModal';
import styles from './styles';

const OfferBar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenOfferModal = useCallback(() => setIsModalOpen(true), []);
  const handleCloseOfferModal = useCallback(() => setIsModalOpen(false), []);

  const templumKycStatus = useRecoilValue(fetchTemplumInvestorKycStatusAttribute('status'));
  const setKycTradeModalIsOpen = useSetRecoilState(kycTradeModalState);
  const handleVerifyKycBeforeTrading = useCallback(() => {
    if (templumKycStatus !== 'KYC_ACCEPTED') {
      setKycTradeModalIsOpen(true);
      return;
    }
    handleOpenOfferModal();
  },[templumKycStatus, handleOpenOfferModal, setKycTradeModalIsOpen]);

  return (
    <Slide in={!isModalOpen} direction='up'>
      <AppBar position='fixed' variant='light' elevation={4} sx={styles.MobileCheckout}>
        <Toolbar>
          <OfferBarContent onClick={handleVerifyKycBeforeTrading} />
          {isModalOpen && <OfferModal onClose={handleCloseOfferModal} open={isModalOpen} />}
        </Toolbar>
      </AppBar>
    </Slide>
  );
};

export default OfferBar;
