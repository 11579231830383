import { ACCREDITED_INVESTOR_CRITERIA, ASSET_TYPES, INDUSTRY_EXPERIENCE, INVESTMENT_DECISIONS, INVESTMENT_OBJECTIVES, QUALIFIED_PURCHASER_CRITERIA, YES_NO } from '../../../../static-info';

import Field from '../../../FormFields/Field';
import { Grid } from '@mui/material';
import HasMilitaryOrGovernmentAffiliations from '../../MilitaryOrGovernmentAffiliations';
import InvestorInfoReadOnly from './read-only';
import PropTypes from 'prop-types';
import RadioGroup from '../../../../netcapital-components/RadioGroup';
import Selectbox from '../../../../netcapital-components/Selectbox';
import TextField from '../../../../netcapital-components/TextField';
import { fetchUserInvestorInfoAttribute } from '../../../../_state';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

const InvestorInfoFields = ({ disabled }) => {
  const [isAccredited, setIsAccredited] = useRecoilState(fetchUserInvestorInfoAttribute('accreditation.isAccreditedInvestor'));
  const [accreditedReason, setAccreditedReason] = useRecoilState(fetchUserInvestorInfoAttribute('accreditation.method'));
  const [isQualifiedPurchaser, setIsQualifiedPurchaser] = useRecoilState(fetchUserInvestorInfoAttribute('isQualifiedPurchaser'));
  const [isOrRelatedToIndustryProfessional, setIsOrRelatedToIndustryProfessional] = useRecoilState(fetchUserInvestorInfoAttribute('affiliations.isOrRelatedToIndustryProfessional'));
  const [financialEntityIdentifier, setFinancialEntityIdentifier] = useRecoilState(fetchUserInvestorInfoAttribute('affiliations.industryProfessionalName'));
  const [isOrRelatedToControlPerson, setIsOrRelatedToControlPerson] = useRecoilState(fetchUserInvestorInfoAttribute('affiliations.isOrRelatedToControlPerson'));
  const [controlEntityIdentifier, setControlEntityIdentifier] = useRecoilState(fetchUserInvestorInfoAttribute('affiliations.controlPersonCompanyName'));
  const [investmentObjectives, setInvestmentObjectives] = useRecoilState(fetchUserInvestorInfoAttribute('investmentObjectives'));
  const [investmentDecisions, setInvestmentDecisions] = useRecoilState(fetchUserInvestorInfoAttribute('investmentDecisions'));
  const [assetTypesExperience, setAssetTypesExperience] = useRecoilState(fetchUserInvestorInfoAttribute('assetTypesExperience'));
  const [industriesExperience, setIndustriesExperience] = useRecoilState(fetchUserInvestorInfoAttribute('industriesExperience'));

  const handleChangeIsAccredited = useCallback((e) => {
    if(e.target.value === 'false'){
      setAccreditedReason('');
      setIsQualifiedPurchaser();
    }
    setIsAccredited(e.target.value === 'true');
  }, [setAccreditedReason, setIsAccredited, setIsQualifiedPurchaser]);
  const handleChangeAccreditedReason = useCallback((e) => setAccreditedReason(e.target.value), [setAccreditedReason]);
  const handleChangeIsQualifiedPurchaser = useCallback((e) => setIsQualifiedPurchaser(e.target.value === 'true'), [setIsQualifiedPurchaser]);
  const handleChangeIsOrRelatedToIndustryProfessional = useCallback((e) => {
    if(e.target.value === 'false'){
      setFinancialEntityIdentifier('');
    }
    setIsOrRelatedToIndustryProfessional(e.target.value === 'true');
  }, [setIsOrRelatedToIndustryProfessional, setFinancialEntityIdentifier]);
  const handleChangeFinancialEntityIdentifier = useCallback((e) => setFinancialEntityIdentifier(e.target.value), [setFinancialEntityIdentifier]);
  const handleChangeIsOrRelatedToControlPerson = useCallback((e) => {
    if(e.target.value === 'false'){
      setControlEntityIdentifier('');
    }
    setIsOrRelatedToControlPerson(e.target.value === 'true');
  }, [setIsOrRelatedToControlPerson, setControlEntityIdentifier]);
  const handleChangeControlEntityIdentifier = useCallback((e) => setControlEntityIdentifier(e.target.value), [setControlEntityIdentifier]);
  const handleChangeInvestmentObjectives = useCallback((e) => setInvestmentObjectives(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value,), [setInvestmentObjectives]);
  const handleChangeInvestmentDecisions = useCallback((e) => setInvestmentDecisions(e.target.value), [setInvestmentDecisions]);
  const handleChangeAssetTypesExperience = useCallback((e) => {
    //When user selects 'No Prior Investing Experience', we want to clear all other options in this case.
    if(Array.isArray(e.target.value) && e.target.value.includes('NO_EXPERIENCE')){
      setAssetTypesExperience(['NO_EXPERIENCE']);
    } else {
      setAssetTypesExperience(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value);
    }
  },[setAssetTypesExperience]);
  const handleChangeIndustriesExperience = useCallback((e) => setIndustriesExperience(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value,), [setIndustriesExperience]);

  if (disabled) return <InvestorInfoReadOnly />;

  return (
    <Grid item container xs={12} rowSpacing={4} columnSpacing={2}>
      <Grid item xs={12}>
        <Field label='Are you an accredited investor? *'>
          <RadioGroup
            options={YES_NO}
            value={isAccredited}
            onChange={handleChangeIsAccredited}
            fieldName={'accreditation.isAccreditedInvestor'}
          />
        </Field>
      </Grid>
      {isAccredited && (
        <Grid item xs={12}>
          <Field label='How are you accredited? *'>
            <Selectbox
              options={ACCREDITED_INVESTOR_CRITERIA.INDIVIDUAL}
              value={accreditedReason || ''}
              onChange={handleChangeAccreditedReason}
              placeholder='Please select one'
              fieldName={'accreditation.method'}
            />
          </Field>
        </Grid>
      )}
      {isAccredited && (
        <Grid item xs={12}>
          <Field label='Are you a qualified purchaser? *'>
            <RadioGroup
              options={QUALIFIED_PURCHASER_CRITERIA.INDIVIDUAL}
              value={isQualifiedPurchaser}
              onChange={handleChangeIsQualifiedPurchaser}
              fieldName={'isQualifiedPurchaser'}
            />
          </Field>
        </Grid>
      )}
      <Grid item xs={12}>
        <Field label='Are you, or is a member of your immediate family, an associated person of a broker-dealer or member of a National Securities Exchange? *'>
          <RadioGroup
            options={YES_NO}
            value={isOrRelatedToIndustryProfessional}
            onChange={handleChangeIsOrRelatedToIndustryProfessional}
            fieldName={'affiliations.isOrRelatedToIndustryProfessional'}
          />
        </Field>
      </Grid>
      {isOrRelatedToIndustryProfessional && (
        <Grid item xs={12}>
          <Field label='Name of Broker-Dealer or National Securities Exchange *'>
            <TextField
              value={financialEntityIdentifier || ''}
              onChange={handleChangeFinancialEntityIdentifier}
              fieldName={'affiliations.industryProfessionalName'}
            />
          </Field>
        </Grid>
      )}
      <Grid item xs={12}>
        <Field label='Are you, or is a member of your immediate family, a director, 10% stakeholder, or senior officer of a publicly traded company? *'>
          <RadioGroup
            options={YES_NO}
            value={isOrRelatedToControlPerson}
            onChange={handleChangeIsOrRelatedToControlPerson}
            fieldName={'affiliations.isOrRelatedToControlPerson'}
          />
        </Field>
      </Grid>
      {isOrRelatedToControlPerson && (
        <Grid item xs={12}>
          <Field label='Name or Ticker Symbol of Company *'>
            <TextField
              value={controlEntityIdentifier || ''}
              onChange={handleChangeControlEntityIdentifier}
              fieldName={'affiliations.controlPersonCompanyName'}
            />
          </Field>
        </Grid>
      )}
      <HasMilitaryOrGovernmentAffiliations
        label='Are you, or is a member of your immediate family, currently or formerly, either: A senior military, government, or political official? *'
        recoilState={{
          hasMilitaryOrGovernmentAffiliation: fetchUserInvestorInfoAttribute('affiliations.hasMilitaryOrGovernmentAffiliation'),
          militaryOrGovernmentAffiliation: fetchUserInvestorInfoAttribute('affiliations.militaryOrGovernmentAffiliation'),
        }}
      />
      <Grid item xs={12}>
        <Field label='What are your investment objectives? *'>
          <Selectbox
            multiple
            options={INVESTMENT_OBJECTIVES}
            value={investmentObjectives || []}
            onChange={handleChangeInvestmentObjectives}
            placeholder='Please select all that apply'
            fieldName={'investmentObjectives'}
          />
        </Field>
      </Grid>
      <Grid item xs={12}>
        <Field label='How do you generally make investment decisions? (optional)'>
          <Selectbox
            options={INVESTMENT_DECISIONS}
            value={investmentDecisions || ''}
            onChange={handleChangeInvestmentDecisions}
            fieldName={'investmentDecisions'}
          />
        </Field>
      </Grid>
      <Grid item xs={12}>
        <Field label='Which of the following asset types do you have experience investing in? *'>
          <Selectbox
            multiple
            options={ASSET_TYPES}
            value={assetTypesExperience || []}
            onChange={handleChangeAssetTypesExperience}
            placeholder='Please select all that apply'
            fieldName={'assetTypesExperience'}
          />
        </Field>
      </Grid>
      <Grid item xs={12}>
        <Field label='Which of the following industries do you have experience investing in? (optional)'>
          <Selectbox
            multiple
            options={INDUSTRY_EXPERIENCE}
            value={industriesExperience || []}
            onChange={handleChangeIndustriesExperience}
            placeholder='Please select all that apply'
            fieldName={'industriesExperience'}
          />
        </Field>
      </Grid>
    </Grid>
  );
};

InvestorInfoFields.defaultProps = {
  disabled: false,
};

InvestorInfoFields.propTypes = {
  disabled: PropTypes.bool,
};

export default InvestorInfoFields;
