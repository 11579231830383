import { Grid, Stack, Tooltip, Typography } from '@mui/material';
import { investorPortfolioValueState, investorState } from '../../_state';

import Delta from '../Delta';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { NumberFormat } from '../../_helpers';
import Section from '../Section';
import { useGetPortfolioValue } from '../../_actions/portfolio.actions';
import { useRecoilValue } from 'recoil';

const ItemTitle = ({title, tooltip}) => (
  <Stack direction='row' spacing={1}>
    <Typography variant='body2' textTransform='uppercase' color='grey.main'>{title}</Typography>
    <Tooltip title={tooltip}><HelpOutlineOutlinedIcon color='info' fontSize='14px' /></Tooltip>
  </Stack>
);

const PortfolioValue = () => {
  const investor = useRecoilValue(investorState);
  const { loaded } = useGetPortfolioValue(investor.entityKey);
  const portfolioValue = useRecoilValue(investorPortfolioValueState);

  return (
    <Section
      canCollapse={false}
      loaded={loaded}
      content={
        <Grid container spacing={{ xs: 2, sm: 3 }}>
          <Grid item xs={6} md={4}>
            <ItemTitle title='Current Value' tooltip='The latest estimated worth of an investor‘s shares in private companies, based on secondary market data or the most recent valuations.' />
            <Typography variant='h3' fontWeight='fontWeightBold'>
              {loaded && NumberFormat.cents(portfolioValue?.currentValue, { minimumFractionDigits: 2 })}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <ItemTitle title='Unrealized Return' tooltip='The percentage increase or decrease in the value of an investor‘s shares compared to the initial investment, not yet realized through sale.' />
            {loaded && (
              <Delta
                value={portfolioValue?.unrealizedReturn || 0}
                type='flat'
                forceDecimals
                formatType='percent'
                variant='h3'
                justifyContent='flex-start'
              />
            )}
          </Grid>
          <Grid item xs={6} md={4}>
            <ItemTitle title='Unrealized Gain/Loss' tooltip='The potential profit or loss on an investor‘s shares, reflecting the difference between their current value and the purchase price.' />
            <Typography variant='h3'>
              {loaded && (
                <Delta
                  value={portfolioValue?.unrealizedGainLoss || 0}
                  type='flat'
                  forceDecimals
                  formatType='money'
                  variant='h3'
                  justifyContent='flex-start'
                />
              )}
            </Typography>
          </Grid>
        </Grid>
      }
    />
  );
};

export default PortfolioValue;
