import { Button, Grid, Stack, useMediaQuery } from '@mui/material';
import { useCreateHoldingEntity, useGetUserHoldingEntities } from '../../_actions/holding-entities.actions';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import AccountEditDisclaimer from './disclaimer';
import AccountNav from './nav';
import AddIcon from '@mui/icons-material/Add';
import CircularLoading from '../../netcapital-components/CircularLoading';
import HoldingEntityAccount from '../../components/Account/HoldingEntity';
import UserAccount from '../../components/Account/User';
import styles from './styles';
import { useCallback } from 'react';
import { useTheme } from '@emotion/react';

const AccountPage = () => {
  const {formId} = useParams();
  const [searchParams] = useSearchParams();
  const holdingEntityKey = searchParams.get('entity');
  const {loaded} = useGetUserHoldingEntities();
  const {save, saving} = useCreateHoldingEntity();
  const navigate = useNavigate();

  const handleSuccessCreateHoldingEntity = useCallback((newHoldingEntity) => {
    navigate(`../?entity=${newHoldingEntity?.entityKey}`);
  }, [navigate]);
  const handleClickCreateHoldingEntity = useCallback(() => {
    save(handleSuccessCreateHoldingEntity);
  }, [save, handleSuccessCreateHoldingEntity]);

  const theme = useTheme();
  const isBelowMediumBreakpoint = useMediaQuery(theme.breakpoints.down('md'));

  if (!loaded) {
    return <CircularLoading />;
  }

  return (
    <Grid container rowSpacing={3} name='AccountPage'>
      {(!isBelowMediumBreakpoint || !formId) && (
        <Grid item xs={12} name='AccountTabsContainer'>
          <Stack direction={{xs: 'column-reverse', sm: 'row'}}>
            <AccountNav currentTab={holdingEntityKey || 'user'} />
            <Button
              variant='contained'
              onClick={handleClickCreateHoldingEntity}
              startIcon={<AddIcon />}
              color='success'
              size='large'
              disabled={saving}
              sx={styles.CreateHoldingEntityButton}
            >
              New Entity Account
            </Button>
          </Stack>
        </Grid>
      )}
      <Grid container item xs={12} name='AccountFormContainer' sx={styles.AccountFormContainer}>
        <Grid item xs={12}>
          <AccountEditDisclaimer isHoldingEntity={Boolean(holdingEntityKey)} />
        </Grid>
        {!holdingEntityKey && <UserAccount />}
        {holdingEntityKey && <HoldingEntityAccount holdingEntityKey={holdingEntityKey} />}
      </Grid>
    </Grid>
  );
};

export default AccountPage;
