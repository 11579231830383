import { MAIN_MENU_WIDTH, PAGE_MARGIN_WIDTHS } from '../layout-styles';

const styles = {
  AppContainer: {
    minHeight: '100vh',
  },
  LayoutContainer: {
    minHeight: '100vh',
  },
  FlexFullWidth: {
    flexGrow: 1,
  },
  RouteContent: () => ({
    maxWidth: 'xl',
    marginTop: {
      xs: 3,
      sm: 4,
      lg: 8, // Offset the height of the sticky header
    },
    marginLeft: {
      lg: `${MAIN_MENU_WIDTH.lg}px`,
      xl: `${MAIN_MENU_WIDTH.xl}px`,
    },
    paddingTop: {
      lg: 6, // This matches MainMenu's distance between SiteLogo and NavItems
    },
    paddingLeft: PAGE_MARGIN_WIDTHS,
    paddingRight: PAGE_MARGIN_WIDTHS,
    width: {
      xs: '100%',
      lg: `calc(100% - ${MAIN_MENU_WIDTH.lg}px)`,
      xl: `calc(100% - ${MAIN_MENU_WIDTH.xl}px)`,
    },
  }),
};

export default styles;
