import { atom } from 'recoil';

const plaidPublicTokenState = atom({
  key: 'plaidPublicTokenState',
  default: undefined,
});

export {
  plaidPublicTokenState,
};
