import Raise from './row';
import { TableBody } from '@mui/material';
import { fetchCompanyFundingHistoryAttribute } from '../../../../_state';
import { useRecoilValue } from 'recoil';

const FundingHistoryBody = () => {
  const fundingHistory = useRecoilValue(fetchCompanyFundingHistoryAttribute('data')) || [];

  return (
    <TableBody>
      {fundingHistory.map(raise => (
        <Raise
          raise={raise}
          key={raise.key}
        />
      ))}
    </TableBody>
  );
};

export default FundingHistoryBody;
