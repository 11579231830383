import { Stack, Tooltip, Typography } from '@mui/material';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { NumberFormat } from '../../../_helpers';
import { companyFundingHistoryGraphState } from '../../../_state';
import styles from './styles';
import { useRecoilValue } from 'recoil';

const TotalRaiseAmount = () => {
  const fundingHistoryGraphData = useRecoilValue(companyFundingHistoryGraphState);
  const totalRaised = fundingHistoryGraphData?.reduce((accumulator, raise) => accumulator + raise.raisedGoal, 0) || 0;

  return (
    <Stack>
      <Stack direction='row' spacing={1}>
        <Typography variant='overline'>Raised to Date</Typography>
        <Tooltip
          title='Due to rounding, numbers presented in this tab representing Raise Goal, Amount Raised and Raised-to-Date may not reflect absolute figures.'
          PopperProps={{
            sx: styles.TooltipPopper
          }}
        >
          <InfoOutlinedIcon sx={styles.HelpIcon} />
        </Tooltip>
      </Stack>
      <Typography variant='h3' color='gain.main'>
        {totalRaised ? NumberFormat.cents(totalRaised) : <span>&nbsp;</span>}
      </Typography>
    </Stack>
  );
};

export default TotalRaiseAmount;
