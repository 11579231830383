// skipcq: JS-C1003 - yup does not expose itself as an ES Module.
import * as Yup from 'yup';

const HoldingEntityDocumentsSchema = Yup.object()
  .shape({})
  .when(
    ['$entityType', '$documents'],
    ([entityType, documents], schema) => {
      if (entityType === 'S_CORP' || entityType === 'C_CORP') {
        return schema.shape({
          CERTIFICATE_OF_INCORPORATION: documents?.CERTIFICATE_OF_INCORPORATION
            ? Yup.string().notRequired()
            : Yup.string().required('Required'),
          OPERATING_AGREEMENT: documents?.OPERATING_AGREEMENT
            ? Yup.string().notRequired()
            : Yup.string().required('Required'),
          EIN_EVIDENCE: documents?.EIN_EVIDENCE
            ? Yup.string().notRequired()
            : Yup.string().required('Required'),
        });
      }
      if (entityType === 'TRUST') {
        return schema.shape({
          TRUST_DOCUMENT: documents?.TRUST_DOCUMENT
            ? Yup.string().notRequired()
            : Yup.string().required('Required'),
          CERTIFICATE_OF_FORMATION: documents?.CERTIFICATE_OF_FORMATION
            ? Yup.string().notRequired()
            : Yup.string().required('Required'),
          EIN_EVIDENCE: documents?.EIN_EVIDENCE
            ? Yup.string().notRequired()
            : Yup.string().required('Required'),
        });
      }
      if (entityType === 'LLC' || entityType === 'LP' || entityType === 'OTHER') {
        return schema.shape({
          CERTIFICATE_OF_FORMATION: documents?.CERTIFICATE_OF_FORMATION
            ? Yup.string().notRequired()
            : Yup.string().required('Required'),
          OPERATING_AGREEMENT: documents?.OPERATING_AGREEMENT
            ? Yup.string().notRequired()
            : Yup.string().required('Required'),
          EIN_EVIDENCE: documents?.EIN_EVIDENCE
            ? Yup.string().notRequired()
            : Yup.string().required('Required'),
        });
      }
      return schema;
    }
  );

export default HoldingEntityDocumentsSchema;
