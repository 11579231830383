import { Box, Stack, Tooltip, Typography } from '@mui/material';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { NumberFormat } from '../../../../_helpers';
import { companyFundingHistoryGraphState } from '../../../../_state';
import { useRecoilValue } from 'recoil';

const FundingHistoryStats = () => {
  const fundingHistoryGraphData = useRecoilValue(companyFundingHistoryGraphState);
  const totalRaised = fundingHistoryGraphData?.reduce((accumulator, raise) => accumulator + raise.raisedGoal, 0) || 0;

  return (
    <Box>
      <Stack direction='row' spacing={1} alignItems={'flex-start'}>
        <Typography variant='body2'>Raised to Date</Typography>
        <Tooltip title='Due to rounding, numbers presented in this tab representing Raise Goal, Amount Raised and Raised-to-Date may not reflect absolute figures.'>
          <InfoOutlinedIcon fontSize='12px' />
        </Tooltip>
      </Stack>
      <Typography variant='h3' color='gain.main'>{totalRaised ? NumberFormat.cents(totalRaised, {notation: 'compact'}) : <span>&nbsp;</span>}</Typography>
    </Box>
  );
};

export default FundingHistoryStats;
