import { atom, selectorFamily } from 'recoil';

const walletState = atom({
  key: 'walletState',
  default: undefined,
});

const fetchWalletAttribute = selectorFamily({
  key: 'fetchWalletAttribute',
  get: attribute => ({get}) => get(walletState)[attribute],
  set: attribute => ({set}, newValue) =>
    set(walletState, prevState => ({...prevState, [attribute]: newValue})),
});

export {
  walletState,
  fetchWalletAttribute,
};
