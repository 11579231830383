const styles = {
  ScrollContainer: {
    overflow: 'auto',
  },
  EndMessage: {
    paddingTop: 1,
    paddingBottom: 1
  },
};

export default styles;
