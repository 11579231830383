const styles = {
  HelpSubMenu: {
    padding: '10px',
  },
  HelpSubMenuCategory: {
    fontWeight: 'fontWeightMedium',
    paddingLeft: '1rem',
  },
  MenuLink: () => ({
    textDecoration: 'none',
  }),
};

export default styles;
