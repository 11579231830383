import { Stack, Typography } from '@mui/material';

import { NumberFormat } from '../../../../_helpers';
import styles from './styles';

const BarTooltip = ({ active, payload, color }) => {
  // TODO: figure out a way to pass the key for the data point, to fully have custom data label/keys.
  const valueKey = 'value';

  if (active && payload?.length) {
    const goalPayload = payload.find(obj => obj.dataKey === 'goal');
    const raisedGoalPayload = payload.find(obj => obj.dataKey === 'raisedGoal');
    const raisedGoal = NumberFormat.cents(goalPayload[valueKey]);
    const amountRaised = NumberFormat.cents(raisedGoalPayload[valueKey]);
    const date = payload[0].payload.endDate;

    return (
      <Stack sx={styles.Tooltip}>
        <Typography variant='caption1' fontWeight='fontWeightMedium' color={color}>{`${raisedGoal} Goal`}</Typography>
        <Typography variant='caption1' fontWeight='fontWeightMedium' color={color}>{`${amountRaised} Raised`}</Typography>
        <Typography variant='caption1' fontWeight='fontWeightMedium' color={color}>{`on ${date}`}</Typography>
      </Stack>
    );
  }

  return null;
};

export default BarTooltip;
