import { useGetHoldingEntityBeneficialOwners, useSaveHoldingEntityBeneficialOwners } from '../../../../_actions/holding-entities.actions';

import AccountFormActions from '../../Form/actions';
import BeneficialOwnersFields from './fields';
import BeneficialOwnersReadOnly from './read-only';
import CircularLoading from '../../../../netcapital-components/CircularLoading';
import FormContext from '../../../../netcapital-components/FormValidation/FormContext';
import HoldingEntityBeneficialOwnersSchema from './schema';
import { Stack } from '@mui/material';
import accountFormStyles from '../../Form/styles';
import { holdingEntityBeneficialOwnersState } from '../../../../_state';
import { useFormValidation } from '../../../../netcapital-components/FormValidation';
import { useSaveAccountForm } from '../../Form/hooks';

const BeneficialOwners = ({ holdingEntityKey, disabled }) => {
  const {loaded} = useGetHoldingEntityBeneficialOwners(holdingEntityKey);
  const {validate, validateField, errors, removeError} = useFormValidation(holdingEntityBeneficialOwnersState, HoldingEntityBeneficialOwnersSchema);
  const {save, saving} = useSaveHoldingEntityBeneficialOwners(holdingEntityKey);
  const {saveAccountForm} = useSaveAccountForm(save, validate);

  if (!loaded) return <CircularLoading />;

  if (disabled) return <BeneficialOwnersReadOnly />;

  return (
    <FormContext.Provider value={{validateField, errors, removeError}}>
      <Stack spacing={3} sx={accountFormStyles.FormContentWell}>
        <BeneficialOwnersFields
          holdingEntityKey={holdingEntityKey}
          disabled={disabled}
        />
        <AccountFormActions
          onSubmit={saveAccountForm}
          isSubmitting={saving}
        />
      </Stack>
    </FormContext.Provider>
  );
};

export default BeneficialOwners;
