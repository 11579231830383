import { investorPortfolioGraphState, investorState } from '../../_state';
import { useCallback, useState } from 'react';

import ChartPortfolio from './chart';
import Section from '../Section';
import { useGetPortfolioGraph } from '../../_actions/portfolio.actions';
import { useRecoilValue } from 'recoil';

const portfolioExpansionStorage = localStorage.getItem('portfolioExpansionStorage') === 'true' || true;

const PortfolioValueHistory = () => {
  const [activeDateRange, setActiveDateRange] = useState('today');
  const investor = useRecoilValue(investorState);

  const portfolioGraph = useRecoilValue(investorPortfolioGraphState);
  const { loaded } = useGetPortfolioGraph(investor.entityKey, activeDateRange);

  const [expanded, setExpanded] = useState(portfolioExpansionStorage);
  const handleCardExpansion = useCallback((value) => {
    setExpanded(value);
    localStorage.setItem('portfolioExpansionStorage', value);
  }, [setExpanded]);

  const handleDateRange = useCallback((dateIdentifier) => {
    setActiveDateRange(dateIdentifier);
  }, []);

  return (
    <Section
      canCollapse={portfolioGraph?.length > 1}
      content={portfolioGraph?.length > 1 && (
        <ChartPortfolio
          data={portfolioGraph}
          setActiveDateRange={handleDateRange}
          activeDateRange={activeDateRange}
        />
      )}
      loaded={loaded}
      expanded={expanded === true && portfolioGraph?.length > 1}
      onAction={handleCardExpansion}
      title='Portfolio Over Time'
    />
  );
};

export default PortfolioValueHistory;
