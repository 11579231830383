import { atom, selectorFamily } from 'recoil';

import _ from 'lodash';
import produce from 'immer';

const notificationResponseState = atom({
  key: 'notificationResponseState',
  default: {}
});

const fetchNotificationResponseAttribute = selectorFamily({
  key: 'fetchNotificationResponseAttribute',
  get: attribute => ({get}) => _.get(get(notificationResponseState), attribute),
  set: attribute => ({set}, newValue) => {
    set(notificationResponseState,
      prevState => produce(prevState, (draft) => _.set(draft, attribute, newValue))
    );
  },
});

export {
  notificationResponseState,
  fetchNotificationResponseAttribute,
};
