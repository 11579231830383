import { atom, selectorFamily } from 'recoil';

const companyProfileState = atom({
  key: 'companyProfileState',
  default: {
    logo: 'https://picsum.photos/672',
    address: {
      city: 'Cornwall on Hudson',
      state: 'NY',
    },
    industries: ['Consumer Products', 'Goods & Services'],
    techSector: 'Hardwaretech',
    distributionModel: 'B2C',
    margin: 'Medium',
    capitalIntensity: 'High',
    businessType: 'Growth',
    socialMedia: {
      linkedIn: 'https://linkedin.com',
      facebook: 'https://facebook.com',
      twitter: 'https://twitter.com',
    },
    website: 'https://google.com',
  }
});

const fetchCompanyProfileAttribute = selectorFamily({
  key: 'fetchCompanyProfileAttribute',
  get: attribute => ({get}) => get(companyProfileState)[attribute],
  set: attribute => ({set}, newValue) =>
    set(companyProfileState, prevState => ({...prevState, [attribute]: newValue})),
});

export {
  companyProfileState,
  fetchCompanyProfileAttribute,
};
