import { Button, Link, Stack, Typography } from '@mui/material';

import { ReactComponent as SvgKycTradeModalProfileCallToAction } from '../../assets/kyc-trade-modal-call-to-action.svg';
import { ReactComponent as SvgKycTradeModalProfileCompleted } from '../../assets/kyc-trade-modal-complete-profile.svg';
import { ReactComponent as SvgKycTradeModalProfilePendingApproval } from '../../assets/kyc-trade-modal-profile-pending-approval.svg';
import { ReactComponent as SvgKycTradeModalProfileRejected } from '../../assets/kyc-trade-modal-rejected-profile.svg';
import styles from './styles';

export const formulateSteps = (kycStatus, userName) => {
  let stepTitle, stepBody, stepImage, reviewStepLabel, reviewStepLabelSubText;

  if(kycStatus === 'KYC_UNDER_REVIEW'){
    stepTitle = 'Please be patient... your application is under review';
    stepImage = <SvgKycTradeModalProfilePendingApproval className='ModalSvg' />;
    reviewStepLabel = 'Complete Profile';
    reviewStepLabelSubText = 'Under Review';
    stepBody = <Typography variant='body1' sx={styles.ModalBody}>Your application has been submitted and is under review. You will be notified if any additional information is required and once your account is approved. In the meantime, please remember that you need to have sufficient funds in your wallet in order to place buy orders. To deposit wallet funds, <Link href={`${process.env.REACT_APP_WEB_HOST}/payments/transfer`}>click here</Link>.</Typography>;
  } else if(kycStatus === 'KYC_REJECTED'){
    stepTitle = 'We\'re sorry...your application was not approved';
    stepImage = <SvgKycTradeModalProfileRejected className='ModalSvg' />;
    reviewStepLabel = 'Complete Profile';
    reviewStepLabelSubText= 'Application Denied';
    stepBody = <Typography variant='body1' sx={styles.ModalBody}>Unfortunately, there was an issue with your trading application that caused it to be denied. If you have not yet received a notification by email, please reach out to our team at <Link href='mailto:help@netcapital.com'>help@netcapital.com</Link>.</Typography>;
  } else if(kycStatus === 'KYC_CALL_TO_ACTION'){
    stepTitle = 'Resubmit application';
    stepImage = <SvgKycTradeModalProfileCallToAction className='ModalSvg' />;
    reviewStepLabel = 'Complete Profile';
    reviewStepLabelSubText = 'Action Required';
    stepBody = <Typography variant='body1' sx={styles.ModalBody}>We&lsquo;re sorry, it appears that there was a problem with your trading application. If you have not yet received a notification by email to remediate this, please reach out to our team at <Link href='mailto:help@netcapital.com'>help@netcapital.com</Link>.</Typography>;
  } else {
    stepTitle = `${userName}, just a few steps left to start trading`;
    stepImage = <SvgKycTradeModalProfileCompleted className='ModalSvg' />;
    reviewStepLabel = 'Complete Profile';
    reviewStepLabelSubText = 'For Approval';
    stepBody = <Typography variant='body1' sx={styles.ModalBody}>{'Before commencing trading on the Marketplace, you must verify your identity by providing basic information, including documentation such as your passport or your driver\'s license. To begin, select "Individual Account" if you intend to trade directly or "Entity Account" if you intend to trade on behalf of a legal entity (e.g., Trust, LLC, etc.).'}</Typography>;
  }

  return (
    [
      {
        label: 'Create Account' //Pseudo-step for already "completed" step
      },
      {
        label: reviewStepLabel,
        labelSubText: reviewStepLabelSubText,
        image: stepImage,
        title: stepTitle,
        body: stepBody,
      },
      {
        label: 'KYC Pending Approval', //display label is overwritten for this step, but we still need this for the map() as a key
        //labelSubText is overwritten for this step
        image: stepImage,
        title: stepTitle,
        body: stepBody,
      },
    ]
  );
};

export const generateModalStepControls = (activeStep, kycStatus, handlePrimaryCta, handleSecondaryCta, handleClose, investor) => {
  let primaryCta, secondaryCta;
  const investorIsEntity = investor?.legalName || investor?.name === 'Unnamed Entity';

  if (activeStep === 1 && (kycStatus === 'KYC_REJECTED' || kycStatus === 'KYC_CALL_TO_ACTION')){
    primaryCta = <Button variant='outlined' color='primary' onClick={handleClose} sx={styles.ButtonFullWidth}>Close</Button>;
    secondaryCta = null;
  } else if (activeStep === 1){
    primaryCta = investorIsEntity ? null : <Button variant='contained' color='primary' onClick={handlePrimaryCta} sx={styles.ButtonFullWidth}>Individual Account</Button>;
    secondaryCta = <Button variant='contained' color='primary' onClick={handleSecondaryCta} sx={styles.ButtonFullWidth}>Entity Account</Button>;
  } else if (activeStep === 2){
    primaryCta = <Button variant='outlined' color='primary' onClick={handleClose} sx={styles.ButtonFullWidth}>Close</Button>;
    secondaryCta = null;
  }

  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
      {primaryCta}
      {secondaryCta}
    </Stack>
  );
};
