import { useEffect, useState } from 'react';

import { APIUserURL } from './_api-urls';
import { useFetchWrapper } from '../_helpers';
import { useRecoilState } from 'recoil';
import { userListingsState } from '../_state';

export function useGetUserListingEntities(entityKey='') {
  const [listingEntities, setListingEntities] = useRecoilState(userListingsState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    function abort(){ controller.abort(); }

    if (Object.keys(listingEntities).length > 0){
      setLoaded(true);
      return abort;
    }

    const url = `${APIUserURL(entityKey)}/user-listings`;
    fetchWrapper.get(url, null, signal, true)
      .then((response) => {
        if (!response) return; // aborted request gives response === undefined
        setListingEntities(response);
        setLoaded(true);
      });

    return abort;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityKey]);

  return { loaded };
}
