import { Box, FormHelperText, Stack } from '@mui/material';

import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import styles from './styles';

const FormFieldErrorMessage = (props) => {

  if (!props.children) {
    return false;
  }

  return (
    <FormHelperText
      error
      component={Stack}
      direction='row'
      spacing={0.5}
      sx={styles.ErrorMessage}
    >
      <WarningAmberOutlinedIcon sx={styles.ErrorMessageIcon} />
      <Box>{props.children}</Box>
    </FormHelperText>
  );
};

export default FormFieldErrorMessage;
