// skipcq: JS-C1003 - yup does not expose itself as an ES Module.
import * as Yup from 'yup';

import { SOURCES_OF_WEALTH } from '../../static-info';

const InvestorAffiliationsSchema = Yup.object({
  isOrRelatedToIndustryProfessional:
    Yup.bool().required('Required'),
  industryProfessionalName:
    Yup.string()
      .when('isOrRelatedToIndustryProfessional', {
        is: true,
        then: (schema) => schema.required('Required'),
      }),
  isOrRelatedToControlPerson:
    Yup.bool().required('Required'),
  controlPersonCompanyName:
    Yup.string()
      .when('isOrRelatedToControlPerson', {
        is: true,
        then: (schema) => schema.required('Required'),
      }),
  hasMilitaryOrGovernmentAffiliation:
    Yup.bool().required('Required'),
  militaryOrGovernmentAffiliation:
    Yup.array().of(Yup.object({
      employedBy: Yup.string().required('Required'),
      position: Yup.string().required('Required'),
      governmentBranch: Yup.string().required('Required'),
      country: Yup.string().required('Required'),
      sourceOfWealth: Yup.array().of(
        Yup.string()
          .oneOf(SOURCES_OF_WEALTH.map(option => option.value))
          .required('Required')
      ).min(1, 'Required').required('Required'),
    }))
      .when('hasMilitaryOrGovernmentAffiliation', {
        is: true,
        then: (schema) => schema.required('Required'),
      })
});

export default InvestorAffiliationsSchema;
