import { createContext } from 'react';

const FormContext = createContext({
  validate: () => { return; },
  validateField: () => () => { return; },
  errors: null,
  removeError: () => () => { return; },
});

export default FormContext;
