import { Button, Tooltip } from '@mui/material';

import PropTypes from 'prop-types';
import styles from '../styles';

const BLOCKED_MESSAGE = 'This company is temporarily prohibited from being traded on the Marketplace';

const OfferButton = ({ isSecondaryMarketOpen, offerType, onClick, size }) => {
  const action = offerType === 'BID' ? 'Buy' : 'Sell';
  const color = offerType === 'BID' ? 'gain' : 'loss';

  if (!isSecondaryMarketOpen) {
    return (
      <Tooltip title={BLOCKED_MESSAGE}>
        <Button
          disabled
          variant='outlined'
          sx={[styles.OfferButton, styles.DisabledButtonWithTooltip]}
          size={size}
        >
          {action}
        </Button>
      </Tooltip>
    );
  }

  return (
    <Button
      onClick={onClick}
      variant='outlined'
      color={color}
      size={size}
      sx={styles.OfferButton}
    >
      {action}
    </Button>
  );
};

OfferButton.defaultProps = {
  size: 'medium',
};

OfferButton.propTypes = {
  isSecondaryMarketOpen: PropTypes.bool.isRequired,
  offerType: PropTypes.oneOf(['BID', 'ASK']).isRequired,
  onClick: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default OfferButton;
