import { Stack, Typography } from '@mui/material';

import CommentForm from './form';
import CommentModal from './comment-modal';
import Comments from './comments';
import CommentsQueryContext from './context';
import { DEFAULT_QUERY_PARAMS } from '../../../_actions/_api-urls';
import { companyState } from '../../../_state';
import { useRecoilValue } from 'recoil';
import { useState } from 'react';

const DiscussionBoard = () => {
  const company = useRecoilValue(companyState);
  const [queryParams, setQueryParams] = useState(DEFAULT_QUERY_PARAMS);

  return (
    <CommentsQueryContext.Provider value={{queryParams, setQueryParams}}>
      <Stack spacing={8}>
        <Stack spacing={2}>
          <Typography variant='cardTitle'>Ask a Question</Typography>
          <Typography fontStyle='italic'>Proofread your comment before submitting: once it’s posted, you can’t edit or delete it. For the fastest help with the web site, email help@netcapital.com instead of commenting.</Typography>
          <CommentForm />
        </Stack>
        <Comments listingKey={company.key} />
        <CommentModal listingKey={company.key} />
      </Stack>
    </CommentsQueryContext.Provider>
  );
};

export default DiscussionBoard;
