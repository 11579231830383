const iconSize = 27;

const styles = {
  SearchBar: {
    width: '100%',
  },
  SearchButton: {
    cursor: 'pointer',
    paddingRight: 0,
  },
  Category: ({ palette })=>({
    width: '6.563rem',
    height: '6.25rem',
    margin: '0.5rem',
    color: palette.primary.main,
    cursor: 'pointer',
    display: 'flex',
    flex: '0 0 100px',
    overflow: 'visible',
    position: 'relative',
    border: `1px solid ${palette.primary.light}`,
  }),
  CategoryName: () => ({
    width: '100%',
    textAlign: 'center',
    fontSize: '0.813rem',
    fontWeight: 700,
  }),
  ActiveCategory: ({ palette }) => ({
    backgroundColor: palette.primary.main,
    borderColor: palette.primary.main,
    color: palette.common.white,
  }),
  CategoryIconContainer: ({ palette }) => ({
    stroke: palette.primary.main,
    color: palette.primary.main,
    lineHeight: 0,
    marginBottom: '0.875rem',
    '> svg': {
      width: `${iconSize}px`,
      height: `${iconSize}px`,
    }
  }),
  ActiveCategoryIconContainer: ({ palette }) => ({
    backgroundColor: palette.primary.main,
    color: palette.common.white,
    stroke: palette.common.white,
  }),
  TableRow: {
    '.MuiTableCell-root': {
      borderBottom: 'none',
    }
  },
  CompanyCell: {
    alignItems: 'center',
  },
  ListingName: {
    textDecoration: 'none',
    fontWeight: 700,
    fontSize: '1rem',
  },
  FirstCell: {
    '&:first-of-type': {
      paddingLeft: 0,
    },
  },
  ValueCells: ({ palette }) => ({
    fontWeight: 400,
    color: palette.text.secondary,
    textAlign: 'center',
    minWidth: '5.25rem',
  }),
  SellButtonCell: {
    paddingLeft: 2,
  },
  OfferButton: {
    maxWidth: '80px',
    textTransform: 'uppercase',
  },
  DisabledButtonWithTooltip: {
    cursor: 'not-allowed',
    '&.Mui-disabled': {
      pointerEvents: 'auto',
    },
  },
};

export default styles;
