import { Table, TableBody, TableContainer, TableHead } from '@mui/material';

import PendingOffer from './PendingOffer';
import PendingOffersHeader from './header';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

const PendingOffersTable = ({ offers, onSelectPrice }) => {
  return (
    <TableContainer>
      <Table stickyHeader size='small'>
        <TableHead>
          <PendingOffersHeader isSelectPrice={onSelectPrice !== null} />
        </TableHead>
        <TableBody>
          {offers.map((offer) => (
            <PendingOffer
              key={uuidv4()}
              offer={offer}
              onSelectPrice={onSelectPrice}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

PendingOffersTable.propTypes = {
  offers: PropTypes.arrayOf(PropTypes.shape({
    pricePerUnit: PropTypes.number.isRequired,
    totalQuantity: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired
  })).isRequired,
  onSelectPrice: PropTypes.func
};

export default PendingOffersTable;
