import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import { Typography } from '@mui/material';
import styles from './styles';

const SortIcon = ({ direction }) => {
  return (
    <Typography>
      <ArrowDownwardOutlinedIcon  color='primary' sx={direction === 'asc' ? [styles.SortIcon, styles.SortIconAsc] : styles.SortIcon} />
    </Typography>
  );
};

export default SortIcon;
