// skipcq: JS-C1003 - yup does not expose itself as an ES Module.
import * as Yup from 'yup';

const AddressSchema = Yup.object({
  streetLine1: Yup.string().required('Required'),
  streetLine2: Yup.string(),
  streetLine3: Yup.string(),
  city: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  zipCode: Yup.string().required('Required'),
  country: Yup.string().required('Required'),
  countryCode: Yup.string().required('Required'),
  phone: Yup.string().required('Required'),
}).transform(addressValue => addressValue === null ? {} : addressValue);

export default AddressSchema;
