import { ACCREDITED_INVESTOR_CRITERIA, QUALIFIED_PURCHASER_CRITERIA, YES_NO } from '../../../../static-info';

import Field from '../../../FormFields/Field';
import { Grid } from '@mui/material';
import RadioGroup from '../../../../netcapital-components/RadioGroup';
import Selectbox from '../../../../netcapital-components/Selectbox';
import { fetchHoldingEntityInfoAttribute } from '../../../../_state';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

const HoldingEntityAccreditation = () => {
  const [isAccredited, setIsAccredited] = useRecoilState(fetchHoldingEntityInfoAttribute('accreditation.isAccreditedInvestor'));
  const [accreditedReason, setAccreditedReason] = useRecoilState(fetchHoldingEntityInfoAttribute('accreditation.method'));
  const [isQualifiedPurchaser, setIsQualifiedPurchaser] = useRecoilState(fetchHoldingEntityInfoAttribute('isQualifiedPurchaser'));

  const handleChangeIsAccredited = useCallback((e) => {
    if(e.target.value === 'false'){
      setAccreditedReason('');
      setIsQualifiedPurchaser();
    }
    setIsAccredited(e.target.value === 'true');
  }, [setAccreditedReason, setIsAccredited, setIsQualifiedPurchaser]);
  const handleChangeAccreditedReason = useCallback((e) => setAccreditedReason(e.target.value), [setAccreditedReason]);
  const handleChangeIsQualifiedPurchaser = useCallback((e) => {
    setIsQualifiedPurchaser(e.target.value === 'true');
  }, [setIsQualifiedPurchaser]);

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Field label='Does the entity qualify as an accredited investor? *'>
          <RadioGroup
            options={YES_NO}
            value={isAccredited}
            onChange={handleChangeIsAccredited}
            fieldName={'accreditation.isAccreditedInvestor'}
          />
        </Field>
      </Grid>
      {isAccredited && (
        <>
          <Grid item xs={12}>
            <Field label='How is the entity accredited? *'>
              <Selectbox
                options={ACCREDITED_INVESTOR_CRITERIA.HOLDING_ENTITY}
                value={accreditedReason || ''}
                onChange={handleChangeAccreditedReason}
                placeholder='Please select one'
                fieldName={'accreditation.method'}
              />
            </Field>
          </Grid>
          <Grid item xs={12}>
            <Field label='Is the entity a Qualified Purchaser? *'>
              <RadioGroup
                options={QUALIFIED_PURCHASER_CRITERIA.HOLDING_ENTITY}
                value={isQualifiedPurchaser}
                onChange={handleChangeIsQualifiedPurchaser}
                fieldName={'accreditation.isQualifiedPurchaser'}
              />
            </Field>
          </Grid>
        </>
      )}
    </>
  );
};

export default HoldingEntityAccreditation;
