import { IconButton, InputAdornment, TextField } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { marketSearchState } from '../../_state';
import styles from './styles';
import { useResetRecoilState } from 'recoil';

const MarketplaceSearch = ({ onSearch }) => {
  const [searchTerms, setSearchTerms] = useState('');
  const resetResults = useResetRecoilState(marketSearchState);
  const prevSearch = useRef('');

  const handleChangeSearchTerms = useCallback((e) => setSearchTerms(e.currentTarget.value), []);

  const handleSearch = useCallback((e) => {
    e.preventDefault();
    setSearchTerms(searchTerms.trim());
    const cleanedSearch = searchTerms.trim();
    prevSearch.current = cleanedSearch;
    if (cleanedSearch.length) {
      resetResults();
      onSearch(cleanedSearch);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerms]);

  useEffect(() => {
    if (searchTerms === '' && prevSearch.current !== '' && searchTerms !== prevSearch.current) {
      resetResults();
      onSearch('');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerms, prevSearch.current]);

  return (
    <form onSubmit={handleSearch}>
      <TextField
        value={searchTerms}
        onChange={handleChangeSearchTerms}
        variant='outlined'
        size='small'
        placeholder='Search for company'
        InputProps={{
          endAdornment: <InputAdornment position='end'>
            <IconButton type='submit' sx={styles.SearchButton} disableRipple>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }}
        sx={styles.SearchBar}
      />
    </form>
  );
};

export default MarketplaceSearch;
