import { Box, Button, Dialog, DialogActions, DialogContent, LinearProgress } from '@mui/material';
import { companyState, companyTeamState } from '../../../_state';
import { useCallback, useState } from 'react';

import Carousel from '../../../netcapital-components/Carousel';
import Member from './member';
import styles from './styles';
import { useGetCompanyTeam } from '../../../_actions/company-team.actions';
import { useIsMobile } from '../../../_helpers';
import { useRecoilValue } from 'recoil';

const Team = () => {
  const company = useRecoilValue(companyState);
  const { loaded } = useGetCompanyTeam(company.key);
  const team = useRecoilValue(companyTeamState);
  const [openIndex, setOpen] = useState(-1);
  const handleOpen = useCallback((index) => {
    setOpen(index);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(-1);
  }, []);

  const isMobile = useIsMobile();
  const hideDots = Boolean((team?.length <= 2 && !isMobile) || (team?.length === 1 && isMobile));

  return (
    <>
      {!loaded && <LinearProgress sx={styles.Progress} />}
      <Box>
        {team?.length > 0 && (
          <Carousel dots={!hideDots} buttonType='large'>
            {team.map((member, index) => (
              <Member key={member.key} index={index} openDetails={handleOpen} data={member} inModal={false} />
            ))}
          </Carousel>
        )}
        <Dialog
          open={openIndex !== -1}
          onClose={handleClose}
          maxWidth="md"
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent>
            {team && openIndex !== -1 && <Member inModal data={team[openIndex]} />}
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default Team;
