import { AuthAPILoginURL, AuthAPIVerifyURL } from '../_actions/_api-urls';
import { addKeysToListItems, preparePayload } from './data-modeling';
import { investorState, sessionState, userState } from '../_state';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';

import axios from 'axios';
import { useAlertActions } from '../_actions/alert.actions';

function fetchVerifyToken(signal) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
    signal
  };

  return axios(AuthAPIVerifyURL, requestOptions).catch(e => {
    if (e?.code !== 'ERR_CANCELED') {
      return undefined;
    }
    return false;
  });
}

function getNewSession(response){
  if (!response || !response.authenticated) return null;

  return {
    accessToken: response.user.accessToken,
    accessTokenExpiresAt: response.user.accessTokenExpiresAt,
    isAuthenticated: response.authenticated
  };
}

function isTokenExpired(expiration) {
  return !expiration || new Date() >= new Date(expiration);
}

function getRequestOptions(method, signal, sessionToken, body, isPublic, contentType){
  const requestOptions = {
    headers: {},
    method,
    withCredentials: true,
    signal,
  };

  if (!isPublic) {
    requestOptions.headers = { 'Authorization': `Bearer ${sessionToken}` };
    requestOptions.withCredentials = false;
  }

  if (body) {
    if (contentType) {
      requestOptions.headers['Content-Type'] = contentType;
      requestOptions.data = body;
    } else {
      requestOptions.headers['Content-Type'] = 'application/json';
      requestOptions.data = preparePayload(body);
    }
  }

  return requestOptions;
}

function useFetchWrapper() {
  const alertActions = useAlertActions();
  const [session, setSession] = useRecoilState(sessionState);
  const setInvestor = useSetRecoilState(investorState);
  const resetInvestorState = useResetRecoilState(investorState);
  const [user, setUser] = useRecoilState(userState);
  const resetUserState = useResetRecoilState(userState);
  let sessionToken = session.accessToken;

  function handleResponse(response) {
    return addKeysToListItems(response.data) || true;
  }

  function handleError(error) {
    if (!error.response){
      return;
    }

    alertActions.error('An error occurred', error.response, error.message, error.request);
    throw error;
  }

  function request(method) {
    return async (url, body, signal, isPublic, contentType) => {
      const accessTokenExpiresAt = session && session.accessTokenExpiresAt;
      const tokenHasExpired = isTokenExpired(accessTokenExpiresAt);

      if (tokenHasExpired){
        const response = await fetchVerifyToken(signal);
        if (response === false) {
          // request was aborted
          return null;
        }

        if (!response?.data?.authenticated){
          setSession(null);
          resetInvestorState();
          resetUserState();
          window.location.href = AuthAPILoginURL(window.location.href);
          return null;
        }

        const newSession = getNewSession(response.data);
        setSession(newSession);
        sessionToken = newSession.accessToken;

        // User logged into a different account
        // in a different app
        if (response.data.entityKey !== user.entityKey) {
          setUser({
            entityKey: response.data.user.entityKey,
            firstName: response.data.user.firstName,
            lastName: response.data.user.lastName,
            role: response.data.user.role,
          });
          setInvestor({
            entityKey: response.data.user.entityKey,
            name: `${response.data.user.firstName} ${response.data.user.lastName}`,
          });
        }
      }

      const requestOptions = getRequestOptions(method, signal, sessionToken, body, isPublic, contentType);
      return axios(url, requestOptions).then(handleResponse).catch(handleError);
    };
  }

  return {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    patch: request('PATCH'),
    delete: request('DELETE')
  };
}

async function fetchRequest(method, url, body, accessToken, isPublic, contentType) {
  const controller = new AbortController();
  const signal = controller.signal;

  if (!isPublic) {
    //refresh token
    const response = await fetchVerifyToken(signal);
    if (response === false) {
      // request was aborted
      return null;
    }

    if (!response?.data?.authenticated){
      window.location.href = AuthAPILoginURL(window.location.href);
      return null;
    }

    const newSession = getNewSession(response.data);
    // TODO: We cannot update the token stored in Recoil
    // in this context because this is not a React hook function.
    accessToken = newSession.accessToken;
  }

  const requestOptions = getRequestOptions(method, signal, accessToken, body, isPublic, contentType);

  const response = await axios(url, requestOptions);
  return response;
}

export {
  useFetchWrapper,
  fetchRequest
};
