const styles = {
  MobileCheckout: () => ({
    top: 'auto',
    bottom: 0,
  }),
  MobileBarContent: () => ({
    paddingTop: 2,
    paddingBottom: 2,
    width: '100%',
  }),
  MobileTradeAction: {
    alignItems: 'end',
    flexGrow: 1,
  },
  MobileTradeButton: {
    maxWidth: '200px',
  },
};

export default styles;
