import { Button } from '@mui/material';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import styles from './styles';

const ReceiptLink = ({ receiptUrl }) => {

  if (!receiptUrl) {
    return (
      <Button
        startIcon={<WarningAmberOutlinedIcon />}
        disableRipple
        size='small'
        sx={[styles.ReceiptLink, styles.ReceiptLinkUnavailable]}
        color='warning'
      >
        The receipt is being processed and will be available soon
      </Button>
    );
  }

  return (
    <Button
      href={receiptUrl} target='_blank' rel='noopener'
      startIcon={<ReceiptOutlinedIcon />}
      disableRipple
      size='small'
      sx={styles.ReceiptLink}
    >
      View Receipt
    </Button>
  );
};

export default ReceiptLink;
