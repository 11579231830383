import { Box, Grid } from '@mui/material';
import { useCallback, useState } from 'react';

import CurrentRaiseContext from './context';
import FundingHistoryChart from './chart';
import FundingHistoryOverview from './overview';
import RaiseFilters from './filters';
import RaisePercentageChart from './raise-percentage';
import Raises from './table';
import TotalRaiseAmount from './total';
import styles from './styles';

const FundingHistory = () => {
  const [selectedRaise, setSelectedRaise] = useState();

  const onChangeSelectedRaise = useCallback((offerKey) => {
    setSelectedRaise(offerKey);
  },[]);

  return (
    <CurrentRaiseContext.Provider value={{ onChangeSelectedRaise, selectedRaise }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TotalRaiseAmount />
        </Grid>
        <Grid container spacing={4} item xs={12} md={9}>
          <Grid item xs={12}>
            <RaiseFilters />
          </Grid>
          <Grid item xs={12}>
            <FundingHistoryOverview />
          </Grid>
          <Grid item xs={12}>
            <FundingHistoryChart />
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box sx={styles.RaisePercentageChartContainer}>
            <RaisePercentageChart />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Raises />
        </Grid>
      </Grid>
    </CurrentRaiseContext.Provider>
  );
};

export default FundingHistory;
