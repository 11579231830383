import { APIUserURL, APIs } from './_api-urls';
import { fetchHoldingEntityBeneficialOwnerVerificationDocuments, holdingEntitiesState, holdingEntityBeneficialOwnersState, holdingEntityDocumentsState, holdingEntityInfoState, templumFormKycStatusState, userState } from '../_state';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { useFetchWrapper } from '../_helpers';

export function useCreateHoldingEntity() {
  const user = useRecoilValue(userState);
  const [holdingEntities, setHoldingEntities] = useRecoilState(holdingEntitiesState);
  const fetchWrapper = useFetchWrapper();
  const [saving, setSaving] = useState(false);

  const save = useCallback((onSuccess) => {
    const controller = new AbortController();
    const signal = controller.signal;
    function abort() {
      controller.abort();
    }

    setSaving(true);

    const url = `${APIs.Users}/${user?.entityKey}/user-entities`;
    fetchWrapper.post(url, null, signal, false)
      .then((response) => {
        if (!response) return; // aborted request gives response === undefined
        const newHoldingEntity = response;
        setHoldingEntities([...holdingEntities, newHoldingEntity]);
        setSaving(false);
        if (typeof onSuccess === 'function') onSuccess(newHoldingEntity);
      });
    return abort;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holdingEntities, user?.entityKey]);

  return { saving, save };
}

export function useGetUserHoldingEntities() {
  const user = useRecoilValue(userState);
  const [holdingEntities, setHoldingEntities] = useRecoilState(holdingEntitiesState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    function abort(){ controller.abort();}

    if (holdingEntities !== undefined) {
      setLoaded(true);
      return abort;
    }

    fetchWrapper.get(`${APIs.Users}/${user?.entityKey}/user-entities`, null, signal, true)
      .then((response) => {
        if (!response) return; // aborted request gives response === undefined
        setHoldingEntities(response);
        setLoaded(true);
      })
      .catch(() => setLoaded(true));

    return abort;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holdingEntities, user?.entityKey]);

  return { loaded };
}

export function useGetHoldingEntityInfo(holdingEntityKey) {
  const user = useRecoilValue(userState);
  const setInfo = useSetRecoilState(holdingEntityInfoState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const abort = () => controller.abort();

    if (!holdingEntityKey) {
      setLoaded(true);
      return abort;
    }

    setLoaded(false);

    const url = `${APIUserURL(user.entityKey)}/user-entities/${holdingEntityKey}/holding-information`;
    fetchWrapper.get(url, null, signal, false)
      .then((response) => {
        if (!response) return;
        setInfo(response);
        setLoaded(true);
      })
      .catch(() => setLoaded(true));

    return abort;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.entityKey, holdingEntityKey]);

  return { loaded };
}

export const useSaveHoldingEntityInfo = (holdingEntityKey) => {
  const user = useRecoilValue(userState);
  const info = useRecoilValue(holdingEntityInfoState);
  const fetchWrapper = useFetchWrapper();
  const [saving, setSaving] = useState(false);
  const setTemplumKycStatus = useSetRecoilState(templumFormKycStatusState);

  const save = useCallback((onSuccess) => {
    const controller = new AbortController();
    const signal = controller.signal;
    const abort = () => controller.abort();

    setSaving(true);

    const url = `${APIUserURL(user.entityKey)}/user-entities/${holdingEntityKey}/holding-information`;
    fetchWrapper.put(url, info, signal)
      .then((response) => {
        if (!response) return;
        setTemplumKycStatus(response.kyc);
        setSaving(false);
        if (typeof onSuccess === 'function') onSuccess();
      })
      .catch(() => setSaving(false));

    return abort;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  return { save, saving };
};

export function useGetHoldingEntityDocuments(holdingEntityKey) {
  const user = useRecoilValue(userState);
  const setDocs = useSetRecoilState(holdingEntityDocumentsState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const abort = () => controller.abort();

    setLoaded(false);

    const url = `${APIUserURL(user.entityKey)}/user-entities/${holdingEntityKey}/verification-documents?kycType=SECONDARY`;
    fetchWrapper.get(url, null, signal, false)
      .then((response) => {
        if (!response) return;
        setDocs(response);
        setLoaded(true);
      })
      .catch(() => setLoaded(true));

    return abort;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.entityKey, holdingEntityKey]);

  return { loaded };
}

export function useSaveHoldingEntityDocument(holdingEntityKey) {
  const user = useRecoilValue(userState);
  const [docs, setDocs] = useRecoilState(holdingEntityDocumentsState);
  const fetchWrapper = useFetchWrapper();
  const [saving, setSaving] = useState(false);
  const setTemplumKycStatus = useSetRecoilState(templumFormKycStatusState);

  const save = useCallback((usage, file, onSuccess) => {
    const controller = new AbortController();
    const signal = controller.signal;
    const abort = () => controller.abort();

    setSaving(true);

    const url = `${APIUserURL(user.entityKey)}/user-entities/${holdingEntityKey}/verification-documents`;
    const formData = new FormData();
    formData.append('usage', usage);
    formData.append('file', file);
    formData.append('kycType', 'SECONDARY');
    fetchWrapper.post(url, formData, signal, false, 'multipart/form-data')
      .then((response) => {
        if (!response) return;
        const newFile = response.files;
        const docIndex = docs.findIndex(doc => doc.usage === usage);
        if (docIndex === -1) {
          setDocs([ ...docs, newFile ]);
        }
        else {
          const docsCopy = [...docs];
          docsCopy[docIndex] = newFile;
          setDocs(docsCopy);
        }
        setTemplumKycStatus(response.kyc);
        setSaving(false);
        if (typeof onSuccess === 'function') onSuccess();
      })
      .catch(() => setSaving(false));

    return abort;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docs]);

  return { save, saving };
}

export function useDeleteHoldingEntityDocument(holdingEntityKey) {
  const user = useRecoilValue(userState);
  const [docs, setDocs] = useRecoilState(holdingEntityDocumentsState);
  const fetchWrapper = useFetchWrapper();
  const [deleting, setDeleting] = useState(false);
  const setTemplumKycStatus = useSetRecoilState(templumFormKycStatusState);

  const destroy = useCallback((fileKey, onSuccess) => {
    const controller = new AbortController();
    const signal = controller.signal;
    const abort = () => controller.abort();

    setDeleting(true);

    const url = `${APIUserURL(user.entityKey)}/user-entities/${holdingEntityKey}/verification-documents/${fileKey}`;
    fetchWrapper.delete(url, null, signal)
      .then((response) => {
        if (!response) return;
        const docIndex = docs.findIndex(doc => doc.key === fileKey);
        const docsCopy = [...docs];
        docsCopy.splice(docIndex, 1);
        setDocs([...docsCopy]);
        setTemplumKycStatus(response.kyc);
        setDeleting(false);
        if (typeof onSuccess === 'function') onSuccess();
      })
      .catch(() => setDeleting(false));

    return abort;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docs]);

  return { destroy, deleting };
}

export function useGetHoldingEntityBeneficialOwners(holdingEntityKey) {
  const user = useRecoilValue(userState);
  const setBeneficialOwnersInfo = useSetRecoilState(holdingEntityBeneficialOwnersState);
  const fetchWrapper = useFetchWrapper();
  const [loaded, setLoaded] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(true);
  const [isRefresh, setIsRefresh] = useState(false);
  const refreshData = useCallback(() => {
    setIsRefresh(true);
    setShouldFetch(true);
  }, []);

  useEffect(() => {
    if (shouldFetch) {
      const controller = new AbortController();
      const signal = controller.signal;
      const abort = () => controller.abort();

      if (!isRefresh) {
        setLoaded(false);
      }

      const url = `${APIUserURL(user.entityKey)}/user-entities/${holdingEntityKey}/beneficial-owners`;
      fetchWrapper.get(url, null, signal, false)
        .then((response) => {
          if (!response) return;
          setBeneficialOwnersInfo(response);
          setLoaded(true);
          setShouldFetch(false);
        })
        .catch(() => {
          setLoaded(true);
          setShouldFetch(false);
        });

      return abort;
    }
    return;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.entityKey, holdingEntityKey, shouldFetch]);

  return { loaded, refreshData };
}

export const useSaveHoldingEntityBeneficialOwners = (holdingEntityKey) => {
  const user = useRecoilValue(userState);
  const beneficialOwnersInfo = useRecoilValue(holdingEntityBeneficialOwnersState);
  const fetchWrapper = useFetchWrapper();
  const [saving, setSaving] = useState(false);
  const setTemplumKycStatus = useSetRecoilState(templumFormKycStatusState);

  const save = useCallback((onSuccess) => {
    const controller = new AbortController();
    const signal = controller.signal;
    const abort = () => controller.abort();

    setSaving(true);

    const url = `${APIUserURL(user.entityKey)}/user-entities/${holdingEntityKey}/beneficial-owners`;
    const payload = {...beneficialOwnersInfo};
    if (payload?.beneficialOwners) {
      // Remove validation attribute `verificationDocType` from all beneneficial owners
      // eslint-disable-next-line no-unused-vars
      payload.beneficialOwners = [...payload.beneficialOwners].map(({verificationDocType, ...keepAttrs}) => keepAttrs);
    }

    fetchWrapper.put(url, payload, signal)
      .then((response) => {
        if (!response) return;
        setTemplumKycStatus(response.kyc);
        setSaving(false);
        if (typeof onSuccess === 'function') onSuccess();
      })
      .catch(() => setSaving(false));

    return abort;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beneficialOwnersInfo]);

  return { save, saving };
};

export const useSaveHoldingEntityBeneficialOwnerVerificationDocument = (holdingEntityKey, beneficialOwnerIndex) => {
  const user = useRecoilValue(userState);
  const [docs, setDocs] = useRecoilState(fetchHoldingEntityBeneficialOwnerVerificationDocuments(beneficialOwnerIndex));
  const fetchWrapper = useFetchWrapper();
  const [saving, setSaving] = useState(false);
  const setTemplumKycStatus = useSetRecoilState(templumFormKycStatusState);

  const save = useCallback((usage, beneficialOwnerKey, file) => {
    const controller = new AbortController();
    const signal = controller.signal;
    const abort = () => controller.abort();

    setSaving(true);

    const url = `${APIUserURL(user.entityKey)}/user-entities/${holdingEntityKey}/verification-documents`;
    const formData = new FormData();
    formData.append('usage', usage);
    formData.append('kycType', 'SECONDARY');
    formData.append('file', file);
    if (beneficialOwnerKey) {
      formData.append('beneficialOwner', beneficialOwnerKey);
    }
    fetchWrapper.post(url, formData, signal, false, 'multipart/form-data')
      .then((response) => {
        if (!response) return;
        setDocs([...docs, response.files]);
        setTemplumKycStatus(response.kyc);
        setSaving(false);
      })
      .catch(() => setSaving(false));

    return abort;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holdingEntityKey, user.entityKey]);

  return { save, saving };
};

export const useDeleteHoldingEntityBeneficialOwnerVerificationDocument = (holdingEntityKey, beneficialOwnerIndex) => {
  const user = useRecoilValue(userState);
  const [docs, setDocs] = useRecoilState(fetchHoldingEntityBeneficialOwnerVerificationDocuments(beneficialOwnerIndex));
  const fetchWrapper = useFetchWrapper();
  const [deleting, setDeleting] = useState(false);
  const setTemplumKycStatus = useSetRecoilState(templumFormKycStatusState);

  const destroy = useCallback((fileKey, beneficialOwnerKey) => {
    const controller = new AbortController();
    const signal = controller.signal;
    const abort = () => controller.abort();

    setDeleting(true);

    const url = `${APIUserURL(user.entityKey)}/user-entities/${holdingEntityKey}/verification-documents/${fileKey}?beneficialOwner=${beneficialOwnerKey}`;
    fetchWrapper.delete(url, null, signal)
      .then((response) => {
        if (!response) return;
        const docIndex = docs.findIndex(doc => doc.key === fileKey);
        const docsCopy = [...docs];
        docsCopy.splice(docIndex, 1);
        setDocs([...docsCopy]);
        setTemplumKycStatus(response);
        setDeleting(false);
      })
      .catch(() => setDeleting(false));

    return abort;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holdingEntityKey, user.entityKey]);

  return { destroy, deleting };
};

export const useDeleteHoldingEntity = (holdingEntityKey) => {
  const user = useRecoilValue(userState);
  const [holdingEntities, setHoldingEntities] = useRecoilState(holdingEntitiesState);
  const fetchWrapper = useFetchWrapper();
  const [deleting, setDeleting] = useState(false);

  const destroy = useCallback((onSuccess) => {
    const controller = new AbortController();
    const signal = controller.signal;
    const abort = () => controller.abort();

    setDeleting(true);

    const url = `${APIUserURL(user.entityKey)}/user-entities/${holdingEntityKey}`;
    fetchWrapper.delete(url, null, signal)
      .then(() => {
        const index = holdingEntities.findIndex(doc => doc.key === holdingEntityKey);
        const copy = [...holdingEntities];
        copy.splice(index, 1);
        setHoldingEntities([...copy]);
        setDeleting(false);
        if (typeof onSuccess === 'function') onSuccess();
      })
      .catch(() => setDeleting(false));

    return abort;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holdingEntities]);

  return { destroy, deleting };
};
