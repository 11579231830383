import { Autocomplete, Grid, InputAdornment } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import CountrySelect from './CountrySelect';
import PropTypes from 'prop-types';
import TextField from '../TextField';
import _ from 'lodash';
import addressFormats from './addressFormats';
import produce from 'immer';
import styles from './styles';

const getCountryConfig = (country) => {
  return addressFormats[country];
};

const getStateOptions = (country) => {
  const fields = _.get(addressFormats[country], 'fields');
  const config = _.find(fields, {'key': 'administrativearea'}) || {};
  return _.map(config.options, (value, key) => {
    return {
      value: key,
      label: value
    };
  });
};

const getFieldLabel = (countryConfig, key) => {
  if (countryConfig && countryConfig.fields) {
    const field = _.find(countryConfig.fields, {key});
    return field ? field.label : null;
  }
  return null;
};

function AddressInput(props) {
  const {
    value,
    onChange,
    forceCountry,
    displayPhone,
    error,
    requirementLabel,
    fieldNamePrefix,
  } = props;

  const [address, setAddress] = useState(value);
  const [stateOptions, setStateOptions] = useState(getStateOptions(address.country));
  const [countryConfig, setCountryConfig] = useState(getCountryConfig(address.country) || {});
  const [selectedState, setSelectedState] = useState(_.find(stateOptions, {value: address.state}));
  const isStateFieldVisible = Boolean(stateOptions && stateOptions.length);
  const [administrativeArea, setAdministrativeArea] = useState(address.state || '');

  const handleChange = useCallback((attribute) => (e) => {
    const newValue = e.target.value;
    const updatedAddress = produce(address, (draft) => {
      _.set(draft, attribute, newValue);
    });
    setAddress(updatedAddress);
    onChange(attribute, newValue);
  }, [onChange, setAddress, address]);

  const renderCountryField = useCallback((params) => <TextField {...params} />, []);
  const handleChangeCountry = useCallback((countryId) => {
    setCountryConfig(getCountryConfig(countryId));
    setStateOptions(getStateOptions(countryId));
    onChange('country', countryId);
  }, [setStateOptions, onChange]);

  const checkStateOptionEquality = useCallback((opt, val) => _.isEqual(opt, val), []);
  const getStateOptionLabel = useCallback((opt) => opt.label || '', []);
  const renderStateField = useCallback((params) => (
    <TextField
      {...params}
      placeholder={`${getFieldLabel(countryConfig, 'administrativearea') ?? 'State'} ${requirementLabel}`}
      fullWidth
      fieldName={`${fieldNamePrefix}.state`}
      value={selectedState?.value}
    />
  ), [countryConfig, fieldNamePrefix, requirementLabel, selectedState?.value]);
  const handleChangeState = useCallback((e, newValue) => {
    setSelectedState(newValue);
    onChange('state', (newValue && typeof(newValue) === 'object') ? newValue.value : newValue);
  }, [onChange, setSelectedState]);
  const handleChangeAdministrativeArea = useCallback((e) => {
    setAdministrativeArea(e.target.value);
    onChange('state', e.target.value);
  },[onChange, setAdministrativeArea]);

  useEffect(() => {
    if (forceCountry && address.country !== forceCountry) {
      const updatedAddress = produce(address, (draft) => {
        _.set(draft, 'country', forceCountry);
      });
      setAddress(updatedAddress);
      onChange('country', forceCountry);
    }
  }, [forceCountry, address, setAddress, onChange]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CountrySelect
          value={forceCountry ? forceCountry : address.country || ''}
          disabled={Boolean(forceCountry)}
          onChange={handleChangeCountry}
          renderInput={renderCountryField}
          error={error}
          sx={styles.AutoComplete}
          requirementLabel={requirementLabel}
          fieldName={`${fieldNamePrefix}.country`}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={address.streetLine1 || ''}
          onChange={handleChange('streetLine1')}
          placeholder={`${getFieldLabel(countryConfig, 'thoroughfare') ?? 'Street Address'} ${requirementLabel}`}
          fullWidth
          error={error}
          fieldName={`${fieldNamePrefix}.streetLine1`}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={address.streetLine2 || ''}
          onChange={handleChange('streetLine2')}
          placeholder={`${getFieldLabel(countryConfig, 'premise') ?? 'Building, Floor, Suite, etc.'} ${requirementLabel === '*' ? '(optional)' : ''}`} //Force (optional) label for streetLine2 when all other 'child' fields are required. If !requirementLabel, we don't want to show any child requirement labels for any 'child' fields (including this one).
          fullWidth
          error={error}
          fieldName={`${fieldNamePrefix}.streetLine2`}
        />
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={12} md={5}>
          <TextField
            value={address.city || ''}
            onChange={handleChange('city')}
            placeholder={`${getFieldLabel(countryConfig, 'localityname') ?? 'City'} ${requirementLabel}`}
            fullWidth
            error={error}
            fieldName={`${fieldNamePrefix}.city`}
          />
        </Grid>
        {isStateFieldVisible && (
          <Grid item xs={12} md={4}>
            <Autocomplete
              disablePortal
              autoHighlight
              options={stateOptions}
              value={selectedState || null}
              onChange={handleChangeState}
              getOptionLabel={getStateOptionLabel}
              isOptionEqualToValue={checkStateOptionEquality}
              renderInput={renderStateField}
              sx={styles.AutoComplete}
            />
          </Grid>
        )}
        {/* To-do: Find a better way to handle countries without pre-defined state/province etc. options */}
        {!isStateFieldVisible && (
          <Grid item xs={12} md={4}>
            <TextField
              value={administrativeArea}
              onChange={handleChangeAdministrativeArea}
              placeholder={`State ${requirementLabel}`}
              fullWidth
              error={error}
              fieldName={`${fieldNamePrefix}.state`}
            />
          </Grid>
        )}
        <Grid item xs={12} md={3}>
          <TextField
            value={address.zipCode || ''}
            onChange={handleChange('zipCode')}
            placeholder={`${getFieldLabel(countryConfig, 'postalcode') ?? 'Zip'} ${requirementLabel}`}
            fullWidth
            error={error}
            fieldName={`${fieldNamePrefix}.zipCode`}
          />
        </Grid>
      </Grid>
      {displayPhone && (
        <Grid container item spacing={2}>
          <Grid item xs={6} sm={3}>
            <TextField
              value={address.countryCode || ''}
              onChange={handleChange('countryCode')}
              placeholder={`Country Code ${requirementLabel}`}
              InputProps={{
                startAdornment: <InputAdornment position='start'>+</InputAdornment>,
              }}
              fullWidth
              error={error}
              fieldName={`${fieldNamePrefix}.countryCode`}
            />
          </Grid>
          <Grid item xs={6} sm={9}>
            <TextField
              value={address.phone || ''}
              onChange={handleChange('phone')}
              placeholder={`Phone Number ${requirementLabel}`}
              fullWidth
              error={error}
              fieldName={`${fieldNamePrefix}.phone`}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

AddressInput.defaultProps = {
  value: {
    streetLine1: '',
    streetLine2: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    countryCode: '',
    phone: '',
  },
  forceCountry: false,
  displayPhone: true,
  error: false,
  requirementLabel: '',
  fieldNamePrefix: 'address',
};

AddressInput.propTypes = {
  value: PropTypes.shape({
    streetLine1: PropTypes.string,
    streetLine2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    zipCode: PropTypes.string,
    countryCode: PropTypes.string,
    phone: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  displayPhone: PropTypes.bool,
  forceCountry: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  error: PropTypes.bool,
  requirementLabel: PropTypes.string,
  fieldNamePrefix: PropTypes.string,
};

export default AddressInput;
