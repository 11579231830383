import { IconButton, Typography, styled } from '@mui/material';
import { investorState, userState } from '../../../_state';
import { useCallback, useState } from 'react';

import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import UserSubMenu from './sub-menu';
import styles from './styles';
import { useRecoilValue } from 'recoil';

const StyledExpandToggleIcon = styled(ExpandMoreOutlinedIcon, {
  shouldForwardProp: (prop) => prop !== 'toggled'
})(styles.ExpandToggleIcon);

const UserMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const user = useRecoilValue(userState);
  const investor = useRecoilValue(investorState);

  if (typeof user === 'undefined'){
    return null;
  }

  return (
    <>
      <IconButton
        onClick={handleClick}
        sx={styles.UserMenuContainer}
        name='UserMenuContainer'
        disableRipple
      >
        <ManageAccountsOutlinedIcon color='primary' sx={styles.UserIcon} />
        <Typography color='primary' sx={styles.UserName}>{investor.name.split(' ')[0]}</Typography>
        <StyledExpandToggleIcon toggled={open} />
      </IconButton>
      <UserSubMenu
        user={user}
        investor={investor}
        anchorEl={anchorEl}
        handleClose={handleClose}
        open={open}
      />
    </>
  );
};

export default UserMenu;
