import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { useCallback, useState } from 'react';

import ConfirmOffer from '../Offer/confirm';
import OfferModalActions from './actions';
import OfferModalContent from './content';
import OfferSuccess from '../Offer/success';
import PropTypes from 'prop-types';
import { companyState } from '../../_state';
import styles from './styles';
import { useGetPendingOffers } from '../../_actions/pending-offers.actions';
import { useGetTransactions } from '../../_actions/transactions.actions';
import { useRecoilValue } from 'recoil';

const OfferModal = (props) => {
  const { open, onClose } = props;

  const [isValidOffer, setIsValidOffer] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const handleIsValidated = useCallback(() => {
    setIsValidOffer(true);
  }, []);

  const company = useRecoilValue(companyState);
  const {refreshData: refreshPendingOffers} = useGetPendingOffers(company?.key);
  const {refreshData: refreshTransactions} = useGetTransactions();

  const handleConfirmation = useCallback(() => {
    setIsConfirmed(true);
    refreshTransactions();
    refreshPendingOffers();
  }, [refreshPendingOffers, refreshTransactions]);

  const handleCancelConfirmation = useCallback(() => {
    setIsConfirmed(false);
    setIsValidOffer(false);
  }, []);

  const handleOfferSuccess = useCallback(() => {
    handleCancelConfirmation();
    onClose();
  }, [handleCancelConfirmation, onClose]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
    >
      <DialogContent sx={styles.OfferModalContent}>
        <OfferModalContent onClose={onClose} />
      </DialogContent>
      <DialogActions sx={styles.OfferModalActions}>
        <OfferModalActions onClose={onClose} onValidated={handleIsValidated} />
      </DialogActions>
      <ConfirmOffer open={isValidOffer && !isConfirmed} onSuccess={handleConfirmation} onCancel={handleCancelConfirmation} />
      <OfferSuccess open={isValidOffer && isConfirmed} onReset={handleOfferSuccess} />
    </Dialog>
  );
};

OfferModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default OfferModal;
