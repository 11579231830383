const styles = {
  HelpIcon: ({ palette }) => ({
    color: palette.primary.main,
    height: '20px',
    width: '20px'
  }),
  NoWrap: {
    whiteSpace: 'nowrap'
  },
  Progress: {
    position: 'absolute',
    top: 0,
    width: '100%',
    left: 0,
    height: '0.313rem'
  },
  TableRow: {
    '&.MuiTableRow-hover:hover': {
      cursor: 'pointer'
    }
  },
  TooltipPopper: {
    '& .MuiTooltip-tooltip': {
      padding: 2
    }
  },
  Container: {
    position: 'relative',
  },
  Loading: {
    position: 'absolute',
    background: 'rgba(255,255,255,0.85)',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  FiltersButton: {
    borderRadius: '1.875rem',
  },
  RaisePercentageChartContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    height: '100%',
  },
  RaisePercentageChartWrap: {
    position: 'relative',
    width: '100%',
  },
  RaisePercentage: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
};

export default styles;
