import { Typography } from '@mui/material';

const PrivacyPolicy = () => {
  return (<>
    <Typography variant='body2'>
      The following describes the Privacy Policy of NetCapital Funding Portal Inc. (the “Funding Portal”) as amended or otherwise modified and in effect from time to time (the “Privacy Policy”) applicable to the services available through the Netcapital website, mobile applications and other interfaces (collectively, the “Site”). By visiting the Site, you are consenting to the collection, use, disclosure, and processing of your personal data as described in this Privacy Policy.

    </Typography><br />
    <Typography variant='body2'>
        On occasion, this Privacy Policy may be revised to reflect changes in the law, regulation, or judicial decisions; our Personal Data Collection and Use Practices policies; the features of the Site; or advances in technology; among other changes. If revisions are made that change the way we collect or use your Personal Data, those changes may be posted in this Privacy Policy and the effective date may be noted in this Privacy Policy. Netcapital may also prominently post such material changes prior to implementing the change.
    </Typography><br />
    <Typography variant='subtitle2'>
Information We May Collect
    </Typography>
    <Typography variant='body2'>
        We may collect personally identifiable information (“Personal Data”) about you that you provide to us while using the Site. Personal Data includes any and all information that can identify you as a specific individual, including, without limitation, your name, address, phone number, credit card number, bank account information, or e-mail address. We also may collect non-personally identifiable information (“Non-Personal Data”), including, without limitation, your log-in name, IP address, search preferences related to specific searches, and messages you leave through your interactions with our Site. Your Non-Personal Data may be linked to your Personal Data under some circumstances, and could be considered Personal Data to the extent such linkage enables you to be personally identified. Netcapital may track users across third-party web sites or other online services, and therefore may not allow “do not track” signals or similar mechanisms.
    </Typography><br />
    <Typography variant='subtitle2'>
Protecting Your Personal Data
    </Typography>
    <Typography variant='body2'>
    To prevent unauthorized access, maintain data accuracy, and ensure correct use of Personal Data, we may employ reasonable and current Internet security methods and technologies. To protect credit card transactions while in transit, Netcapital currently uses Secure Socket Layer technology to encrypt Personal Data. No method of transmitting information over the Internet, or storing data electronically, is 100% secure, however, and Netcapital therefore cannot provide a guaranty of security.
    </Typography>
    <Typography variant='body2'>
        If you purchase certain services for others, we will collect the names of the others as provided by you. You are fully responsible for ensuring that you have the right to provide such information, and Netcapital will not be under any obligations to make any inquiries or otherwise take action to confirm your right to do so. By providing any Personal Data of another customer, you represent that you have all necessary authority and/or have obtained all necessary consents from such person to enable us to collect, use and disclose such Personal Data as set forth in this Privacy Policy.
    </Typography><br />
    <Typography variant='subtitle2'>
        How We Use the Information We Collect
    </Typography>
    <Typography variant='body2'>
        We use Personal Data to provide you with the products and services from our Site, to build features that will make the services available on the Site easier to use, and to contact you about these services and for any other reason we deem appropriate at our sole discretion. This may include faster purchase requests, better customer support and timely notice of new services and special offers and more relevant content.
    </Typography>
    <Typography variant='body2'>
        From time to time, Netcapital may contact you to request feedback on your experience using the Site, to assist us in improving the Site, or to offer special savings or promotions to you, as a Site user or for any other purpose. If you would prefer not to receive notices of special savings or promotions, you may inform us of that preference by opt-out. This however does not restrict or prohibit us or any of our partners from communicating with you in any way and we reserve the right to communicate anything anytime at our sole discretion.
    </Typography><br />
    <Typography variant='subtitle2'>
        Sharing Your Personal Data
    </Typography>
    <Typography variant='body2'>
        Netcapital does share your Personal Data, in ways which may not be disclosed in this policy and you do hereby consent to all disclosures Netcapital might make at its sole discretion. You waive your right to opt-out of any and all sharing of your Personal Data. We realize we’re asking for your trust. If you have any concerns about our privacy policy and agreements, please reach out to help@netcapital.com. We would love to hear your perspective. Netcapital may share your Personal Data with its affiliates and with third parties as described in the categories below:
    </Typography>
    <Typography variant='body2'>
        Certain Services: In order to provide you with certain services, we may share your Personal Data with our affiliates or third party partners. Third-party partners consist of companies with which Netcapital has contracted in order to provide you with services or products featured on our Site.
    </Typography>
    <Typography variant='body2'>
        Agents of Netcapital: Many of the operations we perform require us to hire other companies to help us with these tasks. For example, Netcapital hires companies to help us handle mail and e-mail (including sending and delivering packages, postal mail and e-mail), analyze the data we collect, market our products and services, handle credit card transactions and provide customer service. The companies we employ to help us with these tasks have access to Personal Data to perform their functions, and some may use it for other purposes. Netcapital does not require all agents to whom Personal Data is disclosed to enter into confidentiality agreements and to agree to act in a manner consistent with the relevant principles articulated in this Privacy Policy.
    </Typography>
    <Typography variant='body2'>
        Future Business Transfers: Our business is constantly changing. As part of that process, Netcapital may sell or buy other companies, and Personal Data may be transferred as part of these transactions. It is also possible that Netcapital, or substantially all of its assets, may be acquired by another company, whether by merger, sale of assets or otherwise, and Personal Data may be transferred as part of such a transaction.
    </Typography>
    <Typography variant='body2'>
        Compliance with Law and Other Disclosures: Netcapital reserves the right to release Personal Data in order to comply with applicable law and regulation and when we believe that disclosure is necessary to comply with a judicial proceeding, court order, or legal process served on us. We may also release Personal Data to enforce or apply the terms and conditions applicable to our products and services, protect us or others against fraudulent or inappropriate activities, or otherwise protect the rights, property or safety of Netcapital, our affiliated companies, our customers, or others.
    </Typography>
    <Typography variant='body2'>
        Netcapital may use, publicly display, and publicize your name and likeness for advertising, promotion, and other commercial business purposes, without any additional consideration or compensation. Netcapital may also describe your role in relation to our business.
    </Typography><br />
    <Typography variant='subtitle2'>
        Cookies (& Persistent Local Data)
    </Typography>
    <Typography variant='body2'>
        To serve you faster and with better quality, we may use “cookie” technology and also allow third parties to use cookies on our Site. Cookies are small bits of code, usually stored on a user’s computer hard drive or device, which enable a web site to “personalize” itself for each user by remembering information about the user’s visit to the web site.
    </Typography>
    <Typography variant='body2'>
        Netcapital Cookies (First Party Cookies): We at Netcapital, use our cookies to reduce the time it takes for you to submit purchase requests. For example, we use a cookie to store a registered user’s e-mail address so that the user does not need to enter their e-mail address each time that they log-in onto the Site. We will also use a cookie to keep track of your search criteria while you are engaging Netcapital’s services. Netcapital cookies are associated with a user’s Personal Data.
    </Typography>
    <Typography variant='body2'>
        Netcapital may at its sole discretion allow third parties to have access to the information we collect through our cookies.
    </Typography>
    <Typography variant='body2'>
        Third Party Cookies and Pixels: We may also use third-party advertising companies to serve ads while you are visiting our Site and permit these companies to place and access their own cookies on your computer in the course of serving advertisements on our Site. These companies may use information obtained through their cookies (which may include your name, address, e-mail address or telephone number) about your visits to our and other web sites, in combination with Personal Data or Non Personal data about your purchases and interests from other online sites in order to provide advertisements about goods and services of interest to you. Other companies’ use of their cookies is subject to their own privacy policies and not ours. Such advertisers may have access to our cookies.
    </Typography>
    <Typography variant='body2'>
        In addition, we may share web site usage information about visitors to our Site with these companies for the purpose of managing and targeting advertisements and for market research analysis on our Site and other sites. For these purposes, we and our third-party advertising partners may place pixel tags (also called “clear gifs”) on some of the pages you visit on our Site. We will then use the Personal Data and Non-Personal Data that is collected using these pixel tags to serve you relevant advertisements when you are visiting other sites on the Internet. These advertisements may be targeted to specific searches you conducted on the Site during earlier browsing sessions.
    </Typography>
    <Typography variant='body2'>
        If you would like more information about the information collection practices of third-party advertising companies and your choices about not having this information used by these companies or opt out of online preference marketing, visit Networkadvertising.org. Without cookies, however, you may not have access to certain features on our Site, including access to your profile/account and certain personalized content.
    </Typography>
    <Typography variant='body2'>
        You may also delete all cookies that are already on your computer’s hard drive by searching for files with “cookie” in it. This action could affect your subsequent visits to certain web sites, including our Site, by requiring that, for example, you enter you login name when you return to that web site.
    </Typography><br />
    <Typography variant='subtitle2'>
        How You Can Access or Change Your Personal Data
    </Typography>
    <Typography variant='body2'>
        Netcapital understands that you may want to change, access or delete your Personal Data. You may not do so by accessing your profile yourself or by contacting us via email. All data used in the operation of Netcapital becomes our sole property and can be used at our sole discretion. To protect your privacy and security, we may verify your identity before granting access or making changes to your Personal Data. For security reasons, and for other reasons at our sole discretion, Netcapital will have no obligation to respond to inquires with respect to Personal Data. If you have registered your profile on any of the services available on our Site, your e-mail address and password may be required in order to access your profile information.
    </Typography><br />
    <Typography variant='subtitle2'>
        Use of Plaid Inc.
    </Typography>
    <Typography variant='body2'>
        Netcapital uses Plaid Inc. (“Plaid”) to gather your data from financial institutions. By using our service, you grant Netcapital and Plaid the right, power, and authority to act on your behalf to access and transmit your personal and financial information from the relevant financial institution. You agree to your personal and financial information being transferred, stored, and processed by Plaid in accordance with the Plaid Privacy Policy.
    </Typography><br />
    <Typography variant='subtitle2'>
        Children
    </Typography>
    <Typography variant='body2'>
        Persons under the age of 18 are not eligible to use any services on our Site.
    </Typography><br />
    <Typography variant='subtitle2'>
        How To Contact Us
    </Typography>
    <Typography variant='body2'>
        Should you have any questions, concerns or comments, please contact us at privacy@netcapital.com.
    </Typography><br /><br /><br />
    <Typography variant='caption2'>
        Last Modified on February 22, 2018
    </Typography></>
  );
};

export default PrivacyPolicy;
