const styles = {
  AccordionLabel: {
    flexGrow: 1,
  },
  AccordionActions: {
    paddingRight: 2,
  },
};

export default styles;
