import { Button, Dialog, DialogActions, DialogContent, IconButton } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import Receipt from '../Receipt';
import styles from './styles';
import { transactionDetailsState } from '../../_state';
import { useCallback } from 'react';
import { useIsMobile } from '../../_helpers';
import { useResetRecoilState } from 'recoil';

const ReceiptModal = ({ offerKey, onClose }) => {
  const isMobile = useIsMobile();
  const resetTransactionDetailsState = useResetRecoilState(transactionDetailsState);
  const handleCloseModal = useCallback(() => {
    resetTransactionDetailsState();
    onClose();
  }, [onClose, resetTransactionDetailsState]);

  return (
    <Dialog
      open={Boolean(offerKey)}
      PaperProps={{style: styles.ReceiptModal}}
      fullWidth
      fullScreen={isMobile}
      maxWidth='lg'
    >
      <IconButton
        onClick={handleCloseModal}
        sx={styles.ModalCloseButton}
        aria-label='close'
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Receipt offerKey={offerKey} />
      </DialogContent>
      <DialogActions sx={styles.ReceiptActions}>
        <Button variant='outlined' onClick={handleCloseModal}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

ReceiptModal.defaultProps = {
  offerKey: '',
};

ReceiptModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  offerKey: PropTypes.string,
};

export default ReceiptModal;
