import { Button, Grid } from '@mui/material';

import AddIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import Field from '../../FormFields/Field';
import MilitaryOrGovernmentAffiliation from './MilitaryOrGovernmentAffiliation';
import PropTypes from 'prop-types';
import RadioGroup from '../../../netcapital-components/RadioGroup';
import { YES_NO } from '../../../static-info';
import _ from 'lodash';
import produce from 'immer';
import styles from './styles';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { v4 as uuidv4 } from 'uuid';

const HasMilitaryOrGovernmentAffiliations = ({ recoilState, label }) => {
  const [hasMilitaryOrGovernmentAffiliation, setHasMilitaryOrGovernmentAffiliation] = useRecoilState(recoilState.hasMilitaryOrGovernmentAffiliation);
  const [govtAffiliations, setGovtAffiliations] = useRecoilState(recoilState.militaryOrGovernmentAffiliation);

  const handleCreateGovtAffiliation = useCallback(() => setGovtAffiliations([...govtAffiliations, {_key: uuidv4()}]), [govtAffiliations, setGovtAffiliations]);

  const handleChangeGovtAffiliationAttribute = useCallback((index) => (attribute, newValue) => {
    const updatedValues = produce(govtAffiliations, (draft) => {
      _.set(draft[index], attribute, newValue);
    });
    setGovtAffiliations([...updatedValues]);
  }, [govtAffiliations, setGovtAffiliations]);

  const handleRemoveGovtAffiliation = useCallback((index) => () => {
    const ownersCopy = [...govtAffiliations];
    ownersCopy.splice(index, 1);
    setGovtAffiliations([...ownersCopy]);
  }, [govtAffiliations, setGovtAffiliations]);

  const handleChangeHasMilitaryOrGovernmentAffiliation = useCallback((e) => {
    if(e.target.value === 'true' && govtAffiliations?.length === 0){
      setHasMilitaryOrGovernmentAffiliation(true);
      handleCreateGovtAffiliation();
    } else if(e.target.value === 'false'){
      setHasMilitaryOrGovernmentAffiliation(false);
      setGovtAffiliations([]);
    } else {
      setHasMilitaryOrGovernmentAffiliation(e.target.value === 'true');
    }
  }, [handleCreateGovtAffiliation, govtAffiliations, setHasMilitaryOrGovernmentAffiliation, setGovtAffiliations]);

  return (
    <>
      <Grid item xs={12}>
        <Field label={label}>
          <RadioGroup
            options={YES_NO}
            value={hasMilitaryOrGovernmentAffiliation}
            onChange={handleChangeHasMilitaryOrGovernmentAffiliation}
            fieldName={'affiliations.hasMilitaryOrGovernmentAffiliation'}
          />
        </Field>
      </Grid>
      {hasMilitaryOrGovernmentAffiliation && (
        <Grid item container xs={12} rowSpacing={2} sx={styles.GovtAffiliationsContainer}>
          {govtAffiliations?.map((person, index) => (
            <Grid item xs={12} key={person._key}>
              <MilitaryOrGovernmentAffiliation
                person={person}
                onChange={handleChangeGovtAffiliationAttribute(index)}
                onRemove={handleRemoveGovtAffiliation(index)}
                fieldName={`affiliations.militaryOrGovernmentAffiliation[${index}]`}
              />
            </Grid>
          ))}
          <Button
            variant='outlined'
            color='success'
            size='small'
            onClick={handleCreateGovtAffiliation}
            startIcon={<AddIcon />}
          >
            Add another person
          </Button>
        </Grid>
      )}
    </>
  );
};

HasMilitaryOrGovernmentAffiliations.propTypes = {
  recoilState: PropTypes.shape({
    hasMilitaryOrGovernmentAffiliation: PropTypes.shape({key: PropTypes.string.isRequired}),
    militaryOrGovernmentAffiliation: PropTypes.shape({key: PropTypes.string.isRequired}),
  }).isRequired,
  label: PropTypes.string.isRequired,
};

export default HasMilitaryOrGovernmentAffiliations;
