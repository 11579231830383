import AddressInput from '../../netcapital-components/AddressInput';
import { Box } from '@mui/material';
import Field from './Field';
import PropTypes from 'prop-types';
import _ from 'lodash';
import produce from 'immer';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

const AddressField = (props) => {
  const {
    recoilState,
    label,
    requirementLabel,
    fieldNamePrefix,
    ...other
  } = props;
  const [address, setAddress] = useRecoilState(recoilState);
  const handleChangeAddress = useCallback((attribute, newValue) => {
    const addressObj = address || {};
    const updatedAddress = produce(addressObj, (draft) => {
      _.set(draft, attribute, newValue);
    });
    setAddress(updatedAddress);
  }, [setAddress, address]);

  return (
    <Field label={label ? label : 'Address (optional)'}>
      <Box name='GridMarginFix'>
        <AddressInput
          {...other}
          value={address || {}}
          onChange={handleChangeAddress}
          requirementLabel={requirementLabel}
          fieldNamePrefix={fieldNamePrefix}
        />
      </Box>
    </Field>
  );
};

AddressField.defaultProps = {
  label: 'Address *',
  requirementLabel: '',
  fieldNamePrefix: 'address',
};

AddressField.propTypes = {
  recoilState: PropTypes.shape({key: PropTypes.string.isRequired}).isRequired,
  label: PropTypes.string,
  requirementLabel: PropTypes.string,
  fieldNamePrefix: PropTypes.string,
};

export default AddressField;
