const styles = {
  RadioError: ({ palette }) => ({
    '&.MuiFormControlLabel-root': {
      color: palette.error.main,
    },
    '& .MuiButtonBase-root': {
      color: palette.error.main,
    }
  }),
};

export default styles;
