// skipcq: JS-C1003 - yup does not expose itself as an ES Module.
import * as Yup from 'yup';

import { VERIFICATION_DOC_OPTIONS } from '../../../../static-info';

const IdentityFilesSchema = Yup.object({
  verificationDocType: Yup.string()
    .oneOf(VERIFICATION_DOC_OPTIONS.map(option => option.value))
    .when(
      '$verificationDocType',
      ([verificationDocType], schema) => {
        if (!verificationDocType) {
          return schema.required('Required');
        }
        return schema;
      }
    ),
  verificationDocuments: Yup.object()
    .shape({
      SELFIE: Yup.string().required('Required'),
    })
    .when(
      '$verificationDocType',
      ([verificationDocType], schema) => {
        if (verificationDocType === 'LICENSE') {
          return Yup.object().shape({
            LICENSE: Yup.string()
              .when(
                '$verificationDocuments',
                ([verificationDocuments], innerSchema) => verificationDocuments.LICENSE ? innerSchema.notRequired() : innerSchema.required('Required')
              ),
            LICENSE_BACK: Yup.string()
              .when(
                '$verificationDocuments',
                ([verificationDocuments], innerSchema) => verificationDocuments.LICENSE_BACK ? innerSchema.notRequired() : innerSchema.required('Required')
              ),
          });
        }
        if (verificationDocType === 'ID_CARD') {
          return Yup.object().shape({
            ID_CARD: Yup.string()
              .when(
                '$verificationDocuments',
                ([verificationDocuments], innerSchema) => verificationDocuments.ID_CARD ? innerSchema.notRequired() : innerSchema.required('Required')
              ),
            ID_CARD_BACK: Yup.string()
              .when(
                '$verificationDocuments',
                ([verificationDocuments], innerSchema) => verificationDocuments.ID_CARD_BACK ? innerSchema.notRequired() : innerSchema.required('Required')
              ),
          });
        }
        if (verificationDocType === 'PASSPORT') {
          return Yup.object().shape({
            PASSPORT: Yup.string()
              .when(
                '$verificationDocuments',
                ([verificationDocuments], innerSchema) => verificationDocuments.PASSPORT ? innerSchema.notRequired() : innerSchema.required('Required')
              ),
          });
        }
        return schema;
      }
    )
});

export default IdentityFilesSchema;
