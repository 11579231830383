const styles = {
  NotificationPaper: ({ breakpoints }) => ({
    margin: '12px',
    [breakpoints.up('md')]: {
      margin: '32px',
    },
  }),
};

export default styles;
