import { Button, Grid, Typography } from '@mui/material';
import { TEN_MEGABYTES, VERIFICATION_DOC_FILE_TYPES, VERIFICATION_DOC_OPTIONS } from '../../../static-info';
import { useCallback, useState } from 'react';

import FileUpload from '../../../netcapital-components/FileUpload';
import PropTypes from 'prop-types';
import Selectbox from '../../../netcapital-components/Selectbox';
import { fetchNotificationResponseAttribute } from '../../../_state';
import styles from './styles';
import { useRecoilState } from 'recoil';
import { useSubmitDwollaKycDocument } from '../../../_actions/kyc-dwolla.actions';

const DwollaKycDocument = ({ onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [documentType, setDocumentType] = useRecoilState(fetchNotificationResponseAttribute('docType'));
  const [file, setFile] = useRecoilState(fetchNotificationResponseAttribute('file'));

  const handleChangeDocumentType = useCallback((e) => setDocumentType(e.target.value), [setDocumentType]);
  const [errorMessage, setErrorMessage] = useState();
  const {save, saving} = useSubmitDwollaKycDocument();

  const handleUpdateFile = useCallback(newFile => {
    setLoading(true);
    if (!newFile) {
      setFile(null);
      setLoading(false);
      return;
    }

    if (newFile.size > TEN_MEGABYTES) {
      setErrorMessage('File cannot be larger than 10 MB');
      setLoading(false);
      return;
    }

    setFile(newFile);
    setLoading(false);
    setErrorMessage('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const handleSubmit = useCallback(() => save(onSuccess), [save, onSuccess]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h4'>Document Needed for Identification</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>U.S. Financial laws require that all investors pass identity checks. Please upload one of the following documents.</Typography>
      </Grid>
      <Grid container item xs={12} spacing={4}>
        <Grid item xs={12} md={7}>
          <Typography fontWeight='fontWeightMedium'>Your document must conform to the following image requirements:</Typography>
          <ul>
            <li>Full color image</li>
            <li>All details on ID are legible</li>
            <li>ID is well lit, not blurry, no glare or shadows</li>
            <li>Image is captured directly above the document</li>
            <li>ID occupies most of the image</li>
          </ul>
          <Selectbox
            onChange={handleChangeDocumentType}
            options={VERIFICATION_DOC_OPTIONS}
            placeholder='Document Type'
            value={documentType || ''}
          />
        </Grid>
        <Grid item xs={12} md={5} sx={styles.CellImageUpload}>
          <FileUpload
            fileType={VERIFICATION_DOC_FILE_TYPES.contentTypes.join(',')}
            file={file}
            onChange={handleUpdateFile}
            loading={loading}
            disabled={!documentType}
            showPreview
            maxSize={TEN_MEGABYTES}
          />
          <Typography color='error.main' variant='body2'>{errorMessage}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant='contained'
          onClick={handleSubmit}
          disabled={!documentType || !file || saving}
        >Submit</Button>
      </Grid>
    </Grid>
  );
};

DwollaKycDocument.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default DwollaKycDocument;
