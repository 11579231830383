import { Avatar, Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import { companyCommunityStatsState, companyIsFollowedState, companyState, userState } from '../../_state';
import { useGetCompanyIsFollowed, usePutCompanyIsFollowed } from '../../_actions/company-is-followed.actions';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import { NumberFormat } from '../../_helpers';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { Link as RouterLink } from 'react-router-dom';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import styles from './styles';
import { useCallback } from 'react';
import { useGetCommunityStats } from '../../_actions/company-community-stats.actions';
import { useRecoilValue } from 'recoil';

const CompanyHeader = () => {
  const company = useRecoilValue(companyState);
  const user = useRecoilValue(userState);
  const {loaded: loadedIsFollowed} = useGetCompanyIsFollowed(user.entityKey, company.key);
  const isFollowed = useRecoilValue(companyIsFollowedState);
  useGetCommunityStats(company.key);
  const communityStats = useRecoilValue(companyCommunityStatsState);

  const {save, saving} = usePutCompanyIsFollowed(user.entityKey, company.key);
  const handleToggleIsFollowed = useCallback(() => {
    if (!saving) {
      save();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saving, isFollowed]);

  const numInvestors = (
    <Grid item xs={6} sm={4} md={5} xl={3}>
      <Stack direction='row' alignItems='center' spacing={1}>
        <PeopleAltOutlinedIcon color='primary' />
        <Typography variant='body2'>{NumberFormat.thousands(communityStats.investors)} Investors</Typography>
      </Stack>
    </Grid>
  );

  const numFollowers = (
    <Grid item xs={6} sm={4} md={5} xl={3}>
      <Stack direction='row' alignItems='center' spacing={1}>
        <VisibilityOutlinedIcon color='primary' />
        <Typography variant='body2'>{NumberFormat.thousands(communityStats?.followers)} Followers</Typography>
      </Stack>
    </Grid>
  );

  const followButton = (
    <Grid item xs={12} sm={4} md={5} lg={4} xl={3}>
      <Button
        onClick={handleToggleIsFollowed}
        variant='outlined'
        color={isFollowed ? 'gain' : 'primary'}
        disabled={!loadedIsFollowed || saving || isFollowed === undefined}
        sx={styles.FollowButton}
      >
        {isFollowed ? <CheckIcon sx={styles.CheckCircleIcon} /> : null}
        {isFollowed ? 'FOLLOWING' : 'FOLLOW'}
      </Button>
    </Grid>
  );

  return (
    <Grid container columnSpacing={3} rowSpacing={{xs: 3, lg: 4}} sx={styles.DisableDivider}>
      <Grid item xs={12}>
        <Stack direction='row' spacing={2}>
          <IconButton to='/marketplace' component={RouterLink}>
            <ArrowBackIcon color='primary' />
          </IconButton>
          <Avatar src={company.logoUrl} />
          <Typography variant='h1'>{company.doingBusinessAs}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} container columnSpacing={3} rowSpacing={{xs: 3, lg: 4}} alignItems='center' sx={styles.CommunityStats}>
        {numInvestors}
        {numFollowers}
        {followButton}
      </Grid>
    </Grid>
  );
};

export default CompanyHeader;
