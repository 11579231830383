import { Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';

import { NumberFormat } from '../../_helpers';
import ReceiptLineItem from './ReceiptLineItem';
import ReceiptLink from './ReceiptLink';
import styles from './styles';

const ReceiptsList = ({ transactionDetails }) => {
  return (
    <TableContainer>
      <Table size={'small'} sx={styles.LineItemContainer}>
        <TableBody>
          {transactionDetails?.transactions?.map((tx) => (
            <ReceiptLineItem
              label={`${NumberFormat.thousands(tx.quantity)} Shares`}
              value={<ReceiptLink receiptUrl={tx.receiptUrl} />}
              key={tx._key}
            />
          ))}
          {(!transactionDetails?.transactions || transactionDetails?.transactions?.length === 0) && (
            <TableRow><TableCell><Typography fontStyle={'italic'}>None at this time</Typography></TableCell></TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReceiptsList;
