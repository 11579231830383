import { Alert, Button, Stack, Typography } from '@mui/material';
import { useCallback, useState } from 'react';

import styles from './styles';
import { useRequestPasswordChange } from '../../_actions/user.actions';

const ChangePassword = () => {
  const {save, saving: waitingForPasswordChangeResponse} = useRequestPasswordChange();
  const [didSubmit, setDidSubmit] = useState(false);
  const handleSuccess = useCallback(() => setDidSubmit(true), []);
  const handlePasswordReset = useCallback(() =>
    save(handleSuccess),
  [save, handleSuccess]);

  return (
    <Stack spacing={2} sx={styles.Container}>
      <Typography variant='h5'>Change Password</Typography>
      <Button
        disabled={waitingForPasswordChangeResponse || didSubmit}
        onClick={handlePasswordReset}
        variant='contained'
        size='large'
      >
        Change Password
      </Button>
      {didSubmit && <Alert severity='info'>Please check your email account for instructions to confirm this change.</Alert>}
    </Stack>
  );
};

export default ChangePassword;
