import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import { useCallback, useContext, useState } from 'react';

import AccountFormContext from '../Form/context';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KycDisclosures from './disclosures';
import PropTypes from 'prop-types';
import StepAccordion from './accordion';

const ReviewAndSubmit = ({ holdingEntityKey }) => {
  const [disclosuresExpanded, setDisclosuresExpanded] = useState(true);
  const toggleDisclosuresAccordion = useCallback(() => setDisclosuresExpanded(!disclosuresExpanded), [disclosuresExpanded]);
  const {steps} = useContext(AccountFormContext);

  return (
    <Grid item container rowSpacing={4} columnSpacing={2}>
      <Grid item xs={12}>
        {Object
          .keys(steps || {})
          .filter((formId, index) => index !== Object.keys(steps || {}).length-1)
          .map(formId => (
            <StepAccordion
              holdingEntityKey={holdingEntityKey}
              label={steps[formId].label}
              formComponent={steps[formId].component}
              formId={formId}
              key={formId}
            />
          ))
        }
        <Accordion expanded={disclosuresExpanded}>
          <AccordionSummary onClick={toggleDisclosuresAccordion} expandIcon={<ExpandMoreIcon color='primary' />}>
            <Typography variant='h5'>Disclosures</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <KycDisclosures holdingEntityKey={holdingEntityKey} />
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

ReviewAndSubmit.defaultProps = {
  holdingEntityKey: '',
};

ReviewAndSubmit.propTypes = {
  holdingEntityKey: PropTypes.string,
};

export default ReviewAndSubmit;
