import { useCallback } from 'react';
import { useFetchWrapper } from '../_helpers';

const generateUuid = (char) => {
  const rand = Math.random()*16|0;
  const id = char === 'x' ? rand : (rand&0x3|0x8);
  return id.toString(16);
};

const uuid = () => {
  // RFC4122 version 4 compliant uniqe ids
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, generateUuid);
};

export const useGroundfloorAnalytics = () => {
  const fetchWrapper = useFetchWrapper();

  const track = useCallback((action, data={}) => {
    const controller = new AbortController();
    const signal = controller.signal;
    const abort = () => controller.abort();

    const isPublic = true; // URL is not public, but we do this so the legacy web app can assert privileges via cookie/with-credentials
    const url = `${process.env.REACT_APP_GROUNDFLOOR_ANALYTICS_HOST}/track`;
    const body = {
      action,
      data,
      path: window.location.href,
      timezoneOffset: new Date().getTimezoneOffset(),
      uuid: uuid(),
      referrer: document.referrer
    };
    fetchWrapper.post(url, body, signal, isPublic).catch();

    return abort;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { track };
};
