// skipcq: JS-C1003 - yup does not expose itself as an ES Module.
import * as Yup from 'yup';

import { PERSON_EMPLOYMENT_STATUSES, RESIDENCY_STATUSES } from '../../../../static-info';

import AddressSchema from '../../../../netcapital-components/AddressInput/schema';

const PersonalInfoSchema = Yup.object({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  address: AddressSchema,
  dateOfBirth: Yup.string().required('Required'),
  residencyStatus: Yup.string()
    .oneOf(Object.keys(RESIDENCY_STATUSES)).required('Required'),
  citizenship: Yup.string().required('Required'),
  secureTin: Yup.object()
    .when('residencyStatus', {
      is: 'OTHER',
      then: (schema) => schema.notRequired(),
      otherwise: () => Yup.object().shape({
        key: Yup.string(),
        tin: Yup.string()
          .when('key', {
            is: (key) => Boolean(key),
            then: (schema) => schema.notRequired(),
            otherwise: (schema) => schema.required('Required')
          }),
      }).required('Required')
    }),
  employment: Yup.object({
    status: Yup.string()
      .oneOf(PERSON_EMPLOYMENT_STATUSES.map(option => option.value)).required('Required'),
    employer: Yup.object()
      .when('status', {
        is: (status) => ['EMPLOYED', 'SELF_EMPLOYED'].includes(status),
        then: () => Yup.object().shape({
          name: Yup.string().required('Required'),
          occupation: Yup.string().required('Required'),
          title: Yup.string().required('Required'),
          address: Yup.object({
            streetLine1: Yup.string(),
            streetLine2: Yup.string(),
            streetLine3: Yup.string(),
            city: Yup.string(),
            state: Yup.string(), // TODO: state should be conditionally required by value of Country
            zipCode: Yup.string(),
            country: Yup.string(),
            countryCode: Yup.string(),
            phone: Yup.string(),
          }),
        })
      })
  }),
});

export default PersonalInfoSchema;
