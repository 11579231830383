const styles = {
  Container: {
    position: 'relative',
    display: 'inline-flex',
  },
  CircleContainer: {
    display: 'grid',
    '> .MuiCircularProgress-root': {
      gridColumn: 1,
      gridRow: 1,
    },
  },
  BackgroundCircle: {
    color: 'grey.300',
  },
  Label: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default styles;
