import { atom } from 'recoil';

const companyTeamState = atom({
  key: 'companyTeamState',
  default: [],
});

export {
  companyTeamState,
};
