import { Box } from '@mui/material';
import KycTradeModal from '../../components/KycTradeModal';
import { Outlet } from 'react-router-dom';
import ReceiptLayout from '../Receipt';
import styles from './styles';

const AppContent = () => {

  return (
    <>
      <Box sx={styles.FlexFullWidth}>
        <Box
          component='main'
          sx={styles.RouteContent}
          name='RouteContent'
        >
          <Outlet />
        </Box>
      </Box>
      <KycTradeModal />
      <ReceiptLayout />
    </>
  );
};

export default AppContent;
