import CompanyHeader from '../../components/CompanyHeader';
import CompanyTabs from '../../components/CompanyTabs';
import { Grid } from '@mui/material';
import Loading from '../../components/Loading';
import NotFoundPage from '../Error/NotFound';
import OfferBar from '../../components/OfferBar';
import OfferButtons from '../../components/OfferButtons';
import { companyState } from '../../_state';
import styles from './styles';
import { useGetCompany } from '../../_actions/company.actions';
import { useIsDesktop } from '../../_helpers';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

const CompanyPage = () => {
  const { listingCode } = useParams();
  const { loaded } = useGetCompany(listingCode);
  const company = useRecoilValue(companyState);
  const isDesktop = useIsDesktop();

  if (!loaded) {
    return <Loading loading={!loaded} />;
  }

  if (loaded && Object.keys(company || {}).length === 0) {
    return <NotFoundPage />;
  }

  return (
    <>
      <Grid container spacing={{xs: 3, md:4, xl: 6}} name='CompanyPage' sx={styles.CompanyPageContainer}>
        <Grid item xs={12} md={7}>
          <CompanyHeader />
        </Grid>
        <Grid item xs={12} md={5}>
          <OfferButtons />
        </Grid>
        <Grid item xs={12}>
          <CompanyTabs />
        </Grid>
      </Grid>
      {!isDesktop && <OfferBar />}
    </>
  );
};

export default CompanyPage;
