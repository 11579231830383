const API = `${process.env.REACT_APP_API_HOST}/v2`;

export const API_Legacy = process.env.REACT_APP_API_HOST;

export const APIs = {
  Auth: `${API}/auth`,
  Listings: `${API}/listings`,
  Marketplace: `${API}/marketplace`,
  Offerings: `${API}/offerings`,
  Users: `${API}/users`,
};

export const AuthAPIVerifyURL = `${APIs.Auth}/verify`;
export const AuthAPILoginURL = (redirectUrl) => {
  return `${APIs.Auth}/login${redirectUrl ? `?returnTo=${redirectUrl}` : ''}`;
};

export const APIListingURL = (listingKey) => {
  return `${APIs.Listings}/${listingKey}`;
};

export const APIInvestorURL = (entityKey) => {
  return `${APIs.Marketplace}/investors/${entityKey}`;
};

export const APIUserURL = (entityKey) => {
  return `${APIs.Users}/${entityKey}`;
};

export const DEFAULT_QUERY_PARAMS = {
  page: 1,
  limit: 10,
  pageCount: 1,
  totalCount: 0,
  search: '',
  companyFilter: '',
  orderBy: '',
};
