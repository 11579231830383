const styles = {
  DisableDivider: {
    '+ .MuiDivider-root': {
      display: 'none',
    },
  },
  PriceAndPosition: {
    textAlign: {
      xs: 'center',
      md: 'left',
    },
  },
};

export default styles;
