const styles = {
  ReceiptModal: {
    borderRadius: '0.625rem',
    height: '100%',
  },
  ModalCloseButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: 'primary',
  },
  ReceiptActions: {
    padding: '0.938rem',
  }
};

export default styles;
