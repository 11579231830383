const styles = {
  NoWrap: {
    whiteSpace: 'nowrap'
  },
  DetailsContainer: {
    padding: 2,
  },
};

export default styles;
