import { IconButton, Stack } from '@mui/material';

import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PropTypes from 'prop-types';
import TwitterIcon from '@mui/icons-material/Twitter';
import { formatSocialLinks } from './utils';

const SocialLinks = ({ links }) => {
  if(!links.facebook && !links.linkedIn && !links.twitter){
    return null;
  }
  return (
    <Stack direction='row' spacing={2} justifyContent='center'>
      {(links.facebook && links.facebook !== 'NUL') && (
        <IconButton
          href={formatSocialLinks(links.facebook)}
          color='primary'
          target='_blank'
          rel='noopener'
          disableRipple
          edge='start'
        >
          <FacebookIcon />
        </IconButton>
      )}
      {(links.linkedIn && links.linkedIn !== 'NUL') && (
        <IconButton
          href={formatSocialLinks(links.linkedIn)}
          color='primary'
          target='_blank'
          rel='noopener'
          disableRipple
          edge='start'
        >
          <LinkedInIcon />
        </IconButton>
      )}
      {(links.twitter && links.twitter !== 'NUL') && (
        <IconButton
          href={formatSocialLinks(links.twitter)}
          color='primary'
          target='_blank'
          rel='noopener'
          disableRipple
          edge='start'
        >
          <TwitterIcon />
        </IconButton>
      )}
    </Stack>
  );
};

SocialLinks.defaultProps = {
  links: {
    facebook: '',
    linkedIn: '',
    twitter: '',
  },
};

SocialLinks.propTypes = {
  links: PropTypes.shape({
    facebook: PropTypes.string,
    linkedIn: PropTypes.string,
    twitter: PropTypes.string,
  }),
};

export default SocialLinks;
