import { Avatar, Box, Stack, Typography } from '@mui/material';
import { NumberFormat, pluralize } from '../../_helpers';
import { companyState, offerState } from '../../_state';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ConfirmationDialog from '../../netcapital-components/ConfirmationDialog';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

const OfferSuccess = ({ open, onReset }) => {
  const company = useRecoilValue(companyState);
  const offer = useRecoilValue(offerState);
  const resetOffer = useResetRecoilState(offerState);
  const goBack = useCallback(() => {
    resetOffer();
    onReset();
  }, [resetOffer, onReset]);

  return (
    <ConfirmationDialog
      open={open}
      ctaConfirm={'Close'}
      onConfirm={goBack}
      canCancel={false}
      title={'Congratulations!'}
      titleIcon={<CheckOutlinedIcon color='success' />}
      content={
        <Stack spacing={3}>
          <Typography textAlign='center' component='div'>
            <Stack spacing={1}>
              <Typography>You have successfully placed an order to {offer.offerType === 'BID' ? 'buy' : 'sell'}</Typography>
              <Box>
                <Typography fontWeight='medium' component='span'>{NumberFormat.thousands(offer.quantity)} {pluralize('share', offer.quantity)}</Typography> at <Typography fontWeight='medium' component='span'>{NumberFormat.cents(offer.pricePerUnit)}</Typography> of
              </Box>
            </Stack>
          </Typography>
          <Stack direction='row' justifyContent='center' alignItems='center' spacing={1}>
            <Avatar src={company.logoUrl} />
            <Typography variant='h3'>{company.doingBusinessAs}</Typography>
          </Stack>
          <Typography textAlign='center'>We will update you if anyone accepts your offer.</Typography>
        </Stack>
      }
    />
  );
};

OfferSuccess.propTypes = {
  onReset: PropTypes.func.isRequired,
};

export default OfferSuccess;
