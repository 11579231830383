import { DialogContent, Typography } from '@mui/material';

import { CircularLoading } from '../../netcapital-components/CircularLoading';
import Dialog from '@mui/material/Dialog';
import PropTypes from 'prop-types';
import styles from './styles';

function Loading({ loading, color, message }) {
  return (
    <Dialog open={loading}
      name="Netcapital-Loading"
      fullWidth
      scroll="body"
      maxWidth={'sm'}
      sx={styles.Root}
      PaperProps={{
        elevation: 0,
        sx: styles.DialogPaper
      }}>
      <DialogContent>
        <Typography color={color} align={'center'} component='div'>
          <CircularLoading />
          <p>{message}</p>
        </Typography>
      </DialogContent>
    </Dialog>
  );
}

Loading.defaultProps = {
  color: 'secondary',
  message: 'Loading...',
};

Loading.propTypes = {
  color: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

export default Loading;
