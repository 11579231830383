import { atom, selector, selectorFamily } from 'recoil';

import Session from '../_session';

const investorStateSessionStorageSync = storageKey => ({onSet}) => {
  onSet((newValue, _, isReset) => {
    isReset
      ? delete sessionStorage[storageKey].investor
      : Session.set({ investor: newValue });
  });
};

const investorState = atom({
  key: 'investorState',
  default: Session.get('investor'),
  effects: [
    investorStateSessionStorageSync(Session.STORAGE_KEY),
  ]
});

const investorPortfolioGraphState = atom({
  key: 'investorPortfolioGraphState',
  default: []
});

const investorPortfolioValueState = atom({
  key: 'investorPortfolioValueState',
  default: []
});

const securitiesState = atom({
  key: 'securitiesState',
  default: []
});

const fetchSecurities = selectorFamily({
  key: 'fetchSecurities',
  get: field => ({get}) => get(securitiesState)[field],
  set: field => ({set}, newValue) =>
    set(securitiesState, prevState => ({...prevState, [field]: newValue})),
});

const securitiesByCompanyState = atom({
  key: 'securitiesByCompanyState',
  default: []
});

const availableSharesByCompanyState = selector({
  key: 'availableSharesByCompanyState',
  get: ({get}) => get(securitiesByCompanyState)?.securities?.availableQuantity,
  set: ({get, set}, newValue) => {
    const prevState = get(securitiesByCompanyState);
    set(securitiesByCompanyState, {...prevState, securities: {...prevState.securities, availableQuantity: newValue }});
  }
});

const transactionDetailsState = atom({
  key: 'transactionDetailsState',
  default: []
});

const transactionsState = atom({
  key: 'transactionsState',
  default: []
});

const fetchTransactions = selectorFamily({
  key: 'fetchTransactions',
  get: field => ({get}) => get(transactionsState)[field],
  set: field => ({set}, newValue) =>
    set(transactionsState, prevState => ({...prevState, [field]: newValue})),
});

const transactionsByListingState = atom({
  key: 'transactionsByListingState',
  default: []
});

export {
  investorState,
  securitiesState,
  fetchSecurities,
  securitiesByCompanyState,
  availableSharesByCompanyState,
  transactionDetailsState,
  transactionsState,
  fetchTransactions,
  transactionsByListingState,
  investorPortfolioGraphState,
  investorPortfolioValueState
};
