import { atom, selector, selectorFamily } from 'recoil';

const pendingOffersState = atom({
  key: 'pendingOffersState',
  default: []
});

const pendingAskOffersState = selector({
  key: 'pendingAskOffersState',
  get: ({get}) => {
    const pendingOffers = get(pendingOffersState);
    return pendingOffers.filter((offer) => offer.type === 'ASK');
  }
});

const pendingBidOffersState = selector({
  key: 'pendingBidOffersState',
  get: ({get}) => {
    const pendingOffers = get(pendingOffersState);
    return pendingOffers.filter((offer) => offer.type === 'BID');
  }
});

const fetchPendingOffersAttribute = selectorFamily({
  key: 'fetchPendingOffersAttribute',
  get: attribute => ({get}) => get(pendingOffersState)[attribute],
  set: attribute => ({set}, newValue) =>
    set(pendingOffersState, prevState => ({...prevState, [attribute]: newValue})),
});

export {
  pendingOffersState,
  pendingAskOffersState,
  pendingBidOffersState,
  fetchPendingOffersAttribute,
};
