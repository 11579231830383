import { NumberFormat } from '../../_helpers';

export const formatVerbiageByTransactionAttributes = (market, type, status) => {
  const actions = {
    'ASK': {
      'PUBLISHED': 'Selling',
      'FULFILLED': market === 'ADMINISTRATIVE_TRANSFER' ? 'Transferred' : market === 'CORPORATE_ACTION' ? 'Action Taken' : 'Sold',
      'FAILED': 'Sell',
    },
    'BID': {
      'PUBLISHED': 'Buying',
      'FULFILLED': market === 'ADMINISTRATIVE_TRANSFER' ? 'Transferred' : market === 'CORPORATE_ACTION' ? 'Action Taken' : 'Bought',
      'FAILED': 'Buy',
    },
  };
  return actions?.[type]?.[status] || '';
};

export const getColoringByTransactionAttributes = (market, type, status) => {
  if (market === 'ADMINISTRATIVE_TRANSFER' || market === 'CORPORATE_ACTION')
    return 'common.black';
  if (status === 'FAILED')
    return 'grey.500';
  if (type === 'BID')
    return 'gain.main';
  if (type === 'ASK')
    return 'loss.main';

  return 'common.black';
};

export const formatConfirmModalDescription = (transaction) => {
  const descriptions = {
    'ASK': {
      'SECONDARY': 'Are you sure you want to cancel this secondary ask offer?' //To-do: Language TBD
    },
    'BID': {
      'PRIMARY': `Your commitment of ${NumberFormat.cents(transaction.totalValue)} in ${transaction.listing.doingBusinessAs} will be withdrawn. If your payment has already been processed, you will receive a full refund for the amount you invested.`,
      'SECONDARY': 'Are you sure you want to cancel this secondary bid offer?' //To-do: Language TBD
    }
  };

  return descriptions?.[transaction.type]?.[transaction.market] || '';
};

export const formatSuccessModalDescription = (transaction) => {
  const descriptions = {
    'ASK': {
      'SECONDARY': 'This secondary ask offer has been successfully cancelled.' //To-do: Language TBD
    },
    'BID': {
      'PRIMARY': `Your commitment of ${NumberFormat.cents(transaction.totalValue)} in ${transaction.listing.doingBusinessAs} has successfully been withdrawn. If your payment has already been processed, you will receive a full refund for the amount you invested. We will send you a confirmation email with more information.`,
      'SECONDARY': 'This secondary bid offer has been successfully cancelled.' //To-do: Language TBD
    }
  };

  return descriptions?.[transaction.type]?.[transaction.market] || '';
};
