import { Box, Button } from '@mui/material';

import Carousel from '../../netcapital-components/Carousel';
import styles from './styles';
import { useCallback } from 'react';

const filters = [
  {
    id: 'active',
    label: 'Active'
  },
  {
    id: 'closed',
    label: 'Inactive'
  },
  {
    id: 'disabled',
    label: 'Not Available'
  },
  {
    id: 'acquired',
    label: 'Exits'
  },
];

const SecuritiesFilters = ({ setActiveFilter, activeFilter }) => {
  const handleClickFilter = useCallback((e) => {
    setActiveFilter(e.currentTarget.id);
  },[setActiveFilter]);

  return (
    <Box sx={styles.FiltersBox}>
      <Carousel>
        {filters.map(filter => (
          <Button
            key={filter.id}
            id={filter.id}
            onClick={handleClickFilter}
            variant={activeFilter === filter.id ? 'contained' : 'outlined'}
            size='small'
            sx={styles.FiltersButtons}
          >
            {filter.label}
          </Button>
        ))}
      </Carousel>
    </Box>
  );
};

export default SecuritiesFilters;
