import { Badge, Box, Drawer, IconButton, Typography } from '@mui/material';
import { notificationsState, userState } from '../../../_state';
import { useCallback, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import NotificationsList from '../../../components/NotificationsList';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import styles from './styles';
import { useGetNotifications } from '../../../_actions/notifications.actions';
import { useRecoilValue } from 'recoil';

const NotificationsMenu = () => {
  const [open, setOpen] = useState(false);
  const toggleNotification = useCallback(() => setOpen(!open), [open]);
  const user = useRecoilValue(userState);
  const notifications = useRecoilValue(notificationsState);
  useGetNotifications(user?.entityKey);

  const toggleDrawer = useCallback((event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(!open);
  }, [open]);

  return (
    <Badge
      badgeContent={notifications?.length}
      invisible={!notifications || notifications?.length === 0}
      color='loss'
      overlap='circular'
    >
      <IconButton color='primary' onClick={toggleNotification}>
        <NotificationsNoneOutlinedIcon />
      </IconButton>
      <Drawer
        anchor='right'
        open={open}
        onClose={toggleDrawer}
      >
        <Box sx={styles.Title}>
          <Typography variant='h5'>
            Notifications
          </Typography>
          <IconButton
            color='primary'
            onClick={toggleNotification}
            sx={styles.Close}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <NotificationsList toggleDrawer={toggleDrawer} />
      </Drawer>
    </Badge>
  );
};

export default NotificationsMenu;
