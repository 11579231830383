import { atom } from 'recoil';

const companyIsFollowedState = atom({
  key: 'companyIsFollowedState',
  default: undefined,
});

export {
  companyIsFollowedState,
};
