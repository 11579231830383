import { ReactComponent as InitialLogo } from './netcapital-initial.svg';
import { ReactComponent as Logo } from './netcapital-logo.svg';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

const NetcapitalLogo = (props) => {
  const { color, initial, ...other } = props;

  return (
    <Typography {...other} color={color} component='div'>
      {initial ? <InitialLogo /> : <Logo />}
    </Typography>
  );
};

NetcapitalLogo.defaultProps = {
  color: 'primary',
  initial: false,
};

NetcapitalLogo.propTypes = {
  color: PropTypes.string,
  initial: PropTypes.bool,
};

export default NetcapitalLogo;
