import { Pagination as MUIPagination, Stack, Typography } from '@mui/material';
import { useCallback, useState } from 'react';

import PropTypes from 'prop-types';
import Selectbox from '../Selectbox';
import styles from './styles';

const ITEMS_PER_PAGE = [
  {
    value: 10,
    label: '10',
  },
  {
    value: 25,
    label: '25',
  },
  {
    value: 50,
    label: '50',
  },
];

const Pagination = (props) => {
  const {
    itemsPerPage: defaultItemsPerPage,
    onChangeItemsPerPage,
    onChangePage,
    totalPages,
    ...other
  } = props;

  const [itemsPerPage, setItemsPerPage] = useState(defaultItemsPerPage);
  const handleChangeItemsPerPage = useCallback((e) => {
    setItemsPerPage(e.target.value);
    onChangeItemsPerPage(e.target.value);
  }, [onChangeItemsPerPage]);


  const [page, setPage] = useState(1);
  const handleChangePage = useCallback((e, newValue) => {
    setPage(newValue);
    onChangePage(newValue);
  }, [onChangePage]);

  return (
    <Stack
      direction={{xs: 'column-reverse', sm: 'row'}}
      spacing={{xs: 2, sm: 4}}
      justifyContent={'right'}
      alignItems={'center'}
      name='PaginationContainer'
    >
      <Stack direction='row' spacing={1} alignItems={'center'}>
        <Typography variant='caption' sx={styles.ItemsPerPageLabel}>Rows per page: </Typography>
        <Selectbox
          value={itemsPerPage}
          options={ITEMS_PER_PAGE}
          onChange={handleChangeItemsPerPage}
          sx={styles.ItemsPerPage}
        />
      </Stack>
      {totalPages > 1 && (
        <MUIPagination
          page={page}
          count={totalPages}
          onChange={handleChangePage}
          {...other}
        />
      )}
    </Stack>
  );
};

Pagination.defaultProps = {
  itemsPerPage: ITEMS_PER_PAGE[0].value,
  onChangeItemsPerPage: () => { return; },
  onChangePage: () => { return; },
  totalPages: 1,
};

Pagination.propTypes = {
  itemsPerPage: PropTypes.number,
  onChangeItemsPerPage: PropTypes.func,
  onChangePage: PropTypes.func,
  totalPages: PropTypes.number,
};

export default Pagination;
