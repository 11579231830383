import { useGetHoldingEntityInfo, useSaveHoldingEntityInfo } from '../../../../_actions/holding-entities.actions';

import AccountFormActions from '../../Form/actions';
import CircularLoading from '../../../../netcapital-components/CircularLoading';
import FormContext from '../../../../netcapital-components/FormValidation/FormContext';
import HoldingEntityInfoFields from './fields';
import HoldingEntityInfoSchema from './schema';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import accountFormStyles from '../../Form/styles';
import { holdingEntityInfoState } from '../../../../_state';
import { useFormValidation } from '../../../../netcapital-components/FormValidation';
import { useSaveAccountForm } from '../../Form/hooks';

const HoldingEntityInfo = ({ holdingEntityKey, disabled }) => {
  const { loaded } = useGetHoldingEntityInfo(holdingEntityKey);
  const {save, saving} = useSaveHoldingEntityInfo(holdingEntityKey);
  const {validate, validateField, errors, removeError} = useFormValidation(holdingEntityInfoState, HoldingEntityInfoSchema);
  const {saveAccountForm} = useSaveAccountForm(save, validate);

  if (!loaded) return <CircularLoading />;

  return (
    <FormContext.Provider value={{validateField, errors, removeError}}>
      <Stack spacing={3} sx={accountFormStyles.FormContentWell}>
        <HoldingEntityInfoFields
          holdingEntityKey={holdingEntityKey}
          disabled={disabled}
        />
        <AccountFormActions
          onSubmit={saveAccountForm}
          isSubmitting={saving}
        />
      </Stack>
    </FormContext.Provider>
  );
};

HoldingEntityInfo.defaultProps = {
  disabled: false,
};

HoldingEntityInfo.propTypes = {
  holdingEntityKey: PropTypes.string.isRequired,
};

export default HoldingEntityInfo;
