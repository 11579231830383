import { Box, List } from '@mui/material';

import Footer from '../Footer';
import MenuItems from './items';
import styles from './styles';
const MainMenuContent = ({ isMobile, handleMobileMenuToggle }) => {
  return (
    <>
      <List sx={[styles.MainMenuContainer, styles.MainMenuContent]}>
        <MenuItems isMobile={isMobile} handleMobileMenuToggle={handleMobileMenuToggle} />
      </List>
      <Box sx={styles.MainMenuContainer}>
        <Footer />
      </Box>
    </>
  );
};

export default MainMenuContent;
