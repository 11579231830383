import { Stack, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useCallback, useContext, useState } from 'react';

import FundingHistoryQueryContext from './context';
import SortIcon from '../../../SortIcon';
import styles from '../styles';

const COLUMNS = [
  {
    label: 'Close Date',
    // sortId: 'endDate',
  },
  {
    label: 'Average Share Price',
    // sortId: 'priceAverage',
  },
  {
    label: 'Raise Goal',
    // sortId: '',
  },
  {
    label: 'Amount Raised',
    // sortId: '',
  },
  {
    label: 'Security Type',
    // sortId: 'securityType',
  },
  {
    label: 'Status',
    // sortId: 'offerStatus',
  },
  {
    label: 'Exemption',
    // sortId: 'exemption',
  },
];

const FundingHistoryHeader = () => {
  const {queryParams, setQueryParams} = useContext(FundingHistoryQueryContext);
  const [sortByColLabel, setSortBy] = useState('Close Date');
  const [sortDirection, setSortDirection] = useState('desc');
  const handleSort = useCallback((colLabel) => () => {
    const colSortId = COLUMNS.find(col => col.label === colLabel)?.sortId;
    if (colSortId) {
      setSortBy(colLabel);
      const newSortDirection = sortDirection === 'desc' ? 'asc' : 'desc';
      setSortDirection(newSortDirection);
      setQueryParams({
        ...queryParams,
        orderby: `${colSortId}:${newSortDirection}`
      });
    }
  }, [queryParams, setQueryParams, sortDirection]);

  return (
    <TableHead>
      <TableRow>
        {COLUMNS.map(column => (
          <TableCell key={column.label}>
            <Stack spacing={1} direction='row'>
              <Typography
                onClick={handleSort(column.label)}
                variant='body2'
                color='primary'
                fontWeight='fontWeightMedium'
                sx={styles.NoWrap}
              >
                {column.label}
              </Typography>
              {sortByColLabel === column.label && <SortIcon direction={sortDirection} />}
            </Stack>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default FundingHistoryHeader;
