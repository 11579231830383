import { atom, selectorFamily } from 'recoil';

const offerState = atom({
  key: 'offerState',
  default: {
    offerType: 'BID',
    quantity: 0,
    pricePerUnit: 0
  }
});

const fetchOfferAttribute = selectorFamily({
  key: 'fetchOfferAttribute',
  get: attribute => ({get}) => get(offerState)[attribute],
  set: attribute => ({set}, newValue) =>
    set(offerState, prevState => ({...prevState, [attribute]: newValue})),
});

export {
  offerState,
  fetchOfferAttribute,
};
