import { Stack, Typography } from '@mui/material';

import PropTypes from 'prop-types';

const Field = ({ label, children }) => {
  return (
    <Stack spacing={2}>
      <Typography variant='caption' component='div'>{label}</Typography>
      {children}
    </Stack>
  );
};

Field.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.node.isRequired,
};

export default Field;
