import { Alert, AlertTitle, Button } from '@mui/material';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles';

const notification = ({ title, severity, description, ctaText, onAction, onClose}) => {
  return (
    <Alert severity={severity} onClose={onClose}>
      {title && <AlertTitle sx={{ fontWeight: 500 }}>{title}</AlertTitle>}
      {description}
      {ctaText && <Button variant="text" size="small" color="text" sx={styles.cta}
        onClick={onAction}
        endIcon={<ArrowForwardIcon fontSize='1.25rem' />}>{ctaText}</Button>
      }
    </Alert>
  );
};

export const Notification = React.memo(notification);

Notification.defaultProps = {
  severity: 'error',
  description: '',
  onAction: null,
  onClose: null,
  ctaText: '',
  title: ''
};

Notification.propTypes = {
  severity: PropTypes.oneOf(['error','warning','success','info']),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onClose: PropTypes.func,
  onAction: PropTypes.func,
  ctaText: PropTypes.string,
  title: PropTypes.string.isRequired
};


export default Notification;
